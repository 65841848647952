import { GET_CMS_CONTENT, GET_CMS_CONTENT_FAILED } from "../content/contentTypes";
import { CHECK_AUTH_STATUS } from "../auth/authTypes";
import { SpinnerState } from "@typings/index";

const initialState: SpinnerState = {
    showFullpageSpinner: true,
    showSignUpSpinner: true,
    showDiscoverpageSpinner: true,
};

function spinnerReducer(state = initialState, action: any): any {
    if (typeof window === "undefined") return state;

    switch (action.type) {
        // RESET
        case CHECK_AUTH_STATUS:
        case GET_CMS_CONTENT:
            return {
                ...state,
                showFullpageSpinner: true,
                showSignUpSpinner: true,
                showDiscoverpageSpinner: true,
            };
        case GET_CMS_CONTENT_FAILED:
            return {
                ...state,
                showSignUpSpinner: false,
            };

        default:
            return state;
    }
}

export default spinnerReducer;
