import { Dispatch, ReactElement, SetStateAction, useEffect, useRef, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import strings from "@locale/localization";
import { PodmeColor } from "@typings/index";
import { useDisableScroll } from "../../../hooks";
import HamburgerButton from "@components/HamburgerButton/HamburgerButton";
import { MigrationBanner } from "@containers/Migration";
import SchibstedDataControllerBanner from "./SchibstedDataControllerBanner";
import SlideMenu from "./SlideMenu";
import { Link } from "react-router-dom";
import useRefDimensions from "app/hooks/useRefDimensions";

interface MobileHeaderProps {
    isAuthenticated: boolean;
    username: string;
    loginMethod: string;
    hasSubscription: boolean;
    logoutUser: () => any;
    showSchibstedBanner: boolean;
    scrollingUp: boolean;
    setNavBarHeight: Dispatch<SetStateAction<number>>;
}

export default function MobileHeader({
    isAuthenticated,
    username,
    loginMethod,
    hasSubscription,
    logoutUser,
    showSchibstedBanner,
    scrollingUp,
    setNavBarHeight,
    ...rest
}: MobileHeaderProps): ReactElement {
    const [isActive, setIsActive] = useState<boolean>(false);

    useDisableScroll(isActive);

    const bannersRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);

    const bannersHeight = useRefDimensions(bannersRef).height;
    const headerHeight = useRefDimensions(headerRef).height;

    useEffect(() => {
        setNavBarHeight(bannersHeight + headerHeight);
    }, [bannersHeight, headerHeight, bannersRef.current, headerRef.current]);

    const locale = strings.routes.locale;

    return (
        <>
            <Flex direction="column" position="relative" zIndex="1410" ref={bannersRef}>
                {showSchibstedBanner && <SchibstedDataControllerBanner variant="top" />}
            </Flex>
            <Box
                width="100%"
                as="header"
                //this negative margin fixes weird issue on Safari mobile, 1px wide gap between the top of the screen and header.
                marginTop={"-1px"}
                zIndex={1406}
                position={"sticky"}
                top={0}
                transition={"0.3s ease"}
                transform={scrollingUp ? "translateY(0px)" : "translateY(-230px)"}
                borderBottom={`1px solid ${PodmeColor.Cinder}`}
                ref={headerRef}
                {...rest}
            >
                <Box position="relative" background={`${PodmeColor.Woodsmoke}`}>
                    <Box padding="1rem">
                        <Flex position="relative" justifyContent="space-between" alignItems="center">
                            <Box paddingRight={16} zIndex={1}>
                                <Link to={`/${locale}/`}>
                                    <img
                                        src="/assets/images/brand/podme-logo-white.svg"
                                        alt="Podme logo"
                                        data-testid="navbar-podme-logo"
                                        style={{ height: "27px" }}
                                    />
                                </Link>
                            </Box>
                            <Flex gridGap={4} alignItems="center" zIndex={3} marginTop="0.3rem">
                                <HamburgerButton
                                    data-testid="navbar-menu"
                                    isToggled={isActive}
                                    onClick={() => setIsActive(!isActive)}
                                />
                            </Flex>
                        </Flex>
                    </Box>
                </Box>
                {isAuthenticated ? <MigrationBanner /> : null}
            </Box>
            {isActive && (
                <SlideMenu
                    username={username}
                    loginMethod={loginMethod}
                    hasSubscription={hasSubscription}
                    closeMenu={() => setIsActive(false)}
                    logoutUser={logoutUser}
                />
            )}
        </>
    );
}
