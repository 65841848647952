import React, { useCallback } from "react";
import { HStack, Text, Image, Flex } from "@chakra-ui/react";
import { useMedia } from "app/hooks";
import CustomButton from "./CustomButton";
import PageNumberButton from "./PageNumberButton";
import { Link } from "react-router-dom";
import ThreeDotsButton from "./ThreeDotsButton";
import strings from "app/locale/localization";
import Skeleton from "@components/Skeleton";

interface IProps {
    bottomPagination?: boolean;
    currentPage: number;
    totalPages: number;
    disabledPagination?: boolean;
    urlPath: string;
    fetchingData?: boolean;
    onPageChange: (page: number, scrollUp?: boolean) => void;
}

function Pagination({
    currentPage = 0,
    totalPages,
    urlPath,
    disabledPagination,
    onPageChange,
    bottomPagination = false,
    fetchingData = false,
}: IProps) {
    const isFirstPage = currentPage === 0;
    const isLastPage = currentPage === totalPages - 1;

    const { next, previous } = strings.pagination;

    const { isSmallScreen, isMediumScreen } = useMedia();

    const NUMBER_OF_PREVIOUS_AND_NEXT_PAGES = isSmallScreen || isMediumScreen ? 1 : 2;
    const MAX_NUMBER_OF_ELEMENTS = isSmallScreen || isMediumScreen ? 4 : 6;

    const handlePrevious = () => {
        if (!isFirstPage) onPageChange(currentPage - 1, bottomPagination);
    };

    const handleNext = () => {
        if (!isLastPage) onPageChange(currentPage + 1, bottomPagination);
    };

    const handlePageChange = (page: number) => {
        onPageChange(page, bottomPagination);
    };

    const getPaginationButtons = useCallback(() => {
        const buttons = [];
        let startPage = Math.max(0, currentPage - NUMBER_OF_PREVIOUS_AND_NEXT_PAGES);
        let endPage = Math.min(currentPage + NUMBER_OF_PREVIOUS_AND_NEXT_PAGES, totalPages - 1);
        const offset = isSmallScreen || isMediumScreen ? 3 : 7;

        if (totalPages <= Math.max(7, offset + 2)) {
            startPage = 0;
            endPage = totalPages - 1;
        } else if (totalPages > MAX_NUMBER_OF_ELEMENTS && currentPage < MAX_NUMBER_OF_ELEMENTS) {
            startPage = 0;
            endPage = Math.min(MAX_NUMBER_OF_ELEMENTS, totalPages - 1);
        } else if (currentPage < 2) {
            startPage = 0;
            endPage = Math.min(currentPage + NUMBER_OF_PREVIOUS_AND_NEXT_PAGES, totalPages - 1);
        } else if (currentPage >= totalPages - 3) {
            startPage = Math.max(0, totalPages - offset);
            endPage = totalPages - 3;
        }

        if (
            totalPages >= MAX_NUMBER_OF_ELEMENTS + 2 * NUMBER_OF_PREVIOUS_AND_NEXT_PAGES &&
            currentPage > totalPages - MAX_NUMBER_OF_ELEMENTS
        ) {
            startPage = Math.max(0, totalPages - offset);
            endPage = totalPages - 1;
        }

        if (startPage > 0) {
            buttons.push(
                <PageNumberButton key="0" urlPath={urlPath} page={0} currentPage={currentPage} onPageChange={handlePageChange} />
            );
            if (startPage > 1) {
                buttons.push(
                    <ThreeDotsButton key="start-dots" totalPages={totalPages} baseUrl={urlPath} onPageChange={handlePageChange} />
                );
            }
        }

        for (let page = startPage; page <= endPage; page++) {
            buttons.push(
                <PageNumberButton key={page} urlPath={urlPath} page={page} currentPage={currentPage} onPageChange={handlePageChange} />
            );
        }

        if (totalPages > 8 && endPage < totalPages - 2) {
            buttons.push(<ThreeDotsButton key="end-dots" totalPages={totalPages} baseUrl={urlPath} onPageChange={handlePageChange} />);
            buttons.push(
                <PageNumberButton
                    key={totalPages - 1}
                    urlPath={urlPath}
                    page={totalPages - 1}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                />
            );
        }

        return buttons;
    }, [currentPage, totalPages]);

    return (
        <Skeleton
            isLoaded={!fetchingData}
            width={fetchingData ? ["100%", "50%"] : "auto"}
            rounded="0.5rem"
            display="flex"
            alignItems="center"
        >
            <HStack
                display="flex"
                width={["100%", "unset"]}
                justifyContent={["space-between", null, "unset"]}
                spacing={[1, 4]}
                height="2rem"
                sx={{ "> div": { minHeight: "100%" } }}
            >
                <Link
                    to={{
                        pathname: urlPath,
                        search: isFirstPage || currentPage === 1 ? undefined : `?page=${currentPage}`,
                        state: {
                            scrollToTop: false,
                        },
                    }}
                    style={{
                        height: "100%",
                        maxWidth: isSmallScreen ? "3.2rem" : "100%",
                        pointerEvents: isFirstPage || disabledPagination ? "none" : "auto",
                    }}
                >
                    <CustomButton isDisabled={isFirstPage} onClick={handlePrevious} justifyContent="center">
                        <Image src={`/assets/images/icons/chevron-left-16x16.svg`} height="1.25rem" />
                        {!isSmallScreen && (
                            <Text paddingRight="0.4rem" as="span" noOfLines={1} wordBreak="break-all">
                                {previous}
                            </Text>
                        )}
                    </CustomButton>
                </Link>
                <Flex gridGap="0.25rem">{getPaginationButtons()}</Flex>
                <Link
                    to={{
                        pathname: urlPath,
                        search: `?page=${isLastPage ? totalPages : currentPage + 2}`,
                        state: {
                            scrollToTop: false,
                        },
                    }}
                    style={{
                        height: "100%",
                        maxWidth: isSmallScreen ? "3.2rem" : "100%",
                        pointerEvents: isLastPage ? "none" : "auto",
                    }}
                >
                    <CustomButton
                        isDisabled={isLastPage}
                        onClick={handleNext}
                        paddingLeft={["0.2rem", "0.5rem"]}
                        justifyContent="center"
                    >
                        {!isSmallScreen && (
                            <Text as="span" noOfLines={1} wordBreak="break-all">
                                {next}
                            </Text>
                        )}
                        <Image src={`/assets/images/icons/chevron-right.svg`} height="1.25rem" />
                    </CustomButton>
                </Link>
            </HStack>
        </Skeleton>
    );
}

export default Pagination;
