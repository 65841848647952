import { AspectRatio, Box, BoxProps, Flex, Heading, Image } from "@chakra-ui/react";
import { CSSstylesObject, DeviceBreakpoints, Episode, PodmeColor } from "@typings/index";
import { useMedia } from "../../../../hooks";
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";

import "./mainCarousel.scss";
// import PlaySampleButton from "./components/PlaySampleButton";
import strings from "@locale/localization";
import { SecondaryButton } from "@components/buttons";
import { HeroCard } from "@containers/Homescreen/redux/types";
import useAuth from "@auth/useAuth";
import { useSound } from "@containers/SoundProvider";
import { playerTimeFormat, playerTimeToSeconds } from "@utils/index";
import LoadingIcon from "@components/LoadingIcon";
import { Link } from "react-router-dom";
import InfoSection from "./components/InfoSection";
import { PodcastForMainCarousel } from "../../../../../app/content/contentTypes";
import TagList from "@containers/PodcastPage/components/TagList";
import useSetCorrectLanguageCategories from "@containers/PodcastPage/hooks/useSetCorrectLanguageCategories";
import { SectionProps } from "@components/Cms/CmsSections";
export interface MainCarouselProps {
    podcasts: Episode[] | HeroCard[] | PodcastForMainCarousel[];
    minimalVariant?: boolean;
    onHomescreen?: boolean;
    podcastsCarouselBoxProps?: BoxProps;
    podcastsInfoBoxProps?: BoxProps;
}

export type SectionMainCarouselProps = MainCarouselProps & SectionProps & BoxProps;

export default function MainCarousel({
    podcasts,
    onHomescreen = false,
    minimalVariant = false,
    podcastsInfoBoxProps,
    podcastsCarouselBoxProps,
    testId,
    ...rest
}: SectionMainCarouselProps): ReactElement | null {
    const { locale } = strings.routes;
    const { isLargeScreen, isMediumScreen, isSmallScreen } = useMedia();
    const MAX_NUMBER_OF_CAROUSEL_PODCASTS = podcasts.length;
    const IMAGE_WIDTH = isSmallScreen ? 110 : 150;

    const [carouselWidth, setCarouselWidth] = useState(0);
    const [focusedElementData, setFocusedElementData] = useState<any>({
        ...podcasts[0],
        id: getCarouselElementId(podcasts[0]),
        title: (podcasts[0] as Episode).title ?? (podcasts[0] as HeroCard).mainHeader,
        description: (podcasts[0] as Episode).description ?? (podcasts[0] as HeroCard).shortDescription,
        imageUrl: (podcasts[0] as Episode).smallImageUrl ?? (podcasts[0] as HeroCard).imageUrl,
        destinationPath: (podcasts[0] as Episode).slug ?? (podcasts[0] as HeroCard).destinationPath,
    });
    const [innerCarouselPosition, setInnerCarouselPosition] = useState(1);
    //focusedElementIndex MUST be null on the first render if we want it to work correctly with sessionStorage remembering last clicked podcast image.
    //because of this a lot of additional type safety checks are required down in code (typeof focusedElementIndex === "number" ? ...)
    //so it is less readable, but it works.
    const [focusedElementIndex, setFocusedElementIndex] = useState<number | null>(null);

    // no trailers to play for now
    // const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const carousel = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);
    const previousButton = useRef<HTMLImageElement>(null);
    const nextButton = useRef<HTMLImageElement>(null);
    const focusedElement = useRef<any>({});

    const carouselString = strings.startPage.carousel;
    const goToPodcastString = strings.navigation.goToPodcastPage;
    const string = strings.episodeHome;

    const { hasActiveSubscription, showPremiumModal } = useAuth();
    const { state: sound, play, pause, load } = useSound();

    const correctCategories = useSetCorrectLanguageCategories(focusedElementData);

    useEffect(() => {
        if (carousel && carousel.current) {
            setCarouselWidth(carousel?.current?.scrollWidth - carousel?.current?.offsetWidth + 70); //plus on the end depends on image width and its boxshadows width.
        }

        if (carouselWidth !== 0) {
            //if carouselWidth has changed its initial value we can be sure the browser has displayed it,
            // meaning we are in the browser context now and sessionStorage is defined.
            const lastViewedItem = sessionStorage.getItem("carousel-last-viewed-item");

            //if user is navigating back from podcast page, highlight the last podcast the user clicked.
            //otherwise highlight first podcast.
            if (lastViewedItem) {
                return setFocusedElementIndex(Number(lastViewedItem));
            } else {
                setFocusedElementIndex(0);
            }
        }
    }, [carouselWidth]);

    useEffect(() => {
        if (innerRef.current && carousel.current) {
            carousel.current?.scrollTo({ left: -innerCarouselPosition, behavior: "smooth" });
        }
    }, [innerCarouselPosition]);

    useEffect(() => {
        if (typeof focusedElementIndex === "number") {
            const focusedElementInCarousel = innerRef?.current?.children[focusedElementIndex];
            if (focusedElementInCarousel) {
                (focusedElementInCarousel as HTMLElement).click();
            }
        }
    }, [focusedElementIndex]);

    useEffect(() => {
        const { episodeData } = focusedElementData;

        if (episodeData && episodeData.id === Number(sound.currentAudioId)) {
            episodeData.currentSpot = playerTimeFormat(String(sound.currentTime));
        }
    }, [sound.currentAudioId, sound.currentTime, focusedElementData]);

    if (!podcasts || podcasts.length < 1) return null;

    const episodeIsPlaying = useMemo(
        () => sound.isPlaying && focusedElementData.episodeData && sound.currentAudioId === String(focusedElementData.episodeData.id),
        [sound.isPlaying, sound.currentAudioId, focusedElementData.episodeData]
    );

    const togglePlay = useCallback(
        (episodeId: number, isPremium: boolean, streamUrl: string, currentSpot: string) => {
            if (hasActiveSubscription || !isPremium) {
                load(episodeId, streamUrl, playerTimeToSeconds(currentSpot));

                if (episodeIsPlaying) {
                    pause();
                } else {
                    play(String(episodeId));
                }
            } else {
                showPremiumModal(true);
            }
        },
        [hasActiveSubscription, episodeIsPlaying]
    );

    const loadEpisode = () => {
        if (!focusedElementData.isPlayable && !focusedElementData.episodeData) {
            showPremiumModal(true);
            return;
        }

        togglePlay(
            focusedElementData.episodeData.id,
            focusedElementData.episodeData.isPremium,
            focusedElementData.episodeData.url ?? focusedElementData.episodeData.streamUrl,
            focusedElementData.episodeData.currentSpot
        );
    };

    const styles: CSSstylesObject = {
        carouselContainer: {
            margin: onHomescreen ? ["0 0 1rem", "0 auto"] : ["0 0 3rem", "0 auto"],
            maxWidth: onHomescreen ? "75rem" : "100%",
            width: "100%",
            padding: "2rem 0",
            _after: {},
            sx: {
                [`@media ${DeviceBreakpoints.MediumDown}`]: {
                    padding: "2rem 0",
                },

                [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                    padding: "0",
                },
            },
        },
        mainContentWrapper: {
            display: "grid",
            gridGap: ["1rem", "2rem", null],
            paddingTop: onHomescreen ? undefined : ["2rem", undefined],
            gridTemplateColumns: onHomescreen ? ["100%", null, "calc(65% - 2rem) 35%"] : ["100%", null, "calc(65% - 2rem) 35%"],
            sx: {
                [`@media ${DeviceBreakpoints.MediumDown}`]: {
                    justifyContent: "center",
                },
            },
        },
        headerTitle: {
            color: minimalVariant ? PodmeColor.SoftWhite : "gray",
            padding: "0.5rem 0rem",
            fontSize: "36px",

            sx: {
                [`@media ${DeviceBreakpoints.MediumDown}`]: {
                    padding: "0rem 2rem",

                    fontSize: "2rem",
                },
                [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                    padding: "1rem",
                    marginTop: "1rem",
                    marginBottom: "0rem",
                },
            },
        },
        headerPodcastTitle: {
            fontSize: "36px",
            color: PodmeColor.SoftWhite,
            paddingBottom: "0.8rem",
            sx: {
                [`@media ${DeviceBreakpoints.MediumDown}`]: {
                    padding: "0rem 2rem",
                    fontSize: "2rem",
                    height: "50px",
                },
                [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                    fontSize: "1.5rem",
                    height: "45px",
                    padding: "0rem 1rem",
                },
            },
        },
        categoriesChipsWrapper: {
            fontSize: "0.7rem",
            paddingBottom: "1rem",
            display: "flex",
            gridGap: "0.7rem",
            overflowX: "scroll",
            maxWidth: "100%",
            alignItems: "center",
        },

        categoryChip: {
            padding: "0.5rem 1rem",
            borderRadius: "16px",
            background: PodmeColor.LicoriceBlue,
            color: PodmeColor.SoftWhite,
            textAlign: "center",
            whiteSpace: "nowrap",
        },
        premiumChip: {
            padding: "0.5rem 1rem",
            borderRadius: "16px",
            background: PodmeColor.Cyan,
            color: PodmeColor.BlackPearl,
        },
        elementDataWrapper: {
            paddingLeft: "4rem",
            sx: {
                [`@media ${DeviceBreakpoints.MediumDown}`]: {
                    height: "unset",
                    paddingLeft: "5.5rem",
                    paddingRight: "5.5rem",
                },
                [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                    padding: "0.5rem",
                },
            },
        },
        elementDataDescription: {
            maxWidth: "600px",
            fontSize: "1rem",
            height: !onHomescreen ? "250px" : "unset",
            overflowY: "scroll",
            color: PodmeColor.Silver,
            paddingLeft: "2rem",
            sx: {
                [`@media ${DeviceBreakpoints.MediumDown}`]: {
                    fontSize: "1rem",
                    paddingBottom: "1rem",
                    height: "unset",
                },
                "&::-webkit-scrollbar": {
                    width: "0.5rem",
                    transition: "all 150ms ease-in",
                },

                "&::-webkit-scrollbar-track": {
                    boxShadow: `inset 0 0 6px ${PodmeColor.BlackPearl}30`,
                },

                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: PodmeColor.LicoriceBlue,
                    borderRadius: "0.75rem",
                },

                "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: PodmeColor.LicoriceBlue + "80",
                },
            },
        },
        buttonsWrapper: {
            width: "100%",
            display: ["grid", null, "flex"],
            justifyContent: "space-between",
            marginTop: "0rem",
            gridTemplateColumns: "auto 1fr auto",
            gridGap: ["0.5rem", "1rem"],
            marginBottom: onHomescreen ? ["0", null, "4rem"] : "0",
            sx: {
                [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                    marginTop: "-1rem",
                },
            },
        },

        button: {
            transition: "all 150ms ease-in",
            padding: "1rem !important",
            sx: {
                "&:disabled": {
                    opacity: 0.5,
                },
                "&:active": {
                    filter: "contrast(1.2)",
                },
                "&:hover:not(:disabled)": {
                    opacity: 0.85,
                    transform: "scale(0.99)",
                },
            },
        },
    };

    return (
        <Box
            outline={"none"}
            data-testid={testId || "carousel-container"}
            {...styles.carouselContainer}
            //carousel (when focused with 'tab' or clicked) can be controlled from keyboard by pressing left/right arrow key
            tabIndex={0}
            margin={onHomescreen ? ["0 0 1rem", "0 auto"] : ["0 0 3rem", "0 auto"]}
            paddingRight={!onHomescreen ? "unset" : ["1rem", "2rem"]}
            onKeyDown={(e) => {
                if (e.key === "ArrowLeft") {
                    previousButton?.current?.click();
                }
                if (e.key === "ArrowRight") {
                    nextButton?.current?.click();
                }
            }}
            sx={
                minimalVariant
                    ? {
                          padding: 0,
                      }
                    : styles.carouselContainer.sx
            }
            {...rest}
        >
            {!onHomescreen && (
                <Flex direction="column">
                    <Heading
                        {...styles.headerTitle}
                        sx={
                            minimalVariant
                                ? {
                                      [`@media ${DeviceBreakpoints.MediumDown}`]: {
                                          padding: "0rem 2rem",

                                          fontSize: "2rem",
                                      },
                                      [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                                          padding: ["0 1rem"],
                                      },
                                  }
                                : {
                                      [`@media ${DeviceBreakpoints.MediumDown}`]: {
                                          padding: "0rem 2rem",

                                          fontSize: "2rem",
                                      },
                                      [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                                          padding: "1rem",
                                          marginTop: "1rem",
                                          marginBottom: "0rem",
                                      },
                                  }
                        }
                    >
                        {carouselString.title}
                    </Heading>
                    {!minimalVariant && (
                        <>
                            <Heading {...(styles.headerTitle, styles.headerPodcastTitle)}>{`${focusedElementData?.title
                                .charAt(0)
                                .toUpperCase()}${focusedElementData?.title.slice(1)}`}</Heading>
                            {/* Categories chips are displayed just below the carousel header title on Desktop ONLY */}
                            {isLargeScreen && <TagList categories={correctCategories} />}
                        </>
                    )}
                </Flex>
            )}
            <Box
                {...styles.mainContentWrapper}
                paddingTop={onHomescreen ? "unset" : ["2rem", "unset"]}
                gridTemplateColumns={onHomescreen ? ["100%", null, "60% 40%"] : ["100%", null, "calc(60% - 2rem) 40%"]}
            >
                <Box
                    position={"relative"}
                    // after and before pseudoelements make fade-away masking effect on the sides
                    _after={{
                        position: "absolute",
                        content: '""',
                        transition: "opacity 150ms ease-in",
                        opacity: isFocusedElementFirstCarouselItem(focusedElementData, podcasts) ? 0 : 1,
                        width: "5%",
                        height: "100%",
                        background: `linear-gradient(90deg, ${PodmeColor.BlackPearl} 0%, rgba(0,212,255,0) 100%)`,
                        top: 0,
                        left: 0,
                        pointerEvents: "none",
                        zIndex: 2,
                    }}
                    _before={{
                        position: "absolute",
                        content: '""',
                        width: "5%",
                        height: "100%",
                        background: `linear-gradient(270deg, ${PodmeColor.BlackPearl} 0%, rgba(0,212,255,0) 100%)`,
                        top: 0,
                        right: 0,
                        pointerEvents: "none",
                        zIndex: 2,
                    }}
                >
                    {/* outer carousel element - static */}
                    <Box
                        className="hide-scrollbar"
                        ref={carousel}
                        overflowX={["scroll", null, null, "hidden"]}
                        style={{
                            paddingTop: isSmallScreen ? "2.5rem" : "4rem",
                            paddingBottom: minimalVariant && !isSmallScreen ? 0 : "1rem",
                            display: "flex",
                            alignItems: "flex-end",
                            transition: "0.2s ease",
                            scrollBehavior: "smooth",
                            paddingRight: "2rem",
                            overflowX: "scroll",
                            // scrollBehavior: "smooth",
                        }}
                    >
                        {/* inner carousel element - moving inside the outer one */}
                        <Box
                            ref={innerRef}
                            style={{
                                transition: "0.2s ease",
                                paddingLeft:
                                    isFocusedElementFirstCarouselItem(focusedElementData, podcasts) && isLargeScreen
                                        ? 0
                                        : isLargeScreen
                                          ? "3rem"
                                          : isMediumScreen
                                            ? "2rem"
                                            : "1rem",
                                paddingTop: "4rem",
                                width: "fit-content",
                                display: "flex",
                                gap: isSmallScreen ? "0.5rem" : "1rem",
                                alignItems: "flex-end",
                                marginRight: isLargeScreen || isMediumScreen ? "4rem" : "3rem",
                            }}
                            paddingRight={["4rem", null, "3rem"]}
                            paddingBottom={["1rem", 0, "3rem"]}
                            {...podcastsCarouselBoxProps}
                        >
                            {/* this array is sliced to 'MAX_NUMBER_OF_CAROUSEL_PODCASTS' to display stuff correctly! */}
                            {podcasts.slice(0, MAX_NUMBER_OF_CAROUSEL_PODCASTS).map((podcast, index) => {
                                const episodeData = {
                                    ...podcast,
                                    id: getCarouselElementId(podcast),
                                    title: (podcast as Episode).title ?? (podcast as HeroCard).mainHeader,
                                    description: (podcast as Episode).description ?? (podcast as HeroCard).shortDescription,
                                    imageUrl: (podcast as Episode).smallImageUrl ?? (podcast as HeroCard).imageUrl,
                                    destinationPath: (podcast as Episode).slug ?? (podcast as HeroCard).destinationPath,
                                };

                                return (
                                    <Image
                                        alt={`${episodeData.title} carousel image`}
                                        loading="lazy"
                                        onClick={(e: any) => {
                                            setFocusedElementData(episodeData);
                                            setFocusedElementIndex(index);
                                            //remember last clicked podcast image so it can be highlighted when user navigates back from podcast page
                                            sessionStorage.setItem("carousel-last-viewed-item", index.toString());
                                            e.target.classList.add("element-gets-focus");
                                            if (focusedElement?.current?.element !== e.target || null || undefined) {
                                                focusedElement?.current?.element?.classList.remove("element-gets-focus");
                                                focusedElement?.current?.element?.classList.add("element-lost-focus");

                                                setTimeout(() => {
                                                    focusedElement?.current?.element?.classList.remove("element-lost-focus");
                                                }, 100);
                                            }
                                            focusedElement.current.element = e.target;

                                            //all conditions are correctly set for 'MAX_NUMBER_OF_CAROUSEL_PODCASTS' elements long array.

                                            //only for desktop:
                                            if (index === MAX_NUMBER_OF_CAROUSEL_PODCASTS - 3 && isLargeScreen) {
                                                setInnerCarouselPosition(-carouselWidth + 100);
                                                return;
                                            }
                                            if (index > MAX_NUMBER_OF_CAROUSEL_PODCASTS - 3 && isLargeScreen) {
                                                setInnerCarouselPosition(-carouselWidth);
                                                return;
                                            }
                                            //only for mobile&tablet:

                                            if (index === MAX_NUMBER_OF_CAROUSEL_PODCASTS - 2 && !isLargeScreen) {
                                                setInnerCarouselPosition(-carouselWidth + (isSmallScreen ? 80 : 40));

                                                return;
                                            }
                                            if (index === MAX_NUMBER_OF_CAROUSEL_PODCASTS - 1 && !isLargeScreen) {
                                                setInnerCarouselPosition(index * (-IMAGE_WIDTH - 200));

                                                return;
                                            }

                                            //on first element just set 0
                                            if (index === 0) {
                                                ("worked to zero");
                                                setInnerCarouselPosition(0);
                                                return;
                                            }

                                            //only tablet
                                            if (isMediumScreen && index === MAX_NUMBER_OF_CAROUSEL_PODCASTS - 4) {
                                                setInnerCarouselPosition(index * -IMAGE_WIDTH + 270);
                                                return;
                                            }
                                            if (isMediumScreen) {
                                                setInnerCarouselPosition(index * -IMAGE_WIDTH + 170);
                                                return;
                                            }
                                            //all other cases:

                                            setInnerCarouselPosition(index * -IMAGE_WIDTH);
                                        }}
                                        transformOrigin="bottom"
                                        sx={{
                                            userSelect: "none",
                                        }}
                                        draggable="false"
                                        cursor={"pointer"}
                                        maxHeight={`${IMAGE_WIDTH}px`}
                                        minWidth={`${IMAGE_WIDTH}px`}
                                        borderRadius="8px"
                                        key={index}
                                        src={episodeData.imageUrl}
                                    ></Image>
                                );
                            })}
                        </Box>
                    </Box>
                </Box>
                <Box
                    display={"flex"}
                    flexDirection={isLargeScreen ? "column" : "column-reverse"}
                    justifyContent="flex-end"
                    padding={onHomescreen ? ["0 1rem", "0 2rem", "0"] : ["0 1rem", "0 2rem", "0 0 4rem"]}
                    gridGap="2rem"
                    marginBottom={onHomescreen ? ["1rem", 0] : "0"}
                    {...podcastsInfoBoxProps}
                >
                    <InfoSection
                        onHomescreen={onHomescreen}
                        title={focusedElementData.title}
                        description={focusedElementData.description}
                        destinationPath={focusedElementData.destinationPath}
                        categories={correctCategories}
                        withBackground={minimalVariant}
                    />

                    {typeof focusedElementIndex === "number" && (
                        <Flex {...styles.buttonsWrapper} marginBottom={onHomescreen ? ["0", null, "4rem"] : ["1rem", "0"]}>
                            {/* left arrow button -only on desktop*/}

                            <AspectRatio minWidth="57px" ratio={1 / 1}>
                                <SecondaryButton
                                    {...styles.button}
                                    background={PodmeColor.LicoriceBlue}
                                    disabled={focusedElementIndex <= 0}
                                    onClick={() => {
                                        setFocusedElementIndex((prev) => {
                                            if (typeof prev === "number") {
                                                if (prev === 0) {
                                                    return 0;
                                                }
                                                return prev - 1;
                                            }
                                            return prev;
                                        });
                                    }}
                                >
                                    <Image
                                        width="2rem"
                                        alt="carousel left navigation button"
                                        ref={previousButton}
                                        zIndex="1"
                                        transform="rotate(180deg)"
                                        src="/assets/images/icons/chevron-right.svg"
                                    />
                                </SecondaryButton>
                            </AspectRatio>
                            {onHomescreen ? (
                                <SecondaryButton
                                    background={PodmeColor.LicoriceBlue}
                                    color={PodmeColor.SoftWhite}
                                    onClick={loadEpisode}
                                    gridGap="0.5rem"
                                    width="100%"
                                >
                                    <>
                                        {episodeIsPlaying && sound.isLoading ? (
                                            <Box rounded="50%">
                                                <LoadingIcon spinnerWidth="25px" spinnerHeight="25px" />
                                            </Box>
                                        ) : (
                                            <Box
                                                boxSize={["20px", "25px"]}
                                                as="img"
                                                src={`/assets/images/icons/play-${episodeIsPlaying ? "pause" : "play"}.svg`}
                                                alt="play"
                                            />
                                        )}
                                        {episodeIsPlaying ? string.pauseEpisode : string.playEpisode}
                                    </>
                                </SecondaryButton>
                            ) : (
                                podcasts.map((podcast, idx: number) => (
                                    <Link
                                        key={idx}
                                        style={{
                                            display: (podcast as Episode).title === focusedElementData.title ? "block" : "none",
                                            width: "100%",
                                        }}
                                        to={`/${locale}/${(podcast as Episode).slug}`}
                                    >
                                        <SecondaryButton
                                            background={PodmeColor.LicoriceBlue}
                                            color={PodmeColor.SoftWhite}
                                            width="100%"
                                            // maxHeight={isSmallScreen ? "40px" : "unset"}
                                            // to={`/${locale}/${focusedElementData?.slug}`}
                                        >
                                            {goToPodcastString}
                                        </SecondaryButton>
                                    </Link>
                                ))
                            )}

                            {/*
                            We need all the carousel podcasts' links to be in the DOM at all times for SEO reasons.
                            But only one button will be visible in UI for users - 
                            the one for active podcast chosen from carousel.
                            */}

                            {/* NO TRAILERS TO PLAY FOR NOW */}
                            {/* <PlaySampleButton isPlaying={isPlaying} setIsPlaying={setIsPlaying} /> */}
                            {/* right arrow button -only on desktop*/}
                            <AspectRatio minWidth="57px" ratio={1 / 1}>
                                <SecondaryButton
                                    {...styles.button}
                                    disabled={focusedElementIndex >= podcasts.length - 1}
                                    background={PodmeColor.LicoriceBlue}
                                    onClick={() => {
                                        setFocusedElementIndex((prev) => {
                                            const numberOfElements = innerRef.current?.children.length;
                                            if (typeof prev === "number") {
                                                if (!numberOfElements) return prev; //undefined safety check
                                                if (prev === numberOfElements - 1) {
                                                    return prev;
                                                }

                                                return prev + 1;
                                            }
                                            return prev;
                                        });
                                    }}
                                >
                                    <Image
                                        alt="carousel right navigation button"
                                        ref={nextButton}
                                        disabled={focusedElementIndex + 1 >= MAX_NUMBER_OF_CAROUSEL_PODCASTS}
                                        src="/assets/images/icons/chevron-right.svg"
                                    />
                                </SecondaryButton>
                            </AspectRatio>
                        </Flex>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

function isFocusedElementFirstCarouselItem(focusedElementData: any, carouselItems: Episode[] | HeroCard[] | PodcastForMainCarousel[]) {
    return (
        focusedElementData.id === (carouselItems[0] as Episode).id ||
        focusedElementData.id ===
            (carouselItems[0] as HeroCard)?.destination?.slice((carouselItems[0] as HeroCard)?.destination?.indexOf(":") + 1)
    );
}

function getCarouselElementId(podcast: Episode | HeroCard | PodcastForMainCarousel) {
    return (podcast as Episode).id ?? (podcast as HeroCard).destination.slice((podcast as HeroCard).destination.indexOf(":") + 1);
}
