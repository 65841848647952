import { playerTimeFormat, timespanEightChars } from "@utils/index";
import { useState, useRef, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { TrackPlayback, TRACK_PLAYBACK } from "../redux/GlobalPlayerTypes";

const useTrackPlayback = (
    id: number | undefined,
    currentTime: number,
    isPlaying: boolean,
    isPlayed: boolean,
    episodeLength: number | undefined,
    isAuthenticated: boolean
): void => {
    const dispatch = useDispatch();
    const countdownTime = 20;
    const [timer, setTimer] = useState(countdownTime);
    const intervalId = useRef<number>();

    const episodeDuration = useMemo(() => {
        if (episodeLength) return playerTimeFormat(Math.floor(episodeLength).toString());
    }, [episodeLength]);

    const currentSpot = useMemo(() => {
        if (!isAuthenticated) return "00:00:00";
        return timespanEightChars(Math.floor(currentTime).toString());
    }, [isAuthenticated, currentTime]);

    const clear = () => {
        clearInterval(intervalId.current);
    };

    const trackPlayback = (id: number, currentSpot: string, hasCompleted: boolean) => {
        dispatch<TrackPlayback>({
            type: TRACK_PLAYBACK,
            episodeId: id,
            currentSpot: currentSpot,
            hasCompleted,
        });
    };

    useEffect(() => {
        if (isAuthenticated) {
            if (isPlaying) {
                const interval = setInterval(() => {
                    setTimer((time) => time - 1);
                }, 1000);

                intervalId.current = Number(interval);
            } else if (id && id > 1000 && !isPlaying && currentSpot !== "00:00:00" && currentTime !== episodeLength) {
                trackPlayback(id, currentSpot, isPlayed);
                clear();
            }

            return () => clear();
        }
    }, [isAuthenticated, id, isPlaying, isPlayed]);

    useEffect(() => {
        if (timer === 0) {
            if (id && id > 1000) {
                trackPlayback(id, currentSpot, isPlayed);
            }
            setTimer(20);
        }
    }, [timer, id, currentSpot, isPlayed]);

    useEffect(() => {
        if (isAuthenticated && isPlayed && id && id > 1000 && episodeDuration && episodeDuration !== "00:00") {
            dispatch<TrackPlayback>({
                type: TRACK_PLAYBACK,
                episodeId: id,
                currentSpot: episodeDuration,
                hasCompleted: true,
            });
            clear();
        }
    }, [isAuthenticated, id, isPlayed, episodeDuration]);
};

export default useTrackPlayback;
