import { call, put, PutEffect, select, takeLatest } from "redux-saga/effects";
import { createGetRequest } from "../../../../app/api";

import {
    GET_CONTENT,
    GET_CONTENT_SUCCESS,
    GET_CONTENT_FAILED,
    GetContentResponse,
    GET_PREVIEW_CONTENT,
    GetPreviewContent,
    GET_PREVIEW_CONTENT_FAILED,
    GET_PREVIEW_CONTENT_SUCCESS,
    GetContentFailed,
    GetContentSuccess,
    GetPreviewContentSuccess,
    GetPreviewContentFailed,
} from "./types";
import { getPackageId } from "@utils/index";
import { Region, SagaReturnType } from "@typings/index";
import { RootState } from "app/reducers/rootReducer";
const regionSelector = (state: RootState) => state.global;
function* getContentAuth(): SagaReturnType<PutEffect<GetContentSuccess | GetContentFailed>> {
    try {
        const { location } = yield select(regionSelector);
        const region = location === Region.Norway ? "NO" : location === Region.Finland ? "FI" : "SE";

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const result = yield call(createGetRequest, "cms/home-screen", undefined, undefined, region);

        return yield put<GetContentSuccess>({
            type: GET_CONTENT_SUCCESS,
            content: result,
        });
    } catch (err) {
        return yield put<GetContentFailed>({
            type: GET_CONTENT_FAILED,
            err,
        });
    }
}

function* getPreviewContentAuth({
    region,
    hasSubscription,
}: GetPreviewContent): SagaReturnType<PutEffect<GetPreviewContentSuccess | GetPreviewContentFailed>> {
    try {
        const regionInt = getPackageId(region);

        const result: GetContentResponse = yield call(
            createGetRequest,
            `cms/home-screen/preview?region=${regionInt}&hasSubscription=${hasSubscription}`
        );

        return yield put<GetPreviewContentSuccess>({
            type: GET_PREVIEW_CONTENT_SUCCESS,
            content: result,
        });
    } catch (err) {
        return yield put<GetPreviewContentFailed>({
            type: GET_PREVIEW_CONTENT_FAILED,
            err,
        });
    }
}

export default [takeLatest(GET_CONTENT, getContentAuth), takeLatest(GET_PREVIEW_CONTENT, getPreviewContentAuth)];
