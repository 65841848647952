import { Flex, Text } from "@chakra-ui/react";
import { useMedia } from "app/hooks";
import strings from "@locale/localization";

function ErrorBanner() {
    const { isSmallScreen } = useMedia();
    const { offline } = strings.errors;

    return (
        <Flex
            width="100%"
            justifyContent="center"
            backgroundColor="#cc2900"
            paddingBlock={isSmallScreen ? "0.45rem" : "0.25rem"}
            zIndex="1500"
        >
            <Text fontSize="clamp(0.5rem, calc(2vw + 0.3rem), 0.825rem)" color="#fcdede">
                {offline}
            </Text>
        </Flex>
    );
}

export default ErrorBanner;
