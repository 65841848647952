import { Box, Flex, Image, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { PodmeColor } from "app/types";
import { PrimaryButtonNew, SecondaryButton } from "@components/buttons";
import React, { useEffect, useState } from "react";
import { BANNED_ACCOUNT_MODAL_STORAGE_KEY, IdpRedirectOption } from "@auth/authTypes";
import strings from "@locale/localization";
import useAuth from "@auth/useAuth";

function BlockedAccountModal() {
    const { isAuthenticated, handleSignUpRedirect } = useAuth();
    const content = strings.bannedAccountModal;
    const [isOpen, setIsOpen] = useState(false);
    const handleCloseModal = () => {
        sessionStorage.removeItem(BANNED_ACCOUNT_MODAL_STORAGE_KEY);
        setIsOpen(false);
    };

    const handleSignUpButton = () => {
        sessionStorage.removeItem(BANNED_ACCOUNT_MODAL_STORAGE_KEY);
        handleSignUpRedirect({
            redirectDestination: IdpRedirectOption.DiscoverPage,
        });
        setIsOpen(false);
    };

    useEffect(() => {
        const accountWasBanned = sessionStorage.getItem(BANNED_ACCOUNT_MODAL_STORAGE_KEY);
        if (accountWasBanned) setIsOpen(true);

        return () => handleCloseModal();
    }, []);

    return (
        <Modal closeOnOverlayClick={true} isOpen={isOpen && !isAuthenticated} onClose={handleCloseModal} isCentered>
            <ModalOverlay zIndex="9998" bgColor={PodmeColor.Woodsmoke} opacity="0.75 !important" />
            <Box
                sx={{
                    "> div > .chakra-modal__content-container": {
                        zIndex: "9999",
                    },
                }}
            >
                <ModalContent
                    color={PodmeColor.SoftWhite}
                    display={"flex"}
                    alignContent={"center"}
                    maxW={"40rem"}
                    borderRadius={"1.5rem"}
                    mx={["0", "0.5rem"]}
                    backgroundColor={PodmeColor.Cinder}
                    zIndex="9999 !important"
                >
                    <Flex direction="column" gridGap="1.5rem" p={"1.5rem"}>
                        <Image
                            src="/assets/images/icons/close-16x16.svg"
                            onClick={handleCloseModal}
                            width="24px"
                            position="absolute"
                            top={["1rem", "1.5rem"]}
                            right={["1rem", "1.5rem"]}
                            opacity="50%"
                            cursor="pointer"
                            transition="all 100ms ease-in"
                            _hover={{
                                opacity: "100%",
                            }}
                        />
                        <ModalHeader
                            fontSize={"clamp(16px, calc(2vw + 0.8rem), 20px)"}
                            fontWeight="700"
                            padding="0"
                            lineHeight={"calc(1rem + 0.75em)"}
                            color={PodmeColor.SoftWhite}
                            maxWidth="90%"
                        >
                            {content.title}
                        </ModalHeader>
                        <ModalBody color={PodmeColor.SoftWhite} fontSize={"clamp(14px, calc(2vw + 0.3rem), 16px)"} padding="0">
                            <Text color={PodmeColor.Silver} marginBottom="1rem">
                                {content.message}
                            </Text>
                        </ModalBody>
                        <Flex flexDirection={["column", "row", "row"]} gridGap="1rem" justifyContent="space-between">
                            <PrimaryButtonNew
                                color={PodmeColor.SoftWhite}
                                background={PodmeColor.Transparent}
                                border={`0.5px solid ${PodmeColor.Silver}`}
                                padding="1rem 1.5rem"
                                width="100%"
                                onClick={handleCloseModal}
                            >
                                {content.okBtn}
                            </PrimaryButtonNew>
                            <SecondaryButton width={"100%"} onClick={handleSignUpButton}>
                                {content.connectWithSchibstedBtn}
                            </SecondaryButton>
                        </Flex>
                    </Flex>
                </ModalContent>
            </Box>
        </Modal>
    );
}

export default BlockedAccountModal;
