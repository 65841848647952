import { Flex, Image, ModalBody, ModalHeader, Spinner, Text, Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/reducers/rootReducer";
import { ACCEPT_MIGRATION_MODAL_PROMPT } from "@containers/Migration/redux/types";
import { strings } from "@locale/localization";
import { PodmeColor } from "app/types";
import { SecondaryButton } from "@components/buttons";
import useMigrationCountdown from "app/hooks/useMigrationCountdown";
import useAuth from "@auth/useAuth";

interface IProps {
    onClose: () => void;
    dismissModal: () => void;
    isMigrationHard: boolean;
}

const migrationSelector = (state: RootState) => state.migration;

const MainBox: FC<IProps> = ({ onClose, dismissModal, isMigrationHard }) => {
    const { acceptSuccess, isLoading, isVisible, userAcceptedMigration } = useSelector(migrationSelector);
    const dispatch = useDispatch();
    const { daysLeftToHardMigration } = useMigrationCountdown();
    const { logoutUser } = useAuth();
    const { locale } = strings.routes;
    const { title, body, bodyLastDay, titleHardMigration, bodyHardMigration, laterBtn, laterWithDaysBtn } = strings.migration.mainBox;
    const { link, connectBtn } = strings.migration.banner;
    const getDoItLaterBtnCopy = () => {
        if (isMigrationHard) return laterBtn;
        if (daysLeftToHardMigration >= 0) return strings.formatString(laterWithDaysBtn, daysLeftToHardMigration) as string;

        return laterBtn;
    };

    const titleCopy = isMigrationHard ? titleHardMigration : title;
    const getModalBodyCopy = () => {
        const bodyMessage = strings.formatString(body, daysLeftToHardMigration) as string;

        if (isMigrationHard) return bodyHardMigration;
        if (daysLeftToHardMigration === 1) return bodyLastDay;
        return bodyMessage;
    };

    const renderActionButton = () => {
        if (isMigrationHard || daysLeftToHardMigration <= 10) {
            return (
                <SecondaryButton
                    color={PodmeColor.SoftWhite}
                    background={PodmeColor.Transparent}
                    border={`0.5px solid ${PodmeColor.Silver}`}
                    onClick={logoutUser}
                    width="100%"
                >
                    {strings.navigation.logout}
                </SecondaryButton>
            );
        }

        return (
            <SecondaryButton
                color={PodmeColor.SoftWhite}
                background={PodmeColor.LicoriceBlue}
                border={`0.5px solid ${PodmeColor.SilverTransparent}`}
                onClick={dismissModal}
                isLoading={isLoading && !userAcceptedMigration}
                width="100%"
            >
                {getDoItLaterBtnCopy()}
            </SecondaryButton>
        );
    };
    const handleRedirect = () => {
        dispatch({ type: ACCEPT_MIGRATION_MODAL_PROMPT });
    };

    useEffect(() => {
        if (acceptSuccess) onClose();
    }, [acceptSuccess]);

    return (
        <Flex direction="column" gridGap="1.5rem" p={"1.5rem"}>
            {!isMigrationHard ? (
                <Image
                    src="/assets/images/icons/close-16x16.svg"
                    onClick={dismissModal}
                    width="24px"
                    position="absolute"
                    top={["1rem", "1.5rem"]}
                    right={["1rem", "1.5rem"]}
                    opacity="50%"
                    cursor="pointer"
                    transition="all 100ms ease-in"
                    _hover={{
                        opacity: "100%",
                    }}
                />
            ) : isLoading && isVisible ? (
                <Spinner size={"sm"} />
            ) : null}
            <ModalHeader
                fontSize={"clamp(16px, calc(2vw + 0.8rem), 20px)"}
                fontWeight="700"
                padding="0"
                lineHeight={"calc(1rem + 0.75em)"}
                color={PodmeColor.SoftWhite}
                maxWidth="90%"
            >
                {titleCopy}
            </ModalHeader>
            <ModalBody color={PodmeColor.SoftWhite} fontSize={"clamp(14px, calc(2vw + 0.3rem), 16px)"} padding="0">
                <Text color={PodmeColor.Silver}>{getModalBodyCopy()}</Text>
                <Box marginTop="1rem" maxW="fit-content">
                    <Link to={`/${locale}/schibsted-faq/`} onClick={dismissModal}>
                        <Flex gridGap="0.25rem">
                            <Text color={PodmeColor.SoftWhite} textDecoration="underline" fontSize="16px" fontWeight="500">
                                {link}
                            </Text>
                            <Image src="/assets/images/icons/external-link.svg" alt="link" />
                        </Flex>
                    </Link>
                </Box>
            </ModalBody>
            <Flex
                sx={{
                    "> button": {
                        height: "auto",
                        borderRadius: "1rem",
                        padding: "1rem 2rem",
                        fontWeight: "400",
                    },
                    "> button:hover": {
                        opacity: "70%",
                    },
                }}
                flexDirection={["column", "row"]}
                gridGap="1rem"
                justifyContent={isMigrationHard ? "flex-end" : "space-between"}
            >
                {renderActionButton()}
                <SecondaryButton width={"100%"} onClick={handleRedirect} isLoading={isLoading && isVisible && userAcceptedMigration}>
                    {connectBtn}
                </SecondaryButton>
            </Flex>
        </Flex>
    );
};

export default MainBox;
