import { PublicClientApplication, Configuration } from "@azure/msal-browser";

import strings from "@locale/localization";
import config from "./index";

const { locale } = strings.routes;

const msalConfig = {
    auth: {
        clientId: config.APPLICATION_ID ?? "",
        authority: `https://${config.TENANT_NAME}.b2clogin.com/${config.TENANT_NAME}.onmicrosoft.com/${config.WEB_SIGN_IN_POLICY_NAME}`,
        knownAuthorities: [`${config.TENANT_NAME}.b2clogin.com`],
        postLogoutRedirectUri: `${config.PUBLIC_URL}/${locale}/`,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage",
    },
} satisfies Configuration;

export const scopes = [
    "https://reacthello.onmicrosoft.com/reacthelloapi/user_impersonation",
    "https://reacthello.onmicrosoft.com/reacthelloapi/read",
    "offline_access",
    "openid",
    "profile",
];

export default new PublicClientApplication(msalConfig);
