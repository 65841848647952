import {
    IEpisodeCarousel,
    IEpisodePromoCarousel,
    IListOfHeroCards,
    IListOfPodcasts,
    IPodcastCarousel,
    IPodcastNuggets,
    IPodcastPromoCarousel,
} from "@containers/Homescreen/redux/types";
import { Episode, Pages, PageType, Podcast, StructuredDataContent, StructuredDataContentType } from "@typings/index";
import config from "app/config";
import appInsights from "app/config/appInsights";
import { PodcastsMainCarousel } from "app/content/contentTypes";
import strings from "app/locale/localization";
import { useMemo } from "react";

// types of the content that can be passed to the hook
type BreadcrumbsContent = Array<{ name: string | undefined; url: string }>;
type OrganizationContent = { url: string; logoImgUrl: string; supportEmail: string };
type PodcastListContent = {
    url: string;
    podcastSection: IPodcastCarousel | IPodcastPromoCarousel | IListOfHeroCards | PodcastsMainCarousel | IListOfPodcasts;
};
type EpisodeListContent = {
    url: string;
    episodeSection: IEpisodeCarousel | IPodcastNuggets | IEpisodePromoCarousel;
};
type PodcastPageContent = {
    breadcrumbsList: BreadcrumbsContent;
    podcast: Partial<Podcast> & { url: string; logoUrl: string; numberOfEpisodes: number };
    episodes: Episode[];
};

type EpisodePageContent = {
    episode: Episode | null;
};

// return types of the functions that generate structured data
type BreadcrumbListData = ReturnType<typeof generateBreadcrumbsData>;
type PodcastPageData = ReturnType<typeof generatePodcastPageData>;
type EpisodePageData = ReturnType<typeof generateEpisodePageData>;
type OrganizationData = ReturnType<typeof generateOrganizationData>;
type PodcastListData = ReturnType<typeof generatePodcastListData>;
type EpisodeListData = ReturnType<typeof generateEpisodeListData>;

type Content =
    | BreadcrumbsContent
    | PodcastPageContent
    | EpisodePageContent
    | OrganizationContent
    | PodcastListContent
    | EpisodeListContent;

// type guard functions for the content that is passed to the hook
const isPodcastPageContent = (content: Content): content is PodcastPageContent => "podcast" in content && "episodes" in content;
const isEpisodePageContent = (content: Content): content is EpisodePageContent => "episode" in content;
const isPodcastListContent = (content: Content): content is PodcastListContent => {
    return "podcasts" in content || "promotedPodcasts" in content || "heroCards" in content || "podcastSection" in content;
};
const isEpisodeListContent = (content: Content): content is EpisodeListContent => {
    return "episodes" in content || "episodeLists" in content || "episodeSection" in content || "promotedEpisodes" in content;
};
const isOrganizationContent = (content: Content): content is OrganizationContent => {
    return "url" in content && "logoImgUrl" in content && "supportEmail" in content;
};
const isBreadcrumbsContent = (content: Content): content is BreadcrumbsContent => Array.isArray(content);

// error handler
const handleError = (error: string) => {
    console.error(error);
    appInsights.trackException({ error: new Error(`STRUCTURED_DATA_CONTENT - ${error}`) });
};

const { locale } = strings.routes;

// overloading the hook to handle specific cases of content
function useStructuredData(pageType: typeof Pages.EpisodePage, content: EpisodePageContent): EpisodePageData;
function useStructuredData(pageType: typeof Pages.PodcastPage, content: PodcastPageContent): PodcastPageData;
function useStructuredData(contentType: typeof StructuredDataContent.PodcastList, content: PodcastListContent): PodcastListData;
function useStructuredData(contentType: typeof StructuredDataContent.EpisodeList, content: EpisodeListContent): EpisodeListData;
function useStructuredData(contentType: typeof StructuredDataContent.BreadcrumbsList, content: BreadcrumbsContent): BreadcrumbListData;
function useStructuredData(contentType: typeof StructuredDataContent.Organization, content: OrganizationContent): OrganizationData;

function useStructuredData(
    type: StructuredDataContentType | PageType,
    content: Content
): BreadcrumbListData | OrganizationData | PodcastListData | PodcastPageData | EpisodeListData | null {
    return useMemo(() => {
        if (type === Pages.PodcastPage && isPodcastPageContent(content)) {
            return generatePodcastPageData(content);
        }
        if (type === Pages.EpisodePage && isEpisodePageContent(content)) {
            return generateEpisodePageData(content);
        }
        if (type === StructuredDataContent.PodcastList && isPodcastListContent(content)) {
            return generatePodcastListData(content);
        }
        if (type === StructuredDataContent.EpisodeList && isEpisodeListContent(content)) {
            return generateEpisodeListData(content);
        }
        if (type === StructuredDataContent.BreadcrumbsList && isBreadcrumbsContent(content)) {
            return generateBreadcrumbsData(content);
        }
        if (type === StructuredDataContent.Organization && isOrganizationContent(content)) {
            return generateOrganizationData(content);
        }
        handleError(`Invalid content type for ${type} structured data`);
        return null;
    }, [content, type]);
}

const generatePodcastPageData = (content: PodcastPageContent) => {
    const { breadcrumbsList, podcast, episodes } = content;

    if (!podcast || !episodes) return {};

    const breadcrumbListData = generateBreadcrumbsData(breadcrumbsList);
    const podcastPageData = {
        "@context": "https://schema.org",
        "@type": "PodcastSeries",
        name: podcast.title,
        description: podcast.description,
        url: `${config.PUBLIC_URL}/${podcast.url}`,
        image: podcast.imageUrl,
        publisher: {
            "@type": "Organization",
            name: "Podme",
            logo: {
                "@type": "ImageObject",
                url: `${config.PUBLIC_URL}/${podcast.logoUrl}`,
                width: "96",
                height: "28",
            },
        },
        genre: podcast.categories?.map((category) => category.name).join(" ") ?? "",
        numberOfEpisodes: podcast.numberOfEpisodes,
        episode: episodes.map((episode) => ({
            "@type": "PodcastEpisode",
            name: episode.title,
            description: episode.description,
            url: `${config.PUBLIC_URL}/${podcast.url}/${episode.id}`,
            image: episode.imageUrl ?? episode.smallImageUrl ?? episode.mediumImageUrl,
            datePublished: episode.dateAdded,
            duration: episode.length,
            author: {
                "@type": "Person",
                name: episode.authorFullName,
            },
        })),
    };

    return { breadcrumbListData, podcastPageData };
};

const generateEpisodePageData = (content: EpisodePageContent) => {
    const { episode } = content;

    if (!episode) return {};

    const episodePageData = {
        "@context": "https://schema.org",
        "@type": "Episode",
        name: episode.title,
        partOfSeries: {
            "@type": "PodcastSeries",
            name: episode.podcastTitle,
        },
        description: episode.description,
        datePublished: episode.dateAdded,
        duration: episode.length,
        image: {
            "@type": "ImageObject",
            url: episode.imageUrl ?? episode.smallImageUrl ?? episode.mediumImageUrl,
        },
        author: {
            "@type": "Person",
            name: episode.authorFullName,
        },
    };

    return episodePageData;
};

const generateBreadcrumbsData = (content: BreadcrumbsContent) => {
    if (content.some((item) => !item.name || !item.url)) return {};

    return {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: content.map((item, index: number) => ({
            "@type": "ListItem",
            position: index + 1,
            name: item.name,
            item: `${config.PUBLIC_URL}/${item.url}/`,
        })),
    };
};

const generateOrganizationData = (content: OrganizationContent) => {
    const { url, logoImgUrl, supportEmail } = content;

    return {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "Podme",
        url: `${config.PUBLIC_URL}/${url}/`,
        logo: {
            "@type": "ImageObject",
            url: logoImgUrl,
            width: "96",
            height: "28",
        },
        contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer service",
            email: supportEmail,
        },
    };
};

const generatePodcastListData = (content: PodcastListContent) => {
    const { url, podcastSection } = content;

    const podcastList =
        (podcastSection as IPodcastCarousel).podcasts ??
        (podcastSection as PodcastsMainCarousel).podcasts ??
        (podcastSection as IPodcastPromoCarousel).promotedPodcasts ??
        (podcastSection as IListOfHeroCards).heroCards;

    if (!podcastList) return {};

    return {
        "@context": "https://schema.org",
        "@type": "ItemList",
        name: podcastSection.title,
        url: `${config.PUBLIC_URL}/${url}/`,
        itemListElement:
            podcastList &&
            podcastList.map((podcast, idx) => ({
                "@type": "ListItem",
                position: idx + 1,
                item: {
                    "@type": "PodcastSeries",
                    name: podcast.title,
                    url: `${config.PUBLIC_URL}/${locale}/${podcast.slug ?? podcast.destinationPath}`,
                    image: podcast.imageUrl,
                },
            })),
    };
};

const generateEpisodeListData = (content: EpisodeListContent) => {
    const { url, episodeSection } = content;
    const { locale } = strings.routes;

    const episodesList =
        (episodeSection as IEpisodeCarousel).episodes ??
        (episodeSection as IPodcastNuggets).episodeLists ??
        (episodeSection as IEpisodePromoCarousel).promotedEpisodes;

    if (!episodesList) return {};

    return {
        "@context": "https://schema.org",
        "@type": "ItemList",
        name: episodeSection.title,
        url: `${config.PUBLIC_URL}/${url}`,
        itemListElement:
            episodesList &&
            episodesList.map((episode, idx) => ({
                "@type": "ListItem",
                position: idx + 1,
                item: {
                    "@type": "PodcastEpisode",
                    name: episode.title,
                    url: `${config.PUBLIC_URL}/${locale}/${episode.destinationPath}`,
                    image: episode.imageUrl,
                },
            })),
    };
};

export default useStructuredData;
