import { Episode } from "@typings/index";
import { RootState } from "app/reducers/rootReducer";
import { call, put, select, takeLatest } from "redux-saga/effects";
import routes from "../../../api";

import {
    GetEpisodes,
    GetEpisodesFailed,
    GetEpisodesSuccess,
    GetPodcast,
    GetPodcastFailed,
    GetPodcastSuccess,
    GET_EPISODES,
    GET_EPISODES_FAILED,
    GET_EPISODES_SUCCESS,
    GET_PODCAST,
    GET_PODCAST_FAILED,
    GET_PODCAST_SUCCESS,
    MarkEpisodeCompletion,
    MarkEpisodeCompletionFailed,
    MarkEpisodeCompletionSuccess,
    MARK_EPISODE_COMPLETION,
    MARK_EPISODE_COMPLETION_FAILED,
    MARK_EPISODE_COMPLETION_SUCCESS,
} from "./types";

const podcastPageSelector = (state: RootState) => state.podcastPage;

function* getPodcast({ slug }: GetPodcast): any {
    try {
        const data = yield call(routes.getPodcast, slug);

        yield put<GetPodcastSuccess>({ type: GET_PODCAST_SUCCESS, podcast: data });
    } catch (error) {
        yield put<GetPodcastFailed>({ type: GET_PODCAST_FAILED, error });
    }
}

function* getEpisodes({ slug, pageSize, page, fetchByOldest, isAuthenticated }: GetEpisodes): any {
    try {
        let data;
        if (isAuthenticated) {
            data = yield call(routes.getEpisodesAuth, slug, pageSize, page, fetchByOldest);
        } else {
            data = yield call(routes.getEpisodes, slug, pageSize, page, fetchByOldest);
        }

        return yield put<GetEpisodesSuccess>({
            type: GET_EPISODES_SUCCESS,
            episodes: data,
            isSortedByOldest: fetchByOldest,
            page,
            numberOfEpisodes: data[0].totalNoOfEpisodes,
        });
    } catch (error) {
        return yield put<GetEpisodesFailed>({ type: GET_EPISODES_FAILED, error });
    }
}

function* markEpisodeCompletion({ episodeId, completed }: MarkEpisodeCompletion): any {
    try {
        const { episodes } = yield select(podcastPageSelector);

        const episode: Episode = episodes.find((e: Episode) => e.id === Number(episodeId));

        episode.hasCompleted = completed;

        yield put<MarkEpisodeCompletionSuccess>({ type: MARK_EPISODE_COMPLETION_SUCCESS });
    } catch (err) {
        yield put<MarkEpisodeCompletionFailed>({ type: MARK_EPISODE_COMPLETION_FAILED, err });
    }
}

export default [
    takeLatest(GET_PODCAST, getPodcast),
    takeLatest(GET_EPISODES, getEpisodes),
    takeLatest(MARK_EPISODE_COMPLETION, markEpisodeCompletion),
];
