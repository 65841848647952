import { Flex, Heading, List, ListItem, ListIcon } from "@chakra-ui/layout";
import { PodmeColor } from "@typings/index";
import { ReactComponent as CheckBlack } from "@images/icons/check-black.svg";
import OfferCard from "./OfferCard";
import { PricingContent } from "app/content/contentTypes";

export interface OfferPropositionProps {
    content: PricingContent;
    price: string;
    priceUnitWithMonth: string;
    discountLabel?: string;
    handleClick?: () => void;
}

function OfferProposition({ content, price, priceUnitWithMonth, discountLabel, handleClick }: OfferPropositionProps) {
    return (
        <Flex
            justifyContent={["center", null, null, "space-between"]}
            backgroundColor={PodmeColor.SoftWhite}
            padding={["1rem", "4rem 2rem", "10rem 7rem"]}
            margin={["0 1rem", "0 2rem", 0]}
            borderRadius="24px"
            flexWrap={["wrap", null, null, "nowrap"]}
            gap={["2rem", "3rem", "4rem"]}
        >
            <Flex flexDirection="column" color={PodmeColor.BlackPearl} gap="1.5rem" padding={["1rem", 0]}>
                <Heading as="h1" fontSize={["2em", "3em", "4em"]}>
                    {content.headline}
                </Heading>
                <List spacing="0.75rem" fontWeight={400} fontFamily="Inter">
                    {content.benefits.map((benefit, index) => (
                        <ListItem key={index}>
                            <ListIcon as={CheckBlack} />
                            {benefit}
                        </ListItem>
                    ))}
                </List>
            </Flex>
            <Flex flexShrink={2}>
                <OfferCard
                    title={content.items[0].subheadline}
                    price={price}
                    priceUnitWithMonth={priceUnitWithMonth}
                    ctaButtonText={content.items[0].cta.text}
                    description={content.items[0].paragraph}
                    disclaimer={content.disclaimer.content[0].content[0].value}
                    discountLabel={discountLabel}
                    index={0}
                    handleClick={() => (handleClick ? handleClick() : null)}
                />
            </Flex>
        </Flex>
    );
}

export default OfferProposition;
