import Hls, { HlsConfig } from "hls.js";

const hlsConfig: Partial<HlsConfig> = {
    appendErrorMaxRetry: 10,
    fragLoadingMaxRetry: 10,
    levelLoadingMaxRetry: 10,
    manifestLoadingMaxRetry: 10,
    maxMaxBufferLength: 60,
    maxBufferSize: 100000000,
    backBufferLength: 90,
    certLoadPolicy: {
        default: {
            maxTimeToFirstByteMs: 8000,
            maxLoadTimeMs: 20000,
            timeoutRetry: null,
            errorRetry: null,
        },
    },
    keyLoadPolicy: {
        default: {
            maxTimeToFirstByteMs: 8000,
            maxLoadTimeMs: 20000,
            timeoutRetry: {
                maxNumRetry: 1,
                retryDelayMs: 1000,
                maxRetryDelayMs: 20000,
                backoff: "linear",
            },
            errorRetry: {
                maxNumRetry: 8,
                retryDelayMs: 1000,
                maxRetryDelayMs: 20000,
                backoff: "linear",
            },
        },
    },
    manifestLoadPolicy: {
        default: {
            maxTimeToFirstByteMs: 8000,
            maxLoadTimeMs: 20000,
            timeoutRetry: {
                maxNumRetry: 2,
                retryDelayMs: 0,
                maxRetryDelayMs: 0,
            },
            errorRetry: {
                maxNumRetry: 1,
                retryDelayMs: 1000,
                maxRetryDelayMs: 8000,
            },
        },
    },
    playlistLoadPolicy: {
        default: {
            maxTimeToFirstByteMs: 10000,
            maxLoadTimeMs: 20000,
            timeoutRetry: {
                maxNumRetry: 2,
                retryDelayMs: 0,
                maxRetryDelayMs: 0,
            },
            errorRetry: {
                maxNumRetry: 2,
                retryDelayMs: 1000,
                maxRetryDelayMs: 8000,
            },
        },
    },
    fragLoadPolicy: {
        default: {
            maxTimeToFirstByteMs: 10000,
            maxLoadTimeMs: 120000,
            timeoutRetry: {
                maxNumRetry: 4,
                retryDelayMs: 0,
                maxRetryDelayMs: 0,
            },
            errorRetry: {
                maxNumRetry: 6,
                retryDelayMs: 1000,
                maxRetryDelayMs: 8000,
            },
        },
    },
    steeringManifestLoadPolicy: {
        default: {
            maxTimeToFirstByteMs: 10000,
            maxLoadTimeMs: 20000,
            timeoutRetry: {
                maxNumRetry: 2,
                retryDelayMs: 0,
                maxRetryDelayMs: 0,
            },
            errorRetry: {
                maxNumRetry: 1,
                retryDelayMs: 1000,
                maxRetryDelayMs: 8000,
            },
        },
    },
    enableDateRangeMetadataCues: true,
    enableEmsgMetadataCues: true,
    enableID3MetadataCues: true,
};

const HlsService = {
    createHlsInstance: (audioElement: HTMLAudioElement, audioUrl: string): Hls => {
        const hls = new Hls(hlsConfig);
        hls.loadSource(audioUrl);
        hls.attachMedia(audioElement);

        return hls;
    },
};

export default HlsService;
