import { IdentityProvider, IdpRedirectOption } from "@auth/authTypes";
import useAuth from "@auth/useAuth";
import strings from "app/locale/localization";
import { ComponentType, ReactNode, useCallback } from "react";
import { useHistory } from "react-router";

interface Props<P> {
    renderAs: ComponentType<P>;
    children: ReactNode;
    redirectUrl?: string;
    coupon?: string;
    onClickCallback?: () => void;
    [x: string]: unknown;
}

export default function CTACheckoutButton<T>({
    renderAs: Component,
    redirectUrl,
    coupon,
    onClickCallback,
    children,
    ...rest
}: Props<T>) {
    const routes = strings.routes;
    const url = redirectUrl || `/${routes.locale}/${routes.signup}/checkout${coupon ? `?coupon=${coupon}` : ""}`;

    const history = useHistory();
    const { isAuthenticated, loginWithIdp } = useAuth();

    const handleButtonClick = useCallback(
        (redirectUrl: string) => {
            if (onClickCallback) onClickCallback();

            if (isAuthenticated) {
                history.push(redirectUrl);
                return;
            }

            loginWithIdp(IdentityProvider.Schibsted, IdpRedirectOption.SignupPage, redirectUrl);
        },
        [isAuthenticated]
    );

    return (
        <Component {...(rest as T)} onClick={() => handleButtonClick(url)}>
            {children}
        </Component>
    );
}
