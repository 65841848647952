import routes from "../../../api";
import { call, put, PutEffect, select, takeLatest } from "redux-saga/effects";
import {
    ACCEPT_MIGRATION_MODAL_PROMPT,
    ACCEPT_MIGRATION_MODAL_PROMPT_FAILED,
    ACCEPT_MIGRATION_MODAL_PROMPT_SUCCESS,
    AcceptMigrationModalPromptFailed,
    AcceptMigrationModalPromptSuccess,
    DISMISS_MIGRATION_ERROR_BOX,
    DISMISS_MIGRATION_ERROR_BOX_FAILED,
    DISMISS_MIGRATION_ERROR_BOX_SUCCESS,
    DISMISS_MIGRATION_MODAL_PROMPT,
    DISMISS_MIGRATION_MODAL_PROMPT_FAILED,
    DISMISS_MIGRATION_MODAL_PROMPT_SUCCESS,
    DismissMigrationErrorBoxFailed,
    DismissMigrationErrorBoxSuccess,
    DismissMigrationModalPromptFailed,
    DismissMigrationModalPromptSuccess,
    GET_MIGRATION_COMPLETED_MODAL_PROMPT,
    GET_MIGRATION_COMPLETED_MODAL_PROMPT_FAILED,
    GET_MIGRATION_COMPLETED_MODAL_PROMPT_SUCCESS,
    GET_MIGRATION_MODAL_PROMPT,
    GET_MIGRATION_MODAL_PROMPT_FAILED,
    GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID,
    GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_FAILED,
    GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_SUCCESS,
    GET_MIGRATION_MODAL_PROMPT_SUCCESS,
    GetMigrationCompletedModalPromptFailed,
    GetMigrationCompletedModalPromptSuccess,
    GetMigrationModalPromptFailed,
    GetMigrationModalPromptHistoryRecordIdFailed,
    GetMigrationModalPromptHistoryRecordIdSuccess,
    GetMigrationModalPromptSuccess,
    MigrationErrorCode,
    TRIGGER_MIGRATION_BY_USER,
    TriggerMigrationByUseFailed,
    TriggerMigrationByUserSuccess,
    CHECK_MIGRATION_DATE,
} from "./types";
import { SagaReturnType } from "@typings/index";
import { RootState } from "app/reducers/rootReducer";
import Cookies from "universal-cookie";
import config from "../../../../app/config";
import strings from "app/locale/localization";
function* getMigrationModalPrompt(): SagaReturnType<PutEffect<GetMigrationModalPromptSuccess | GetMigrationModalPromptFailed>> {
    try {
        const cookies = new Cookies();
        let migrationDate = { daysLeft: 0, hardMigrationDateCopy: "" };

        const data = yield call(routes.getMigrationModalPrompt);
        if (data.canMigrate || data.showBanner) {
            migrationDate = yield call(routes.getMigrationDate, data.hardMigrationStartDate);
        }

        if (data.migrationSessionId && data.migrationSessionId !== "00000000-0000-0000-0000-000000000000") {
            cookies.set("migrationSessionId", data.migrationSessionId, {
                path: "/",
                maxAge: 3600,
            });
        }

        return yield put<GetMigrationModalPromptSuccess>({
            type: GET_MIGRATION_MODAL_PROMPT_SUCCESS,
            payload: {
                ...data,
                isVisible: data.shouldGetMigrationPrompt || data.migratedPodmeAccount,
                daysLeft: migrationDate.daysLeft || 0,
                hardMigrationDateCopy: migrationDate.hardMigrationDateCopy,
            },
        });
    } catch (err) {
        return yield put<GetMigrationModalPromptFailed>({
            type: GET_MIGRATION_MODAL_PROMPT_FAILED,
            err,
        });
    }
}

function* acceptMigrationModalPrompt(): SagaReturnType<
    PutEffect<AcceptMigrationModalPromptSuccess | AcceptMigrationModalPromptFailed>
> {
    try {
        const cookies = new Cookies();
        const migrationSessionId = cookies.get("migrationSessionId") || "";

        yield call(routes.patchMigrationModalPromptAccept, migrationSessionId);

        return yield put<AcceptMigrationModalPromptSuccess>({
            type: ACCEPT_MIGRATION_MODAL_PROMPT_SUCCESS,
            payload: { acceptSuccess: true, migrationSessionId: migrationSessionId },
        });
    } catch (err) {
        return yield put<AcceptMigrationModalPromptFailed>({
            type: ACCEPT_MIGRATION_MODAL_PROMPT_FAILED,
            err,
            showToastErrorMessage: true,
            message: strings.errors.general,
        });
    }
}

function* triggerMigrationByUser(): SagaReturnType<PutEffect<TriggerMigrationByUserSuccess | TriggerMigrationByUseFailed>> {
    try {
        const migrationSessionId = yield call(routes.getMigrationModalPromptHistoryRecordId);
        yield call(routes.patchMigrationModalPromptAccept, migrationSessionId);

        return yield put<AcceptMigrationModalPromptSuccess>({
            type: ACCEPT_MIGRATION_MODAL_PROMPT_SUCCESS,
            payload: { acceptSuccess: true, migrationSessionId: migrationSessionId },
        });
    } catch (err) {
        return yield put<AcceptMigrationModalPromptFailed>({
            type: ACCEPT_MIGRATION_MODAL_PROMPT_FAILED,
            err,
            showToastErrorMessage: true,
            message: strings.errors.general,
        });
    }
}

function* dismissMigrationModalPrompt(): SagaReturnType<
    PutEffect<DismissMigrationModalPromptSuccess | DismissMigrationModalPromptFailed>
> {
    try {
        const migrationSelector = (state: RootState) => state.migration;
        const { migrationSessionId, isHard, canMigrate, isError, isMigrationCompleted } = yield select(migrationSelector);

        if (isHard !== undefined && !isHard && canMigrate && !isError && !isMigrationCompleted) {
            yield call(routes.patchMigrationModalPromptDismiss, migrationSessionId);
        }

        return yield put<DismissMigrationModalPromptSuccess>({
            type: DISMISS_MIGRATION_MODAL_PROMPT_SUCCESS,
            payload: { isDismissed: true, isVisible: false },
        });
    } catch (err) {
        return yield put<DismissMigrationModalPromptFailed>({
            type: DISMISS_MIGRATION_MODAL_PROMPT_FAILED,
            err,
            showToastErrorMessage: true,
            message: strings.errors.general,
        });
    }
}

function* getMigrationModalPromptHistoryRecordId(): SagaReturnType<
    PutEffect<GetMigrationModalPromptHistoryRecordIdSuccess | GetMigrationModalPromptHistoryRecordIdFailed>
> {
    try {
        const cookies = new Cookies();
        const migrationSessionId = yield call(routes.getMigrationModalPromptHistoryRecordId);

        cookies.set("migrationSessionId", migrationSessionId, { path: "/", maxAge: 3600 });

        return yield put<GetMigrationModalPromptHistoryRecordIdSuccess>({
            type: GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_SUCCESS,
            payload: { migrationSessionId },
        });
    } catch (err) {
        return yield put<GetMigrationModalPromptHistoryRecordIdFailed>({
            type: GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_FAILED,
            err,
            showToastErrorMessage: true,
            message: strings.errors.general,
        });
    }
}

function* getMigrationCompletedModalPrompt(): SagaReturnType<PutEffect<
    GetMigrationCompletedModalPromptSuccess | GetMigrationCompletedModalPromptFailed
> | void> {
    try {
        const cookies = new Cookies();

        if (cookies.get("userIsMigrating")) {
            yield put({
                type: GET_MIGRATION_COMPLETED_MODAL_PROMPT,
            });

            const migrationSessionId = cookies.get("migrationSessionId");
            const schibstedSession: { access_token: string } = JSON.parse(localStorage.getItem("SCHIBSTED_SESSION") as string);

            cookies.set("mobileMigrationSessionId", migrationSessionId, {
                path: "/",
                maxAge: 3600,
            });
            const isMigrationCompleted = yield call(
                routes.getMigrationCompletedModalPrompt,
                migrationSessionId,
                schibstedSession.access_token
            );

            cookies.remove("userIsMigrating", { path: "/" });
            cookies.remove("migrationSessionId", { path: "/" });

            localStorage.removeItem("ROPC_SESSION");

            for (const entry in localStorage) {
                if (entry.includes(config.WEB_SIGN_IN_POLICY_NAME.toLowerCase())) localStorage.removeItem(entry);
            }

            return yield put({
                type: GET_MIGRATION_COMPLETED_MODAL_PROMPT_SUCCESS,
                payload: { isMigrationCompleted, isVisible: true },
            });
        }
    } catch (err: any) {
        let responseErr = err;

        if (err.response && (err.response.data as MigrationErrorCode)) {
            responseErr = err.response.data;

            return yield put({
                type: GET_MIGRATION_COMPLETED_MODAL_PROMPT_FAILED,
                err: responseErr,
            });
        }
        return yield put({
            type: GET_MIGRATION_COMPLETED_MODAL_PROMPT_FAILED,
        });
    }
}

function* dismissMigrationErrorBox(): SagaReturnType<PutEffect<DismissMigrationErrorBoxSuccess | DismissMigrationErrorBoxFailed>> {
    try {
        const cookies = new Cookies();

        cookies.remove("userIsMigrating", { path: "/" });
        cookies.remove("migrationSessionId", { path: "/" });
        cookies.remove("mobileMigrationSessionId", { path: "/" });

        return yield put({
            type: DISMISS_MIGRATION_ERROR_BOX_SUCCESS,
            payload: { isError: false, error: null },
        });
    } catch (err) {
        return yield put({
            type: DISMISS_MIGRATION_ERROR_BOX_FAILED,
            err,
        });
    }
}

export default [
    takeLatest(GET_MIGRATION_MODAL_PROMPT, getMigrationModalPrompt),
    takeLatest(CHECK_MIGRATION_DATE, getMigrationModalPrompt),
    takeLatest(ACCEPT_MIGRATION_MODAL_PROMPT, acceptMigrationModalPrompt),
    takeLatest(TRIGGER_MIGRATION_BY_USER, triggerMigrationByUser),
    takeLatest(DISMISS_MIGRATION_MODAL_PROMPT, dismissMigrationModalPrompt),
    takeLatest(GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID, getMigrationModalPromptHistoryRecordId),
    takeLatest(GET_MIGRATION_MODAL_PROMPT_SUCCESS, getMigrationCompletedModalPrompt),
    takeLatest(DISMISS_MIGRATION_ERROR_BOX, dismissMigrationErrorBox),
    takeLatest(DISMISS_MIGRATION_ERROR_BOX_SUCCESS, getMigrationCompletedModalPrompt),
];
