import { MutableRefObject, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { RootState } from "app/reducers/rootReducer";
import { Region } from "../../app/types";

const globalSelector = (state: RootState) => state.global;

const useDataController = (): MutableRefObject<null>[] => {
    const ref = useRef(null);
    const { location } = useSelector(globalSelector);

    useEffect(() => {
        const { init } = require("@schibsted/data-controller/dist/data-controller-sdk.cjs");

        if (ref.current) {
            const sdkInstance = init({
                brandName: "Podme",
                language: location === Region.Sweden ? "sv" : location,
                layout: "subheader",
                hideAfter: 5000,
                minimizeText: true,
            });

            sdkInstance.render(ref.current, { permanent: true });
        }
    }, []);

    return [ref];
};

export default useDataController;
