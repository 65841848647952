import { ReactElement } from "react";
import { Flex, Image, Link } from "@chakra-ui/react";
import strings from "@locale/localization";
import { Region } from "../types/index";

interface Props {
    justifyContent?: string;
}

export default function AppIcons({ justifyContent = "center" }: Props): ReactElement {
    const { locale } = strings.routes;

    function getPlaystoreUrl() {
        switch (locale) {
            case Region.Finland:
                return "https://play.google.com/store/apps/details?id=podme.pme&hl=fi";
            case Region.Norway:
                return "https://play.google.com/store/apps/details?id=podme.pme&hl=no";
            case Region.Sweden:
                return "https://play.google.com/store/apps/details?id=podme.pme&hl=se";
        }
    }

    function getAppstoreUrl() {
        switch (locale) {
            case Region.Finland:
                return "https://apps.apple.com/fi/app/podme/id1291728228?l=fi";
            case Region.Norway:
                return "https://itunes.apple.com/us/app/podme/id1291728228?ls=1&mt=8";
            case Region.Sweden:
                return "https://itunes.apple.com/us/app/podme/id1291728228?ls=1&mt=8";
        }
    }

    return (
        <Flex width="100%" gridGap="1rem" maxHeight="50px" justifyContent={justifyContent}>
            <Link target="_blank" rel="noopener noreferrer" href={getAppstoreUrl()} display="block">
                <Image
                    style={{ maxHeight: "100%", display: "inline" }}
                    src={`/assets/images/brand/appstore_logo_${locale}.svg`}
                    alt="app store"
                />
            </Link>
            <Link target="_blank" rel="noopener noreferrer" href={getPlaystoreUrl()} display="block">
                <Image
                    style={{ width: "auto", maxHeight: "100%", display: "inline" }}
                    src={`/assets/images/brand/google-play-${locale}.svg`}
                    alt="google play store"
                />
            </Link>
        </Flex>
    );
}
