import { ChakraProps, Flex, Heading } from "@chakra-ui/react";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import { FaqEntity } from "app/api/types";
import { ContentfulDocument } from "app/content/contentTypes";
import { FaqQuestions } from "..";

interface Props {
    faqContent: {
        headline?: string;
        textContent?: string;
        items: {
            question: string;
            answer: ContentfulDocument;
        }[];
    };
    faqSectionProps?: {
        answerAsDocumentNode?: boolean;
        accordionItemClickHandler?: (faq: FaqEntity) => void;
        testIds?: {
            faqSectionTestId?: string;
            faqHeaderTestId?: string;
        };
    };
}

const FaqSection = ({ faqContent, faqSectionProps, ...rest }: Props & ChakraProps) => {
    const { headline, textContent, items } = faqContent;
    const { answerAsDocumentNode, accordionItemClickHandler, testIds, ...cssStyles } = faqSectionProps || {};
    const { faqSectionTestId, faqHeaderTestId } = testIds || {};

    const hasHeadline = headline && headline.trim() !== "";
    const hasTextContent = textContent && textContent.trim() !== "";

    return (
        <Flex
            direction="column"
            gridGap="2rem"
            aria-labelledby={faqHeaderTestId}
            data-testid={faqSectionTestId}
            margin={["0 1rem", "0 2rem", "0 2rem", "0"]}
            {...cssStyles}
            {...rest}
        >
            <Flex direction="column" gridGap="0.5rem">
                {hasHeadline && (
                    <Heading as="h2" fontSize="2rem" id={faqHeaderTestId}>
                        {headline}
                    </Heading>
                )}
                {hasTextContent && <Prose dangerouslySetInnerHTML={{ __html: textContent }} />}
            </Flex>
            <FaqQuestions faqs={items} accordionItemClickHandler={accordionItemClickHandler} asDocumentNode={answerAsDocumentNode} />
        </Flex>
    );
};

export default FaqSection;
