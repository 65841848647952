import { PodmeColor } from "app/types";
import strings from "@locale/localization";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/reducers/rootReducer";
import { useEffect } from "react";
import { CHECK_MIGRATION_DATE } from "@containers/Migration/redux/types";

const migrationModalSelector = (state: RootState) => state.migration;
function useMigrationCountdown() {
    const dispatch = useDispatch();
    const { daysLeft } = useSelector(migrationModalSelector);
    const { titleWithCounter, title, titleWithCounterLastDay } = strings.migration.banner;

    const getMigrationStyle = () => {
        if (daysLeft >= 11 && daysLeft <= 20)
            return {
                color: PodmeColor.BlackPearl,
                backgroundColor: PodmeColor.WarningBackground,
                iconSrc: "/assets/images/icons/alert_info_black_icon.svg",
                linkIconSrc: "/assets/images/icons/external-link-black.svg",
            };
        if (daysLeft <= 10)
            return {
                color: PodmeColor.SoftWhite,
                backgroundColor: PodmeColor.AlertRedBackground,
                iconSrc: "/assets/images/icons/alert_triangle_white.svg",
                linkIconSrc: "/assets/images/icons/external-link.svg",
            };
        return {
            color: PodmeColor.SoftWhite,
            backgroundColor: PodmeColor.LicoriceBlue,
            iconSrc: "/assets/images/icons/alert_info_white_icon.svg",
            linkIconSrc: "/assets/images/icons/external-link.svg",
        };
    };
    const getMigrationInfoTitle = () => {
        if (daysLeft > 0) {
            if (daysLeft === 1) return titleWithCounterLastDay;
            return strings.formatString(titleWithCounter, daysLeft) as string;
        }
        return title;
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            dispatch({ type: CHECK_MIGRATION_DATE });
        }, 3600000);

        return () => clearInterval(intervalId);
    }, []);

    return { daysLeftToHardMigration: daysLeft, getMigrationStyle, getMigrationInfoTitle };
}

export default useMigrationCountdown;
