import { ReactElement } from "react";
import { IListOfHeroCards } from "../redux/types";
import MainCarousel from "@containers/StartPage/components/MainCarousel/MainCarousel";
import { PodcastsMainCarousel } from "app/content/contentTypes";

interface Props {
    content: IListOfHeroCards | PodcastsMainCarousel;
}

export default function ListOfHeroCards({ content }: Props): ReactElement {
    const carouselItems = (content as IListOfHeroCards).heroCards || (content as PodcastsMainCarousel).podcasts;

    // THE MAIN CAROUSEL CAN EITHER HAVE A LIST OF PODCASTS OR A LIST OF EPISODES
    // THAT'S WHY I'M REMOVING THE STRUCTURED DATA FOR NOW, IT'D BE TOO CUSTOM

    // if (carouselItems.every((item) => item.destination.startsWith("episode"))) {
    //     structuredData = useStructuredData(StructuredDataContent.EpisodeList, {
    //         url: `${config.PUBLIC_URL}${pathname}`,
    //         podcastSection: content,
    //     });
    // } else if (carouselItems.every((item) => item.destination.startsWith("podcast"))) {
    //     structuredData = useStructuredData(StructuredDataContent.PodcastList, {
    //         url: `${config.PUBLIC_URL}${pathname}`,
    //         podcastSection: content,
    //     });
    // }

    return (
        <>
            {/* <Helmet>
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
            </Helmet> */}
            <MainCarousel podcasts={carouselItems} onHomescreen />
        </>
    );
}
