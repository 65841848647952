import { CSSProperties, ReactElement } from "react";
import { Link as ReactLink } from "react-router-dom";
import { Box, Spinner, keyframes, Link } from "@chakra-ui/react";

import { CSSstylesObject, DeviceBreakpoints, PodmeColor } from "../../types";

//CSS common styles:
const glowAnimationKeyframes = keyframes`
0% {
    box-shadow: 0px 0px 2px 0px $white;
}

20% {
    box-shadow: 0px 0px 4px 0px $white;
}

50% {
    box-shadow: 0px 0px 6px 0px $white;
}

70% {
    box-shadow: 0px 0px 7px 0px $white;
}

90% {
    box-shadow: 0px 0px 4px 0px $white;
}

100% {
    box-shadow: 0px 0px 2px 0px $white;
}
`;
const glowEffectAnimation = `${glowAnimationKeyframes} infinite 1.2s linear forwards`;

const spinnerStyles: CSSstylesObject = {
    position: "relative",
    right: 0,
    marginLeft: "0.5rem",
    top: "calc(50% - 12px)",
};

const commonSxStyles = {
    "&:hover": {
        opacity: 0.9,
        transform: "scale(0.99)",
        textDecoration: "none",

        "&:disabled": {
            opacity: 0.5,
            transform: "none",
        },
    },

    "&:disabled": {
        cursor: "not-allowed",
        opacity: 0.5,
    },

    "&:focus,&:focus-visible,&:focus-within": {
        outline: "none",
        boxShadow: `0px 0px 7px 0px ${PodmeColor.SoftWhite}`,
        animation: `${glowEffectAnimation}`,
    },
};

interface ButtonProps {
    color?: PodmeColor;
    background?: PodmeColor;
    shadow?: boolean;
    children?: string | ReactElement | ReactElement[] | undefined;
    to?: string | { pathname: string; state?: { [key: string]: any } };
    isLoading?: boolean;
    theme?: CSSProperties;
    rounded?: boolean;
    styles?: any;
    border?: string;
    spinnerBoxSize?: number;
    isDisabled?: boolean;
    [x: string]: any;
    onClick?: (evt?: any) => void;
}

export function PrimaryButton({
    color,
    background,
    isLoading,
    shadow = false,
    children,
    to,
    rounded,
    ...rest
}: ButtonProps): ReactElement {
    const primaryButtonStyles: CSSstylesObject = {
        background: background ? background : PodmeColor.Cyan,
        color: color ? color : PodmeColor.BlackPearl,
        fontSize: "1rem",
        fontWeight: "600",
        textDecoration: "none",
        display: "inline-block",
        textAlign: "center",
        position: "relative",
        padding: "1rem 2rem",
        shadow: shadow ? `2px 4px 3px -5px ${PodmeColor.BlackPearl}` : "none",
        borderRadius: rounded ? "100px" : "12px",
    };
    //advanced styles must be passed in through sx prop:
    const sxStyles = {
        "&:disabled": {
            opacity: 0.8,
        },
    };
    if (to) {
        return (
            <Link href={`${to}`}>
                <Box as={"button"} sx={sxStyles} {...primaryButtonStyles} style={{ cursor: "pointer" }} {...rest}>
                    {children}
                </Box>
            </Link>
        );
    }

    return (
        <Box as={"button"} sx={sxStyles} {...primaryButtonStyles} disabled={isLoading} {...rest}>
            {children}
        </Box>
    );
}

export function PrimaryButtonNew({
    color,
    background,
    isLoading,
    shadow = false,
    children,
    to,
    rounded = false,
    border,
    styles,
    spinnerBoxSize,
    isDisabled,
    ...rest
}: ButtonProps): ReactElement {
    const primaryButtonNewStyles = {
        color: color ? color : PodmeColor.BlackPearl,
        background: background ? background : PodmeColor.Cyan,
        borderRadius: rounded ? "3rem" : "1rem",
        boxShadow: shadow ? `2px 4px 3px -5px ${PodmeColor.BlackPearl}` : "none",
        border,
        minWidth: "50px",
        fontSize: "1rem",
        fontWeight: "500",
        textDecoration: "none",
        minHeight: "55px",
        position: "relative",
        padding: "1rem 1.5rem",
        transition: "transform 150ms cubic-bezier(0.215, 0.61, 0.355, 1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "nowrap",
        opacity: isLoading ? 0.7 : 1,
        cursor: isLoading ? "default" : "pointer",
        ...styles,
    };
    const primaryButtonSXstyles = {
        [`@media ${DeviceBreakpoints.SmallOnly}`]: {
            fontSize: "16px",
        },
    };

    if (to) {
        return (
            <Link
                as={ReactLink}
                {...primaryButtonNewStyles}
                sx={{ ...commonSxStyles, ...primaryButtonSXstyles }}
                to={to}
                style={{ width: "100% " }}
                {...rest}
            >
                {children}
            </Link>
        );
    }

    return (
        <Box
            as="button"
            disabled={isDisabled || isLoading}
            {...primaryButtonNewStyles}
            sx={{ ...commonSxStyles, ...primaryButtonSXstyles }}
            {...rest}
        >
            {children}
            {isLoading && (
                <Spinner
                    {...spinnerStyles}
                    thickness="2px"
                    top="unset"
                    size="sm"
                    boxSize={spinnerBoxSize}
                    color={color ?? PodmeColor.BlackPearl}
                />
            )}
        </Box>
    );
}

export function SecondaryButton({
    color,
    background,
    isLoading,
    shadow = false,
    children,
    to,
    rounded = false,
    border,
    styles,
    spinnerBoxSize,
    isDisabled,
    ...rest
}: ButtonProps): ReactElement {
    const secondaryButtonStyles = {
        color: color ? color : PodmeColor.BlackPearl,
        background: background ?? PodmeColor.SoftWhite,
        borderRadius: rounded ? "3rem" : "1rem",
        boxShadow: shadow ? `2px 4px 3px -5px ${PodmeColor.BlackPearl}` : "none",
        border,
        minWidth: "50px",
        whiteSpace: "nowrap",
        fontSize: "min(4vw,1rem)",
        fontWeight: 500,
        textDecoration: "none",
        position: "relative",
        transition: "transform 150ms cubic-bezier(0.215, 0.61, 0.355, 1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem 1.5rem",
        opacity: isLoading ? 0.7 : 1,
        cursor: isLoading ? "default" : "pointer",
        ...styles,
    };

    if (to) {
        return (
            <Link
                as={ReactLink}
                to={to}
                {...secondaryButtonStyles}
                sx={{
                    ...commonSxStyles,
                }}
                _hover={{ textDecoration: "none" }}
                {...rest}
            >
                {children}
            </Link>
        );
    }

    return (
        <Box
            as="button"
            {...secondaryButtonStyles}
            disabled={isDisabled || isLoading}
            filter={isLoading ? "brightness(0.95)" : undefined}
            sx={{
                ...commonSxStyles,
            }}
            _hover={{ textDecoration: "none" }}
            {...rest}
        >
            {children}
            {isLoading && (
                <Spinner
                    {...spinnerStyles}
                    thickness="2px"
                    top="unset"
                    size="sm"
                    color={color ?? PodmeColor.BlackPearl}
                    boxSize={spinnerBoxSize}
                    filter="brightness(1.1)"
                />
            )}
        </Box>
    );
}

export function TertiaryButton({
    color,
    background,
    isLoading,
    shadow = false,
    children,
    to,
    rounded = false,
    border,
    styles,
    spinnerBoxSize,
    ...rest
}: ButtonProps): ReactElement {
    const secondaryButtonStyles = {
        color: color ? color : PodmeColor.SoftWhite,
        background: background ?? PodmeColor.BlackPearl,
        borderRadius: rounded ? "3rem" : "1rem",
        boxShadow: shadow ? `2px 4px 3px -5px ${PodmeColor.BlackPearl}` : "none",
        border,
        minWidth: "50px",
        whiteSpace: "nowrap",
        fontSize: "1rem",
        fontWeight: 500,
        textDecoration: "none",
        position: "relative",
        transition: "transform 150ms cubic-bezier(0.215, 0.61, 0.355, 1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem 1.5rem",
        opacity: isLoading ? 0.7 : 1,
        cursor: isLoading ? "default" : "pointer",
        ...styles,
    };

    if (to) {
        return (
            <Link
                as={ReactLink}
                to={to}
                {...secondaryButtonStyles}
                sx={{
                    ...commonSxStyles,
                    "&:disabled": {
                        backgroundColor: `${PodmeColor.BlackPearl} !important`,
                        color: PodmeColor.SoftWhite,
                    },
                }}
                _hover={{ textDecoration: "none" }}
                {...rest}
            >
                {children}
            </Link>
        );
    }

    return (
        <Box
            as="button"
            {...secondaryButtonStyles}
            disabled={isLoading}
            sx={{
                ...commonSxStyles,
                "&:focus,&:focus-visible,&:focus-within": { boxShadow: `0px 0px 7px 0px ${PodmeColor.Cinder}` },
                "&:disabled": {
                    backgroundColor: `${PodmeColor.BlackPearl} !important`,
                    color: PodmeColor.SoftWhite,
                },
            }}
            _hover={{ textDecoration: "none" }}
            {...rest}
        >
            {children}
            {isLoading && (
                <Spinner
                    {...spinnerStyles}
                    thickness="2px"
                    top="unset"
                    size="sm"
                    color={color ?? PodmeColor.BlackPearl}
                    boxSize={spinnerBoxSize}
                />
            )}
        </Box>
    );
}

export default PrimaryButton;
