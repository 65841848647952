import { AnyAction } from "redux";
import types, {
    CANCEL_RENEWAL,
    CANCEL_RENEWAL_FAILED,
    CANCEL_RENEWAL_SUCCESS,
    REACTIVATE_RENEWAL,
    REACTIVATE_RENEWAL_SUCCESS,
    SettingsPageState,
} from "./SettingsPageTypes";

const defaultState: SettingsPageState = {
    transactions: [],
    transaction: null,
    transactionCount: 0,
    error: null,
    subscriptionCancelled: false,
    subscriptionRenewed: false,
    isLoading: false,
    failedTransaction: null,
};

function SettingsPageReducer(state = defaultState, action: AnyAction): SettingsPageState {
    switch (action.type) {
        case types.MY_TRANSACTIONS_LOADED:
            return {
                ...state,
                transactions: action.response,
            };

        case types.MY_TRANSACTIONS_LOADED_FAILED:
            return {
                ...state,
                error: "Failed to load user transaction details",
            };

        case types.TRANSACTION_COUNT_LODAED:
            if (action.response) {
                return {
                    ...state,
                    transactionCount: action.response,
                };
            }

            return state;

        case types.TRANSACTION_COUNT_LODAED_FAILED:
            return {
                ...state,
                error: "Failed to load user podcasts",
            };

        case types.MY_TRANSACTION_LOADED:
            return {
                ...state,
                transaction: action.response,
            };

        case types.MY_TRANSACTION_LOADED_FAILED:
            return {
                ...state,
                error: "Failed to load transaction details",
            };

        case types.MY_FAILED_TRANSACTION_LOADED:
            return {
                ...state,
                failedTransaction: action.response,
            };

        case types.MY_FAILED_TRANSACTION_LOADED_FAILED:
            return {
                ...state,
                error: "Failed to load transaction details",
            };

        case REACTIVATE_RENEWAL:
            return {
                ...state,
                subscriptionRenewed: false,
                subscriptionCancelled: false,
                isLoading: true,
            };

        case REACTIVATE_RENEWAL_SUCCESS:
            return {
                ...state,
                subscriptionRenewed: true,
                isLoading: false,
            };

        case CANCEL_RENEWAL:
            return {
                ...state,
                subscriptionCancelled: false,
                subscriptionRenewed: false,
                isLoading: true,
            };

        case CANCEL_RENEWAL_SUCCESS:
            return {
                ...state,
                subscriptionCancelled: true,
                isLoading: false,
            };

        case CANCEL_RENEWAL_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
}

export default SettingsPageReducer;
