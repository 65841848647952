import strings from "@locale/localization";

export function getErrorMessageByCode(errorCode?: number): string {
    const string = strings.errors.adyen;

    switch (errorCode) {
        case 2:
            return string.refused;
        case 5:
            return string.blockedCard;
        case 6:
            return string.cardExpired;
        case 7:
            return string.invalidAmount;
        case 8:
            return string.invalidCardNumber;

        case 9:
            return string.issuerUnavailable;
        case 10:
            return string.notSupported;

        case 11:
            return string.threeDSFailed;

        case 12:
            return string.notEnoughBalance;

        case 20:
            return string.fraud;

        case 24:
            return string.cvcDeclined;

        default:
            return string.generalError;
    }
}

export enum AzureErrorCode {
    wrongCredentials = "AADB2C90225",
}
export function getErrorMessageByAzureCode(errorCode?: string): string {
    const string = strings.errors;

    if (errorCode === AzureErrorCode.wrongCredentials) return string.invalidDetailsSent;
    return string.general;
}
