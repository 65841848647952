import { ReactElement, useCallback } from "react";
import { Box, Heading, Text, List, ListItem, Flex } from "@chakra-ui/react";
import Cookies from "universal-cookie";

import strings from "@locale/localization";

import { PodmeColor } from "../../../types";
import Span from "../../../components/Span";
import { PrimaryButtonNew } from "../../../components/buttons";
import CheckmarkListIcon from "../../../components/CheckmarkListIcon";
import { ReactComponent as PremiumBadge } from "@images/icons/premium-57px.svg";
import { Link } from "react-router-dom";

interface PremiumPromoViewProps {
    isAuthenticated: boolean;
    showTrialCopy?: boolean;
    perks: string[] | undefined;
    episodeClicked?: boolean;
    ctaOnClick: () => void;
}

export default function PremiumPromoView({
    isAuthenticated,
    showTrialCopy = true,
    perks,
    episodeClicked,
    ctaOnClick,
}: PremiumPromoViewProps): ReactElement {
    const string = strings.onboardingModal;
    const { locale, login } = strings.routes;

    const goBackUrl = typeof window !== "undefined" ? window.location.pathname : "";

    const handleCookieSet = useCallback(() => {
        const cookies = new Cookies();
        cookies.set("prevPath", location.pathname, {
            path: "/",
            maxAge: 3600,
        });
    }, [location]);

    return (
        <Box
            gridColumn={"1 / -1"}
            gridRow={"1 / -1"}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gridGap="4rem"
        >
            <Box display="flex" flexDirection="column" gridGap="2rem" position="relative" zIndex={1}>
                <Flex flexDirection="column" gridGap="0.5rem">
                    <PremiumBadge style={{ transform: "scale(1.27)", transformOrigin: "left" }} />
                    <Heading as="h1" fontSize="1.875rem" fontWeight="600">
                        {episodeClicked
                            ? strings.formatString(string.titleEpisode, "Premium")
                            : strings.formatString(string.titleGeneral, "Premium")}
                    </Heading>
                </Flex>
                <Box display="flex" flexDirection="column" gridGap={4}>
                    <Heading as="h3" fontSize="1rem" fontWeight="500">
                        {strings.formatString(string.perksTitle, <Span color={PodmeColor.Cyan}>Premium</Span>)}
                    </Heading>
                    <List size="lg" display="flex" flexDir="column" gridGap="1rem" maxH={["unset", "15rem"]} overflowY="auto">
                        {perks &&
                            perks.map((perk) => (
                                <ListItem display="flex" key={perk} color={PodmeColor.Silver}>
                                    <CheckmarkListIcon />
                                    {perk}
                                </ListItem>
                            ))}
                        {showTrialCopy && (
                            <ListItem color={PodmeColor.Silver} display="flex">
                                <CheckmarkListIcon />
                                <Text as="span">{string.trialCopyNewUsers}</Text>
                            </ListItem>
                        )}
                    </List>
                </Box>
            </Box>
            <Box
                display="flex"
                flexDir="column"
                gridGap={[6, 12]}
                alignItems="center"
                position={["sticky", "relative"]}
                zIndex={1}
                bottom={0}
            >
                <PrimaryButtonNew
                    background={PodmeColor.Cyan}
                    width={["100%"]}
                    onClick={ctaOnClick}
                    fontSize="1rem"
                    borderRadius="1rem"
                >
                    {string.buttonCta}
                </PrimaryButtonNew>
                {!isAuthenticated && (
                    <Link to={{ pathname: `/${locale}/${login}`, state: { goBackUrl } }} onClick={handleCookieSet}>
                        <Text textAlign="left">
                            {strings.formatString(string.alreadyHaveAccount, <Span isUnderlined>{string.login}</Span>)}
                        </Text>
                    </Link>
                )}
            </Box>
        </Box>
    );
}
