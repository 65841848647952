import { ReactElement, useState } from "react";
import { animated, useSpring } from "react-spring";
import { Box, Flex, Image, Select } from "@chakra-ui/react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { CSSstylesObject, PodmeColor, Region } from "../../types";

const flagStyle: CSSstylesObject = {
    width: "auto",
    height: " 25px",
    maxHeight: "100%",
    pointerEvents: "none",
    borderRadius: "8px",
};

const listElementStyle: CSSstylesObject = {
    borderRadius: "8px",
    padding: "0.6rem 1.5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0,
};

interface Props {
    locale: Region;
    onChange: (value: string) => void;
}

export default function CountrySelect({ locale, onChange }: Props): ReactElement {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const animatedListStyle: any = useSpring({
        config: { duration: 150 },
        from: { visibility: "hidden" },
        to: { visibility: isOpen ? "visible" : "hidden" },
    });

    function renderSwitch(loc: Region) {
        switch (loc) {
            case Region.Norway:
                return (
                    <Flex justifyContent="space-between" alignItems="center" key="no" width="100%">
                        <Image {...flagStyle} src={`/assets/images/icons/norway_flag.svg`} alt="flag" />
                        {isOpen ? <FaChevronUp color={PodmeColor.Silver} /> : <FaChevronDown color={PodmeColor.Silver} />}
                    </Flex>
                );
            case Region.Finland:
                return (
                    <Flex justifyContent="space-between" alignItems="center" key="fi" width="100%">
                        <Image {...flagStyle} src={`/assets/images/icons/finland_flag.svg`} alt="flag" />
                        {isOpen ? <FaChevronUp color={PodmeColor.Silver} /> : <FaChevronDown color={PodmeColor.Silver} />}
                    </Flex>
                );
            default:
                return (
                    <Flex justifyContent="space-between" alignItems="center" key="se" width="100%">
                        <Image {...flagStyle} src={`/assets/images/icons/sweden_flag.svg`} alt="flag" />
                        {isOpen ? <FaChevronUp color={PodmeColor.Silver} /> : <FaChevronDown color={PodmeColor.Silver} />}
                    </Flex>
                );
        }
    }

    return (
        <Box
            position="relative"
            minHeight="0px"
            minWidth="0px"
            border={`1px solid ${PodmeColor.LicoriceBlue}`}
            background={PodmeColor.LicoriceBlue}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="0.5rem"
            transition="border-color 0.2s ease 0s"
            borderRadius="8px"
            width="88px"
            color={PodmeColor.BlackPearl}
            cursor="pointer"
            onClick={() => setIsOpen(!isOpen)}
            _hover={{
                background: `${PodmeColor.Cinder}`,
            }}
        >
            {renderSwitch(locale)}

            <Select
                cursor="pointer"
                left="0px"
                opacity={0}
                position="absolute"
                top="0px"
                width="100%"
                zIndex={2}
                display="none"
                onChange={(evt: { target: { value: string } }) => onChange(evt.target.value)}
                value={locale}
                name="location"
            >
                <option value="se">Sverige</option>
                <option value="no">Norge</option>
                <option value="fi">Suomi</option>
            </Select>

            <animated.ul
                style={{
                    ...animatedListStyle,
                    listStyle: "none",
                    margin: 0,
                    padding: 0,
                    position: "absolute",
                    top: "-140px",
                    left: 0,
                    right: 0,
                    borderRadius: "8px",
                    backgroundColor: PodmeColor.Cinder,
                }}
            >
                <Box
                    as={"li"}
                    {...listElementStyle}
                    backgroundColor={locale === "se" ? PodmeColor.LicoriceBlue : "inherit"}
                    onClick={() => {
                        onChange("se");
                        setIsOpen(false);
                    }}
                >
                    <img
                        style={{ height: "25px", borderRadius: "8px" }}
                        src="/assets/images/icons/sweden_flag.svg"
                        alt="sweden_flag"
                    />
                </Box>
                <Box
                    as={"li"}
                    {...listElementStyle}
                    backgroundColor={locale === "no" ? PodmeColor.LicoriceBlue : "inherit"}
                    onClick={() => {
                        onChange("no");
                        setIsOpen(false);
                    }}
                >
                    <img
                        style={{ height: "25px", borderRadius: "8px" }}
                        src="/assets/images/icons/norway_flag.svg"
                        alt="norway_flag"
                    />
                </Box>
                <Box
                    as={"li"}
                    {...listElementStyle}
                    backgroundColor={locale === "fi" ? PodmeColor.LicoriceBlue : "inherit"}
                    onClick={() => {
                        onChange("fi");
                        setIsOpen(false);
                    }}
                >
                    <img
                        style={{ height: "25px", borderRadius: "8px" }}
                        src="/assets/images/icons/finland_flag.svg"
                        alt="finland_flag"
                    />
                </Box>
            </animated.ul>
        </Box>
    );
}
