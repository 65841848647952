import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Flex, Box, Button, Image, Text } from "@chakra-ui/react";

import LoadingIcon from "@components/LoadingIcon";
import VolumeControl from "@components/VolumeControl/VolumeControl";
import { PodmeColor } from "@typings/index";
import { PlayObject } from "@containers/GlobalPlayer/redux/GlobalPlayerTypes";
import { SoundState } from "@containers/SoundProvider/types";
import strings from "@locale/localization";
import AudioSlider from "@components/AudioSlider/AudioSlider";
import { formatEpisodeDuration, playerTimeFormat, playerTimeToSeconds } from "@utils/index";
import { useDeviceOnline } from "../../../../../app/hooks";

const STORED_VOLUME_KEY = "player-volume";

interface IProps {
    playObject: PlayObject;
    sound: SoundState;
    skipBackwards: () => void;
    skipForwards: () => void;
    setVolume: (evt: any) => void;
    setPlayPosition: (evt: any) => void;
    togglePlay: (evt: any) => void;
    toggleMuted: (evt: any) => void;
    [x: string]: any;
}

function DesktopPlayer({
    playObject,
    sound,
    setPlayPosition,
    skipBackwards,
    skipForwards,
    togglePlay,
    setVolume,
    toggleMuted,
    ...rest
}: IProps): ReactElement {
    const { locale } = strings.routes;
    const episodeTitleRef = useRef<HTMLParagraphElement>(null);
    const [audioTrackChanging, setAudioTrackChanging] = useState(false);
    const [playedTimeLoading, setPlayedTimeLoading] = useState<boolean>(false);
    const [isAudioMuted, setAudioMuted] = useState(sound.currentAudio !== undefined && sound.isMuted);
    const isOnline = useDeviceOnline();

    const { currentTime, totalTime } = useMemo(() => {
        if (sound.currentAudio === undefined && playObject.currentSpot) {
            return formatEpisodeDuration(playerTimeToSeconds(playObject.currentSpot), playObject.length);
        }
        if (sound.currentAudio?.duration) {
            return formatEpisodeDuration(sound.currentTime, playerTimeFormat(String(sound.currentAudio.duration)));
        }
        return formatEpisodeDuration(sound.currentTime, playObject.length);
    }, [sound, playObject.length]);
    const [currentTimeStamp, setCurrentTimeStamp] = useState<string>(currentTime);

    const playPosition = useMemo(() => {
        if (sound.currentAudio === undefined) {
            const durationInSeconds = playerTimeToSeconds(playObject.length);
            const timeInSeconds = playerTimeToSeconds(playObject.currentSpot);
            return (timeInSeconds / durationInSeconds) * 100;
        }

        return (sound.currentTime / sound.currentAudio.duration) * 100;
    }, [sound, playObject]);

    useEffect(() => {
        if (sound.isLoading) {
            setPlayedTimeLoading(true);
        } else {
            setTimeout(() => {
                setPlayedTimeLoading(false);
            }, 300);
        }
    }, [sound.isLoading]);

    useEffect(() => {
        if (!audioTrackChanging) setCurrentTimeStamp(currentTime);
    }, [currentTime]);

    useEffect(() => {
        if (sound.currentAudio) {
            setAudioMuted(sound.isMuted);
        } else {
            const storedVolumeState = localStorage.getItem(STORED_VOLUME_KEY);
            if (storedVolumeState) {
                const parsedValue: { volume: number; muted: boolean } = JSON.parse(storedVolumeState);
                setAudioMuted(parsedValue.muted);
            }
        }
    }, [sound.isMuted]);

    return (
        <Flex
            justifyContent={["space-between", "center"]}
            alignItems="center"
            gridGap={[2, 2, 3]}
            width="100%"
            maxWidth="85rem"
            padding="0.55rem 2rem"
            sx={{
                "* p": {
                    fontFamily: "Garnett, sans-serif",
                },
            }}
            {...rest}
        >
            <Flex gridGap={2} alignItems="center" flex="1" zIndex="1">
                <Flex width="100%">
                    <Flex justifyContent="space-between" width="100%" gridGap={"1rem"}>
                        <Flex gridGap="0.75rem" flex="2" alignItems="center">
                            <Flex alignItems="center" height="2.5rem" width="2.5rem" flexShrink={0}>
                                <Image
                                    width={"auto"}
                                    height={"auto"}
                                    borderRadius={"4px"}
                                    src={playObject?.imageUrl}
                                    alt={playObject?.title}
                                />
                            </Flex>
                            <Flex flexDirection="column" justifyContent="center" flex="3" width="fit-content" maxWidth="20rem">
                                <Flex justifyContent="space-between" alignItems="flex-end" lineHeight="1em">
                                    <Box>
                                        <Link to={`/${locale}/${playObject?.slug}`}>
                                            <Text
                                                color={PodmeColor.Silver}
                                                lineHeight={1.25}
                                                fontSize={"clamp(9px, calc(2vw + 0.1rem), 12px)"}
                                                fontStyle="normal"
                                                letterSpacing={"-0.01em"}
                                                noOfLines={1}
                                                wordBreak="break-all"
                                            >
                                                {playObject?.podcastTitle}
                                            </Text>
                                        </Link>
                                    </Box>
                                </Flex>
                                <Box lineHeight="1em" minHeight={"1rem"} overflow={"hidden"}>
                                    <Link to={`/${locale}/${playObject.slug}/${playObject.id}`}>
                                        <Text
                                            fontSize={["0.875rem", "1em", "0.9rem"]}
                                            fontWeight={600}
                                            noOfLines={[1]}
                                            ref={episodeTitleRef}
                                            wordBreak="break-all"
                                        >
                                            {playObject?.title}
                                        </Text>
                                    </Link>
                                </Box>
                            </Flex>
                        </Flex>
                        <Flex alignItems="center" flex="6">
                            <Box width={sound.currentTime >= 3600 ? "4.2rem" : "3rem"}>
                                <Text
                                    textAlign="right"
                                    width="100%"
                                    color={PodmeColor.White}
                                    fontWeight={"600"}
                                    fontSize={"clamp(10px, calc(2vw + 0.1rem), 12px)"}
                                >
                                    {currentTimeStamp}
                                </Text>
                            </Box>
                            <Box display={"flex"} alignItems={"center"} padding={"0 0.8rem"} width={"100%"}>
                                <AudioSlider
                                    value={isNaN(playPosition) ? 0 : playPosition}
                                    audioTrackChanging={audioTrackChanging}
                                    setAudioTrackChanging={setAudioTrackChanging}
                                    setCurrentTimeStamp={setCurrentTimeStamp}
                                    totalTime={totalTime}
                                    handleOnChange={(val) => {
                                        setPlayPosition(val);
                                    }}
                                />
                            </Box>
                            <Text color={PodmeColor.Silver} fontSize={"clamp(10px, calc(2vw + 0.1rem), 12px)"}>
                                {totalTime}
                            </Text>
                        </Flex>
                        <Flex flex="1" justifyContent="flex-end">
                            <Flex
                                gridGap="1rem"
                                sx={{
                                    svg: {
                                        maxHeight: "30px",
                                        transition: "all 100ms ease",
                                    },
                                    button: {
                                        outline: "none",
                                        boxShadow: "none",
                                        border: "none",
                                    },
                                    "button:hover": {
                                        filter: "opacity(0.9)",
                                    },
                                    "button:active": {
                                        filter: "opacity(0.7)",
                                    },
                                    "button:focus": {
                                        outline: "none",
                                        boxShadow: "none",
                                        border: "none",
                                    },
                                }}
                            >
                                <Flex alignItems="center" maxWidth="30px">
                                    <Button
                                        variant="unstyled"
                                        onClick={skipBackwards}
                                        disabled={sound.currentAudio === undefined || !isOnline}
                                        background={"none"}
                                        padding={"0"}
                                        transition={"all 100ms ease-in"}
                                        minWidth="unset"
                                        boxSize="30px"
                                        _focus={{
                                            boxShadow: "unset",
                                        }}
                                        _hover={{
                                            filter: "opacity(0.9)",
                                        }}
                                        _active={{
                                            filter: "opacity(0.7)",
                                        }}
                                    >
                                        <Image src="/assets/images/icons/skip-backwards.svg" alt="skip backwards button" />
                                    </Button>
                                </Flex>
                                <Flex flexShrink={0} width={"2rem"} justifyContent="center" alignItems="center">
                                    {playedTimeLoading || !isOnline ? (
                                        <Box rounded="50%" bgColor={PodmeColor.SoftWhite}>
                                            <LoadingIcon variant="white" spinnerWidth="32px" spinnerHeight="32px" />
                                        </Box>
                                    ) : (
                                        <Button
                                            onClick={togglePlay}
                                            background={PodmeColor.SoftWhite}
                                            padding={"0"}
                                            transition={"all 100ms ease-in"}
                                            rounded="50%"
                                            boxSize="2rem"
                                            minWidth="unset"
                                        >
                                            {sound.isPlaying ? (
                                                <Image
                                                    filter="invert(1)"
                                                    src="/assets/images/icons/play-pause.svg"
                                                    alt="pause audio button"
                                                />
                                            ) : (
                                                <Image
                                                    filter="invert(1)"
                                                    src="/assets/images/icons/play-play.svg"
                                                    alt="play audio button"
                                                />
                                            )}
                                        </Button>
                                    )}
                                </Flex>
                                <Flex alignItems="center" maxWidth="30px">
                                    <Button
                                        variant="unstyled"
                                        onClick={skipForwards}
                                        disabled={sound.currentAudio === undefined || !isOnline}
                                        background={"none"}
                                        padding={"0"}
                                        transition={"all 100ms ease-in"}
                                        minWidth="unset"
                                        boxSize="30px"
                                        _focus={{
                                            boxShadow: "unset",
                                        }}
                                        _hover={{
                                            filter: "opacity(0.9)",
                                        }}
                                        _active={{
                                            filter: "opacity(0.7)",
                                        }}
                                    >
                                        <Image src="/assets/images/icons/skip-forward.svg" alt="skip forward button" />
                                    </Button>
                                </Flex>
                                <VolumeControl
                                    value={sound.volume}
                                    isMuted={isAudioMuted}
                                    setVolume={setVolume}
                                    toggleMuted={toggleMuted}
                                    isDisabled={sound.currentAudio === undefined || !isOnline}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default DesktopPlayer;
