import { AnyAction } from "redux";
import {
    ACCEPT_MIGRATION_MODAL_PROMPT,
    ACCEPT_MIGRATION_MODAL_PROMPT_FAILED,
    ACCEPT_MIGRATION_MODAL_PROMPT_SUCCESS,
    DISMISS_MIGRATION_ERROR_BOX_SUCCESS,
    DISMISS_MIGRATION_MODAL_PROMPT,
    DISMISS_MIGRATION_MODAL_PROMPT_FAILED,
    DISMISS_MIGRATION_MODAL_PROMPT_SUCCESS,
    GET_MIGRATION_COMPLETED_MODAL_PROMPT_FAILED,
    GET_MIGRATION_COMPLETED_MODAL_PROMPT_SUCCESS,
    GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID,
    GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_FAILED,
    GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_SUCCESS,
    GET_MIGRATION_MODAL_PROMPT_SUCCESS,
    MigrationState,
    OPEN_MIGRATION_MODAL_PROMPT,
    OPEN_MIGRATION_MODAL_PROMPT_SUCCESS,
    TRIGGER_MIGRATION_BY_USER,
    TRIGGER_MIGRATION_BY_USER_FAILED,
    TRIGGER_MIGRATION_BY_USER_SUCCESS,
} from "./types";

const initialState: MigrationState = {
    isVisible: false,
    isDismissed: false,
    isHard: false,
    isSuccess: false,
    isMigrationCompleted: false,
    isError: false,
    shouldGetMigrationPrompt: false,
    canMigrate: false,
    migrationSessionId: "",
    showBanner: false,
    errorCode: null,
    migratedPodmeAccount: false,
    acceptSuccess: false,
    userAcceptedMigration: false,
    isLoading: false,
    daysLeft: 0,
    oldUserId: "",
    migrationPromptHistoryRecordId: 0,
    hardMigrationStartDate: "",
    hardMigrationDateCopy: "",
};

function migrationReducer(state = initialState, action: AnyAction): MigrationState {
    switch (action.type) {
        case GET_MIGRATION_MODAL_PROMPT_SUCCESS:
            const isPromptVisible = action.payload.isMigrationHard ? true : action.payload.isVisible;
            return {
                ...state,
                isVisible: isPromptVisible,
                shouldGetMigrationPrompt: action.payload.shouldGetMigrationPrompt,
                canMigrate: action.payload.canMigrate,
                migrationSessionId: action.payload.migrationSessionId,
                isHard: action.payload.isMigrationHard,
                showBanner: action.payload.showBanner,
                migratedPodmeAccount: action.payload.migratedPodmeAccount,
                hardMigrationStartDate: action.payload.hardMigrationStartDate,
                hardMigrationDateCopy: action.payload.hardMigrationDateCopy,
                daysLeft: action.payload.daysLeft || 0,
            };
        case DISMISS_MIGRATION_MODAL_PROMPT:
            return {
                ...state,
                isLoading: true,
                userAcceptedMigration: false,
            };
        case DISMISS_MIGRATION_MODAL_PROMPT_SUCCESS:
            return {
                ...state,
                isDismissed: action.payload.isDismissed,
                isVisible: action.payload.isVisible,
                isError: false,
                errorCode: null,
                isLoading: false,
            };

        case DISMISS_MIGRATION_MODAL_PROMPT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case OPEN_MIGRATION_MODAL_PROMPT:
            return {
                ...state,
                isVisible: true,
            };

        case OPEN_MIGRATION_MODAL_PROMPT_SUCCESS:
            return {
                ...state,
                isVisible: action.payload.isVisible,
                isDismissed: action.payload.isDismissed,
            };

        case ACCEPT_MIGRATION_MODAL_PROMPT:
            return {
                ...state,
                isLoading: true,
                userAcceptedMigration: true,
            };

        case ACCEPT_MIGRATION_MODAL_PROMPT_SUCCESS:
            return {
                ...state,
                acceptSuccess: action.payload.acceptSuccess,
                oldUserId: action.payload.oldUserId,
                migrationSessionId: action.payload.migrationSessionId,
            };

        case ACCEPT_MIGRATION_MODAL_PROMPT_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        case TRIGGER_MIGRATION_BY_USER:
            return {
                ...state,
                isLoading: true,
                userAcceptedMigration: true,
            };

        case TRIGGER_MIGRATION_BY_USER_SUCCESS:
            return {
                ...state,
                acceptSuccess: action.payload.acceptSuccess,
            };

        case TRIGGER_MIGRATION_BY_USER_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID:
            return {
                ...state,
                isLoading: true,
            };
        case GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_SUCCESS:
            return {
                ...state,
                migrationSessionId: action.payload.migrationSessionId,
                isLoading: false,
            };

        case GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case GET_MIGRATION_COMPLETED_MODAL_PROMPT_SUCCESS:
            return {
                ...state,
                isMigrationCompleted: action.payload.isMigrationCompleted,
                isVisible: action.payload.isVisible,
            };

        case GET_MIGRATION_COMPLETED_MODAL_PROMPT_FAILED:
            return {
                ...state,
                errorCode: action.err,
                isError: true,
                isVisible: true,
            };

        case DISMISS_MIGRATION_ERROR_BOX_SUCCESS:
            return {
                ...state,
                isError: false,
                errorCode: null,
                isVisible: false,
            };

        default:
            return state;
    }
}

export default migrationReducer;
