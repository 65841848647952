import {
    EpisodeModalAction,
    EpisodeHomeState,
    GET_EPISODE,
    GET_EPISODES,
    GET_EPISODES_SUCCESS,
    GET_EPISODE_FAILED,
    GET_EPISODE_SUCCESS,
} from "./types";

const initialState: EpisodeHomeState = {
    episode: null,
    episodeId: undefined,
    episodes: null,
    show404: false,
    loading: true,
    showMore: true,
    isSortedByOldest: false,
    currentPage: 0,
};

export default function episodeHomeReducer(state = initialState, action: EpisodeModalAction): EpisodeHomeState {
    switch (action.type) {
        case GET_EPISODE:
            return {
                ...state,
                show404: false,
                episodeId: action.id,
            };

        case GET_EPISODE_SUCCESS:
            return {
                ...state,
                episode: action.episode,
                loading: false,
            };

        case GET_EPISODE_FAILED:
            return {
                ...state,
                show404: true,
                loading: false,
            };

        case GET_EPISODES:
            return {
                ...state,
                showMore: true,
                currentPage: action.page,
            };

        case GET_EPISODES_SUCCESS: {
            const newEpisodesCount = action.episodes.length;

            if (state.isSortedByOldest !== action.isSortedByOldest) {
                state.episodes = [];
                state.showMore = newEpisodesCount >= 10 ? true : false;
            } else if (newEpisodesCount < 10) {
                state.showMore = false;
            } else {
                state.showMore = true;
            }

            return {
                ...state,
                episodes: action.episodes,
                isSortedByOldest: action.isSortedByOldest,
                loading: false,
            };
        }

        default:
            return state;
    }
}
