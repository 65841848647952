import { ReactElement, useMemo } from "react";
import { Flex } from "@chakra-ui/react";
import Tag from "./Tag";
import { Link } from "react-router-dom";
import strings from "app/locale/localization";
import { Category } from "../PodcastPage";
import { getCategoryPageUrl } from "@utils/index";
interface TagListProps {
    categories: Category[];
}

export default function TagList({ categories }: TagListProps): ReactElement {
    const categoryList = useMemo(
        () =>
            categories
                ? categories
                      .filter((cat) => Object.values(cat).every((item) => !!item))
                      .sort((a) => (a.key.toLowerCase() === "premium" ? -1 : 1))
                : [],
        [categories]
    );
    const { locale } = strings.routes;

    if (categories?.length < 1) {
        const categoryItems = Array.from({ length: 3 }, (x, i) => (
            <Tag key={i} category={{ id: 1, key: "placeholder", name: "placeholder" }} isLoading={true} />
        ));
        return (
            <Flex flexWrap="wrap" gridGap={2}>
                {categoryItems}
            </Flex>
        );
    }

    return (
        <Flex flexWrap="wrap" gridGap="0.5rem">
            {categoryList.map((cat: Category) => {
                const categoryPageUrl = getCategoryPageUrl(cat);

                return (
                    <Link key={cat.id} to={`/${locale}/${categoryPageUrl}`}>
                        <Tag category={cat} />
                    </Link>
                );
            })}
        </Flex>
    );
}
