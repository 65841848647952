import { createContext, ReactElement, useCallback, useContext, useEffect, useState } from "react";

import { Region } from "@typings/index";
import { useDispatch, useSelector } from "react-redux";
import { identity as schibstedIdentity } from "../config/schibsted-init";

import { RootState } from "../reducers/rootReducer";
import appConfig from "../../app/config";
import useAuth from "@auth/useAuth";
import { GET_USER_PRIVACY_SETTINGS, IdentityProvider } from "@auth/authTypes";
import { UPDATE_CONSENT_ANALYTICS, UPDATE_CONSENT_BRAZE, UPDATE_CONSENT_MARKETING } from "app/tracking/trackingTypes";

export enum ConsentCategory {
    Advertising = "CMP:advertising",
    Analytics = "CMP:analytics",
    Marketing = "CMP:marketing",
}

export enum ISSCategory {
    Test = "identity-pre.schibsted.com",
    Sweden = "login.schibsted.com",
    Norway = "payment.schibsted.no",
    Finland = "login.schibsted.fi",
}
interface PrivacyConfig {
    propertyId: number;
    consentLanguage: string;
    baseEndpoint: string;
    groupPmId?: number;
    userId?: number;
    realm?: string;
    authId?: string | null;
}

interface UsePrivacy {
    showConsentModal: () => void;
}

const PrivacyContext = createContext<UsePrivacy | undefined>(undefined);

interface PrivacyProviderProps {
    children: ReactElement;
    isDisabled?: boolean;
}

const regionSelector = (state: RootState) => state.global.location;
const authSelector = (state: RootState) => state.auth;

function PrivacyProvider({ children, isDisabled }: PrivacyProviderProps): ReactElement {
    const {
        GROUP_PM_ID_FI,
        GROUP_PM_ID_NO,
        GROUP_PM_ID_SE,
        PROPERTY_ID_SE,
        PROPERTY_ID_NO,
        PROPERTY_ID_FI,
        PROPERTY_HREF_FI,
        PROPERTY_HREF_NO,
        PROPERTY_HREF_SE,
    } = appConfig;
    const region = useSelector(regionSelector);
    const dispatch = useDispatch();
    const { accountIss, isLoading } = useSelector(authSelector);
    const { isAuthenticated, userId, loginProvider } = useAuth();

    const [config, setConfig] = useState<PrivacyConfig | null>(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [externalUserId, setExternalUserId] = useState<string | null>(null);
    const [userSdrn, setUserSdrn] = useState<string | null>(null);

    // Get userId and reinitialize event listeners when user logs in
    useEffect(() => {
        if (isAuthenticated) {
            if (loginProvider === IdentityProvider.Schibsted) {
                if (schibstedIdentity && schibstedIdentity.getExternalId && schibstedIdentity.getUserSDRN) {
                    try {
                        schibstedIdentity?.getExternalId("sourcepoint").then((externalId) => {
                            setExternalUserId(externalId);
                        });
                        schibstedIdentity?.getUserSDRN().then((userSdrn) => {
                            setUserSdrn(userSdrn);
                        });
                    } catch (ignored) {}
                }
            } else if (userId) {
                setExternalUserId(userId);
            }
        } else {
            setExternalUserId(null);
            setUserSdrn(null);
        }
    }, [isAuthenticated]);

    //
    // useEffect in which we set up the Sourcepoint configuration based on the current website region or (if logged in with Schibsted) the account realm
    //

    useEffect(() => {
        if (isLoading || isDisabled) return;

        if (accountIss) {
            if (accountIss.includes(ISSCategory.Sweden) || accountIss.includes(ISSCategory.Test)) {
                setConfig((state) => ({
                    ...state,
                    baseEndpoint: "https://cmp.podme.com",
                    propertyHref: PROPERTY_HREF_SE,
                    consentLanguage: "sv",
                    propertyId: PROPERTY_ID_SE,
                    groupPmId: GROUP_PM_ID_SE,
                }));
            }
            if (accountIss.includes(ISSCategory.Norway)) {
                setConfig((state) => ({
                    ...state,
                    baseEndpoint: "https://cmp.podme.com",
                    propertyHref: PROPERTY_HREF_NO,
                    consentLanguage: "no",
                    propertyId: PROPERTY_ID_NO,
                    groupPmId: accountIss.includes(ISSCategory.Test) ? GROUP_PM_ID_SE : GROUP_PM_ID_NO,
                }));
            }
            if (accountIss.includes(ISSCategory.Finland)) {
                setConfig((state) => ({
                    ...state,
                    baseEndpoint: "https://cmp.podme.com",
                    propertyHref: PROPERTY_HREF_FI,
                    consentLanguage: "fi",
                    propertyId: PROPERTY_ID_FI,
                    groupPmId: GROUP_PM_ID_FI,
                }));
            }
        } else {
            switch (region) {
                case Region.Norway:
                    setConfig((state) => ({
                        ...state,
                        baseEndpoint: "https://cmp.podme.com",
                        propertyHref: PROPERTY_HREF_NO,
                        consentLanguage: "no",
                        propertyId: PROPERTY_ID_NO,
                        groupPmId: GROUP_PM_ID_NO,
                    }));
                    break;
                case Region.Finland:
                    setConfig((state) => ({
                        ...state,
                        baseEndpoint: "https://cmp.podme.com",
                        propertyHref: PROPERTY_HREF_FI,
                        consentLanguage: "fi",
                        propertyId: PROPERTY_ID_FI,
                        groupPmId: GROUP_PM_ID_FI,
                    }));
                    break;
                case Region.Sweden:
                    setConfig((state) => ({
                        ...state,
                        baseEndpoint: "https://cmp.podme.com",
                        propertyHref: PROPERTY_HREF_SE,
                        consentLanguage: "sv",
                        propertyId: PROPERTY_ID_SE,
                        groupPmId: GROUP_PM_ID_SE,
                    }));
                    break;
            }
        }
    }, [region, accountIss, isLoading, externalUserId]);

    //
    // useEffect in which we call a proper function (either PSI or SCC) based on the current region
    //

    useEffect(() => {
        if (!config?.propertyId || isLoading || isDisabled) return;

        // added the import here because otherwise server side will try and import and that will fail
        const { scc, psi } = require("@schibsted/sourcepoint");
        if (accountIss) {
            if (accountIss.includes(ISSCategory.Norway)) {
                psi(window, document, navigator, {
                    ...config,
                    authId: externalUserId,
                });
            } else {
                scc(window, document, navigator, {
                    ...config,
                    authId: externalUserId,
                });
            }
        } else if (region === Region.Norway) {
            scc(window, document, navigator, {
                ...config,
                authId: externalUserId,
            });
        } else {
            scc(window, document, navigator, {
                ...config,
                authId: externalUserId,
            });
        }

        setIsInitialized(true);

        return () => {
            setIsInitialized(false);
        };
    }, [config?.propertyId, accountIss, isLoading, externalUserId, isDisabled]);

    const showConsentModal = useCallback(() => {
        if (isDisabled) return;
        window._scc_.showPrivacyManager();
    }, [isDisabled]);

    const consentAnalyticsCallback = (value: string) => {
        dispatch({ type: UPDATE_CONSENT_ANALYTICS, value });
    };
    const consentMarketingCallback = (value: string) => {
        dispatch({ type: UPDATE_CONSENT_MARKETING, value });
        dispatch({ type: UPDATE_CONSENT_BRAZE, value, userSdrn });
    };

    useEffect(() => {
        let unsubscribeAnalyticsFn: () => void | undefined;
        let unsubscribeMarketingFn: () => void | undefined;

        if (region === Region.Norway && isInitialized && externalUserId) {
            dispatch({ type: GET_USER_PRIVACY_SETTINGS });
        }

        if (isInitialized && typeof window !== "undefined" && window?._scc_) {
            unsubscribeAnalyticsFn = window._scc_.subscribe(ConsentCategory.Analytics, consentAnalyticsCallback);
            unsubscribeMarketingFn = window._scc_.subscribe(ConsentCategory.Marketing, consentMarketingCallback);
        }

        return () => {
            if (unsubscribeAnalyticsFn) unsubscribeAnalyticsFn();
            if (unsubscribeMarketingFn) unsubscribeMarketingFn();
        };
    }, [isInitialized, externalUserId, region]);

    return <PrivacyContext.Provider value={{ showConsentModal }}>{children}</PrivacyContext.Provider>;
}

function usePrivacy(): UsePrivacy {
    const context = useContext(PrivacyContext);

    if (context === undefined) {
        throw new Error("usePrivacy must be used within a PrivacyProvider");
    }
    return context;
}

export { PrivacyProvider, usePrivacy };
