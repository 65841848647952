import { ReactElement } from "react";
import { Box, Text, Divider } from "@chakra-ui/react";
import { PodmeColor } from "../types/index";

interface Props {
    copy: string;
}

export default function DividerWithCopy({ copy }: Props): ReactElement {
    return (
        <Box display="flex" alignItems="center" width="100%">
            <Divider flexGrow={1} width="unset" borderColor={PodmeColor.Silver + "20"} borderBottomWidth="1px" opacity={1} />
            <Text color={PodmeColor.Silver} fontSize="0.875rem" flexShrink={1} as="span" paddingLeft={3} paddingRight={3}>
                {copy}
            </Text>
            <Divider flexGrow={1} width="unset" borderColor={PodmeColor.Silver + "20"} borderBottomWidth="1px" opacity={1} />
        </Box>
    );
}
