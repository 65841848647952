import { useEffect } from "react";

function useDisableScroll(isDisabled: boolean): void {
    useEffect(() => {
        const body = document.querySelector("body");
        if (body) {
            if (isDisabled) {
                body.style.overflowY = "hidden";
                document.addEventListener("touchmove", handleTouchMove, { passive: false });
            } else {
                body.setAttribute("overflow-y", "auto");
                body.style.removeProperty("overflow-y");
                document.removeEventListener("touchmove", handleTouchMove, { passive: false } as any);
            }
        }

        return () => {
            if (body) {
                body.setAttribute("overflow-y", "auto");
                body.style.removeProperty("overflow-y");
            }
            document.removeEventListener("touchmove", handleTouchMove, { passive: false } as any);
        };
    }, [isDisabled]);

    function handleTouchMove(e: any) {
        e.preventDefault();
    }
}

export default useDisableScroll;
