import React, { ReactElement } from "react";
import { Flex, Box, Button, Image } from "@chakra-ui/react";
import { useDeviceOnline } from "../../../../app/hooks";
import { PodmeColor } from "../../../types";
import LoadingIcon from "@components/LoadingIcon";

interface Props {
    isLoading: boolean;
    isPlaying: boolean;
    isDisabled: boolean;
    skipBackwards: () => void;
    skipForwards: () => void;
    play: (evt: any) => void;
}

export default function BigPlayerButtons({
    isLoading,
    isPlaying,
    isDisabled,
    skipBackwards,
    skipForwards,
    play,
}: Props): ReactElement {
    const isOnline = useDeviceOnline();

    return (
        <Flex justifyContent={"center"} gridGap={"2rem"} width="100%">
            <Box display={"flex"} alignItems={"center"} maxWidth="40px">
                <Button
                    variant="unstyled"
                    onClick={skipBackwards}
                    disabled={isDisabled}
                    background={"none"}
                    cursor="pointer"
                    padding={"0"}
                    _focus={{
                        boxShadow: "unset",
                        outline: "none",
                        border: "none",
                    }}
                    _hover={{
                        boxShadow: "unset",
                    }}
                    _active={{
                        filter: "opacity(0.6)",
                    }}
                >
                    <Image src="/assets/images/icons/skip-backwards.svg" alt="skip backwards button" />
                </Button>
            </Box>
            <Box
                flexShrink={0}
                height={"4.5rem"}
                width={"4.5rem"}
                style={{ aspectRatio: "1" }}
                maxWidth="4.5rem"
                sx={{
                    "&:focus circle": {
                        fill: PodmeColor.Cyan,
                    },
                    circle: {
                        transition: "fill 200ms ease",
                    },
                }}
            >
                {isLoading || !isOnline ? (
                    <Button
                        height={"100%"}
                        width={"auto"}
                        maxWidth="50px"
                        background={PodmeColor.SoftWhite}
                        padding={"0"}
                        minWidth="100%"
                        rounded="50%"
                        _focus={{
                            boxShadow: "unset",
                        }}
                        _hover={{
                            boxShadow: "unset",
                        }}
                        _active={{
                            filter: "opacity(0.6)",
                        }}
                    >
                        <LoadingIcon spinnerHeight="4rem" spinnerWidth="4rem" variant="white" />
                    </Button>
                ) : (
                    <Box
                        as="button"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        onClick={play}
                        background={PodmeColor.SoftWhite}
                        padding={"0"}
                        width={"100%"}
                        height={"100%"}
                        transition={"all 150ms ease-in"}
                        cursor="pointer"
                        rounded="50%"
                        sx={{
                            "> img": {
                                width: "100%",
                                maxWidth: "3rem",
                            },
                        }}
                        _focus={{
                            boxShadow: "unset",
                            outline: "none",
                            border: "none",
                        }}
                        _hover={{
                            boxShadow: "unset",
                        }}
                        _active={{
                            filter: "opacity(0.6)",
                        }}
                    >
                        {isPlaying ? (
                            <Image filter="invert(1)" src="/assets/images/icons/play-pause.svg" alt="pause audio button" />
                        ) : (
                            <Image filter="invert(1)" src="/assets/images/icons/play-play.svg" alt="play audio button" />
                        )}
                    </Box>
                )}
            </Box>
            <Box display={"flex"} alignItems={"center"} maxWidth="40px">
                <Button
                    variant="unstyled"
                    onClick={skipForwards}
                    disabled={isDisabled}
                    background={"none"}
                    padding={"0"}
                    width={"100%"}
                    transition={"all 100ms ease-in"}
                    cursor="pointer"
                    _focus={{
                        boxShadow: "unset",
                        outline: "none",
                        border: "none",
                    }}
                    _hover={{
                        boxShadow: "unset",
                    }}
                    _active={{
                        filter: "opacity(0.6)",
                    }}
                >
                    <Image src="/assets/images/icons/skip-forward.svg" alt="skip forward button" />
                </Button>
            </Box>
        </Flex>
    );
}
