import { ReactElement } from "react";
import { Flex, Spinner } from "@chakra-ui/react";
import { PodmeColor } from "../types/index";

interface Props {
    variant?: "white" | "default";
    spinnerWidth?: string | string[];
    spinnerHeight?: string | string[];
}

export default function LoadingIcon({ variant = "default", spinnerWidth = "100%", spinnerHeight = "100%" }: Props): ReactElement {
    return (
        <Flex
            backgroundColor={variant === "white" ? "transparent" : PodmeColor.LicoriceBlue}
            borderRadius="50%"
            height={spinnerHeight}
            width={spinnerWidth}
            alignItems="center"
            justifyContent="center"
            pointerEvents="none"
        >
            <Spinner
                width="50%"
                height="50%"
                color={variant === "white" ? PodmeColor.BlackPearl : PodmeColor.SoftWhite}
                speed="0.7s"
                pointerEvents="none"
            />
        </Flex>
    );
}
