import { keyframes } from "@chakra-ui/react";

export const fadeIn = keyframes`
0% {
    visibility: visible;
    opacity: 0;
    transform: scale(0.95);
}
85% {
    opacity: 1;
}
100% {
    opacity: 1;
    transform: scale(1);
}
`;
