import { Box, Heading, HeadingProps, Image, ImageProps, Link, LinkProps } from "@chakra-ui/react";
import { documentToReactComponents, Options as ContentfulRenderOptions } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { Text } from "@contentful/rich-text-types/dist/types/types";
import { ReactNode } from "react";
import { AssetHyperlink, Paragraph } from "@contentful/rich-text-types/dist/types/nodeTypes";
import strings from "@locale/localization";
import { ContentfulDocument, ContentfulNode } from "app/content/contentTypes";

interface DocumentLinkProps {
    asStoreIcon?: boolean;
}

export interface CmsDocumentProps {
    document: ContentfulDocument;
    linkProps?: DocumentLinkProps & LinkProps;
    imageProps?: ImageProps;
    h6Props?: HeadingProps;
    stringReplaceValues?: string[];
}

export function CmsDocument({ document, h6Props, linkProps = {}, imageProps, stringReplaceValues }: CmsDocumentProps) {
    const { locale } = strings.routes;

    const renderOptions: ContentfulRenderOptions = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: ContentfulNode, children: ReactNode) => {
                if (stringReplaceValues && stringReplaceValues.length > 0) {
                    const textNode = (node as Paragraph).content[0] as Text;
                    textNode.value = strings.formatString(textNode.value, ...stringReplaceValues) as string;
                }

                return <p>{children}</p>;
            },
            [BLOCKS.HEADING_6]: (node: ContentfulNode, children: ReactNode) => {
                return (
                    <Heading as="h6" {...h6Props}>
                        {children}
                    </Heading>
                );
            },
            [INLINES.HYPERLINK]: (node: ContentfulNode, children: ReactNode) => {
                const { asStoreIcon, ...restLinkProps } = linkProps;
                const { uri } = node.data;
                const openInNewTab = !uri.includes("podme.com");
                const isAppStoreLink = uri.includes("apple") && children?.toString().toLowerCase().includes("app store");
                const isPlayStoreLink = uri.includes("play.google") && children?.toString().toLowerCase().includes("google play");
                const isStoreLink = isAppStoreLink || isPlayStoreLink;

                if (asStoreIcon && isStoreLink) {
                    return (
                        <Link href={uri} target="_blank" {...restLinkProps} borderBottom={undefined}>
                            {isPlayStoreLink && (
                                <Image
                                    maxHeight="2.5rem"
                                    display="inline"
                                    src={`/assets/images/brand/google-play-${locale}.svg`}
                                    alt="Google play store"
                                />
                            )}
                            {isAppStoreLink && (
                                <Image
                                    display="inline"
                                    maxHeight="2.5rem"
                                    src={`/assets/images/brand/appstore_logo_${locale}.svg`}
                                    alt="App store"
                                />
                            )}
                        </Link>
                    );
                }

                return (
                    <Link
                        href={uri}
                        target={openInNewTab ? "_blank" : "_self"}
                        sx={{
                            u: {
                                textDecoration: "none",
                            },
                        }}
                        {...restLinkProps}
                    >
                        {children}
                    </Link>
                );
            },
            [INLINES.ASSET_HYPERLINK]: (node: ContentfulNode) => {
                const uri = (node as AssetHyperlink).content[0]?.value || "";
                const hasLink = uri !== "";
                const openInNewTab = !uri.includes("podme.com");

                return (
                    <Link href={uri} target={openInNewTab ? "_blank" : "_self"} {...linkProps}>
                        <Image
                            src={`https:${node.data.target.file.url}`}
                            alt={node.data.target.file.fileName}
                            cursor={hasLink ? "pointer" : "default"}
                            style={{
                                transition: "transform 200ms ease-in-out",
                                transitionTimingFunction: "cubic-bezier(0.215, 0.61, 0.355, 1)",
                            }}
                            _hover={
                                hasLink
                                    ? {
                                          transform: "scale(1.05)",
                                      }
                                    : undefined
                            }
                            {...imageProps}
                        />
                    </Link>
                );
            },
            [BLOCKS.EMBEDDED_ASSET]: (node: ContentfulNode) => {
                return (
                    <Box>
                        <Image src={`https://${node.data.target.file.url}`} alt={node.data.target.description} {...imageProps} />
                    </Box>
                );
            },
        },
    };

    return <>{documentToReactComponents(document, renderOptions)}</>;
}

export default CmsDocument;
