import { SUBFLOW_SESSION_KEY } from "@typings/index";
import Cookies from "universal-cookie";

function useSubflow() {
    const cookies = new Cookies();

    const saveSubflow = () => {
        cookies.set(SUBFLOW_SESSION_KEY, "true", { path: "/", maxAge: 1800 });
    };

    const removeSubflow = () => {
        cookies.remove(SUBFLOW_SESSION_KEY, {
            path: "/",
        });
    };

    return { saveSubflow, removeSubflow };
}

export default useSubflow;
