import { ObjectValues, OfferTypeKey } from "../types";

export const VIEW_ITEM = "tracking/VIEW_ITEM";
export const SELECT_ITEM = "tracking/SELECT_ITEM";
export const BEGIN_CHECKOUT = "tracking/BEGIN_CHECKOUT";
export const ADD_PAYMENT_INFO = "tracking/ADD_PAYMENT_INFO";
export const PURCHASE = "tracking/PURCHASE";
export const COUPON_INTERACTION = "tracking/COUPON_INTERACTION";
export const REWARD_VOUCHER_INTERACTION = "tracking/REWARD_VOUCHER_INTERACTION";
export const TRACKING_EVENT = "tracking/TRACKING_EVENT";
export const ADD_TO_CART = "tracking/ADD_TO_CART";
export const UPDATE_CONSENT_MARKETING = "auth/UPDATE_CONSENT_MARKETING";
export const UPDATE_CONSENT_BRAZE = "auth/UPDATE_CONSENT_BRAZE";
export const UPDATE_CONSENT_ANALYTICS = "auth/UPDATE_CONSENT_ANALYTICS";

export interface ViewItemEvent {
    event: "view_item";
    ecommerce: {
        currency: string;
        value: number;
        items: {
            item_id: string;
            item_name: string;
            item_category: string;
            item_variant: string;
            price: number;
            currency: string;
            quantity: 1;
        }[];
    };
}

export interface SelectItemEvent {
    event: "select_item";
    ecommerce: {
        items: {
            item_id: string;
            item_name: string;
            item_category: string;
            item_variant: string;
            price: number;
            currency: string;
            quantity: 1;
        }[];
    };
}

export interface AddToCartEvent {
    event: "add_to_cart";
    ecommerce: {
        items: {
            item_id: string;
            item_name: string;
            item_brand: string;
            quantity: 1;
        }[];
    };
    user: {
        is_premium: boolean;
        logged_in: boolean;
    };
}

export interface BeginCheckoutEvent {
    event: "begin_checkout";
    ecommerce: {
        offer_type: OfferTypeKey;
        student_flow: boolean;
        envoy_sharing_flow: boolean;
        currency: string;
        value: number;
        user_id: string;
        items: {
            item_id: string;
            item_name: string;
            item_category: string;
            item_variant: string;
            price: number;
            currency: string;
            quantity: 1;
        }[];
    };
}

export interface AddPaymentInfoEvent {
    event: "add_payment_info";
    ecommerce: {
        offer_type: OfferTypeKey;
        student_flow: boolean;
        envoy_sharing_flow: boolean;
        currency: string;
        value: number;
        payment_type: string;
        coupon?: string;
        user_id: string;
        items: {
            item_id: string;
            item_name: string;
            item_category: string;
            item_variant: string;
            price: number;
            currency: string;
            quantity: 1;
            coupon?: string;
        }[];
    };
}

export interface PurchaseEvent {
    event: "purchase";
    ecommerce: {
        offer_type: OfferTypeKey;
        student_flow: boolean;
        transaction_id: string;
        value: number;
        currency: string;
        payment_type: string | null;
        coupon?: string;
        user_id: string;
        items: {
            item_id: string;
            item_name: string;
            item_category: string;
            item_variant: string;
            price: number;
            currency: string;
            quantity: 1;
            coupon?: string;
        }[];
    };
    ios_subscription_flow?: boolean;
    envoy_sharing_flow?: boolean;
}

export type EcommerceEvent = ViewItemEvent | SelectItemEvent | BeginCheckoutEvent | AddPaymentInfoEvent | PurchaseEvent;

export interface TrackingEventAction {
    type: typeof TRACKING_EVENT;
    event: string;
    [x: string]: unknown;
}

export interface ViewItemEventAction {
    type: typeof VIEW_ITEM;
    event: ViewItemEvent;
}

export interface SelectItemEventAction {
    type: typeof SELECT_ITEM;
    event: SelectItemEvent;
}

export interface AddToCartEventAction {
    type: typeof ADD_TO_CART;
    event: AddToCartEvent;
}

export interface BeginCheckoutEventAction {
    type: typeof BEGIN_CHECKOUT;
    event: BeginCheckoutEvent;
}

export interface AddPaymentInfoEventAction {
    type: typeof ADD_PAYMENT_INFO;
    event: AddPaymentInfoEvent;
}

export interface PurchaseEventAction {
    type: typeof PURCHASE;
    event: PurchaseEvent;
}

export interface CouponInteractionEvent {
    type: typeof COUPON_INTERACTION;
    couponName: string;
}

export interface RewardVoucherInteractionEvent {
    type: typeof REWARD_VOUCHER_INTERACTION;
    couponName: string;
}

export interface UpdateConsentMarketing {
    type: typeof UPDATE_CONSENT_MARKETING;
    value: string;
}

export interface UpdateConsentBraze {
    type: typeof UPDATE_CONSENT_BRAZE;
    value: string;
    userSdrn: string | null;
}

export interface UpdateConsentAnalytics {
    type: typeof UPDATE_CONSENT_ANALYTICS;
    value: string;
}

// type is used as event key in google tag manager, dont modify.
export const CONFIRMATION_PAGE_EVENT_TYPE = {
    PAGE_VIEW: "view_subscription_confirmation_page",
    EXPLORE_WEB: "click_subscription_completed_explore_web",
    EXPLORE_APP: "click_subscription_completed_explore_app",
} as const;

export type ConfirmationPageEventType = ObjectValues<typeof CONFIRMATION_PAGE_EVENT_TYPE>;
