import { Box, StyleProps } from "@chakra-ui/react";
import Skeleton from "@components/Skeleton";
import { PodmeColor } from "@typings/index";

interface Props {
    isLoaded: boolean;
    currentTimeInPercentage: number | undefined;
}

function EpisodeProgressBar({ isLoaded, currentTimeInPercentage, ...rest }: Props & StyleProps) {
    return (
        <Skeleton isLoaded={isLoaded} width={isLoaded ? "100%" : "unset"} height={isLoaded ? "4px" : "unset"}>
            <Box
                background={PodmeColor.LicoriceBlue}
                width="100%"
                height={["4px", "3px"]}
                position="relative"
                borderRadius="1rem"
                {...rest}
            >
                <Box
                    bgColor={PodmeColor.Cyan}
                    height={["4px", "3px"]}
                    position="absolute"
                    as={"span"}
                    width={currentTimeInPercentage ? `${currentTimeInPercentage <= 100 ? currentTimeInPercentage : 100}%` : "0%"}
                    borderRadius={currentTimeInPercentage ? (currentTimeInPercentage < 100 ? "1rem 0 0 1rem" : "1rem") : "1rem"}
                />
            </Box>
        </Skeleton>
    );
}

export default EpisodeProgressBar;
