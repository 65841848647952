import { Document, Node } from "@contentful/rich-text-types/dist/types/types";
import { ContentType } from "@containers/Homescreen/redux/types";
import { CmsWebPage, ObjectValues, OfferTypeKey, Region, RegionalLocale, WebPageOptions } from "@typings/index";
import { FaqEntity } from "app/api/types";

export const GET_FOOTER_PAGES_CONTENT = "cms/GET_FOOTER_PAGES_CONTENT";
export const GET_FOOTER_PAGES_CONTENT_SUCCESS = "cms/GET_FOOTER_PAGES_CONTENT_SUCCESS";
export const GET_FOOTER_PAGES_CONTENT_FAILED = "cms/GET_FOOTER_PAGES_CONTENT_FAILED";

export const GET_WEB_PAGE = "cms/GET_WEB_PAGE";
export const GET_WEB_PAGE_SUCCESS = "cms/GET_WEB_PAGE_SUCCESS";
export const GET_WEB_PAGE_FAILED = "cms/GET_WEB_PAGE_FAILED";

export const GET_CMS_CONTENT = "cms/GET_CMS_CONTENT";
export const GET_CMS_CONTENT_SUCCESS = "cms/GET_CMS_CONTENT_SUCCESS";
export const GET_CMS_CONTENT_FAILED = "cms/GET_CMS_CONTENT_FAILED";

export const GET_STARTPAGE_CONTENT = "cms/GET_STARTPAGE";
export const GET_STARTPAGE_CONTENT_SUCCESS = "cms/GET_STARTPAGE_SUCCESS";
export const GET_STARTPAGE_CONTENT_FAILED = "cms/GET_STARTPAGE_FAILED";

export const ENABLED_CONTENT_PREVIEW = "cms/ENABLED_CONTENT_PREVIEW";

export type ContentfulNode = Node;
export type ContentfulDocument = Document;

export const REGION_IDENTIFIER = {
    SWEDEN: Region.Sweden,
    NORWAY: Region.Norway,
    FINLAND: Region.Finland,
} as const;

export type RegionIdentifier = ObjectValues<typeof REGION_IDENTIFIER>;

export interface HeroSection {
    description: string;
    searchpageCTAButton: string;
    signupCTAButton: string;
    title: string;
    type: "heroSectionWeb";
}

export interface ContentBriefingItem {
    subheadline: string;
    paragraph: Document | string;
}
export interface ContentBriefingType {
    title?: string;
    headline: string;
    items: ContentBriefingItem[];
    type: ContentType.ContentBriefing;
}

export interface FaqContent {
    title?: string;
    type: ContentType.Faq;
    headline?: string;
    textContent?: string;
    items: FaqEntity[];
}

export interface ValuePropositionContent {
    title?: string;
    headline: string;
    paragraph?: string;
    benefits: string[];
    showImage?: boolean;
    cta: {
        type: "cta";
        text: string;
        slug: string;
        coupon?: string;
        sticky?: boolean;
    };
    disclaimer?: string;
    type: "valueProposition";
}

export interface TextContentSection {
    title: string;
    text: string;
    type: ContentType.TextContent;
}

export interface PricingContent {
    title: string;
    headline: string;
    disclaimer: ContentfulDocument;
    benefits: string[];
    items: {
        type: "pricingItem";
        offerType: OfferTypeKey;
        subheadline: string;
        paragraph: string;
        cta: {
            type: "cta";
            text: string;
            slug: string;
            coupon?: string;
            sticky?: boolean;
        };
    }[];
    region: "SWEDEN" | "NORWAY" | "FINLAND";
    type: "pricing";
}

//This one is different from 'PodcastCategory', similar to categories used on Search Page where
//every category is also served with image.
export interface ExplorerPodcastCategory {
    destination?: string;
    imageUrl?: any;
    image?: { url: string };
    key: string;
    name: string;
}
export interface CategoriesExplorerSection {
    title: string;
    subtitle: string;
    mainButton: string;
    categories: ExplorerPodcastCategory[];
    type: "webCategoriesExplorer";
}

export interface PodcastCategory {
    description: string;
    id: number;
    isPrimary: boolean;
    key: string;
    name: string;
}
export interface PodcastForMainCarousel {
    id: number;
    title: string;
    slug: string;
    imageUrl: string;
    description: string;
    categories: PodcastCategory[];
    destination: string | null;
    destinationPath: string | null;
}
export interface PodcastsMainCarousel {
    title: string;
    destination: string | null;
    podcasts: PodcastForMainCarousel[];
    type: "podcastCarousel";
}

export interface CmsStartpageResponse {
    sections: CmsWebPage[] | null;
}

export interface CmsImage {
    contentType: string;
    details: { size: number; image: { width: number; height: number } };
    image: { width: number; height: number };
    size: number;
    fileName: string;
    url: string;
}

interface FooterPages {
    podmePages: { title: string; slug: string }[];
}

export interface ContentState {
    footerPages?: FooterPages | null;
    webPages?: any[];
    startPage?: CmsStartpageResponse;
    content?: CmsContent;
    isPreview: boolean;
}

export interface StartPageContent {
    heroImage: CmsImage;
    podcastTrailers: any;
}

export interface PremiumOffer {
    ctaButton: string;
    description: string;
    terms: string;
    horizontalContentImage: {
        imageName: string;
        desktop: { url: string; details: { size: number; image: { width: number; height: number } } };
        mobile: { url: string; details: { size: number; image: { width: number; height: number } } };
    };
    coupon?: string;
}

export interface Trailer {
    title: string;
    audioFile: {
        url: string;
    };
    audioDuration: string;
    image: {
        url: string;
    };
    podcastSlug: string;
    podcastTitle: string;
}

export interface CmsContent {
    podcastPageValueProposition: ValuePropositionContent;
    myAccountPageValueProposition: ValuePropositionContent;
    podcastPages: PodcastPageContent[];
    region: RegionIdentifier;
    premiumBenefits: string[];
}

export interface PodcastPageContent {
    slug: string;
    pageTitle?: string;
    pageSubtitle?: string;
    pageDescription?: string;
    heroImage?: {
        imageName: string;
        laptop: { url: string; details: { size: number; image: { width: number; height: number } } };
        mobile: { url: string; details: { size: number; image: { width: number; height: number } } };
    };
    premiumOffer?: ValuePropositionContent;
    trailer?: Trailer;
}

export interface GetCmsContent {
    type: typeof GET_CMS_CONTENT;
    region: Region;
}
export interface GetStartpageContent {
    type: typeof GET_STARTPAGE_CONTENT;
    region: RegionalLocale;
}

export interface GetCmsContentSuccess {
    type: typeof GET_CMS_CONTENT_SUCCESS;
    content: CmsContent;
}
export interface GetStartpageSuccess {
    type: typeof GET_STARTPAGE_CONTENT_SUCCESS;
    content: CmsWebPage;
}

export interface GetWebPage {
    type: typeof GET_WEB_PAGE;
    options: WebPageOptions;
}
