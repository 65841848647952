import {
    ApplicationInsights,
    ITelemetryItem,
} from "@microsoft/applicationinsights-web";
import config from "../config";

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: config.AI_KEY,
        enableAutoRouteTracking: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        namePrefix: "Web",
    },
});

appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
    if (envelope && envelope.tags) {
        envelope.tags["ai.cloud.role"] = "Web";
    }
});

export default appInsights;
