import { Region } from "@typings/index";
import types from "./types";

function getInitialSearchPageData(): any {
    return {
        type: types.GET_INITIAL_CATEGORIES_DATA,
    };
}

function getPodcastsByCategory(categoryId: number, page: number, pageSize: number, region: Region): any {
    return {
        type: types.GET_PODCASTS_BY_CATEGORY,
        categoryId,
        page,
        pageSize,
        region,
        WAIT_FOR_ACTION: [types.GET_PODCASTS_BY_CATEGORY_SUCCESS, types.GET_PODCASTS_BY_CATEGORY_FAILED],
    };
}

function getCategoryPageContent(entryTitle: string): any {
    return {
        type: types.GET_CATEGORY_PAGE_CONTENT,
        entryTitle,
        WAIT_FOR_ACTION: [types.GET_CATEGORY_PAGE_CONTENT_SUCCESS, types.GET_CATEGORY_PAGE_CONTENT_FAILED],
    };
}

function getPopularPodcastsByCategory(categoryId: string, podType: number, page: number, pageSize: number): any {
    return {
        type: types.GET_POPULAR_PODCASTS_BY_CATEGORY,
        categoryId,
        podType,
        page,
        pageSize,
    };
}

export default {
    getInitialSearchPageData,
    getPodcastsByCategory,
    getPopularPodcastsByCategory,
    getCategoryPageContent,
};
