import { Identity } from "@schibsted/account-sdk-browser";
import { Region } from "@typings/index";

import config from "./index";

const isDev = process.env.NODE_ENV === "development";

let identity: Identity | undefined;

function init(region: Region): void {
    if (typeof window !== "undefined") {
        switch (region) {
            case Region.Sweden:
                identity = new Identity({
                    clientId: isDev ? config.SCHIBSTED_CLIENT_ID : config.SCHIBSTED_CLIENT_ID_SE,
                    redirectUri: config.SCHIBSTED_REDIRECT_URL,
                    env: isDev ? config.SCHIBSTED_ENV : config.SCHIBSTED_ENV_SE,
                    sessionDomain: isDev ? config.SCHIBSTED_SESSION_DOMAIN : config.SCHIBSTED_SESSION_DOMAIN_SE,
                });
                break;
            case Region.Norway:
                identity = new Identity({
                    clientId: isDev ? config.SCHIBSTED_CLIENT_ID : config.SCHIBSTED_CLIENT_ID_NO,
                    redirectUri: config.SCHIBSTED_REDIRECT_URL,
                    env: isDev ? config.SCHIBSTED_ENV : config.SCHIBSTED_ENV_NO,
                    sessionDomain: isDev ? config.SCHIBSTED_SESSION_DOMAIN : config.SCHIBSTED_SESSION_DOMAIN_NO,
                });
                break;
            case Region.Finland:
                identity = new Identity({
                    clientId: isDev ? config.SCHIBSTED_CLIENT_ID : config.SCHIBSTED_CLIENT_ID_FI,
                    redirectUri: config.SCHIBSTED_REDIRECT_URL,
                    env: isDev ? config.SCHIBSTED_ENV : config.SCHIBSTED_ENV_FI,
                    sessionDomain: isDev ? config.SCHIBSTED_SESSION_DOMAIN : config.SCHIBSTED_SESSION_DOMAIN_FI,
                });
                break;
        }
    }
}

export { identity, init };
