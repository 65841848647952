import { Episode } from "@typings/index";

export const GET_EPISODE = "episodePage/GET_EPISODE";
export const GET_EPISODE_SUCCESS = "episodePage/GET_EPISODE_SUCCESS";
export const GET_EPISODE_FAILED = "episodePage/GET_EPISODE_FAILED";

export const GET_EPISODES = "episodePage/GET_EPISODES";
export const GET_EPISODES_SUCCESS = "episodePage/GET_EPISODES_SUCCESS";
export const GET_EPISODES_FAILED = "episodePage/GET_EPISODES_FAILED";

export interface EpisodeHomeState {
    episode: Episode | null;
    episodeId: number | undefined;
    episodes: Episode[] | null;
    show404: boolean;
    loading: boolean;
    showMore: boolean;
    isSortedByOldest: boolean;
    currentPage: number;
}

export interface GetEpisode {
    type: typeof GET_EPISODE;
    id: number;
    isAuthenticated: boolean;
}

export interface GetEpisodeSuccess {
    type: typeof GET_EPISODE_SUCCESS;
    episode: Episode;
}

export interface GetEpisodeFailed {
    type: typeof GET_EPISODE_FAILED;
}

export interface GetEpisodes {
    type: typeof GET_EPISODES;
    slug: string;
    page: number;
    fetchByOldest: boolean;
    isAuthenticated: boolean;
}

export interface GetEpisodesSuccess {
    type: typeof GET_EPISODES_SUCCESS;
    episodes: Episode[];
    page: number;
    isSortedByOldest: boolean;
}

export interface GetEpisodesFailed {
    type: typeof GET_EPISODES_FAILED;
}

export type EpisodeModalAction =
    | GetEpisode
    | GetEpisodeSuccess
    | GetEpisodeFailed
    | GetEpisodes
    | GetEpisodesSuccess
    | GetEpisodesFailed;
