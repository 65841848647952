import { Flex, Image } from "@chakra-ui/react";
import { ReactElement, useState } from "react";

import { useMedia } from "app/hooks";
import Skeleton from "@components/Skeleton";

interface IProps {
    smallImageUrl: string;
    imageUrl: string;
    podcastTitle: string;
    marginTop?: string;
    loadingEpisodes?: boolean;
}

function EpisodeImage({ smallImageUrl, imageUrl, podcastTitle, marginTop, loadingEpisodes = false }: IProps): ReactElement {
    const { isSmallScreen, isLargeScreen } = useMedia();
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    return (
        <Flex
            alignSelf="flex-start"
            height="100%"
            maxWidth={["48px", "100%"]}
            maxHeight={["48px", "100%"]}
            rounded={["0.5rem", "1rem"]}
            flex={["0 0 48px", null, "0 0 96px"]}
            zIndex="1"
            marginTop={marginTop}
        >
            <Skeleton
                isLoaded={typeof window === "undefined" ? true : isImageLoaded && !loadingEpisodes}
                boxSize={isSmallScreen ? "48px" : isLargeScreen ? "96px" : "80px"}
                rounded={["0.5rem", "1rem"]}
            >
                <Image
                    loading="lazy"
                    src={smallImageUrl ?? imageUrl}
                    alt={podcastTitle}
                    objectFit={"contain"}
                    width="100%"
                    height="100%"
                    rounded={["0.5rem", "1rem"]}
                    onLoad={() => setIsImageLoaded(true)}
                    onError={() => setIsImageLoaded(true)}
                />
            </Skeleton>
        </Flex>
    );
}

export default EpisodeImage;
