import { ReactElement } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import strings from "@locale/localization";
import { CSSstylesObject, PodmeColor } from "../../types/index";
import { selectItemEventAction } from "../../tracking/trackingActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/reducers/rootReducer";
import { Link } from "react-router-dom";
import CTACheckoutButton from "@components/CTACheckoutButton";

const styles: CSSstylesObject = {
    accountMenuWrapper: {
        position: "absolute",
        top: "45px",
        right: 0,
        visibility: "hidden",
        transition: "visibility 0.2s",
        zIndex: 99,

        boxShadow: `0px 1px 4px 1px ${PodmeColor.Woodsmoke}80`,
        borderRadius: "16px",
    },
    accountMenu: {
        display: "flex",
        fontSize: "0.9em",
        flexDirection: "column",
        position: "relative",
        minWidth: "220px",
        padding: "0 1rem",
        backgroundColor: PodmeColor.Cinder,
        color: PodmeColor.SoftWhite,
        borderRadius: "16px",
    },
    menuItem: {
        padding: "1rem 0",
        textDecoration: "none",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: `1px solid ${PodmeColor.LicoriceBlue}`,
        sx: {
            "&:hover": {
                opacity: 0.8,
            },

            svg: {
                marginRight: "0.5rem",
            },
        },
    },
};

interface AccountAreaProps {
    username: string;
    locale: string;
    hasPackageSubscription: boolean;
    logout: () => void;
    color?: string;
}
const globalState = (state: RootState) => state.global;

function AccountArea({ username, locale, hasPackageSubscription, logout, color }: AccountAreaProps): ReactElement {
    const string = strings.navigation;
    const routes = strings.routes;
    const dispatch = useDispatch();
    const { currency, packageId, price } = useSelector(globalState);

    return (
        <Box
            color={color ? color : "inherit"}
            sx={{
                "&:hover": {
                    //when this button is hovered, show account menu
                    ".account-menu-wrapper": {
                        visibility: "visible",
                    },
                    ".dropdown-arrow": {
                        transform: "rotateZ(-90deg)",
                        transition: "0.15s linear",
                        marginBottom: "0.5rem",
                        marginTop: "0.1rem",
                    },
                },
            }}
            data-testid="navbar-account-settings-button"
        >
            <Flex
                gridGap={2}
                alignItems="center"
                padding={1}
                backgroundColor={PodmeColor.Cinder}
                border={`1px solid ${PodmeColor.Cinder}`}
                borderRadius="1.25rem"
                cursor="pointer"
            >
                <Image width="28px" height="28px" src={`/assets/images/icons/profile-avatar-filled.svg`} alt="avatar" />
                <Flex>
                    <Text as="address" fontStyle="normal" fontWeight={400} letterSpacing="-0.03em">
                        {username}
                    </Text>
                    <Box
                        className="dropdown-arrow"
                        transition={"0.15s linear"}
                        transform="rotateZ(90deg)"
                        paddingLeft={"0.5rem"}
                        paddingRight={"0.8rem"}
                        marginTop={"0.5rem"}
                    >
                        <MdOutlineArrowForwardIos style={{ fontSize: "0.8em", fill: PodmeColor.Silver, marginTop: "1px" }} />
                    </Box>
                </Flex>
            </Flex>
            <Box {...styles.accountMenuWrapper} className="account-menu-wrapper" data-testid="navbar-account-menu-wrapper">
                <Box {...styles.accountMenu}>
                    <Link data-testid="navbar-account-area-my-account-link" to={`/${locale}/${routes.dashboard}`}>
                        <Box {...styles.menuItem}>
                            <Box as={"span"}>{string.myAccount}</Box>
                            <MdOutlineArrowForwardIos style={{ fontSize: "0.8em", fill: PodmeColor.Silver }} />
                        </Box>
                    </Link>
                    {!hasPackageSubscription && (
                        <CTACheckoutButton
                            renderAs={Box}
                            as="button"
                            data-testid="navbar-account-area-get-premium-link"
                            style={{ color: PodmeColor.Cyan }}
                            onClickCallback={() => {
                                dispatch(
                                    selectItemEventAction(
                                        "Desktop / Navbar (Account Area) / Get Premium button",
                                        String(packageId),
                                        currency,
                                        price
                                    )
                                );
                            }}
                        >
                            <Box {...styles.menuItem} display="flex">
                                <Box as={"span"}>{string.getPremium}</Box>
                                <MdOutlineArrowForwardIos style={{ fontSize: "0.8em", fill: PodmeColor.Silver }} />
                            </Box>
                        </CTACheckoutButton>
                    )}

                    <Box
                        as={"button"}
                        display={"grid"}
                        justifyContent="space-between"
                        data-testid="navbar-account-area-logout-button"
                        {...styles.menuItem}
                        onClick={() => {
                            logout();
                        }}
                    >
                        <Box as={"span"}>{string.logout}</Box>
                        <img
                            src="/assets/images/icons/logout_icon.svg"
                            alt="logout icon"
                            style={{ height: "1rem", marginRight: "5px" }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default AccountArea;
