import { ReactElement, useState } from "react";
import { Slider, SliderTrack, SliderFilledTrack, SliderThumb, Box, Flex, Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { PodmeColor } from "../../types/index";
import { ReactComponent as VolumeIconLoud } from "@images/icons/volume-loud.svg";
import { ReactComponent as VolumeIconLowSound } from "@images/icons/volume-low-sound.svg";
import { ReactComponent as VolumeIconOff } from "@images/icons/volume-mute.svg";

interface Props {
    value: number;
    isMuted: boolean;
    isDisabled?: boolean;
    setVolume: (volume: number) => void;
    toggleMuted: (prevVolume: number) => void;
}

export default function VolumeControl({ value, isMuted, isDisabled, setVolume, toggleMuted }: Props): ReactElement {
    const [prevVolume, setPrevVolume] = useState(value ?? 0.5);
    const [showVolumeSlider, setShowVolumeSlider] = useState(false);

    return (
        <Box
            display={["none", "none", "flex"]}
            alignItems="center"
            justifyContent="center"
            position="relative"
            onMouseEnter={() => (isDisabled ? null : setShowVolumeSlider(true))}
            onMouseLeave={() => (isDisabled ? null : setShowVolumeSlider(false))}
        >
            <Button
                variant="unstyled"
                onClick={() => toggleMuted(prevVolume)}
                height="2.5rem"
                minWidth="unset"
                alignItems="center"
                cursor="pointer"
                sx={
                    isDisabled
                        ? {}
                        : !isMuted
                        ? {
                              "&:hover path": {
                                  fill: PodmeColor.Cyan,
                              },
                          }
                        : {
                              "&:hover path": {
                                  stroke: PodmeColor.Cyan,
                              },
                          }
                }
                disabled={isDisabled}
            >
                {isMuted || value === 0 ? (
                    <VolumeIconOff style={{ pointerEvent: "none" }} />
                ) : value < 0.5 ? (
                    <VolumeIconLowSound style={{ pointerEvent: "none" }} />
                ) : (
                    <VolumeIconLoud style={{ pointerEvent: "none" }} />
                )}
            </Button>

            {showVolumeSlider && (
                <Flex
                    onMouseEnter={() => setShowVolumeSlider(true)}
                    alignItems={"center"}
                    as={motion.div}
                    position="absolute"
                    top="-6.5rem"
                    left="50%"
                    transform="translateX(-50%)"
                    height="6.5rem"
                    width="2rem"
                    border={`1px solid ${PodmeColor.Cinder}`}
                    borderRadius="0.3rem"
                    _before={{
                        content: "''",
                        display: "block",
                        position: "absolute",
                        backgroundColor: PodmeColor.Woodsmoke,
                        width: "100%",
                        height: "100%",
                        borderRadius: "0.3rem",
                    }}
                    _after={{
                        content: "''",
                        display: "block",
                        position: "absolute",
                        bottom: 0,
                        borderColor: `transparent transparent ${PodmeColor.Woodsmoke} ${PodmeColor.Woodsmoke}`,
                        borderStyle: "solid",
                        borderWidth: "5px",
                        left: "47%",
                        transform: "rotate(-45deg) translateX(-50%)",
                        boxShadow: `-1px 1px ${PodmeColor.LicoriceBlue}`,
                    }}
                >
                    <Slider
                        height="80%"
                        paddingLeft="0 !important"
                        paddingRight="0 !important"
                        aria-label="volume-control"
                        orientation="vertical"
                        min={0}
                        max={1}
                        step={0.01}
                        value={isMuted ? 0 : value}
                        onChange={(val) => setVolume(val)}
                        onChangeStart={(val) => {
                            setPrevVolume(val);
                        }}
                        onChangeEnd={(val) => {
                            if (Number(val) > 0) {
                                setPrevVolume(val);
                            }
                        }}
                        width="100%"
                    >
                        <SliderTrack bg={PodmeColor.Cinder}>
                            <SliderFilledTrack bg={PodmeColor.Cyan} transition="background-color 150ms ease" />
                        </SliderTrack>
                        <SliderThumb
                            as={motion.div}
                            left={value === 0 || isMuted ? "50% !important" : undefined}
                            _focus={{ outline: "none " }}
                            width="10px"
                            height="10px"
                            backgroundColor={PodmeColor.SoftWhite}
                        />
                    </Slider>
                </Flex>
            )}
        </Box>
    );
}
