import { ContentType } from "@containers/Homescreen/redux/types";
import { Podcast } from "@typings/index";
import { ExplorerPodcastCategory } from "app/content/contentTypes";

const GET_INITIAL_CATEGORIES_DATA = "categoriespage/GET_INITIAL_CATEGORIES_DATA";

const GET_CATEGORIES = "categoriespage/GET_CATEGORIES";
const GET_CATEGORIES_SUCCESS = "categoriespage/GET_CATEGORIES_SUCCESS";
const GET_CATEGORIES_FAILED = "categoriespage/GET_CATEGORIES_FAILED";

const GET_PODCASTS_BY_CATEGORY = "categoriespage/GET_PODCASTS_BY_CATEGORY";
const GET_PODCASTS_BY_CATEGORY_SUCCESS = "categoriespage/GET_PODCASTS_BY_CATEGORY_SUCCESS";
const GET_PODCASTS_BY_CATEGORY_FAILED = "categoriespage/GET_PODCASTS_BY_CATEGORY_FAILED";

const GET_CATEGORY_PAGE_CONTENT = "categoriespage/GET_CATEGORY_PAGE_CONTENT";
const GET_CATEGORY_PAGE_CONTENT_SUCCESS = "categoriespage/GET_CATEGORY_PAGE_CONTENT_SUCCESS";
const GET_CATEGORY_PAGE_CONTENT_FAILED = "categoriespage/GET_CATEGORY_PAGE_CONTENT_FAILED";

const GET_POPULAR_PODCASTS_BY_CATEGORY = "categoriespage/GET_POPULAR_PODCASTS_BY_CATEGORY";
const GET_POPULAR_PODCASTS_BY_CATEGORY_SUCCESS = "categoriespage/GET_POPULAR_PODCASTS_BY_CATEGORY_SUCCESS";
const GET_POPULAR_PODCASTS_BY_CATEGORY_FAILED = "categoriespage/GET_POPULAR_PODCASTS_BY_CATEGORY_FAILED";

export interface PodcastByCategory {
    id: number;
    isBookmarked: boolean;
    isPremium: boolean;
    largeImageUrl: string;
    mediumImageUrl: string;
    slug: string;
    smallImageUrl?: string;
    imageUrl?: string;
    title: string;
}

export interface CategoriesPageState {
    categories: {
        allCategoriesDescription: string;
        categoriesList: ExplorerPodcastCategory[];
    } | null;
    pageContent: {
        description: string;
        title: string;
        displayTitle: string;
        type: string;
        sections: {
            content: {
                destination?: string;
                title: string;
                podcasts: Podcast[];
                type: ContentType;
            };
        }[];
    } | null;
    bookmarkToggled: boolean;
    bookmarks: any[];
    podcastsByCategory: Partial<Podcast>[] | null;
    popularPodcastsByCategory: PodcastByCategory[] | null;
    isFetchingPodcasts: boolean;
    isFetchingPageContent: boolean;
    numberOfPodcasts: number;
}

export default {
    GET_INITIAL_CATEGORIES_DATA,
    GET_CATEGORIES,
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAILED,
    GET_PODCASTS_BY_CATEGORY,
    GET_PODCASTS_BY_CATEGORY_SUCCESS,
    GET_PODCASTS_BY_CATEGORY_FAILED,
    GET_POPULAR_PODCASTS_BY_CATEGORY,
    GET_POPULAR_PODCASTS_BY_CATEGORY_SUCCESS,
    GET_POPULAR_PODCASTS_BY_CATEGORY_FAILED,
    GET_CATEGORY_PAGE_CONTENT,
    GET_CATEGORY_PAGE_CONTENT_SUCCESS,
    GET_CATEGORY_PAGE_CONTENT_FAILED,
};
