import {
    GET_STUDENT_LANDING_CONTENT,
    GET_STUDENT_LANDING_CONTENT_FAILED,
    GET_STUDENT_LANDING_CONTENT_SUCCESS,
    StudentLandingPageState,
} from "./types";

import { AnyAction } from "redux";

const initialState: StudentLandingPageState = {
    pageContent: null,
    isFetchingPageContent: false,
    error: null,
};

function studentLandingPageReducer(state = initialState, action: AnyAction): StudentLandingPageState {
    switch (action.type) {
        case GET_STUDENT_LANDING_CONTENT:
            return {
                ...state,
                isFetchingPageContent: true,
            };
        case GET_STUDENT_LANDING_CONTENT_SUCCESS:
            return {
                ...state,
                pageContent: action.payload,
                isFetchingPageContent: false,
            };
        case GET_STUDENT_LANDING_CONTENT_FAILED:
            return {
                ...state,
                error: action.error,
                isFetchingPageContent: false,
            };
        default:
            return state;
    }
}

export default studentLandingPageReducer;
