import { memo, ReactElement, useMemo, useState } from "react";
import { Heading, Flex, Text, AspectRatio, Image, Box } from "@chakra-ui/react";
import { IBannerWithEpisodeList, IEpisode } from "../redux/types";
import { Episode, StructuredDataContent } from "@typings/index";
import { PodmeColor } from "@typings/index";
import EpisodeSlideShelfModal from "./EpisodeSlideShelfModal";
import { Helmet } from "react-helmet";
import config from "app/config";
import { useStructuredData } from "app/hooks";
import { useLocation } from "react-router";
import { isEmpty } from "lodash";
import { uid } from "@utils/index";

interface Props {
    content: IBannerWithEpisodeList;
}

export function BannerWithEpisodeList({ content }: Props): ReactElement {
    const { pathname } = useLocation();

    const [isEpisodeListShown, setIsEpisodeListShown] = useState(false);

    const showTitle = !content.hideTitle && content.title.trim() !== "";
    const showDescription = content.description.trim() !== "";

    const episodes: Episode[] = content.episodes.map((episode: IEpisode) => ({
        id: episode.id,
        podcastId: 0,
        authorFullName: "",
        title: episode.title,
        podcastTitle: episode.podcastTitle,
        length: episode.length,
        description: "",
        imageUrl: episode.imageUrl,
        slug: episode.podcastSlug,
        dateAdded: episode.dateAdded,
        smallImageUrl: "",
        mediumImageUrl: "",
        hasCompleted: false,
        isPremium: episode.isPremium,
        episodeCanBePlayed: episode.isPlayable,
        onlyAsPackageSubscription: false,
        totalNoOfEpisodes: 0,
    }));

    const structuredData = useStructuredData(StructuredDataContent.EpisodeList, {
        url: `${config.PUBLIC_URL}${pathname}`,
        episodeSection: content,
    });

    const structuredDataKey = useMemo(() => uid(), []);

    return (
        <>
            <Helmet>
                {!isEmpty(structuredData) && (
                    <script type="application/ld+json" key={structuredDataKey}>
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>
            <Flex
                justifyContent="center"
                position="relative"
                padding={["0 1rem 2rem 1rem", "3rem 2rem", "3rem 0"]}
                maxWidth="70rem"
                margin="0 auto"
                width="100%"
                isolation="isolate"
                _before={{
                    content: [undefined, "''"],
                    display: "block",
                    position: "absolute",
                    inset: "-1rem",
                    zIndex: "-1",
                    backdropFilter: ["none", "blur(0.5rem)"],
                    background: [
                        `linear-gradient(90deg, 
                                                rgba(21, 25, 31, 1) 0%, 
                                                rgba(21, 25, 31, 0.95) 15%, 
                                                rgba(21, 25, 31, 0.5) 40%,  
                                                rgba(21, 25, 31, 0.5) 60%, 
                                                rgba(21, 25, 31, 0.95) 85%, 
                                                rgba(21, 25, 31, 1) 100%)`,
                    ],
                }}
                _after={{
                    content: [undefined, "''"],
                    display: "block",
                    position: "absolute",
                    inset: "-2rem",
                    zIndex: "-1",
                    backdropFilter: ["none", "blur(0.5rem)"],
                    background: [
                        `radial-gradient(circle at 50% -150%, 
                                                rgba(21, 25, 31, 0.5) 0%, 
                                                rgba(21, 25, 31, 0.1) 50%,  
                                                rgba(21, 25, 31, 0.5) 100%)`,
                    ],
                }}
            >
                <Box display={["none", "block"]} position="absolute" inset="0" overflow="hidden" backdropBlur="1rem" zIndex="-2">
                    <Image
                        src={content.imageUrl}
                        alt={content.title}
                        width="100%"
                        height="100%"
                        objectFit="cover"
                        objectPosition="center"
                    />
                </Box>
                <AspectRatio ratio={125 / 48} width="100%" maxWidth="30rem">
                    <Flex
                        position="relative"
                        width="100%"
                        justifyContent={!showTitle ? "flex-end !important" : "space-between !important"}
                        rounded={["1rem", "1.5rem"]}
                        gridGap="1rem"
                        cursor="pointer"
                        transition="all 100ms ease-in"
                        onClick={() => setIsEpisodeListShown(() => true)}
                    >
                        <Box position="absolute" zIndex="5" width="100%">
                            <Image
                                src={content.imageUrl}
                                alt={content.title}
                                height="100%"
                                width="100%"
                                objectFit="cover"
                                objectPosition="center"
                            />
                        </Box>
                        <Flex
                            zIndex="10"
                            width="100%"
                            height="100%"
                            _hover={{
                                boxShadow: `inset 0 0 10rem 10rem ${PodmeColor.BlackPearl}20`,
                            }}
                        >
                            <Flex
                                padding="1rem"
                                width="100%"
                                alignItems="center"
                                justifyContent={showTitle ? "space-between" : "flex-end"}
                            >
                                {showTitle && (
                                    <Flex direction="column" gridGap="0.5rem" flex="10">
                                        <Heading as="h4" fontSize={["1.125rem", "1.5rem"]} noOfLines={2}>
                                            {content.title}
                                        </Heading>
                                        {showDescription && (
                                            <Text fontSize={["0.875rem", "1rem"]} color={PodmeColor.SoftWhite} noOfLines={2}>
                                                {content.description}
                                            </Text>
                                        )}
                                    </Flex>
                                )}
                                <Box flex="1" maxWidth="fit-content">
                                    <Image src="/assets/images/icons/chevron-right.svg" alt="mover right button" />
                                </Box>
                            </Flex>
                        </Flex>
                    </Flex>
                </AspectRatio>
            </Flex>
            {isEpisodeListShown && (
                <EpisodeSlideShelfModal title={content.title} episodesList={episodes} setIsEpisodeListShown={setIsEpisodeListShown} />
            )}
        </>
    );
}

export default memo(BannerWithEpisodeList);
