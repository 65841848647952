import { ReactElement, useMemo, useRef, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { IListOfPodcasts, IPodcast } from "../redux/types";
import { carouselElementsGroup } from "../utils";
import { useCarousel, useMedia, useStructuredData } from "app/hooks";
import CarouselButton from "./CarouselButton";
import { Helmet } from "react-helmet";
import config from "app/config";
import { StructuredDataContent } from "@typings/index";
import { useLocation } from "react-router";
import { isEmpty } from "lodash";
import { uid } from "@utils/index";
import ListItemRow from "./ListItemRow";

interface Props {
    content: IListOfPodcasts;
}

export default function ListOfPodcasts({ content }: Props): ReactElement {
    const { isSmallScreen } = useMedia();
    const { pathname } = useLocation();

    const structuredDataKey = useMemo(() => uid(), []);

    const [carouselHover, setCarouselHover] = useState<boolean>(false);

    const carouselRef = useRef<HTMLDivElement | null>(null);

    const CAROUSEL_STEP = 1;
    const CAROUSEL_ELEMENTS_GAP = 16;
    const CAROUSEL_ELEMENT_WIDTH = isSmallScreen ? 300 : 600;

    const { handleCarouselMove, bind, isFirstElementVisible, isLastElementVisible } = useCarousel(
        carouselRef,
        CAROUSEL_ELEMENT_WIDTH,
        CAROUSEL_ELEMENTS_GAP,
        CAROUSEL_STEP
    );

    const carouselElements = carouselElementsGroup(content.podcasts.filter(Boolean), 3);

    const structuredData = useStructuredData(StructuredDataContent.PodcastList, {
        url: `${config.PUBLIC_URL}${pathname}`,
        podcastSection: content,
    });

    return (
        <>
            <Helmet>
                {!isEmpty(structuredData) && (
                    <script type="application/ld+json" key={structuredDataKey}>
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>
            <Box
                position="relative"
                onMouseEnter={() => setCarouselHover(true)}
                onMouseLeave={() => setCarouselHover(false)}
                paddingTop="1rem"
            >
                <Flex
                    gridGap="1rem"
                    padding={["0 1rem", "0 2rem"]}
                    overflowX="scroll"
                    style={{
                        scrollSnapType: "inline mandatory",
                        scrollPaddingInline: isSmallScreen ? "1rem" : "2rem",
                        overscrollBehaviorInline: "contain",
                    }}
                    {...bind()}
                    ref={carouselRef}
                    css={{
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                >
                    <CarouselButton
                        visible={!isFirstElementVisible && carouselHover && !isSmallScreen}
                        direction="left"
                        carouselElWidth={430}
                        handleCarouselMove={() => handleCarouselMove("left")}
                    />
                    {carouselElements.map((group: IPodcast[], idx) => (
                        <Flex
                            key={idx}
                            direction="column"
                            gridGap="1rem"
                            style={{
                                scrollSnapAlign: "start",
                            }}
                        >
                            {group.map((podcast) => (
                                <ListItemRow key={podcast.id} item={podcast} />
                            ))}
                        </Flex>
                    ))}
                    <CarouselButton
                        visible={!isLastElementVisible && carouselHover && !isSmallScreen}
                        direction="right"
                        carouselElWidth={430}
                        handleCarouselMove={() => handleCarouselMove("right")}
                    />
                </Flex>
            </Box>
        </>
    );
}
