import { AnyAction } from "redux";
import {
    ContentState,
    GET_CMS_CONTENT_SUCCESS,
    GET_FOOTER_PAGES_CONTENT_SUCCESS,
    CmsContent,
    ENABLED_CONTENT_PREVIEW,
    GET_STARTPAGE_CONTENT_SUCCESS,
    REGION_IDENTIFIER,
    GET_WEB_PAGE_SUCCESS,
} from "./contentTypes";

const initialState: ContentState = {
    footerPages: undefined,
    webPages: undefined,
    startPage: {
        sections: null,
    },
    content: undefined,
    isPreview: false,
};

function contentReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case GET_FOOTER_PAGES_CONTENT_SUCCESS: {
            return {
                ...state,
                footerPages: action.content,
            };
        }
        case GET_STARTPAGE_CONTENT_SUCCESS: {
            return {
                ...state,
                startPage: {
                    sections: action.content,
                },
            };
        }

        case GET_WEB_PAGE_SUCCESS: {
            return {
                ...state,
                webPages: [...(state.webPages || []), ...action.content],
            };
        }

        case GET_CMS_CONTENT_SUCCESS:
            const cmsContent: CmsContent = {
                ...action.content,
                region: REGION_IDENTIFIER[action.content.region as keyof typeof REGION_IDENTIFIER],
            };

            return {
                ...state,
                content: cmsContent,
            };

        case ENABLED_CONTENT_PREVIEW:
            return {
                ...state,
                isPreview: true,
            };

        default:
            return state;
    }
}

export default contentReducer;
