import { AnyAction, Dispatch } from "redux";

export interface AsyncAction {
    type: string;
    waitFor: string[];
    resolveCallback?: (value?: unknown) => void;
    rejectCallback?: (value?: unknown) => void;
}

export function serverDataMiddleware(
    promisesList: Promise<unknown>[]
): () => (dispatch: Dispatch<AnyAction>) => (action: AnyAction) => AnyAction | Promise<unknown> {
    const pendingActionList: AsyncAction[] = [];

    let resolveCallback: ((value?: unknown) => void) | undefined = undefined;

    const initalPromise = new Promise((resolve) => {
        resolveCallback = resolve;
    });

    promisesList.push(initalPromise);

    return () =>
        (next: Dispatch<AnyAction>) =>
        (action: AnyAction): AnyAction | Promise<unknown> => {
            if (typeof window !== "undefined") {
                return next(action);
            }

            const actionType = action.type;

            for (let i = pendingActionList.length - 1; i >= 0; i--) {
                const pendingAction = pendingActionList[i];

                if (pendingAction.waitFor.includes(actionType) && pendingAction.resolveCallback) {
                    pendingAction.resolveCallback();
                    if (resolveCallback) resolveCallback();

                    pendingActionList.splice(pendingActionList.indexOf(pendingAction), 1);
                }
            }

            if (!action.WAIT_FOR_ACTION) {
                return next(action);
            }

            const pendingAction: AsyncAction = {
                type: actionType,
                waitFor: action.WAIT_FOR_ACTION,
            };

            const promise = new Promise((resolve, reject) => {
                pendingAction.resolveCallback = resolve;
                pendingAction.rejectCallback = reject;
            });

            pendingActionList.push(pendingAction);
            promisesList.push(promise);

            next(action);

            return promise;
        };
}
