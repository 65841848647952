import { CSSstylesObject, DeviceBreakpoints, Episode, PodmeColor } from "../../types/index";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

import { timeToMinutes, dateFormatter, playerTimeToSeconds, limitCopyLength } from "../../utils";
import { Box, Image, keyframes } from "@chakra-ui/react";
import PlayButton from "@components/PlayButton";
import { useMedia } from "../../../app/hooks";

const placeholderShimmerAnimationKeyframes = keyframes`
0% {
    background-position: -468px 0;
}

100% {
    background-position: 468px 0;
}
`;

const placeholderShimmerAnimation = `${placeholderShimmerAnimationKeyframes} infinite 1s linear forwards`;
const styles: CSSstylesObject = {
    episodeItem: {
        display: "flex",
        padding: "0.5rem 0",
        position: "relative",
        lineHeight: "1.25rem",
        cursor: "pointer",
        sx: {
            [`@media ${DeviceBreakpoints.SmallOnly}`]: { padding: "0.5rem 0" },
            "&:last-child": {
                marginBottom: "none",
            },
        },
        // -webkit-tap-highlight-color: transparent;
    },
    episodeItemImageContainer: { position: "relative" },
    episodeItemImage: {
        height: "70px",
        width: "auto",
        position: "relative",
        display: "block",
        sx: {
            [`@media ${DeviceBreakpoints.SmallOnly}`]: { height: "60px" },
        },
    },
    episodeItemLink: {
        textDecoration: "none",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
    },
    episodeItemTitle: {
        fontSize: "1em",
        color: PodmeColor.White,
        fontWeight: 400,
        maxWidth: "90%",
    },
    episodeItemDate: {
        color: PodmeColor.Cyan,
        fontSize: "0.8em",
        fontWeight: 100,
    },
    angleIcon: {
        position: "absolute",
        top: "calc(50% - 10px)",
        right: "1rem",
        backgroundImage: 'url("/assets/images/icons/angle-down.png")',
        backgroundSize: "contain",
        height: "10px",
        width: "20px",
        transform: "rotate(-90deg)",
        pointerEvents: "none",
        sx: {
            [`@media ${DeviceBreakpoints.SmallOnly}`]: { right: 0 },
        },
    },
    shimmer: {
        background: `${PodmeColor.Woodsmoke}`,
        backgroundImage: `linear-gradient(to right, ${PodmeColor.Woodsmoke} 0%, ${PodmeColor.LicoriceBlue} 20%, ${PodmeColor.Woodsmoke} 40%, ${PodmeColor.Woodsmoke} 100%)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "800px 300px",
        display: "inline-block",
        position: "relative",
        animation: placeholderShimmerAnimation,
    },
    imageOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: `rgba(${PodmeColor.BlackPearl}, 0.5)`,
    },
    imagePlayIcon: {
        position: "absolute",
        top: "calc(50% - 25px)",
        left: 0,
        right: 0,
        backgroundPosition: "center",
        backgroundSize: "contain",
        maxWidth: "80%",
        margin: "0 auto",
        sx: {
            [`@media ${DeviceBreakpoints.SmallOnly}`]: { top: "calc(50% - 20px)" },
        },
    },
    premiumBadge: {
        backgroundImage: 'url("/assets/images/icons/premium-badge.png")',
        width: "20%",
        height: "20px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        position: "absolute",
        top: "-3px",
        right: "6%",
    },
    episodeItemProgressBar: {
        height: "3px",
        width: "100%",
        backgroundColor: `rgba(${PodmeColor.Cyan}, 0.2)`,
        position: "relative",
    },
    episodeItemProgress: {
        position: "absolute",
        left: 0,
        top: 0,
        height: "3px",
        backgroundColor: PodmeColor.Cyan,
    },
};
interface Props {
    episode: Episode;
    episodeUrl: string;
    podcastTitle: string;
    podcastSlug: string;
    [x: string]: unknown;
}

function EpisodeItem({ episode, episodeUrl, podcastTitle, podcastSlug }: Props): ReactElement {
    const { isSmallScreen } = useMedia();

    //return loading skeletons if episode not loaded
    if (!episode || (episode && !episode.id)) {
        return (
            <Box as={"section"} {...styles.episodeItem}>
                <Box {...styles.episodeItemImageContainer}>
                    <Box
                        {...styles.episodeItemImage}
                        {...styles.shimmer}
                        width="70px"
                        sx={{
                            [`@media ${DeviceBreakpoints.SmallOnly}`]: { width: "60px" },
                        }}
                    />
                </Box>

                <Box {...styles.episodeItemLink} justifyContent="flex-start">
                    <Box
                        {...styles.episodeItemTitle}
                        {...styles.shimmer}
                        width="70%"
                        height="20px"
                        marginBottom="0.5rem"
                        sx={{
                            [`@media ${DeviceBreakpoints.SmallOnly}`]: { height: "15px" },
                        }}
                    />
                    <Box {...styles.episodeItemDate} {...styles.shimmer} width="10%" height="10px" />
                </Box>
                <Box as={"span"} {...styles.angleIcon} />
            </Box>
        );
    }

    const { title, smallImageUrl, length, dateAdded } = episode;

    const currentSpot = playerTimeToSeconds(episode.currentSpot ?? "");
    const episodeDuration = playerTimeToSeconds(length);
    const currentTimeInPercentage = Math.floor((currentSpot / episodeDuration) * 100);

    //return episodeItem with data if loaded
    return (
        <Box as="article" {...styles.episodeItem} gridGap={4}>
            <Box {...styles.episodeItemImageContainer} flex={["0 0 60px", null, "0 0 70px"]} boxSize={["60px", null, "70px"]}>
                <Image {...styles.episodeItemImage} src={smallImageUrl} alt={title} />
                <Box as={"span"} {...styles.imageOverlay} />
                <Box {...styles.imagePlayIcon} boxSize={["40px", null, "50px"]}>
                    <PlayButton
                        playObject={{
                            id: episode.id,
                            title: episode.title,
                            imageUrl: episode.smallImageUrl,
                            slug: podcastSlug,
                            podcastTitle: podcastTitle,
                            isPremium: episode.isPremium,
                            streamUrl: episode.streamUrl,
                            length: episode.length,
                            currentSpot: episode.currentSpot,
                        }}
                        zIndex={2}
                        type="green"
                    />
                </Box>

                {episode.isPremium && <Box as={"span"} {...styles.premiumBadge} />}
            </Box>
            <Link to={episodeUrl}>
                <Box {...styles.episodeItemLink}>
                    <Box>
                        <Box as={"h3"} {...styles.episodeItemTitle}>
                            {limitCopyLength(title, isSmallScreen ? 70 : 100)}
                        </Box>
                        <Box as={"span"} {...styles.episodeItemDate}>
                            {dateFormatter(dateAdded)} ({timeToMinutes(length)})
                        </Box>
                    </Box>
                    <Box {...styles.episodeItemProgressBar}>
                        <Box
                            as={"span"}
                            {...styles.episodeItemProgress}
                            style={{
                                width: `${currentTimeInPercentage}%`,
                                maxWidth: "100%",
                            }}
                        />
                    </Box>
                </Box>
            </Link>
            <Box as={"span"} {...styles.angleIcon} />
        </Box>
    );
}

export default EpisodeItem;
