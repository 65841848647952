import { call, put, takeLatest, PutEffect } from "redux-saga/effects";
import routes from "../../../api";
import { SagaReturnType } from "@typings/index";

import {
    GET_EPISODE,
    GET_EPISODE_SUCCESS,
    GET_EPISODE_FAILED,
    GET_EPISODES,
    GET_EPISODES_SUCCESS,
    GET_EPISODES_FAILED,
    GetEpisode,
    GetEpisodeSuccess,
    GetEpisodeFailed,
    GetEpisodes,
    GetEpisodesSuccess,
    GetEpisodesFailed,
} from "./types";

export function* getEpisode({
    id,
    isAuthenticated,
}: GetEpisode): SagaReturnType<PutEffect<GetEpisodeSuccess> | PutEffect<GetEpisodeFailed>> {
    try {
        let data;
        if (isAuthenticated) {
            data = yield call(routes.getEpisodeAuth, id);
        } else {
            data = yield call(routes.getEpisode, id);
        }

        return yield put({ type: GET_EPISODE_SUCCESS, episode: data });
    } catch (error) {
        return yield put({ type: GET_EPISODE_FAILED, error });
    }
}

export function* getEpisodes({
    slug,
    page,
    fetchByOldest,
    isAuthenticated,
}: GetEpisodes): SagaReturnType<PutEffect<GetEpisodesSuccess> | PutEffect<GetEpisodesFailed>> {
    try {
        let data;
        if (isAuthenticated) {
            data = yield call(routes.getEpisodesAuth, slug, 10, page, fetchByOldest);
        } else {
            data = yield call(routes.getEpisodes, slug, 10, page, fetchByOldest);
        }

        return yield put({ type: GET_EPISODES_SUCCESS, episodes: data, isSortedByOldest: fetchByOldest, page });
    } catch (error) {
        return yield put({ type: GET_EPISODES_FAILED, error });
    }
}

export default [takeLatest(GET_EPISODE, getEpisode), takeLatest(GET_EPISODES, getEpisodes)];
