import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Flex, Image } from "@chakra-ui/react";
import { PlayObject, LoadPlayerMedia, LOAD_PLAYER_MEDIA } from "@containers/GlobalPlayer/redux/GlobalPlayerTypes";
import useAuth from "../auth/useAuth";
import { useSound } from "../containers/SoundProvider";
import { playerTimeToSeconds } from "@utils/index";
import LoadingIcon from "./LoadingIcon";
import { useDeviceOnline } from "../../app/hooks";
import { PodmeColor } from "@typings/index";
import { addToCartEventAction } from "../tracking/trackingActions";

interface Props {
    playObject: PlayObject;
    type?: "white" | "green" | "default";
    callback?: () => void;
    [x: string]: any;
}

export default function PlayButton({ playObject, type = "default", callback, ...rest }: Props): ReactElement {
    const dispatch = useDispatch();
    const isOnline = useDeviceOnline();
    const { isAuthenticated, hasActiveSubscription, showPremiumModal } = useAuth();
    const [hasAccess, setHasAccess] = useState<boolean>(hasActiveSubscription || (playObject && !playObject.isPremium));
    const triggerCallback = useMemo(() => {
        let callbackNotTriggered = true;
        return () => {
            if (callback && callbackNotTriggered) {
                callback();
                callbackNotTriggered = false;
            }
        };
    }, []);

    const {
        state: { isPlaying, isLoading, currentAudioId },
        load,
        play,
        pause,
    } = useSound();

    const episodeIsPlaying = isPlaying && currentAudioId === String(playObject.id);

    const togglePlay = useCallback(
        (evt: any) => {
            evt.preventDefault();
            evt.stopPropagation();

            if (hasAccess && playObject.streamUrl) {
                if (isOnline) {
                    load(String(playObject.id), playObject.streamUrl, playerTimeToSeconds(playObject.currentSpot));

                    dispatch<LoadPlayerMedia>({ type: LOAD_PLAYER_MEDIA, playObject });

                    if (episodeIsPlaying) {
                        pause();
                    } else {
                        play(String(playObject.id));

                        dispatch(
                            addToCartEventAction(
                                String(playObject.id),
                                playObject.title,
                                playObject.podcastTitle,
                                hasActiveSubscription ?? false,
                                isAuthenticated
                            )
                        );
                    }
                }
            } else if (!hasAccess) {
                showPremiumModal(true);
            }

            triggerCallback();
        },
        [
            playObject.id,
            playObject.currentSpot,
            playObject.streamUrl,
            playObject.podcastTitle,
            hasAccess,
            episodeIsPlaying,
            hasActiveSubscription,
            isAuthenticated,
        ]
    );

    useEffect(() => {
        setHasAccess(hasActiveSubscription || (playObject && !playObject.isPremium));
    }, [playObject, isAuthenticated, hasActiveSubscription]);

    switch (type) {
        case "white":
            return (
                <Box as="button" data-testid="play-button" style={{ height: "100%", width: "100%" }} onClick={togglePlay} {...rest}>
                    {episodeIsPlaying && isLoading ? (
                        <LoadingIcon />
                    ) : (
                        <img
                            style={{ height: "100%", width: "100%", pointerEvents: "none" }}
                            src={`/assets/images/icons/${episodeIsPlaying ? "pause" : "play"}-white.svg`}
                            alt="play"
                        />
                    )}
                </Box>
            );
        case "green":
            return (
                <Box as="button" data-testid="play-button" style={{ height: "100%", width: "100%" }} onClick={togglePlay} {...rest}>
                    {episodeIsPlaying && isLoading ? (
                        <LoadingIcon />
                    ) : (
                        <img
                            style={{ height: "100%", width: "100%", pointerEvents: "none" }}
                            src={`/assets/images/icons/icon-${episodeIsPlaying ? "pause" : "play"}.png`}
                            alt="play"
                        />
                    )}
                </Box>
            );
        default:
            return (
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    as="button"
                    rounded="50%"
                    height="100%"
                    width="100%"
                    _hover={{ transform: "scale(1.1)" }}
                    sx={{
                        "&:hover > div": {
                            outline: `1px solid ${PodmeColor.LicoriceBlue}`,
                        },
                    }}
                    data-testid="play-button"
                    onClick={(evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();
                        if (episodeIsPlaying && isLoading) {
                            return;
                        }
                        togglePlay(evt);
                    }}
                    {...rest}
                >
                    <Flex
                        className="image-wrapper"
                        rounded="50%"
                        bgColor={PodmeColor.LicoriceBlue}
                        height="min(12vw, 3rem)"
                        width="min(12vw, 3rem)"
                        padding="0.5rem"
                    >
                        {currentAudioId === String(playObject.id) && isLoading ? (
                            <LoadingIcon />
                        ) : (
                            <Image
                                invert="1"
                                style={{ height: "100%", width: "100%", pointerEvents: "none" }}
                                src={`/assets/images/icons/play-${episodeIsPlaying ? "pause" : "play"}.svg`}
                                alt="play"
                            />
                        )}
                    </Flex>
                </Flex>
            );
    }
}
