import { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, List, ListItem, Heading, GridItem, Text, Flex, Image, Link, Button } from "@chakra-ui/react";
import Cookies from "universal-cookie";
import { NavLink } from "react-router-dom";

import { CSSstylesObject, PodmeColor, Region } from "@typings/index";
import strings from "@locale/localization";
import CountrySelect from "@components/CountrySelect/CountrySelect";
import { RootState } from "../../reducers/rootReducer";
import { useMedia } from "../../hooks";
import AppIcons from "@components/AppIcons";
import { usePrivacy } from "app/contexts/PrivacyProvider";
import { useGetEnabledFeatures } from "app/api/hooks/user";
import PodmePagesLinkList from "./PodmePagesLinkList";

const styles: CSSstylesObject = {
    footerContainerWithoutBanners: {
        background: PodmeColor.BlackPearl,
        border: `1px solid ${PodmeColor.Cinder}`,
        borderBottom: "none",
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: [4, 8],
        paddingRight: [4, 8],
    },
};

const globalState = (state: RootState) => state.global;
const contentState = (state: RootState) => state.content;

export default function Footer(): ReactElement {
    const cookies = new Cookies();
    const { location } = useSelector(globalState);
    const { footerPages } = useSelector(contentState);
    const { podmePages } = footerPages || {};
    const { locale, cookies: cookiesRoute, userTerms } = strings.routes;
    const string = strings.footer;
    const { privacyPolicyLink, consentViewsAndSettings } = strings.settingsPage.privacy;
    const { isSmallScreen, isMediumScreen } = useMedia();
    const { showConsentModal } = usePrivacy();
    const enabledFeatures = useGetEnabledFeatures();

    useEffect(() => {
        if (typeof window !== "undefined" && location) {
            const regionSelect: HTMLInputElement | null = document.querySelector("[name='location']");
            if (regionSelect) regionSelect.value = location;
        }
    }, [location]);

    function setLanguage(loc: string) {
        document.location.pathname = `/${loc}/`;
        const todaysDate = new Date();
        todaysDate.setDate(todaysDate.getDate() + 7);
        cookies.set("location", loc, { path: "/", expires: todaysDate });
    }

    return (
        <Box as="footer">
            <Box {...styles.footerContainerWithoutBanners}>
                {isMediumScreen && (
                    <GridItem colSpan={1}>
                        <Box paddingBottom="3rem">
                            <img
                                style={{
                                    maxHeight: "100%",
                                    width: "108px",
                                }}
                                src={"/assets/images/brand/podme-logo-white.svg"}
                                alt="logo"
                            />
                        </Box>
                    </GridItem>
                )}
                <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(4, 1fr)", "repeat(5, 1fr)"]} gap={[12, 6]}>
                    {!isMediumScreen && (
                        <GridItem colSpan={1}>
                            <Box height={["33px", "45px"]}>
                                <img
                                    style={{
                                        maxHeight: "100%",
                                        width: isSmallScreen ? "80px" : "108px",
                                        margin: isSmallScreen ? 0 : "0 auto",
                                    }}
                                    src={"/assets/images/brand/podme-logo-white.svg"}
                                    alt="logo"
                                />
                            </Box>
                        </GridItem>
                    )}
                    <Flex direction="column" gridGap={["1rem", null, "1.5rem"]}>
                        <Heading as="h3" color={PodmeColor.SoftWhite} fontWeight="700" fontSize={["1em", "1.25em"]}>
                            PODME
                        </Heading>
                        <PodmePagesLinkList linkList={podmePages} isSheerIdEnabled={enabledFeatures?.data?.isSheerIdEnabled} />
                    </Flex>

                    <Flex direction="column" gridGap={["1rem", null, "1.5rem"]}>
                        <Heading as="h3" color={PodmeColor.SoftWhite} fontWeight="700" fontSize={["1em", "1.25em"]}>
                            {string.information.toUpperCase()}
                        </Heading>
                        <List spacing={3} color={PodmeColor.Silver}>
                            <ListItem padding={"0.2rem 0"}>
                                <NavLink to={`/${locale}/${cookiesRoute}`}>{string.aboutCookies}</NavLink>
                            </ListItem>
                            <ListItem padding={"0.2rem 0"}>
                                <NavLink to={`/${locale}/${userTerms}`}>{string.userTerms}</NavLink>
                            </ListItem>
                            <ListItem padding={"0.2rem 0"}>
                                <Link style={{ textDecoration: "none" }} isExternal href={privacyPolicyLink}>
                                    {string.integrityPolicy}
                                </Link>
                            </ListItem>
                            {/* Norway has no consent manager for psi */}
                            {location !== "no" && (
                                <ListItem padding={"0.2rem 0"}>
                                    <Button
                                        bg="none"
                                        padding="0"
                                        height="unset"
                                        minHeight="unset"
                                        _hover={{ bg: "none" }}
                                        _focus={{ boxShadow: "none" }}
                                        _active={{
                                            "> span": {
                                                color: PodmeColor.SoftWhite,
                                            },
                                        }}
                                        onClick={showConsentModal}
                                    >
                                        <Box as="span" fontWeight="500" color={PodmeColor.Silver} transition="color 150ms ease-out">
                                            {consentViewsAndSettings}
                                        </Box>
                                    </Button>
                                </ListItem>
                            )}
                            {/* Norway has no press site for now, only display links for Finland and Sweden */}
                            {location !== "no" && (
                                <ListItem padding={"0.2rem 0"}>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={
                                            location === "fi"
                                                ? "https://www.sttinfo.fi/uutishuone/69818765/podme"
                                                : "https://sepress.podme.com/"
                                        }
                                    >
                                        {string.press}
                                    </a>
                                </ListItem>
                            )}
                        </List>
                    </Flex>
                    <GridItem colSpan={1}>
                        <Grid gridGap={8}>
                            <Flex direction="column" gridGap={["1rem", null, "1.5rem"]}>
                                <Heading as="h3" color={PodmeColor.SoftWhite} fontWeight="700" fontSize={["1em", "1.25em"]}>
                                    {string.downloadOurApp.toUpperCase()}
                                </Heading>
                                <AppIcons justifyContent="flexStart" />
                            </Flex>
                            {!isMediumScreen && (
                                <Flex direction="column" gridGap={["1rem", null, "1.5rem"]}>
                                    <Heading as="h3" color={PodmeColor.SoftWhite} fontWeight="700" fontSize={["1em", "1.25em"]}>
                                        {string.region.toUpperCase()}
                                    </Heading>
                                    <CountrySelect
                                        onChange={(loc) => {
                                            sessionStorage?.setItem("carousel-last-viewed-item", "0");
                                            setLanguage(loc);
                                        }}
                                        locale={locale as Region}
                                    />
                                </Flex>
                            )}
                        </Grid>
                    </GridItem>
                    {isMediumScreen && (
                        <GridItem>
                            <Flex direction="column" gridGap={["1rem", null, "1.5rem"]}>
                                <Heading as="h3" color={PodmeColor.SoftWhite} fontWeight="700" fontSize={["1em", "1.25em"]}>
                                    {string.region.toUpperCase()}
                                </Heading>
                                <CountrySelect onChange={(loc) => setLanguage(loc)} locale={locale as Region} />
                            </Flex>
                        </GridItem>
                    )}

                    {location === "no" && (
                        <GridItem colStart={[2]} colEnd={[-2]} paddingTop={[null, null, 12]}>
                            <ResponsiblePublisherBanner />
                        </GridItem>
                    )}
                </Grid>
            </Box>
        </Box>
    );
}

export function FooterBottomBanner(): ReactElement {
    return (
        <Box background={PodmeColor.BlackPearl} borderTop={`1px solid ${PodmeColor.Cinder}`}>
            <Text fontSize="0.9em" color={PodmeColor.Silver} textAlign="center" padding={4}>
                © Podme AB {new Date(Date.now()).getUTCFullYear()}
            </Text>
        </Box>
    );
}

function ResponsiblePublisherBanner(): ReactElement {
    const string = strings.footer;

    return (
        <Flex gridGap={4} alignItems="center" background={PodmeColor.BlackPearl}>
            <a href="https://www.nored.no/Redaktoeransvar">
                <Image src={"/assets/images/icons/utgivare.svg"} boxSize="40px" minWidth="40px" alt="redaktorsansvar" />
            </a>
            <Text fontSize="0.9em" color={PodmeColor.Silver}>
                {string.responsiblePublisher}
            </Text>
        </Flex>
    );
}
