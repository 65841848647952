import { ReactElement, useEffect, useState } from "react";
import { Box, Spinner } from "@chakra-ui/react";
import { createPortal } from "react-dom";

import { useDisableScroll } from "../../hooks";
import { PodmeColor } from "../../types/index";

interface Props {
    isShown: boolean;
    bgOpacity?: string;
    [x: string]: unknown;
}

function FullpageSpinner({ isShown, bgOpacity, ...rest }: Props): ReactElement | null {
    const [showSpinner, setShowSpinner] = useState<boolean>(isShown);
    useDisableScroll(showSpinner);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (isShown) {
            setShowSpinner(true);
        } else {
            timeout = setTimeout(() => setShowSpinner(false), 1000);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [isShown]);

    if (!showSpinner) return null;

    if (typeof window !== "undefined") {
        return (
            <>
                {createPortal(
                    <Box
                        data-testid="fullpage-spinner"
                        position="fixed"
                        inset="0"
                        zIndex={2000}
                        isolation="isolate"
                        background={`${PodmeColor.BlackPearl}${bgOpacity ? bgOpacity : ""}`}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Spinner {...rest} size={"xl"} color={PodmeColor.Cyan} />
                    </Box>,
                    document.body
                )}
            </>
        );
    }

    return (
        <Box
            data-testid="fullpage-spinner"
            position="fixed"
            inset="0"
            zIndex={2000}
            isolation="isolate"
            background={`${PodmeColor.BlackPearl}${bgOpacity ? bgOpacity : ""}`}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Spinner {...rest} size={"xl"} color={PodmeColor.Cyan} />
        </Box>
    );
}

export default FullpageSpinner;
