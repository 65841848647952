import { useState, useEffect } from "react";

const SHOW_SCHIBSTED_BANNER_LS = "showSchibstedBanner";

export function useSchibstedDataBanner() {
    const [showSchibstedBanner, setShowSchibstedBanner] = useState<boolean>(true);

    useEffect(() => {
        const bannerLsItem = localStorage.getItem(SHOW_SCHIBSTED_BANNER_LS);
        const timeNowInMs = new Date().getTime();
        const WEEK_IN_MS = 604800000;

        if (!bannerLsItem) {
            localStorage.setItem(SHOW_SCHIBSTED_BANNER_LS, String(timeNowInMs));
        } else {
            if (timeNowInMs - Number(bannerLsItem) > WEEK_IN_MS) {
                setShowSchibstedBanner(true);
                localStorage.setItem(SHOW_SCHIBSTED_BANNER_LS, String(new Date().getTime()));
            } else setShowSchibstedBanner(false);
        }
    }, []);

    return { showSchibstedBanner };
}
