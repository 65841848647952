import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { RootState } from "app/reducers/rootReducer";
import useMedia from "./useMedia";
import { PodmeColor } from "@typings/index";
import { ToastMessage } from "@components/index";

const messageState = (state: RootState) => state.message;

export default function useToastMessage() {
    const toast = useToast();
    const { isSmallScreen } = useMedia();
    const { showErrorToast, showSuccessToast, message } = useSelector(messageState);

    useEffect(() => {
        showErrorToast &&
            toast({
                title: message,
                position: isSmallScreen ? "bottom" : "top",
                status: "error",
                duration: 5000,
                isClosable: true,
                render: (props) => (
                    <ToastMessage
                        isSuccess={false}
                        message={message}
                        onClose={props.onClose}
                        bg={PodmeColor.AlertRedBackground}
                        color={PodmeColor.White}
                    />
                ),
            });

        showSuccessToast &&
            toast({
                position: isSmallScreen ? "bottom" : "top",
                status: "success",
                duration: 5000,
                isClosable: true,
                render: (props) => (
                    <ToastMessage
                        isSuccess={true}
                        message={message}
                        onClose={props.onClose}
                        bg={PodmeColor.CheckmarkGreen}
                        color={PodmeColor.White}
                    />
                ),
            });
    }, [showErrorToast, showSuccessToast]);
}
