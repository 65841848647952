import { ActionWithAsyncResult } from "@typings/index";
import {
    GetStudentLandingContent,
    GET_STUDENT_LANDING_CONTENT,
    GET_STUDENT_LANDING_CONTENT_FAILED,
    GET_STUDENT_LANDING_CONTENT_SUCCESS,
} from "./types";

export function getStudentLandingPageData(): ActionWithAsyncResult<GetStudentLandingContent> {
    return {
        type: GET_STUDENT_LANDING_CONTENT,
        WAIT_FOR_ACTION: [GET_STUDENT_LANDING_CONTENT_SUCCESS, GET_STUDENT_LANDING_CONTENT_FAILED],
    };
}
