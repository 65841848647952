import { findEmailsAndLinks } from "@containers/EpisodePage/utils";
import { hasTextContent, sanitizeText } from "@utils/index";

export const getFormattedDescription = (description: string | undefined) => {
    if (description === undefined || !hasTextContent(description)) return "";

    let sanitizedText = sanitizeText(description);
    sanitizedText = findEmailsAndLinks(sanitizedText);

    // creating proper space for line breaks
    sanitizedText = sanitizedText.replaceAll(/\n/g, "</br>");

    sanitizedText = sanitizedText.replaceAll(/__([^__]*)__/g, "<b>$1</b>"); // replace __boldtext__ with <b>boldtext</b>
    sanitizedText = sanitizedText.replaceAll(/\*([^*]*)\*/g, "<i>$1</i>"); // replace *italictext* with <i>italictext</i>

    if (!sanitizedText.startsWith("<p>") && !sanitizedText.endsWith("</p>")) {
        sanitizedText = "<p>" + sanitizedText + "</p>";
    }

    return sanitizedText;
};
