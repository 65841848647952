import { Podcast, Episode } from "@typings/index";

export const GET_PODCAST = "podcastPage/GET_PODCAST";
export const GET_PODCAST_SUCCESS = "podcastPage/GET_PODCAST_SUCCESS";
export const GET_PODCAST_FAILED = "podcastPage/GET_PODCAST_FAILED";

export const GET_EPISODES = "podcastPage/GET_EPISODES";
export const GET_EPISODES_SUCCESS = "podcastPage/GET_EPISODES_SUCCESS";
export const GET_EPISODES_FAILED = "podcastPage/GET_EPISODES_FAILED";

export const OPEN_PURCHASE_MODAL = "podcastPage/OPEN_PURCHASE_MODAL";
export const OPEN_PREMIUM_LISTEN_MODAL = "podcastPage/OPEN_PREMIUM_LISTEN_MODAL";

export const GET_USER_SUBSCRIPTIONS = "podcastPage/GET_USER_SUBSCRIPTIONS";
export const GET_USER_SUBSCRIPTIONS_SUCCESS = "podcastPage/GET_USER_SUBSCRIPTIONS_SUCCESS";
export const GET_USER_SUBSCRIPTIONS_FAILED = "podcastPage/GET_USER_SUBSCRIPTIONS_FAILED";

export const MARK_EPISODE_COMPLETION = "podcastPage/MARK_EPISODE_COMPLETION";
export const MARK_EPISODE_COMPLETION_SUCCESS = "podcastPage/MARK_EPISODE_COMPLETION_SUCCESS";
export const MARK_EPISODE_COMPLETION_FAILED = "podcastPage/MARK_EPISODE_COMPLETION_FAILED";

export interface PodcastPageState {
    podcast: Podcast | undefined;
    episodes: Episode[] | null;
    show404: boolean;
    podcastLoading: boolean;
    showMore: boolean;
    isSortedByOldest: boolean;
    hasBookmark: boolean | undefined;
    currentPage: number;
    currentSlug: string | undefined;
    numberOfEpisodes: number;
    fetchingEpisodes: boolean;
}

export interface GetPodcast {
    type: typeof GET_PODCAST;
    slug: string;
}

export interface GetPodcastSuccess {
    type: typeof GET_PODCAST_SUCCESS;
    podcast: Podcast;
}

export interface GetPodcastFailed {
    type: typeof GET_PODCAST_FAILED;
    error: any;
}

export interface GetEpisodes {
    type: typeof GET_EPISODES;
    slug: string;
    pageSize: number;
    page: number;
    fetchByOldest: boolean;
    isAuthenticated: boolean;
}

export interface GetEpisodesSuccess {
    type: typeof GET_EPISODES_SUCCESS;
    episodes: Episode[];
    isSortedByOldest: boolean;
    page: number;
    numberOfEpisodes: number;
}

export interface GetEpisodesFailed {
    type: typeof GET_EPISODES_FAILED;
    error: any;
}

export interface MarkEpisodeCompletion {
    type: typeof MARK_EPISODE_COMPLETION;
    episodeId: number | string;
    completed: boolean;
}
export interface MarkEpisodeCompletionSuccess {
    type: typeof MARK_EPISODE_COMPLETION_SUCCESS;
}
export interface MarkEpisodeCompletionFailed {
    type: typeof MARK_EPISODE_COMPLETION_FAILED;
    err: any;
}
