import { CSSProperties, ReactElement } from "react";
import { Helmet } from "react-helmet";
import { Box } from "@chakra-ui/react";
import { Region } from "../../types/index";
import GridContainer from "../GridContainer";
import strings from "@locale/localization";
import { useLocation } from "react-router";
import config from "app/config";

function getLocale(locale: Region): string {
    switch (locale) {
        case "no":
            return "nb_NO";
        case "fi":
            return "fi_FI";
        default:
            return "sv_SE";
    }
}
interface Props {
    children: any;
    meta?: any;
    wrap?: boolean;
    columnSpan?: string | Array<null | string>;
    collapseTop?: boolean;
    styles?: CSSProperties;
    noIndex?: boolean;
    [x: string]: any;
}

function Page({ children, meta, columnSpan, collapseTop, noIndex = false, styles, ...rest }: Props): ReactElement {
    const { locale } = strings.routes;

    const location = useLocation();

    let canonicalUrl = `${config.PUBLIC_URL}${location.pathname}`;

    if (location.search) {
        const urlParams = new URLSearchParams(location.search);
        const allowedParams = ["category", "page"];

        for (const param of allowedParams) {
            if (urlParams.has(param)) {
                canonicalUrl += location.search;
            }
        }
    }

    const getMetaIndexTags = ({ botName = "robots" }: { botName?: string }) => {
        const isDev = process.env.NODE_ENV !== "production";

        if (isDev) return <meta name={botName} content="noindex, nofollow" />;
        if (noIndex) return <meta name={botName} content="noindex, nofollow" />;
        return <meta name={botName} content="index, follow" />;
    };

    return (
        <Box
            as="main"
            letterSpacing={"0.01rem"}
            minHeight="80vh"
            paddingTop={collapseTop ? 0 : ["35px", null, "50px"]}
            {...rest}
            style={styles}
        >
            <GridContainer>
                <Box gridColumn={columnSpan ?? "2 / -2"} gridRow="1/-1" justifySelf="center" width="100%">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{meta?.title}</title>
                        <meta name="description" content={meta?.description} />

                        <meta property="og:title" content={meta?.title} />
                        <meta property="og:description" content={meta?.description} />
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={meta?.url ? `https://podme.com${meta?.url}` : canonicalUrl} />
                        <meta property="og:image" content={meta?.image || ""} />
                        <meta property="og:locale" content={getLocale(locale as Region)} />
                        <meta property="og:site_name" content="Podme" />

                        {getMetaIndexTags({ botName: "robots" })}
                        {meta?.scripts &&
                            meta.scripts
                                .filter(Boolean)
                                .map((scriptSrc: string, index: number) => (
                                    <script key={index} type="text/javascript" src={scriptSrc} />
                                ))}
                    </Helmet>
                    {children}
                </Box>
            </GridContainer>
        </Box>
    );
}

export default Page;
