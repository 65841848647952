import { keyframes, Skeleton, SkeletonCircle as Circle } from "@chakra-ui/react";
import { PodmeColor } from "@typings/index";
import { ReactElement } from "react";

interface SkeletonProps {
    children?: ReactElement;
    isLoaded?: boolean;
    startColor?: PodmeColor;
    [x: string]: any;
}

const leftRight = keyframes`
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
  `;

export default function ({ children, isLoaded = false, ...rest }: SkeletonProps): ReactElement {
    return (
        <Skeleton
            fadeDuration={0}
            sx={
                !isLoaded
                    ? {
                          animation: `${leftRight} 2s infinite`,
                          background: `linear-gradient(90deg, ${PodmeColor.Cinder}, ${PodmeColor.LicoriceBlue}, ${PodmeColor.Cinder}, ${PodmeColor.Cinder})`,
                          backgroundSize: "200% 100%",
                      }
                    : undefined
            }
            isLoaded={isLoaded}
            {...rest}
        >
            {children}
        </Skeleton>
    );
}

export function SkeletonCircle({
    children,
    isLoaded = false,
    startColor = PodmeColor.Cinder,
    endColor = PodmeColor.LicoriceBlue,
    ...rest
}: SkeletonProps): ReactElement {
    return (
        <Circle
            startColor={PodmeColor.Cyan + "30"}
            endColor={PodmeColor.LicoriceBlue}
            fadeDuration={0}
            sx={
                !isLoaded
                    ? {
                          animation: `${leftRight} 2s infinite`,
                          background: `linear-gradient(90deg, ${startColor}, ${endColor}, ${startColor}, ${startColor})`,
                          backgroundSize: "200% 100%",
                      }
                    : undefined
            }
            isLoaded={isLoaded}
            {...rest}
        >
            {children}
        </Circle>
    );
}
