import useAuth from "@auth/useAuth";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import PlayButton from "@components/PlayButton";
import PremiumBadge from "@components/PremiumBadge";
import { SkeletonCircle } from "@components/Skeleton";
import { PodmeColor } from "@typings/index";
import { uid, dateFormatter, timeToMinutes } from "@utils/index";
import strings from "app/locale/localization";
import { memo, ReactElement, useMemo } from "react";
import { Link } from "react-router-dom";
import { useGetEpisode } from "../queries";
import { IEpisode, IPodcast } from "../redux/types";

interface ListItemRowProps {
    item: IEpisode | IPodcast;
}

function ListItemRow({ item }: ListItemRowProps): ReactElement | null {
    const { locale } = strings.routes;
    const key = useMemo(() => uid(), []);

    // const { id, destinationPath, imageUrl, podcastTitle, title, isPremium, dateAdded, length } = item;
    const data = useMemo(
        () => ({
            isEpisode: !!(item as IEpisode)?.dateAdded,
            id: item?.id,
            title: item?.title,
            description: (item as IPodcast)?.description ?? null,
            podcastTitle: (item as IEpisode)?.podcastTitle ?? (item as IPodcast)?.title,
            slug: item?.destinationPath,
            imageUrl: item?.imageUrl,
            length: (item as IEpisode)?.length ?? null,
            isPremium: item?.isPremium ?? null,
            dataAdded: (item as IEpisode)?.dateAdded ?? null,
        }),
        [item?.id]
    );

    const { isAuthenticated } = useAuth();
    const episodeData = data.isEpisode && useGetEpisode(data.id, isAuthenticated);

    return !!data ? (
        <Link to={`/${locale}/${data.slug}`}>
            <Flex
                key={key}
                bgColor={PodmeColor.Cinder}
                rounded={["1rem", "1.5rem"]}
                alignItems="center"
                transition="all 100ms ease-in"
                padding={["0.5rem", "1rem"]}
                gridGap="1rem"
                height="100%"
                minWidth={["calc(100vw - 40px)", "37.5rem"]}
                sx={{
                    "&:hover": {
                        background: [PodmeColor.Cinder + "00", PodmeColor.Cinder + "90"],
                        boxShadow: `inset 0 0 10rem 10rem rgba(255,255,255, 0.05)`,
                    },
                }}
            >
                <Box boxSize={["70px", "96px"]} maxW={["70px", "96px"]} flex={["7", "3"]}>
                    <Image
                        loading="lazy"
                        backgroundColor={PodmeColor.LicoriceBlue}
                        height="100%"
                        maxHeight={["70px", "96px"]}
                        src={data.imageUrl}
                        rounded={["0.5rem", "1rem"]}
                        alt={data.podcastTitle + " | " + data.title}
                    />
                </Box>
                <Flex alignItems="center" justifyContent="space-between" width="100%" height="100%" flex="12" gridGap={["0.5rem", 0]}>
                    <Flex
                        flexDirection="column"
                        justifyContent="center"
                        gridGap="0.25rem"
                        height="100%"
                        flex="20"
                        maxWidth={data?.isEpisode ? ["70%", "80%"] : "100%"}
                    >
                        {data.podcastTitle?.toLowerCase() !== data.title?.toLocaleLowerCase() && (
                            <Flex gridGap={2} alignItems="center">
                                {data.isPremium && (
                                    <>
                                        <PremiumBadge width="min(15dvw, 4rem)" marginBottom="1px" flexShrink="0" />
                                        <Box as="span" height="0.75rem" width="2px" backgroundColor={PodmeColor.Silver} />
                                    </>
                                )}
                                <Heading
                                    as="h3"
                                    fontSize="min(3dvw, 0.875rem)"
                                    fontWeight="400"
                                    color={PodmeColor.Silver}
                                    noOfLines={1}
                                    wordBreak="break-all"
                                >
                                    {data.podcastTitle}
                                </Heading>
                            </Flex>
                        )}
                        <Flex direction="column" gridGap={["0.125rem", "0.25rem"]}>
                            {data.isPremium && !data.isEpisode && (
                                <PremiumBadge width="min(15dvw, 4rem)" marginBottom="1px" flexShrink="0" />
                            )}
                            <Heading as="h3" fontSize={["14px", null, "18px"]} noOfLines={data.isEpisode ? 2 : 1}>
                                {data.title}
                            </Heading>
                        </Flex>
                        {data.description && (
                            <Text
                                fontFamily="Garnett, sans-serif"
                                fontSize="min(3dvw, 0.875rem)"
                                color={PodmeColor.Silver}
                                noOfLines={2}
                                width="100%"
                            >
                                {data.description}
                            </Text>
                        )}
                        {data.dataAdded && (
                            <Text fontFamily="Garnett, sans-serif" fontSize="min(3dvw, 0.875rem)" color={PodmeColor.Silver}>
                                {dateFormatter(data.dataAdded)} • {timeToMinutes(data.length)}
                            </Text>
                        )}
                    </Flex>
                    {data.isEpisode && !!episodeData && (
                        <Flex
                            flex="8"
                            boxSize="100%"
                            maxWidth={["70px", "96px"]}
                            transition="transform 100ms ease-in"
                            rounded="50%"
                            height="100%"
                            marginRight={["-0.25rem", "-1rem"]}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <SkeletonCircle
                                boxSize={episodeData.data ? "100%" : "min(12vw, 3rem)"}
                                style={{ alignSelf: "center" }}
                                isLoaded={!!episodeData.data}
                                boxShadow={!!episodeData.data ? undefined : `0 0 2rem ${PodmeColor.BlackPearl}`}
                            >
                                <PlayButton
                                    playObject={{
                                        id: episodeData.data?.id as number,
                                        title: episodeData.data?.title as string,
                                        podcastTitle: episodeData.data?.podcastTitle as string,
                                        slug: episodeData.data?.slug as string,
                                        imageUrl: episodeData.data?.smallImageUrl ?? data.imageUrl ?? "",
                                        length: episodeData.data?.length as string,
                                        currentSpot: episodeData.data?.currentSpot,
                                        streamUrl: episodeData.data?.streamUrl,
                                        isPremium: episodeData.data?.isPremium as boolean,
                                    }}
                                />
                            </SkeletonCircle>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </Link>
    ) : null;
}

export default memo(ListItemRow);
