import { isAudioUrlMp3 } from "@utils/index";
import HlsService from "app/services/HlsService";
import { SoundState, SoundAction } from "./types";

export const initalState: SoundState = {
    isPlaying: false,
    isPlayed: false,
    isLoading: false,
    currentAudio: undefined,
    currentAudioId: undefined,
    loadedAudioList: [],
    currentTime: 0,
    currentPosition: 0,
    volume: 1,
    isMuted: false,
};

export function SoundReducer(state: SoundState, action: SoundAction): SoundState {
    const STORED_VOLUME_KEY = "player-volume";

    switch (action.type) {
        case "LOAD_AUDIO": {
            if (!state.loadedAudioList.some((x) => x.id === action.id)) {
                state.isLoading = true;
                const audio = new Audio(action.url);
                audio.id = action.id;

                const storedVolumeState = localStorage.getItem(STORED_VOLUME_KEY);
                if (storedVolumeState) {
                    const parsedValue: { volume: number; muted: boolean } = JSON.parse(storedVolumeState);
                    audio.volume = parsedValue.volume;
                    audio.muted = parsedValue.muted;
                    state.volume = parsedValue.volume;
                    state.isMuted = parsedValue.muted;
                } else {
                    audio.volume = state.isMuted ? 0 : state.volume;
                }

                state.isPlaying = false;
                state.isPlayed = false;
                state.currentAudio?.pause();
                state.currentTime = 0;
                state.currentPosition = 0;

                if (window.Hls && Hls.isSupported() && !isAudioUrlMp3(action.url)) {
                    HlsService.createHlsInstance(audio, action.url);
                }

                if (action.startTime) {
                    audio.currentTime = action.startTime;
                    state.currentTime = action.startTime;
                }

                state.loadedAudioList.pop();

                return {
                    ...state,
                    currentAudio: audio,
                    currentAudioId: action.id,
                    loadedAudioList: state.loadedAudioList.concat(audio),
                };
            }

            return state;
        }

        case "AUDIO_LOADED": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "PLAY_AUDIO": {
            const audioItemToPlay = state.loadedAudioList.find((x) => x.id === action.id);

            if (audioItemToPlay && audioItemToPlay.paused) {
                audioItemToPlay
                    .play()
                    .then(() => (state.isPlaying = true))
                    .catch(() => {
                        state.currentAudio?.pause();
                        state.isPlaying = false;
                    });

                return { ...state, currentAudio: audioItemToPlay, currentAudioId: action.id };
            }

            return { ...state, isPlaying: true };
        }
        case "PAUSE_AUDIO": {
            state.currentAudio?.pause();

            return { ...state, isPlaying: false };
        }

        case "SEEK": {
            if (state.currentAudio === undefined) return state;

            if (state.currentAudio && state.currentAudio.duration) {
                state.isLoading = true;

                const newTime = Math.floor(state.currentAudio.duration * (action.timeInPercentage / 100));
                state.currentAudio.currentTime = newTime;
                state.currentPosition = Math.round(action.timeInPercentage);
            }
            return { ...state };
        }

        case "SEEKED": {
            const audioItemToPlay = state.loadedAudioList.find((x) => x.id === action.id);

            if (audioItemToPlay && !audioItemToPlay.paused) {
                audioItemToPlay.play();

                return { ...state, isPlaying: true, currentAudio: audioItemToPlay, currentAudioId: action.id, isLoading: false };
            }

            return { ...state, isLoading: false };
        }

        // case "CAN_PLAY": {
        //     return {
        //         ...state,
        //         isLoading: false,
        //     };
        // }

        case "SKIP_BACKWARDS": {
            if (state.currentAudio === undefined) return state;

            const skipTime = state.currentTime - 15 >= 0 ? state.currentTime - 15 : 0;
            state.currentAudio.currentTime = skipTime;

            return {
                ...state,
                currentTime: state.currentAudio.currentTime,
            };
        }

        case "SKIP_FORWARDS": {
            if (state.currentAudio === undefined) return state;

            const skipTime = state.currentTime + 15 <= state.currentAudio.duration ? state.currentTime + 15 : state.currentTime;
            state.currentAudio.currentTime = skipTime;

            return {
                ...state,
                currentTime: state.currentAudio.currentTime,
            };
        }

        case "SET_VOLUME": {
            if (state.currentAudio === undefined) return state;

            state.currentAudio.muted = false;
            state.currentAudio.volume = action.volume;

            const isMuted = action.volume > 0 ? false : true;

            localStorage.setItem(STORED_VOLUME_KEY, JSON.stringify({ volume: action.volume, muted: isMuted }));

            return {
                ...state,
                volume: action.volume,
                isMuted,
            };
        }

        case "TOGGLE_MUTED": {
            if (state.currentAudio === undefined) return state;

            const isMuted = !state.isMuted;
            if (isMuted) {
                state.currentAudio.muted = true;
            } else {
                state.currentAudio.muted = false;
                state.currentAudio.volume = action.prevVolume;
                state.volume = action.prevVolume;
            }

            localStorage.setItem(STORED_VOLUME_KEY, JSON.stringify({ volume: state.volume, muted: isMuted }));

            return {
                ...state,
                isMuted: isMuted,
            };
        }

        case "UPDATE_CURRENT_POSITION": {
            if (state.currentAudio === undefined || (state.currentAudio && !state.currentAudio.duration)) return state;
            const pos = Math.floor((action.currentTime / state.currentAudio.duration) * 100);

            return { ...state, currentTime: action.currentTime, currentPosition: pos };
        }

        case "AUDIO_ENDED": {
            return { ...state, isPlaying: false, isPlayed: true };
        }
    }
}
