import { ChakraProps, Flex, Heading } from "@chakra-ui/react";
import VisibilitySensor from "react-visibility-sensor";
import BannerWithEpisodeList from "@containers/Homescreen/components/BannerWithEpisodeList";
import DynamicContent from "@containers/Homescreen/components/DynamicContent";
import EpisodeCarousel from "@containers/Homescreen/components/EpisodeCarousel";
import EpisodePromoCarousel from "@containers/Homescreen/components/EpisodePromoCarousel";
import ListOfEpisodes from "@containers/Homescreen/components/ListOfEpisodes";
import ListOfHeroCards from "@containers/Homescreen/components/ListOfHeroCards";
import PodcastCarousel from "@containers/Homescreen/components/PodcastCarousel";
import PodcastNuggets from "@containers/Homescreen/components/PodcastNuggets";
import PodcastPromoCarousel from "@containers/Homescreen/components/PodcastPromoCarousel";
import PremiumPromo from "@containers/Homescreen/components/PremiumPromo";
import {
    ContentType,
    ExploreCategoriesSectionContent,
    IBannerWithEpisodeList,
    IBannerWithPodcastLink,
    IEpisodeCarousel,
    IEpisodePromoCarousel,
    IListOfEpisodes,
    IListOfHeroCards,
    IListOfPodcasts,
    IPodcastCarousel,
    IPodcastNuggets,
    IPodcastPromoCarousel,
    IPremiumPromo,
    LandingHeroContent,
    Section,
    SectionContent,
} from "@containers/Homescreen/redux/types";
import ValueProposition, { ValuePropositionProps } from "@components/ValueProposition/ValueProposition";
import { CSSstylesObject, PageType, PodmeColor } from "app/types";
import ContentBriefing, { ContentBriefingProps } from "@containers/StartPage/components/ContentBriefing";
import {
    ValuePropositionContent,
    PodcastsMainCarousel,
    ContentBriefingType,
    PricingContent,
    FaqContent,
    TextContentSection,
} from "app/content/contentTypes";
import MainCarousel, { SectionMainCarouselProps } from "@containers/StartPage/components/MainCarousel/MainCarousel";
import { uid } from "app/utils";
import { useMemo } from "react";
import ListOfPodcasts from "@containers/Homescreen/components/ListOfPodcasts";
import { BannerWithPodcastLink } from "@containers/Homescreen/components/BannerWithPodcastLink";
import OfferProposition, { OfferPropositionProps } from "@components/OfferProposition";
import { FaqEntity } from "app/api/types";
import FaqSection from "@components/FaqSection/FaqSection";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import HeroSection from "@containers/StartPage/components/HeroSection";
import ExploreCategoriesSection from "@containers/StartPage/components/ExploreCategoriesSection";

interface CmsSectionsProps {
    sections: Section[];
    config?: RenderSectionConfig;
}

export interface SectionProps {
    testId?: string;
}

interface RenderSectionConfig {
    renderBy?: PageType;
    omitAllTitles?: boolean;
    podcastCarouselProps?: {
        asTypeMain: boolean;
    } & Partial<SectionMainCarouselProps>;
    valuePropositionSectionProps?: Partial<ValuePropositionProps>;
    contentBriefingSectionProps?: Partial<ContentBriefingProps> & ChakraProps;
    pricingSectionProps?: Omit<OfferPropositionProps, "content">;
    faqSectionProps?: {
        answerAsDocumentNode?: boolean;
        accordionItemClickHandler?: (faq: FaqEntity) => void;
        testIds?: {
            faqSectionTestId?: string;
            faqHeaderTestId?: string;
        };
    } & ChakraProps;
    textContentProps?: {
        styles: CSSstylesObject;
    };
    heroSectionProps?: {
        isFirstSection?: boolean;
    };
}

function hasArrayEmptyOrAllNulls(obj: SectionContent) {
    return Object.values(obj).some((arr) => {
        if (Array.isArray(arr)) {
            return arr.length === 0 || arr.every((item) => item == null);
        }
        return false;
    });
}

function renderSectionByType(contentType: ContentType, content: SectionContent, config: RenderSectionConfig) {
    const {
        podcastCarouselProps,
        valuePropositionSectionProps,
        contentBriefingSectionProps,
        pricingSectionProps,
        faqSectionProps,
        heroSectionProps,
        textContentProps,
    } = config || {};
    const key = useMemo(() => uid(), []);

    switch (contentType) {
        case ContentType.ListOfHeroCards:
            return <ListOfHeroCards key={key} content={content as IListOfHeroCards} />;
        case ContentType.PodcastCarousel:
        case ContentType.PodcastCarouselNew:
            if (podcastCarouselProps) {
                const { asTypeMain, ...restCarouselProps } = podcastCarouselProps;
                if (asTypeMain)
                    return <MainCarousel key={key} podcasts={(content as PodcastsMainCarousel).podcasts} {...restCarouselProps} />;
            }
            return (
                <PodcastCarousel
                    key={key}
                    sectionContent={content as IPodcastCarousel}
                    cardSize={[144, 195]}
                    {...podcastCarouselProps}
                />
            );
        case ContentType.EpisodeCarousel:
            return <EpisodeCarousel key={key} content={content as IEpisodeCarousel} cardSize={[144, 195]} />;
        case ContentType.ListOfEpisodes:
            return <ListOfEpisodes key={key} content={content as IListOfEpisodes} />;
        case ContentType.ListOfPodcasts:
            return <ListOfPodcasts key={key} content={content as IListOfPodcasts} />;
        case ContentType.PodcastNuggets:
            return <PodcastNuggets key={key} content={content as IPodcastNuggets} />;
        case ContentType.PremiumPromo:
            return <PremiumPromo key={key} content={content as IPremiumPromo} />;
        case ContentType.DynamicContent:
            return <DynamicContent key={key} />;
        case ContentType.EpisodePromoCarousel:
            return (
                <EpisodePromoCarousel key={key} content={content as IEpisodePromoCarousel} width={[144, 195]} height={[204, 260]} />
            );
        case ContentType.PodcastPromoCarousel:
            return (
                <PodcastPromoCarousel key={key} content={content as IPodcastPromoCarousel} width={[144, 195]} height={[204, 260]} />
            );
        case ContentType.BannerWithEpisodeList:
            return <BannerWithEpisodeList key={key} content={content as IBannerWithEpisodeList} />;
        case ContentType.BannerWithPodcastLink:
            return <BannerWithPodcastLink key={key} content={content as IBannerWithPodcastLink} />;
        case ContentType.ValueProposition:
            return (
                <ValueProposition
                    key={key}
                    sectionContent={content as ValuePropositionContent}
                    bgColor={PodmeColor.White}
                    border={`1px solid ${PodmeColor.Silver}`}
                    {...valuePropositionSectionProps}
                />
            );
        case ContentType.ContentBriefing:
            return <ContentBriefing key={key} cmsContent={content as ContentBriefingType} {...contentBriefingSectionProps} />;
        case ContentType.Pricing:
            return pricingSectionProps ? (
                <OfferProposition key={key} content={content as PricingContent} {...pricingSectionProps} />
            ) : null;

        case ContentType.Faq:
            return <FaqSection faqContent={content as FaqContent} faqSectionProps={faqSectionProps} />;
        case ContentType.TextContent:
            const { styles } = textContentProps || {};

            return <Prose key={key} {...styles} dangerouslySetInnerHTML={{ __html: (content as TextContentSection).text || "" }} />;

        case ContentType.LandingHero:
            const { isFirstSection } = heroSectionProps || {};
            return <HeroSection key={key} cmsContent={content as LandingHeroContent} isFirstSection={!!isFirstSection} />;

        case ContentType.ExploreCategoriesSection:
            return <ExploreCategoriesSection cmsContent={content as ExploreCategoriesSectionContent} />;

        default:
            return null;
    }
}

function renderTitleComponent(title: string, isOmitted?: boolean) {
    if (isOmitted || title === "") return null;
    return (
        <Heading as="h2" fontSize={["1.125rem", "1.5rem", "1.75rem"]}>
            {title}
        </Heading>
    );
}

function mapSectionToComponent(section: Section, config: RenderSectionConfig) {
    const key = useMemo(() => uid(), []);
    const { omitAllTitles, podcastCarouselProps } = config;
    const { asTypeMain, onHomescreen } = podcastCarouselProps || {};

    const {
        content: { type, title },
    } = section;

    const podcastCarouselOnStartPage = type === ContentType.PodcastCarousel && asTypeMain && onHomescreen;

    const showSectionTitle =
        type !== ContentType.ListOfHeroCards &&
        type !== ContentType.PremiumPromo &&
        type !== ContentType.BannerWithEpisodeList &&
        type !== ContentType.BannerWithPodcastLink &&
        type !== ContentType.PodcastNuggets;

    const isTitleOmitted = omitAllTitles || !showSectionTitle || podcastCarouselOnStartPage;

    if (hasArrayEmptyOrAllNulls(section.content)) return null;

    return (
        <VisibilitySensor key={key}>
            <Flex direction="column">
                {title && renderTitleComponent(title, isTitleOmitted)}
                {type && renderSectionByType(type as ContentType, section.content, config)}
            </Flex>
        </VisibilitySensor>
    );
}

const sectionsDefaultConfig: RenderSectionConfig = {
    omitAllTitles: false,
};

function renderSectionsAsComponents(sections: Section[], config?: RenderSectionConfig) {
    if (!sections || sections.length === 0) return <></>;
    return sections.map((section) => mapSectionToComponent(section, config || sectionsDefaultConfig));
}

export function CmsSections(props: CmsSectionsProps) {
    const { sections, config } = props;

    return <>{renderSectionsAsComponents(sections, config)}</>;
}

export default CmsSections;
