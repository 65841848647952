import { Flex, Image, Text } from "@chakra-ui/react";
import { TRIGGER_MIGRATION_BY_USER } from "@containers/Migration/redux/types";
import strings from "@locale/localization";
import { RootState } from "app/reducers/rootReducer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SecondaryButton } from "@components/buttons";
import useMigrationCountdown from "app/hooks/useMigrationCountdown";

const migrationModalSelector = (state: RootState) => state.migration;

const MigrationBanner = () => {
    const dispatch = useDispatch();
    const { showBanner, isLoading, hardMigrationDateCopy } = useSelector(migrationModalSelector);
    const { getMigrationInfoTitle, getMigrationStyle } = useMigrationCountdown();
    const [isOnAccountPage, setIsOnAccountPage] = useState(false);
    const [isOnFaq, setIsOnFaq] = useState(false);

    const { connectBtn, body, moreInfoBtn } = strings.migration.banner;
    const { locale, dashboard } = strings.routes;

    useEffect(() => {
        if (location.pathname.includes(dashboard)) setIsOnAccountPage(true);
        else setIsOnAccountPage(false);
        if (location.pathname.includes(`/${locale}/schibsted-faq/`)) setIsOnFaq(true);
        else setIsOnFaq(false);
    }, [location.pathname]);

    const handleMigrateAccount = () => {
        dispatch({ type: TRIGGER_MIGRATION_BY_USER });
    };

    const migrationStyle = getMigrationStyle();

    if (!showBanner || isOnAccountPage) return null;
    return (
        <Flex
            bgColor={migrationStyle.backgroundColor}
            padding={["0.5rem 1rem", "0.75rem 1.5rem", "0.75rem 2rem"]}
            alignItems={"center"}
            justifyContent={["space-between"]}
            fontSize={["14px"]}
            wrap={"wrap"}
            gridGap={"1rem"}
        >
            <Flex flex="1" gridGap="1rem" alignItems="flex-start" flexDirection={["column", "row", "row", "row"]}>
                <Image src={migrationStyle.iconSrc} alt="info message" display={["none", "none", "block"]} />
                <Flex flexDirection="column">
                    <Text color={migrationStyle.color} fontSize={["16px", "20px"]} lineHeight="22px" fontWeight={"bold"}>
                        {getMigrationInfoTitle()}
                    </Text>
                    <Text color={migrationStyle.color} fontSize={"14px"} lineHeight="24px" display={["none", "none", "block"]}>
                        {strings.formatString(body, hardMigrationDateCopy)}
                    </Text>
                </Flex>
            </Flex>
            <Flex flex={["1", "0"]} gridGap={["0.5rem", "1rem"]} justifyContent={["space-between"]}>
                <SecondaryButton
                    to={`/${locale}/schibsted-faq/`}
                    backgroundColor={"transparent"}
                    color={migrationStyle.color}
                    height="46px"
                    border={`1px solid ${migrationStyle.color}`}
                    padding={["1rem", "1rem 1.5rem"]}
                    display={isOnFaq ? "none" : "flex"}
                    flex="1"
                >
                    {moreInfoBtn}
                </SecondaryButton>
                <SecondaryButton
                    flex="3"
                    height="46px"
                    minHeight="46px"
                    padding={["1rem", "1rem 1.5rem"]}
                    onClick={handleMigrateAccount}
                    isLoading={isLoading}
                >
                    {connectBtn}
                </SecondaryButton>
            </Flex>
        </Flex>
    );
};

export default MigrationBanner;
