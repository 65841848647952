import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { IdentityProvider } from "../../auth/authTypes";
import { Box, Divider, Flex, Grid, Heading, Image, Text, Link } from "@chakra-ui/react";
import { PodmeColor } from "../../types";
import { SecondaryButton } from "../buttons";
import strings from "@locale/localization";
import DividerWithCopy from "@components/DividerWithCopy";
import SchibstedLoginButton from "@components/SchibstedLoginButton/SchibstedLoginButton";

interface SocialLoginSectionProps {
    isSignupPage: boolean;
    login: (provider: IdentityProvider) => void;
    setShowConnectAccountsView?: Dispatch<SetStateAction<boolean>>;
}

export default function SocialLoginSection({
    isSignupPage,
    login,
    setShowConnectAccountsView,
}: SocialLoginSectionProps): ReactElement {
    const string = strings.loginPage;
    const { ctaBtn } = string.connectAccounts;

    return (
        <Grid gridGap={6} flexBasis="10%">
            <SchibstedLoginButton
                text={isSignupPage ? string.continueWithSchibsted : string.loginWithSchibsted}
                bgColor={"#1D72DB" as PodmeColor}
                login={login}
            />
            {!isSignupPage ? (
                <>
                    <DividerWithCopy copy={string.or} />
                    <MigrationDeadlineSocialLoginInfo />
                    <SecondaryButton
                        onClick={() => setShowConnectAccountsView && setShowConnectAccountsView(true)}
                        width="100%"
                        background={PodmeColor.LicoriceBlue}
                        position="relative"
                    >
                        <Text as="span" color={PodmeColor.SoftWhite} fontSize="0.875rem">
                            {ctaBtn}
                        </Text>
                    </SecondaryButton>
                </>
            ) : (
                <Divider width="unset" borderColor={PodmeColor.Silver + "20"} borderBottomWidth="1px" opacity={1} />
            )}
        </Grid>
    );
}

function MigrationDeadlineSocialLoginInfo() {
    const { headline, paragraph, supportEmail } = strings.loginPage.migrationDeadlineInfo;

    return (
        <Flex gridGap="1rem" backgroundColor={PodmeColor.LightBlue} color={PodmeColor.BlackPearl} padding="1rem">
            <Box minWidth="24px">
                <Image src="/assets/images/icons/info-icon-black.png" alt="info icon" />
            </Box>
            <Flex direction="column" gridGap="0.5rem">
                <Heading as="h5" fontSize="0.875rem" fontWeight="700" lineHeight="1.5">
                    {headline}
                </Heading>
                <Text fontSize="0.875rem" fontWeight="500" lineHeight="1.5">
                    {paragraph}{" "}
                    <Link href={`mailto:${supportEmail}`} color={PodmeColor.DarkBlue}>
                        {supportEmail}
                    </Link>
                </Text>
            </Flex>
        </Flex>
    );
}
