import { CSSstylesObject, PodmeColor } from "../../types/index";

export const styles: CSSstylesObject = {
    inputFieldWrapper: {
        //input:not([value='']) . <=== this selects every input with value different than empty.
        sx: {
            "input:focus + label,input:focus-within + label,input:not([value='']) + label": {
                top: "8px",
                fontSize: "0.75rem",
            },
            "input:focus": {
                boxShadow: `0 0 0 2px ${PodmeColor.SoftWhite}!important`,
            },
        },
    },
    error: {
        border: "none",
        boxShadow: `0 0 0 2px ${PodmeColor.Red}`,
    },
    errorText: {
        marginTop: "3px",
        marginLeft: "2px",
        fontSize: "0.8em",
        color: PodmeColor.Red,
    },

    inputField: {
        position: "relative",
        minHeight: "60px",
        borderRadius: "0.88rem",
        border: `2px solid ${PodmeColor.Silver}`,
    },
    inputFieldLabel: {
        fontSize: ["0.875rem", "1rem"],
        color: PodmeColor.Silver,
        pointerEvents: "none",
        position: "absolute",
        top: ["calc(50% - 10px)", "calc(50% - 12px)"],
        left: "1rem",
        transition: "font 0.1s ease, top 0.1s ease, transform 0.1s ease",
    },
    inputFieldInput: {
        background: "transparent",
        color: "inherit",
        transition: "all 100ms ease 0s",
        outline: "0!important",
        height: "100%",
        minHeight: "60px",
        width: "100%",
        padding: "1rem 1rem 0 1rem",
        border: "none",
        borderRadius: "0.88rem",
    },
};
