import { ReactElement, useMemo, useRef, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { IEpisode, IEpisodeCarousel } from "../redux/types";
import CarouselButton from "./CarouselButton";
import { useMedia, useCarousel } from "app/hooks";
import { carouselElementsGroup } from "../utils";
import { Helmet } from "react-helmet";
import CarouselSquareCard from "./CarouselSquareCard";
import { StructuredDataContent } from "@typings/index";
import useStructuredData from "app/hooks/useStructuredData";
import { useLocation } from "react-router";
import { isEmpty } from "lodash";
import { uid } from "@utils/index";

interface Props {
    content: IEpisodeCarousel;
    cardSize: number[];
}

export default function EpisodeCarousel({ content, cardSize }: Props): ReactElement {
    const { isSmallScreen } = useMedia();
    const { pathname } = useLocation();

    const [carouselHover, setCarouselHover] = useState<boolean>(false);

    const carouselRef = useRef<HTMLDivElement | null>(null);

    const CAROUSEL_STEP = 1;
    const CAROUSEL_ELEMENTS_GAP = 16;
    const CAROUSEL_ELEMENT_WIDTH = Number(isSmallScreen ? cardSize[0] : cardSize[1]);

    const { handleCarouselMove, bind, isFirstElementVisible, isLastElementVisible } = useCarousel(
        carouselRef,
        CAROUSEL_ELEMENT_WIDTH,
        CAROUSEL_ELEMENTS_GAP,
        CAROUSEL_STEP
    );

    const carouselElements = carouselElementsGroup(content.episodes.filter(Boolean), CAROUSEL_STEP);

    const structuredData = useStructuredData(StructuredDataContent.EpisodeList, {
        url: pathname,
        episodeSection: content,
    });

    const structuredDataKey = useMemo(() => uid(), []);

    return (
        <>
            <Helmet>
                {!isEmpty(structuredData) && (
                    <script type="application/ld+json" key={structuredDataKey}>
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>

            <Box position="relative" onMouseEnter={() => setCarouselHover(true)} onMouseLeave={() => setCarouselHover(false)}>
                <Flex
                    gridGap={4}
                    overflowX="scroll"
                    padding={["1rem", "1rem 2rem"]}
                    minHeight={typeof window === "undefined" ? ["13rem", "17rem"] : undefined}
                    ref={carouselRef}
                    {...bind()}
                    style={{
                        scrollSnapType: "x mandatory",
                        scrollPaddingInline: isSmallScreen ? "1rem" : "2rem",
                        overscrollBehaviorInline: "contain",
                    }}
                    css={{
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                >
                    <CarouselButton
                        visible={!isFirstElementVisible && carouselHover && !isSmallScreen}
                        direction="left"
                        carouselElWidth={CAROUSEL_ELEMENT_WIDTH}
                        handleCarouselMove={() => handleCarouselMove("left")}
                    />
                    {carouselElements.map((group, groupIdx) => (
                        <Flex
                            key={groupIdx}
                            gridGap={4}
                            style={{
                                scrollSnapAlign: "start",
                            }}
                        >
                            {group.map((el, idx: number) => (
                                <CarouselSquareCard itemData={el as IEpisode} cardSize={cardSize} key={idx} />
                            ))}
                        </Flex>
                    ))}
                    <CarouselButton
                        visible={!isLastElementVisible && carouselHover && !isSmallScreen}
                        direction="right"
                        carouselElWidth={CAROUSEL_ELEMENT_WIDTH}
                        handleCarouselMove={() => handleCarouselMove("right")}
                    />
                </Flex>
            </Box>
        </>
    );
}
