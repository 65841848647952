import { CSSstylesObject, DeviceBreakpoints, PodmeColor } from "app/types";
import { Box, BoxProps, Heading, List, ListItem } from "@chakra-ui/react";
import { ReactElement } from "react";
import { ContentBriefingType } from "app/content/contentTypes";
import { useMedia } from "app/hooks";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import CmsDocument, { CmsDocumentProps } from "@components/Cms/CmsDocument";

const styles: CSSstylesObject = {
    instructionStepsContainer: {
        background: PodmeColor.SoftWhite,
        color: PodmeColor.BlackPearl,
        padding: "3rem",
        maxWidth: "100vw",
        marginBottom: "6rem",
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
        borderRadius: "16px",
    },
    instructionStepsTitle: {
        fontWeight: 600,
        fontSize: "2.25rem",
        marginBottom: "2rem",
        sx: {
            [`@media ${DeviceBreakpoints.MediumDown}`]: {
                fontSize: "1.75rem",
                marginBottom: "2rem",
            },

            [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                fontSize: "1.75rem",
                marginBottom: "1.5rem",
            },
        },
    },
    stepsList: {
        display: "grid",
        gridGap: "2rem",
        sx: {
            [`@media ${DeviceBreakpoints.MediumDown}`]: {
                gridTemplateColumns: "1fr 1fr",
            },

            [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                gridTemplateColumns: "1fr",
                gridGap: "1rem",
            },
        },
    },
    stepWrapper: {
        paddingBottom: "1rem",
    },
    stepBadge: {
        width: "60px",
        height: "60px",
        background: PodmeColor.Sand,
        transform: "rotateZ(45deg)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "12px",
        marginBottom: "1rem",
        sx: {
            [`@media ${DeviceBreakpoints.MediumDown}`]: {
                width: "48px",
                height: "48px",
            },
        },
    },
    stepBadgeNumber: {
        transform: "rotateZ(-45deg)",
        fontWeight: 700,
        fontSize: "2.2rem",
        sx: {
            [`@media ${DeviceBreakpoints.MediumDown}`]: {
                fontSize: "1.4rem",
            },
        },
    },
    stepTitle: {
        fontSize: "20px",
        paddingTop: "1rem",
        paddingBottom: "0.5rem",
        sx: {
            [`@media ${DeviceBreakpoints.MediumDown}`]: {
                fontSize: "1rem",
            },

            [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                fontSize: "1.4rem",
            },
        },
    },
    stepDescription: {
        fontSize: "1rem",
    },
};

interface Props {
    cmsContent: ContentBriefingType;
    customGridTemplateColumns?: string[];
    cmsDocumentProps?: Partial<CmsDocumentProps>;
}

export type ContentBriefingProps = Props & BoxProps;
export default function ContentBriefing({
    cmsContent,
    customGridTemplateColumns,
    cmsDocumentProps,
    ...rest
}: ContentBriefingProps): ReactElement {
    const { headline, items } = cmsContent;
    const { isSmallScreen, isMediumScreen } = useMedia();
    const isMediumDown = isSmallScreen || isMediumScreen;

    return (
        <Box
            as="section"
            aria-labelledby="steps-section-header"
            {...styles.instructionStepsContainer}
            borderRadius={isMediumDown ? "0px" : "1.5rem"}
            padding={isSmallScreen ? "3rem 1rem" : "3rem"}
            {...rest}
        >
            <Heading id="steps-section-header" {...styles.instructionStepsTitle}>
                {headline}
            </Heading>
            <List
                display="grid"
                gridTemplateColumns={customGridTemplateColumns || ["1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"]}
                gridGap={isSmallScreen ? "1rem" : "2rem"}
            >
                {items.map((step, index) => {
                    return (
                        <ListItem key={step.subheadline} {...styles.stepWrapper}>
                            <Box {...styles.stepBadge}>
                                <Box as="span" {...styles.stepBadgeNumber}>
                                    {index + 1}
                                </Box>
                            </Box>
                            <Heading {...styles.stepTitle}>{step.subheadline}</Heading>
                            <Box {...styles.stepDescription}>
                                {typeof step.paragraph === "string" ? (
                                    <Prose dangerouslySetInnerHTML={{ __html: step.paragraph }} sx={{ ">p": { margin: "0" } }} />
                                ) : (
                                    <CmsDocument
                                        document={step.paragraph}
                                        imageProps={{
                                            height: "42px",
                                            marginTop: "1rem",
                                            ...cmsDocumentProps?.imageProps,
                                        }}
                                        linkProps={{
                                            textDecoration: "none",
                                            borderBottom: `1px solid ${PodmeColor.BlackPearl}`,
                                            _hover: {
                                                textDecoration: "none",
                                            },
                                            ...cmsDocumentProps?.linkProps,
                                        }}
                                    />
                                )}
                            </Box>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
}
