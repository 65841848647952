import {
    GET_EPISODES,
    GET_EPISODES_FAILED,
    GET_EPISODES_SUCCESS,
    GET_PODCAST,
    GET_PODCAST_FAILED,
    GET_PODCAST_SUCCESS,
    MARK_EPISODE_COMPLETION_SUCCESS,
    PodcastPageState,
} from "./types";

import strings from "@locale/localization";
import { Category, Episode } from "@typings/index";

import { TRACK_PLAYBACK_SUCCESS } from "@containers/GlobalPlayer/redux/GlobalPlayerTypes";

const initialState: PodcastPageState = {
    podcast: undefined,
    episodes: null,
    show404: false,
    podcastLoading: false,
    fetchingEpisodes: false,

    showMore: false,
    isSortedByOldest: false,
    hasBookmark: undefined,
    currentPage: 0,
    currentSlug: undefined,
    numberOfEpisodes: 0,
};

function podcastPageReducer(state = initialState, action: any): any {
    switch (action.type) {
        case GET_PODCAST:
            return {
                ...state,
                podcast: {},
                episodes: null,
                podcastLoading: true,
                show404: false,
                currentSlug: action.slug,
            };

        case GET_PODCAST_SUCCESS:
            const categories: any = strings.categories;
            action.podcast.categories = action.podcast.categories.map((cat: Category) => ({
                id: cat.id,
                key: cat.key,
                name: categories[cat.key.toLowerCase()],
            }));

            return {
                ...state,
                podcast: action.podcast,
                podcastLoading: false,
            };

        case GET_PODCAST_FAILED:
            return {
                ...state,
                podcastLoading: false,
                show404: true,
            };

        case GET_EPISODES:
            return {
                ...state,
                currentPage: action.page,
                fetchingEpisodes: true,
            };

        case GET_EPISODES_SUCCESS: {
            const newEpisodesCount = action.episodes.length;

            if (state.isSortedByOldest !== action.isSortedByOldest) {
                state.episodes = null;
                state.showMore = newEpisodesCount >= 10 ? true : false;
            } else if (newEpisodesCount < 10) {
                state.showMore = false;
            } else {
                state.showMore = true;
            }

            return {
                ...state,
                episodes: action.episodes,
                currentPage: action.page,
                isSortedByOldest: action.isSortedByOldest,
                numberOfEpisodes: action.numberOfEpisodes,
                fetchingEpisodes: false,
            };
        }

        case GET_EPISODES_FAILED: {
            return {
                ...state,
                fetchingEpisodes: false,
            };
        }

        case MARK_EPISODE_COMPLETION_SUCCESS:
            return {
                ...state,
            };

        case TRACK_PLAYBACK_SUCCESS:
            if (!state.episodes) return state;

            const episodes: Episode[] = state.episodes.map((e: Episode) => {
                if (e.id === action.episodeId && action.hasCompleted) {
                    e.hasCompleted = true;
                }
                return e;
            });
            return {
                ...state,
                episodes,
            };

        default:
            return state;
    }
}

export default podcastPageReducer;
