import { Flex, Text } from "@chakra-ui/react";
import FaqQuestions from "@components/FaqQuestions/FaqQuestions";
import { ActionWithAsyncResult, CmsWebPage, PodmeColor, Region } from "@typings/index";
import strings from "@locale/localization";
import { RootState } from "app/reducers/rootReducer";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GetWebPage, GET_WEB_PAGE, GET_WEB_PAGE_FAILED, GET_WEB_PAGE_SUCCESS } from "app/content/contentTypes";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import CmsSections from "@components/Cms/CmsSections";
import { getLocale } from "@utils/index";

interface Props {
    slug: string;
    migrationHeader?: boolean;
}

function FaqPage({ slug, migrationHeader }: Props) {
    const { webPages } = useSelector((state: RootState) => state.content);
    const content: CmsWebPage = webPages?.find((page) => page.slug === slug);
    const [textContent, setTextContent] = useState(content ? content.textContent : "");
    const { locale } = strings.routes;
    const dispatch = useDispatch();
    const hasTextContent = content?.textContent && content.textContent.trim() !== "";

    useEffect(() => {
        if (content) {
            setTextContent(content.textContent);
        }
    }, [content]);

    useMemo(() => {
        if (!content)
            dispatch<ActionWithAsyncResult<GetWebPage>>({
                type: GET_WEB_PAGE,
                options: {
                    region: getLocale(locale as Region),
                    slug,
                },
                WAIT_FOR_ACTION: [GET_WEB_PAGE_SUCCESS, GET_WEB_PAGE_FAILED],
            });
    }, [locale, slug, content]);

    const renderMigrationHeader = () => {
        const { title, body, firstQuestion, firstAnswer } = strings.migration.schibstedFaq;
        return (
            <Flex flexDirection="column" flex="1" gridGap="1rem" paddingBottom={["2rem", "3rem", "6rem"]}>
                <Text color={PodmeColor.White} fontSize={"36px"} lineHeight="46px" fontWeight={"bold"}>
                    {title}
                </Text>
                <Text color={PodmeColor.Silver} fontSize={"16px"} lineHeight="26px" paddingBottom="1rem">
                    {body}
                </Text>
                <FaqQuestions faqs={[{ question: firstQuestion, answer: firstAnswer }]} />
            </Flex>
        );
    };

    const cmsSections = useMemo(
        () =>
            content?.layoutComponents?.map((section) => {
                return {
                    content: section,
                };
            }),
        [content]
    );

    if (!content) return null;

    return (
        <Flex as="section" className="content" direction="column" gridGap="4.5rem" paddingTop={["1rem", "0"]}>
            {migrationHeader && renderMigrationHeader()}
            {hasTextContent && <Prose dangerouslySetInnerHTML={{ __html: textContent }} />}

            <CmsSections
                sections={cmsSections}
                config={{
                    faqSectionProps: {
                        margin: "0",
                    },
                }}
            />
        </Flex>
    );
}

export default FaqPage;
