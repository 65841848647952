import { ShowErrorToastAction } from "@typings/index";

export const GET_MIGRATION_MODAL_PROMPT = "GET_MIGRATION_MODAL_PROMPT";
export const GET_MIGRATION_MODAL_PROMPT_SUCCESS = "GET_MIGRATION_MODAL_PROMPT_SUCCESS";
export const GET_MIGRATION_MODAL_PROMPT_FAILED = "GET_MIGRATION_MODAL_PROMPT_FAILED";

export const CHECK_MIGRATION_DATE = "CHECK_MIGRATION_DATE";

export const DISMISS_MIGRATION_MODAL_PROMPT = "DISMISS_MIGRATION_MODAL_PROMPT";
export const DISMISS_MIGRATION_MODAL_PROMPT_SUCCESS = "DISMISS_MIGRATION_MODAL_PROMPT_SUCCESS";
export const DISMISS_MIGRATION_MODAL_PROMPT_FAILED = "DISMISS_MIGRATION_MODAL_PROMPT_FAILED";

export const OPEN_MIGRATION_MODAL_PROMPT = "OPEN_MIGRATION_MODAL_PROMPT";
export const OPEN_MIGRATION_MODAL_PROMPT_SUCCESS = "OPEN_MIGRATION_MODAL_PROMPT_SUCCESS";
export const OPEN_MIGRATION_MODAL_PROMPT_FAILED = "OPEN_MIGRATION_MODAL_PROMPT_FAILED";

export const ACCEPT_MIGRATION_MODAL_PROMPT = "ACCEPT_MIGRATION_MODAL_PROMPT";
export const ACCEPT_MIGRATION_MODAL_PROMPT_SUCCESS = "ACCEPT_MIGRATION_MODAL_PROMPT_SUCCESS";
export const ACCEPT_MIGRATION_MODAL_PROMPT_FAILED = "ACCEPT_MIGRATION_MODAL_PROMPT_FAILED";

export const TRIGGER_MIGRATION_FROM_MOBILE = "TRIGGER_MIGRATION_FROM_MOBILE";

export const TRIGGER_MIGRATION_BY_USER = "TRIGGER_MIGRATION_BY_USER";
export const TRIGGER_MIGRATION_BY_USER_SUCCESS = "TRIGGER_MIGRATION_BY_USER_SUCCESS";
export const TRIGGER_MIGRATION_BY_USER_FAILED = "TRIGGER_MIGRATION_BY_USER_FAILED";

export const GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID = "GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID";
export const GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_SUCCESS = "GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_SUCCESS";
export const GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_FAILED = "GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_FAILED";

export const GET_MIGRATION_COMPLETED_MODAL_PROMPT = "GET_MIGRATION_COMPLETED_MODAL_PROMPT";
export const GET_MIGRATION_COMPLETED_MODAL_PROMPT_SUCCESS = "GET_MIGRATION_COMPLETED_MODAL_PROMPT_SUCCESS";
export const GET_MIGRATION_COMPLETED_MODAL_PROMPT_FAILED = "GET_MIGRATION_COMPLETED_MODAL_PROMPT_FAILED";

export const DISMISS_MIGRATION_ERROR_BOX = "DISMISS_MIGRATION_ERROR_BOX";
export const DISMISS_MIGRATION_ERROR_BOX_SUCCESS = "DISMISS_MIGRATION_ERROR_BOX_SUCCESS";
export const DISMISS_MIGRATION_ERROR_BOX_FAILED = "DISMISS_MIGRATION_ERROR_BOX_FAILED";

export type MigrationErrorCode = "ME-01" | "ME-02" | "ME-03";

export interface IMigrationModalPrompt {
    isMigrationHard: boolean;
    shouldGetMigrationPrompt: boolean;
    migrationSessionId: string;
    isVisible: boolean;
    showBanner: boolean;
    migratedPodmeAccount: boolean;
}

export interface IMigrationDate {
    daysLeft: number;
}
export interface GetMigrationModalPrompt {
    type: typeof GET_MIGRATION_MODAL_PROMPT;
}
export interface GetMigrationModalPromptSuccess {
    type: typeof GET_MIGRATION_MODAL_PROMPT_SUCCESS;
    payload: IMigrationModalPrompt;
}

export interface GetMigrationModalPromptFailed {
    type: typeof GET_MIGRATION_MODAL_PROMPT_FAILED;
    err: any;
}

export interface CheckMigrationDate {
    type: typeof CHECK_MIGRATION_DATE;
}
export interface DismissMigrationModalPrompt {
    type: typeof DISMISS_MIGRATION_MODAL_PROMPT;
}

export interface DismissMigrationModalPromptSuccess {
    type: typeof DISMISS_MIGRATION_MODAL_PROMPT_SUCCESS;
    payload: { isDismissed: boolean; isVisible: boolean };
}

export interface DismissMigrationModalPromptFailed extends ShowErrorToastAction {
    type: typeof DISMISS_MIGRATION_MODAL_PROMPT_FAILED;
    err: any;
}

export interface OpenMigrationModalPrompt {
    type: typeof OPEN_MIGRATION_MODAL_PROMPT;
}
export interface OpenMigrationModalPromptSuccess {
    type: typeof OPEN_MIGRATION_MODAL_PROMPT_SUCCESS;
    payload: { isDismissed: boolean; isVisible: boolean };
}
export interface OpenMigrationModalPromptFailed {
    type: typeof OPEN_MIGRATION_MODAL_PROMPT_FAILED;
    err: any;
}

export interface AcceptMigrationModalPrompt {
    type: typeof ACCEPT_MIGRATION_MODAL_PROMPT;
}

export interface TriggerMigrationByUser {
    type: typeof ACCEPT_MIGRATION_MODAL_PROMPT;
}
export interface AcceptMigrationModalPromptSuccess {
    type: typeof ACCEPT_MIGRATION_MODAL_PROMPT_SUCCESS;
    payload: { acceptSuccess: boolean; migrationSessionId: string };
}

export interface TriggerMigrationFromMobile {
    type: typeof TRIGGER_MIGRATION_FROM_MOBILE;
    migrationSessionId: string;
}

export interface TriggerMigrationByUserSuccess {
    type: typeof ACCEPT_MIGRATION_MODAL_PROMPT_SUCCESS;
    payload: { acceptSuccess: boolean; migrationSessionId: string };
}

export interface TriggerMigrationByUseFailed extends ShowErrorToastAction {
    type: typeof ACCEPT_MIGRATION_MODAL_PROMPT_FAILED;
    err: any;
}
export interface AcceptMigrationModalPromptFailed extends ShowErrorToastAction {
    type: typeof ACCEPT_MIGRATION_MODAL_PROMPT_FAILED;
    err: any;
}
export interface GetMigrationModalPromptHistoryRecordId {
    type: typeof GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID;
}
export interface GetMigrationModalPromptHistoryRecordIdSuccess {
    type: typeof GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_SUCCESS;
    payload: { migrationSessionId: string };
}
export interface GetMigrationModalPromptHistoryRecordIdFailed extends ShowErrorToastAction {
    type: typeof GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_FAILED;
    err: any;
}
export interface GetMigrationCompletedModalPrompt {
    type: typeof GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID;
}
export interface GetMigrationCompletedModalPromptSuccess {
    type: typeof GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_SUCCESS;
    payload: { isMigrationCompleted: boolean; isVisible: boolean };
}
export interface GetMigrationCompletedModalPromptFailed {
    type: typeof GET_MIGRATION_MODAL_PROMPT_HISTORY_RECORD_ID_FAILED;
    err: any;
    isError: boolean;
}
export interface DismissMigrationErrorBox {
    type: typeof DISMISS_MIGRATION_ERROR_BOX;
}
export interface DismissMigrationErrorBoxSuccess {
    type: typeof DISMISS_MIGRATION_ERROR_BOX_SUCCESS;
    payload: { isError: boolean; error: string };
}
export interface DismissMigrationErrorBoxFailed extends ShowErrorToastAction {
    type: typeof DISMISS_MIGRATION_ERROR_BOX_FAILED;
    err: any;
}

export interface MigrationState {
    isVisible: boolean;
    shouldGetMigrationPrompt: boolean;
    canMigrate: boolean;
    migrationSessionId: string;
    isDismissed: boolean;
    isHard: boolean;
    isSuccess: boolean;
    showBanner: boolean;
    isMigrationCompleted: boolean;
    errorCode: string | null;
    isError: boolean;
    migratedPodmeAccount: boolean;
    acceptSuccess: boolean;
    userAcceptedMigration: boolean;
    isLoading: boolean;
    daysLeft: number;
    oldUserId: string;
    migrationPromptHistoryRecordId: number;
    hardMigrationStartDate: string;
    hardMigrationDateCopy: string;
}

export type MigrationModalAction =
    | GetMigrationModalPrompt
    | GetMigrationModalPromptSuccess
    | GetMigrationCompletedModalPromptFailed
    | DismissMigrationModalPrompt
    | DismissMigrationModalPromptSuccess
    | DismissMigrationModalPromptFailed
    | OpenMigrationModalPrompt
    | OpenMigrationModalPromptSuccess
    | OpenMigrationModalPromptFailed
    | AcceptMigrationModalPrompt
    | AcceptMigrationModalPromptSuccess
    | AcceptMigrationModalPromptFailed
    | GetMigrationModalPromptHistoryRecordId
    | GetMigrationModalPromptHistoryRecordIdSuccess
    | GetMigrationModalPromptHistoryRecordIdFailed
    | GetMigrationCompletedModalPrompt
    | GetMigrationCompletedModalPromptSuccess
    | GetMigrationCompletedModalPromptFailed
    | DismissMigrationErrorBox
    | DismissMigrationErrorBoxSuccess
    | DismissMigrationErrorBoxFailed;
