import { IdentityProvider } from "@auth/authTypes";
import useAuth from "@auth/useAuth";
import { SchibstedLoginOverlay } from "@components/SchibstedLoginOverlay/SchibstedLoginOverlay";
import { PodmeColor } from "@typings/index";
import { PrimaryButtonNew } from "..";

interface Props {
    login: (provider: IdentityProvider) => void;
    text: string;
    bgColor: PodmeColor;
}

function SchibstedLoginButton({ text, bgColor, login }: Props) {
    const { showRedirectOverlay } = useAuth();

    return (
        <>
            {showRedirectOverlay && <SchibstedLoginOverlay />}

            <PrimaryButtonNew
                data-testid="schibsted-login-button"
                background={bgColor}
                color={PodmeColor.SoftWhite}
                onClick={() => login(IdentityProvider.Schibsted)}
                borderRadius="1rem"
                fontSize="1rem"
            >
                {text}
            </PrimaryButtonNew>
        </>
    );
}

export default SchibstedLoginButton;
