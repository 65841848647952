import {
    GetEpisode,
    GetEpisodeFailed,
    GET_EPISODE,
    GET_EPISODE_FAILED,
    PLAY_INTERRUPTED_OFFLINE,
    TrackPlayback,
    TRACK_PLAYBACK,
} from "./GlobalPlayerTypes";

export function getEpisodeById(episodeId: number): GetEpisode {
    return {
        type: GET_EPISODE,
        episodeId,
    };
}

export function getEpisodeByIdFailed(error: any): GetEpisodeFailed {
    return {
        type: GET_EPISODE_FAILED,
        error,
    };
}

export function trackPlayback(episodeId: number, currentSpot: string, hasCompleted: boolean): TrackPlayback {
    return {
        type: TRACK_PLAYBACK,
        episodeId,
        currentSpot,
        hasCompleted,
    };
}

export function playInterruptedOffline() {
    return {
        type: PLAY_INTERRUPTED_OFFLINE,
    };
}
