import { Box } from "@chakra-ui/react";
import { useDataController, useMedia } from "../../../hooks";
import { FC, ReactElement } from "react";
import { DeviceBreakpoints, PodmeColor } from "app/types";

type TSchibBannerVariant = "top" | "bottom";
interface IProps {
    variant?: TSchibBannerVariant;
}
const getVariantStyles = (variant: TSchibBannerVariant) => {
    const { isLargeScreen } = useMedia();
    const baseStyles = {
        ".sch-datacontroller__logo": {
            maxHeight: "1rem",
            flex: "unset",
            marginRight: "0",
            // safari browser doesnt render SVGs properly without a width set up
            minWidth: "6.5rem",
        },
        ".sch-datacontroller__text": {
            color: PodmeColor.Silver,
        },
        ".sch-datacontroller__read-more": {
            color: PodmeColor.Cyan,
        },
        "svg > rect": {
            fill: PodmeColor.SoftWhite,
        },
        "svg > path": {
            fill: PodmeColor.SoftWhite,
        },
    };

    switch (variant) {
        case "top": {
            return {
                ...baseStyles,
                ".sch-datacontroller": {
                    backgroundColor: PodmeColor.Woodsmoke,
                    borderTop: isLargeScreen ? `1px solid ${PodmeColor.LicoriceBlue}` : "unset",
                },
                ".sch-datacontroller__wrapper": {
                    [`@media ${DeviceBreakpoints.MediumDown}`]: {
                        padding: "0.5rem 1rem 0.25rem 1rem",
                    },
                    [`@media ${DeviceBreakpoints.LargeUp}`]: {
                        padding: "0.5rem 1rem 0.25rem 1.75rem",
                    },
                },
                ".sch-datacontroller__content": {
                    justifyContent: "start",
                    textAlign: "left",
                    gap: ["0.5rem", null, "1.5rem"],
                },
            };
        }
        case "bottom":
            return {
                ...baseStyles,
                ".sch-datacontroller": {
                    backgroundColor: PodmeColor.Woodsmoke,
                    borderTop: "unset",
                },
                ".sch-datacontroller__wrapper": {
                    [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                        padding: "0.5rem 1rem 0.25rem 1rem",
                    },
                    [`@media ${DeviceBreakpoints.SmallUp}`]: {
                        padding: "0.5rem 1rem",
                    },
                },
                ".sch-datacontroller__content": {
                    justifyContent: "center",
                    textAlign: "center",
                    gap: ["0.5rem", null, "1.5rem"],
                },
            };
        default: {
            return baseStyles;
        }
    }
};
const SchibstedDataControllerBanner: FC<IProps> = ({ variant = "bottom" }): ReactElement => {
    const [schibstedBannerRef] = useDataController();
    const styles = getVariantStyles(variant);

    return <Box data-testid="schibsted-data-controller-banner" ref={schibstedBannerRef} sx={styles} />;
};

export default SchibstedDataControllerBanner;
