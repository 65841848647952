import { all, call, put, takeLatest, take } from "redux-saga/effects";
import { AxiosError, Method } from "axios";

import routes, { createRequest } from "../api";
import * as types from "../actions/actionTypes";

import authSaga from "../auth/authSaga";
import trackingSaga from "app/tracking/trackingSaga";
import loggingSaga from "./loggingSaga";
import contentSaga from "../content/contentSaga";
import { podcastPageSaga } from "@containers/PodcastPage/redux";
import { episodePageSaga } from "@containers/EpisodePage/redux";
import { globalPlayerSaga } from "@containers/GlobalPlayer/redux";
import { saga as settingsPageSaga } from "@containers/SettingsPage/redux";
import { migrationSaga } from "@containers/Migration/redux";
import { homescreenSaga } from "@containers/Homescreen/redux";
import { homescreenAuthSaga } from "@containers/HomescreenAuth/redux";
import { categoriesSaga } from "@containers/AllCategoriesPage/redux";
import { studentLandingSaga } from "@containers/StudentLandingPageHitract/redux";
import {
    LOGOUT_USER,
    LOGOUT_USER_WITH_ERROR_SUCCESS,
    RefreshToken,
    REFRESH_TOKEN,
    REFRESH_TOKEN_FAILED,
    REFRESH_TOKEN_SUCCESS,
    UNAUTHORIZED_REQUEST,
    FORBIDDEN_REQUEST,
    BANNED_ACCOUNT_MODAL_STORAGE_KEY,
} from "@auth/authTypes";
import { GET_MIGRATION_COMPLETED_MODAL_PROMPT_SUCCESS } from "@containers/Migration/redux/types";

function* getSubscriptions(): any {
    try {
        const data = yield call(routes.getPremiumSubscriptions);

        yield put({ type: types.GET_SUBSCRIPTIONS_SUCCESS, subscriptions: data });
    } catch (error: any) {
        if (error.response) {
            yield put({ type: types.GET_SUBSCRIPTIONS_FAILED, error });
        }
    }
}

function* getRegionInfo(): any {
    try {
        const data: string = yield call(routes.getRegionInfo);
        yield put({ type: types.GET_REGION_INFO_SUCCESS, region: data.toLowerCase() });
    } catch (ignored) {}
}

function* watchUnauthorizedRequests(): any {
    const retryArray: string[] = [];

    while (true) {
        const { context }: { context: AxiosError } = yield take(UNAUTHORIZED_REQUEST);

        yield put<RefreshToken>({ type: REFRESH_TOKEN });

        const refreshStatus = yield take([REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILED]);

        if (refreshStatus.type === REFRESH_TOKEN_SUCCESS && context.config && !retryArray.includes(context.config?.url ?? "")) {
            const { url, method, data } = context.config;

            if (url && method) {
                try {
                    retryArray.push(url);
                    const request = createRequest(method as Method);
                    yield request(url, data);
                } catch (_: any) {}
            }
        } else {
            yield put({ type: LOGOUT_USER });
        }
    }
}

function* watchForbiddenRequests(): any {
    while (true) {
        const { context }: { context: AxiosError } = yield take(FORBIDDEN_REQUEST);

        if (context?.response?.data) {
            if (
                context.response.data?.hasOwnProperty("Type") &&
                (context.response.data as { Type: string }).Type === "AttemptToCreateLegacyAccountError"
            ) {
                sessionStorage.setItem(BANNED_ACCOUNT_MODAL_STORAGE_KEY, "true");
                yield put({ type: LOGOUT_USER });
            }
        }
    }
}

export default function* rootSaga(): any {
    yield all([
        takeLatest(types.GET_SUBSCRIPTIONS, getSubscriptions),
        takeLatest(GET_MIGRATION_COMPLETED_MODAL_PROMPT_SUCCESS, getSubscriptions),
        takeLatest(LOGOUT_USER_WITH_ERROR_SUCCESS, getSubscriptions),
        takeLatest(types.GET_REGION_INFO, getRegionInfo),
        ...authSaga,
        ...podcastPageSaga,
        ...trackingSaga,
        ...loggingSaga,
        ...contentSaga,
        ...globalPlayerSaga,
        ...episodePageSaga,
        ...settingsPageSaga,
        ...migrationSaga,
        ...homescreenSaga,
        ...homescreenAuthSaga,
        ...categoriesSaga,
        ...studentLandingSaga,
        watchUnauthorizedRequests(),
        watchForbiddenRequests(),
    ]);
}
