import { useEffect } from "react";
import { useLocation } from "react-router";
import Cookies from "universal-cookie";

import { EnvoySession, ENVOY_SESSION_KEY } from "@typings/index";

const ENVOY_SHARE_LINK_HASH = "envoy_share_link_hash";

function useEnvoySharing() {
    const location = useLocation();
    const cookies = new Cookies();
    const searchParams = new URLSearchParams(location.search);

    const saveEnvoySession = (envoySession: EnvoySession) => {
        cookies.set(ENVOY_SESSION_KEY, envoySession, {
            path: "/",
            maxAge: 1800,
        });
    };

    const removeEnvoySession = () => {
        cookies.remove(ENVOY_SESSION_KEY, {
            path: "/",
        });
    };

    useEffect(() => {
        const envoyShareLinkHash = searchParams.get(ENVOY_SHARE_LINK_HASH);

        if (envoyShareLinkHash) {
            const envoySession = {
                is_on_envoy_flow: true,
                envoy_share_link_hash: envoyShareLinkHash,
            } satisfies EnvoySession;

            saveEnvoySession(envoySession);
        }
    }, [searchParams]);

    return { removeEnvoySession };
}

export default useEnvoySharing;
