interface Config {
    TENANT_NAME: string;
    PASSWORD_RESET_POLICY: string;
    APPLICATION_ID: string;
    SCOPE_ID: string;
    BACKEND_API: string;
    BACKEND_API_WITHOUT_VERSION: string;
    WEB_SIGN_IN_POLICY_NAME: string;
    PUBLIC_URL: string;
    CMS_API: string;
    PUBLIC_KEY: string;
    ROPC_POLICY: string;
    AI_KEY: string;
    EMAIL_REGEX: RegExp;

    GROUP_PM_ID_SE: number;
    GROUP_PM_ID_FI: number;
    GROUP_PM_ID_NO: number;

    PROPERTY_ID_SE: number;
    PROPERTY_ID_FI: number;
    PROPERTY_ID_NO: number;

    PROPERTY_HREF_SE: string;
    PROPERTY_HREF_NO: string;
    PROPERTY_HREF_FI: string;

    SCHIBSTED_CLIENT_ID: string;
    SCHIBSTED_CLIENT_SECRET: string;
    SCHIBSTED_ENV: string;
    SCHIBSTED_SESSION_DOMAIN: string;

    SCHIBSTED_CLIENT_ID_SE: string;
    SCHIBSTED_CLIENT_SECRET_SE: string;
    SCHIBSTED_ENV_SE: string;
    SCHIBSTED_SESSION_DOMAIN_SE: string;

    SCHIBSTED_CLIENT_ID_NO: string;
    SCHIBSTED_CLIENT_SECRET_NO: string;
    SCHIBSTED_ENV_NO: string;
    SCHIBSTED_SESSION_DOMAIN_NO: string;

    SCHIBSTED_CLIENT_ID_FI: string;
    SCHIBSTED_CLIENT_SECRET_FI: string;
    SCHIBSTED_ENV_FI: string;
    SCHIBSTED_SESSION_DOMAIN_FI: string;

    SCHIBSTED_REDIRECT_URL: string;

    BRAZE_API_KEY: string;
    BRAZE_SDK_ENDPOINT: string;
}

const config: Config = {
    TENANT_NAME: process.env.TENANT_NAME ?? "",
    PASSWORD_RESET_POLICY: process.env.PASSWORD_RESET_POLICY ?? "",
    APPLICATION_ID: process.env.APPLICATION_ID ?? "",
    SCOPE_ID: process.env.SCOPE_ID ?? "",
    BACKEND_API: process.env.BACKEND_API ?? "",
    BACKEND_API_WITHOUT_VERSION: process.env.BACKEND_API_WITHOUT_VERSION ?? "",
    WEB_SIGN_IN_POLICY_NAME: process.env.WEB_SIGN_IN_POLICY_NAME ?? "",
    PUBLIC_URL: process.env.PUBLIC_URL ?? "",
    CMS_API: process.env.CMS_API ?? "",
    PUBLIC_KEY: process.env.PUBLIC_KEY ?? "",
    ROPC_POLICY: process.env.ROPC_POLICY ?? "",
    AI_KEY: process.env.AI_KEY ?? "",

    GROUP_PM_ID_SE: 687344,
    GROUP_PM_ID_FI: 696031,
    GROUP_PM_ID_NO: 692277,

    PROPERTY_ID_SE: 27122,
    PROPERTY_ID_FI: 27685,
    PROPERTY_ID_NO: 27263,

    PROPERTY_HREF_SE: "https://podme.com",
    PROPERTY_HREF_NO: "https://podme.com-no",
    PROPERTY_HREF_FI: "https://podme.com-fi",

    SCHIBSTED_CLIENT_ID: process.env.SCHIBSTED_CLIENT_ID ?? "",
    SCHIBSTED_CLIENT_SECRET: process.env.SCHIBSTED_CLIENT_SECRET ?? "",
    SCHIBSTED_ENV: process.env.SCHIBSTED_ENV ?? "",
    SCHIBSTED_SESSION_DOMAIN: "https://id-pre.se.podme.com",

    SCHIBSTED_CLIENT_ID_SE: process.env.SCHIBSTED_CLIENT_ID_SE ?? "",
    SCHIBSTED_CLIENT_SECRET_SE: process.env.SCHIBSTED_CLIENT_SECRET_SE ?? "",
    SCHIBSTED_ENV_SE: process.env.SCHIBSTED_ENV_SE ?? "",
    SCHIBSTED_SESSION_DOMAIN_SE: "https://id.se.podme.com",

    SCHIBSTED_CLIENT_ID_NO: process.env.SCHIBSTED_CLIENT_ID_NO ?? "",
    SCHIBSTED_CLIENT_SECRET_NO: process.env.SCHIBSTED_CLIENT_SECRET_NO ?? "",
    SCHIBSTED_ENV_NO: process.env.SCHIBSTED_ENV_NO ?? "",
    SCHIBSTED_SESSION_DOMAIN_NO: "https://id.no.podme.com",

    SCHIBSTED_CLIENT_ID_FI: process.env.SCHIBSTED_CLIENT_ID_FI ?? "",
    SCHIBSTED_CLIENT_SECRET_FI: process.env.SCHIBSTED_CLIENT_SECRET_FI ?? "",
    SCHIBSTED_ENV_FI: process.env.SCHIBSTED_ENV_FI ?? "",
    SCHIBSTED_SESSION_DOMAIN_FI: "https://id.fi.podme.com",

    SCHIBSTED_REDIRECT_URL: process.env.SCHIBSTED_REDIRECT_URL ?? "",

    BRAZE_API_KEY: process.env.BRAZE_API_KEY ?? "",
    BRAZE_SDK_ENDPOINT: process.env.BRAZE_SDK_ENDPOINT ?? "",

    EMAIL_REGEX:
        /^[a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
};

export default config;
