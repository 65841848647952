import { useState, useEffect, ChangeEvent, SyntheticEvent } from "react";

const useForm = (
    callback: () => void,
    validate: (vals: any, errors: any, isCouponInvalid?: boolean) => any
): {
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: (event?: SyntheticEvent) => void;
    handleBlur: (event?: ChangeEvent<HTMLInputElement>) => void;
    values: any;
    errors: any;
} => {
    const [values, setValues] = useState<any>(<any>{});
    const [errors, setErrors] = useState<any>({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
            setValues(<any>{});
        }
    }, [errors, isSubmitting]);

    const handleSubmit = (evt?: SyntheticEvent) => {
        evt?.preventDefault();
        const currentErrors = validate(values, errors);

        setErrors({ ...errors, ...currentErrors });
        setIsSubmitting(true);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const inputName = event.target.name;

        if (inputName) {
            let value = event.target.value;

            if (inputName !== "name") {
                value = value?.replace(/\s/g, "");
            }

            if (inputName === "coupon") {
                value = value?.replace(/\s/g, "").toUpperCase();
            }

            setValues((val: any): any => ({ ...val, [inputName]: value }));
        } else {
            event.persist();
            let value = event.target.value;

            if (event.target.name !== "name") {
                value = event.target.value.replace(/\s|\D/g, "");
            }

            setValues((val: any): any => ({ ...val, [event.target.name]: value }));
        }
    };

    const handleBlur = (event?: ChangeEvent<HTMLInputElement>) => {
        setIsSubmitting(false);

        let inputName = "";

        if (event) {
            const element: HTMLInputElement = event.currentTarget;
            inputName = element.name;
        }

        const currentErrors = validate(values, errors);
        const inputError = { [inputName]: currentErrors[inputName] };

        setErrors(inputError);
    };

    return {
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        errors,
    };
};

export default useForm;
