import { Box, keyframes, Text } from "@chakra-ui/react";
import { CSSstylesObject, PodmeColor } from "@typings/index";
import React, { ReactElement, useEffect, useMemo, useRef, useState } from "react";

interface IProps {
    text: string;
    isEpisodePlaying: boolean;
    textBoxWidth: number;
}

function TextScroller({ text, isEpisodePlaying, textBoxWidth }: IProps): ReactElement {
    const textElRef = useRef<HTMLSpanElement>(null);

    const [textWidth, setTextWidth] = useState<number>(0);

    const ANIMATION_BASE_DURATION = 5;
    const ANIMATION_PAUSE_DURATION = 1.5;
    const SCROLLER_ELEMETS_GAP = textBoxWidth / 2;

    useEffect(() => {
        if (textElRef.current?.clientWidth && textElRef.current.clientWidth !== textWidth) {
            setTextWidth((prev) => {
                if (textElRef.current?.clientWidth) return textElRef.current.clientWidth;
                return prev;
            });
        }
    }, [textElRef.current?.clientWidth]);

    const scrollable = useMemo(() => {
        if (!textWidth) return false;

        return textWidth >= textBoxWidth && isEpisodePlaying;
    }, [textWidth, isEpisodePlaying]);

    const animationDuration = useMemo(() => {
        if (!textWidth) return ANIMATION_BASE_DURATION;

        return ANIMATION_BASE_DURATION + Math.round(Math.pow((textWidth - SCROLLER_ELEMETS_GAP) / textBoxWidth, 2));
    }, [textBoxWidth, textWidth]);

    const marquee = keyframes`
        0%, ${(ANIMATION_PAUSE_DURATION / animationDuration) * 100}% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-100%);
        }
    `;

    const styles: CSSstylesObject = {
        episodeScrollerWrapper: {
            zIndex: "1",
            position: "relative",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: textBoxWidth,
            maxHeight: "18px",
            lineHeight: "10px",
            _after: {
                content: "''",
                display: "block",
                position: "absolute",
                inset: 0,
                right: "-2px",
                background: `linear-gradient(90deg,  #0E101400 0%, rgba(14, 16, 20, 0) 75%, rgba(14, 16, 20, 90) 95%, rgba(14, 16, 20, 99) 99%, ${PodmeColor.Woodsmoke} 100%)`,
                zIndex: 2,
            },
        },
        episodeTitle: {
            display: "inline-block",
            as: "h3",
            fontSize: ["0.875em", "1em", "1.125em"],
            fontWeight: 600,
            lineHeight: 1.25,
            animation: scrollable ? `${marquee} ${animationDuration}s linear infinite` : undefined,
            paddingRight: textBoxWidth / 2,
            wordBreak: "break-all",
        },
    };

    return (
        <Box {...styles.episodeScrollerWrapper}>
            <Text as="span" ref={textElRef} {...styles.episodeTitle}>
                {text}
            </Text>
            <Text as="span" {...styles.episodeTitle}>
                {text}
            </Text>
        </Box>
    );
}

export default TextScroller;
