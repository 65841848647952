import { Component, ErrorInfo, ReactNode } from "react";
import appInsights from "app/config/appInsights";
import jwtDecode from "jwt-decode";
import GlobalErrorBoundaryFallbackPage from "./GlobalErrorBoundaryFallbackPage/GlobalErrorBoundaryFallbackPage";

class GlobalErrorBoundary extends Component<{
    children: ReactNode | ReactNode[];
}> {
    state = { hasError: false };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ hasError: true });

        try {
            const errorDetails = this.#getErrorDetails(error, errorInfo);
            const environmentInfo = this.#getEnvironmentInfo();
            const clientEventTime = new Date().toString();
            const dataLayer = this.#getDataLayer();
            const userId = this.#getUserId();

            appInsights.trackException({
                exception: new Error(`[web-app ERROR]: ${error}`),
                properties: { ...errorDetails, ...environmentInfo, userId, dataLayer, clientEventTime },
            });
        } catch (catchError) {
            appInsights.trackException({
                exception: new Error(`[web-app ERROR] <ERROR IN HANDLING>: ${catchError}`),
                properties: { error, errorInfo },
            });
        }
    }

    render() {
        if (this.state.hasError) {
            return <GlobalErrorBoundaryFallbackPage />;
        }

        return this.props.children;
    }

    #getErrorDetails(error: Error, errorInfo: ErrorInfo) {
        if (!error) {
            return {
                errorMessage: "Error object is undefined",
                errorType: "UndefinedError",
                stackTrace: "No stack trace available",
                componentStack: "No component stack available",
            };
        }

        return {
            errorMessage: error.message || "Unknown error message",
            errorType: error.name || "Unknown error type",
            stackTrace: error.stack || "No stack trace available",
            componentStack: errorInfo.componentStack || "No component stack available",
        };
    }

    #getEnvironmentInfo() {
        const defaultUserAgentStr = "User agent unknown";
        const defaultLanguageStr = "Language unknown";
        const defaultScreenSizeStr = "Screen size unknown";
        try {
            return {
                userAgent: navigator.userAgent || defaultUserAgentStr,
                language: navigator.language || defaultLanguageStr,
                screenSize: window.screen ? `${window.screen.width}x${window.screen.height}` : defaultScreenSizeStr,
            };
        } catch (e) {
            return {
                userAgent: `[error] ${defaultUserAgentStr}`,
                language: `[error] ${defaultLanguageStr}`,
                screenSize: `[error] ${defaultScreenSizeStr}`,
            };
        }
    }

    #getDataLayer() {
        return window?.dataLayer ?? [];
    }

    #getUserId() {
        try {
            const parsedTokensStr = localStorage.getItem("SCHIBSTED_SESSION") ?? "{}";
            const parsedTokens = JSON.parse(parsedTokensStr);
            if (!parsedTokens?.access_token) return null;
            const { sub: userId } = jwtDecode(parsedTokens.access_token) as { sub: string };
            return userId;
        } catch (error) {
            return null;
        }
    }
}

export default GlobalErrorBoundary;
