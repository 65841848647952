import { Region } from "@typings/index";

export const GET_CONTENT = "homescreen/auth/GET_CONTENT";
export const GET_CONTENT_SUCCESS = "homescreen/auth/GET_CONTENT_SUCCESS";
export const GET_CONTENT_FAILED = "homescreen/auth/GET_CONTENT_FAILED";

export const GET_PREVIEW_CONTENT = "homescreen/auth/GET_PREVIEW_CONTENT";
export const GET_PREVIEW_CONTENT_SUCCESS = "homescreen/auth/GET_PREVIEW_CONTENT_SUCCESS";
export const GET_PREVIEW_CONTENT_FAILED = "homescreen/auth/GET_PREVIEW_CONTENT_FAILED";

export enum ContentType {
    ListOfHeroCards = "listOfHeroCards",
    ListOfEpisodes = "listOfEpisodes",
    EpisodeCarousel = "episodeCarousel",
    BannerLink = "bannerLink",
    PodcastNuggets = "podcastNuggets",
    EpisodePromoCarousel = "episodePromoCarousel",
    PremiumPromo = "premiumPromo",
    DynamicContent = "dynamicContent",
    BannerWithEpisodeList = "bannerWithEpisodeList",
    PodcastCarousel = "podcastCarousel",
    PodcastPromoCarousel = "podcastPromoCarousel",
}

export interface HomescreenAuthState {
    sections: Section[];
    isFetching: boolean;
}

export interface GetContentResponse {
    sections: Section[];
}

export interface Section {
    content:
        | IListOfHeroCards
        | IListOfEpisodes
        | IPodcastCarousel
        | IPodcastNuggets
        | IEpisodePromoCarousel
        | IBannerWithEpisodeList
        | IPremiumPromo
        | IPodcastPromoCarousel;
}

interface Content {
    title: string;
    type: ContentType;
}

export interface HeroCard {
    destinationPath: string;
    destination: string;
    episodeData: IEpisodeData[];
    hasPodcastBookmark: boolean;
    imageUrl: string;
    isPlayable: true;
    mainHeader: string;
    podcastId: number;
    shortDescription: string;
    subHeader: string | null;
    type: "episode" | "podcast";
}

export interface IListOfHeroCards extends Content {
    heroCards: HeroCard[];
}

export interface IListOfEpisodes extends Content {
    episodes: IEpisode[];
}

export interface IPodcastCarousel extends Content {
    podcasts: IPodcast[];
}

export interface IEpisodeCarousel extends Content {
    episodes: IEpisode[];
}

export interface IPodcastNuggets extends Content {
    subtitle: string;
    episodeLists: { type: string; title: string; episodes: IEpisode[] }[];
}

export interface IEpisodePromoCarousel extends Content {
    isToplist: boolean;
    promotedEpisodes: IEpisode[];
}

export interface IPodcastPromoCarousel extends Content {
    isToplist: boolean;
    promotedPodcasts: IPodcast[];
}

export interface IBannerWithEpisodeList extends Content {
    imageUrl: string;
    episodes: IEpisode[];
    hideTitle: boolean;
    description: string;
}

export interface IPremiumPromo extends Content {
    titleBoldSection: string;
    buttonText: string;
    disclaimer: string;
    podcasts: IPodcast[];
}

export interface IBannerLink extends Content {
    description: string;
    imageUrl: string;
    title: string;
}

export interface GetContent {
    type: typeof GET_CONTENT;
}

export interface GetContentSuccess {
    type: typeof GET_CONTENT_SUCCESS;
    content: GetContentResponse;
}

export interface GetContentFailed {
    type: typeof GET_CONTENT_FAILED;
    err: any;
}

export interface GetPreviewContent {
    type: typeof GET_PREVIEW_CONTENT;
    region: Region;
    hasSubscription: boolean;
}

export interface GetPreviewContentSuccess {
    type: typeof GET_PREVIEW_CONTENT_SUCCESS;
    content: GetContentResponse;
}

export interface GetPreviewContentFailed {
    type: typeof GET_PREVIEW_CONTENT_FAILED;
    err: any;
}
interface IEpisodeData {
    authorFullName: string;
    byteLength: number;
    currentSpot: string;
    dateAdded: Date;
    description: string;
    episodeCanBePlayed: true;
    episodeCreatedAt: Date;
    episodeUpdatedAt: Date;
    hasCompleted: boolean;
    hasPlayed: boolean;
    hlsV3Url: string;
    id: number;
    isPremium: boolean;
    length: string;
    mediumImageUrl: string;
    mpegDashUrl: string;
    number: number;
    onlyAsPackageSubscription: boolean;
    podcastId: number;
    podcastImageUrl: string;
    podcastTitle: string;
    publishDate: Date;
    smallImageUrl: string;
    smoothStreamingUrl: string;
    title: string;
    type: string;
    url: string;
}

export interface IPodcast {
    destinationPath: string;
    id: number;
    imageUrl: string;
    title: string;
}

export interface IEpisode {
    dateAdded: string;
    destinationPath: string;
    id: number;
    imageUrl: string;
    isPlayable: boolean;
    isPremium: boolean;
    length: string;
    podcastTitle: string;
    podcastSlug: string;
    title: string;
    streamUrl?: string;
}
