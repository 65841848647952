import { Box } from "@chakra-ui/react";
import { PodmeColor } from "@typings/index";

interface IProps {
    isDisabled?: boolean;
    onClick?: () => void;
    paddingRight?: string;
    paddingLeft?: string | string[];
    children: React.ReactNode;
    [key: string]: any;
}

function CustomButton({ isDisabled, onClick, paddingRight, paddingLeft, children, ...rest }: IProps) {
    return (
        <Box
            as="button"
            role="button"
            height="100%"
            display="inline-flex"
            alignItems="center"
            backgroundColor={PodmeColor.Cinder}
            border={`1px solid ${PodmeColor.Silver}20`}
            rounded="0.5rem"
            padding={`0.25rem 0.2rem`}
            paddingLeft={paddingLeft || undefined}
            paddingRight={paddingRight || undefined}
            opacity={isDisabled ? 0.5 : 1}
            cursor={isDisabled ? "default" : "pointer"}
            lineHeight="1.7"
            _hover={!isDisabled ? { filter: "brightness(1.5)" } : undefined}
            onClick={onClick}
            disabled={isDisabled}
            {...rest}
        >
            {children}
        </Box>
    );
}

export default CustomButton;
