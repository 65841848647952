import {
    Currency,
    Package,
    SubscriptionState,
    SubscriptionType,
    SubscriptionPlatform,
    ShowSuccessToastAction,
    ShowErrorToastAction,
} from "@typings/index";

export const CANCEL_RENEWAL = "SettingsPage/CANCEL_RENEWAL";
export const CANCEL_RENEWAL_SUCCESS = "SettingsPage/CANCEL_RENEWAL_SUCCESS";
export const CANCEL_RENEWAL_FAILED = "SettingsPage/CANCEL_RENEWAL_FAILED";

export const REACTIVATE_RENEWAL = "SettingsPage/REACTIVATE_RENEWAL";
export const REACTIVATE_RENEWAL_SUCCESS = "SettingsPage/REACTIVATE_RENEWAL_SUCCESS";
export const REACTIVATE_RENEWAL_FAILED = "SettingsPage/REACTIVATE_RENEWAL_FAILED";

export const GET_TRANSACTIONS = "SettingsPage/GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCESS = "SettingsPage/GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAILED = "SettingsPage/GET_TRANSACTIONS_FAILED";

export const MY_TRANSACTIONS_LOADED = "SettingsPage/MY_TRANSACTIONS_LOADED";
export const MY_TRANSACTIONS_LOADED_FAILED = "SettingsPage/MY_TRANSACTIONS_LOADED_FAILED";

export const TRANSACTION_COUNT_LODAED = "SettingsPage/TRANSACTION_COUNT_LODAED";
export const TRANSACTION_COUNT_LODAED_FAILED = "SettingsPage/TRANSACTION_COUNT_LODAED_FAILED";

export const MY_TRANSACTION_LOADED = "SettingsPage/MY_TRANSACTION_LOADED";
export const MY_TRANSACTION_LOADED_FAILED = "SettingsPage/MY_TRANSACTION_LOADED_FAILED";

export const MY_FAILED_TRANSACTION_LOADED = "SettingsPage/MY_FAILED_TRANSACTION_LOADED";
export const MY_FAILED_TRANSACTION_LOADED_FAILED = "SettingsPage/MY_FAILED_TRANSACTION_LOADED_FAILED";

export interface Transaction {
    id: number;
    dateOfTransaction: string;
    title: string;
    priceDecimal: number;
    paymentMethod: string;
    typeOfTransaction: string;
    transactionObjectId: number;
    isTransactionSuccessful: boolean;
    currency?: Currency;
}
export interface SubscriptionPlan {
    name: string;
    packageId: Package;
    price: number;
    priceDecimal: number;
    currency: Currency;
    planGuid: string;
    productId?: string;
    monthLimit?: number;
    nextPlanPriceDecimal?: number;
    nextPlanProductId?: string;
}
export interface Subscription {
    podcastId: number | null;
    subscriptionState: SubscriptionState;
    subscriptionType: SubscriptionType;
    subscriptionPlatform: SubscriptionPlatform;
    expirationDate: string;
    startDate: string;
    subscriptionPlan: SubscriptionPlan;
    imageUrl: string;
    podcastTitle: string | null;
    willBeRenewed: boolean;
}

export interface SettingsPageState {
    transactions: Transaction[];
    transaction: Transaction | null;
    transactionCount: number;
    error: string | null;
    subscriptionCancelled: boolean;
    subscriptionRenewed: boolean;
    isLoading: boolean;
    failedTransaction: any;
}

export interface CancelRenewal {
    type: typeof CANCEL_RENEWAL;
    productId: number | null;
}
export interface CancelRenewalSuccess extends ShowSuccessToastAction {
    type: typeof CANCEL_RENEWAL_SUCCESS;
}
export interface CancelRenewalFailed extends ShowErrorToastAction {
    type: typeof CANCEL_RENEWAL_FAILED;
}
export interface ReactivateRenewal {
    type: typeof REACTIVATE_RENEWAL;
    userId: string;
}
export interface ReactivateRenewalSuccess extends ShowSuccessToastAction {
    type: typeof REACTIVATE_RENEWAL_SUCCESS;
}
export interface ReactivateRenewalFailed extends ShowErrorToastAction {
    type: typeof REACTIVATE_RENEWAL_FAILED;
}

export type SettingsPageActions =
    | CancelRenewal
    | CancelRenewalSuccess
    | CancelRenewalFailed
    | ReactivateRenewal
    | ReactivateRenewalSuccess
    | ReactivateRenewalFailed;

export default {
    CANCEL_RENEWAL,
    CANCEL_RENEWAL_SUCCESS,
    CANCEL_RENEWAL_FAILED,
    REACTIVATE_RENEWAL,
    REACTIVATE_RENEWAL_SUCCESS,
    REACTIVATE_RENEWAL_FAILED,
    MY_TRANSACTIONS_LOADED,
    MY_TRANSACTIONS_LOADED_FAILED,
    TRANSACTION_COUNT_LODAED,
    TRANSACTION_COUNT_LODAED_FAILED,
    MY_TRANSACTION_LOADED,
    MY_TRANSACTION_LOADED_FAILED,
    MY_FAILED_TRANSACTION_LOADED,
    MY_FAILED_TRANSACTION_LOADED_FAILED,
} as const;
