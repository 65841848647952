import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { Box, Flex, Grid, GridItem, Heading, List, ListItem, Text } from "@chakra-ui/react";

import { PrimaryButtonNew } from "@components/buttons";
import CheckmarkListIcon from "@components/CheckmarkListIcon";
import Page from "@components/Page/Page";
import { PodmeColor } from "@typings/index";
import { TRACKING_EVENT } from "app/tracking/trackingTypes";
import useAuth from "@auth/useAuth";
import strings from "app/locale/localization";
import { RootState } from "app/reducers/rootReducer";
import FullpageSpinner from "@components/FullpageSpinner/FullpageSpinner";
import useSubflow from "app/hooks/useSubflow";

const contentState = (state: RootState) => state.content.content;
const globalState = (state: RootState) => state.global;

function PremiumPage() {
    const { getPremium } = strings.navigation;
    const { locale, login, discover } = strings.routes;
    const { meta } = strings.startPage;
    const { trialCopyNewUsers, buttonCta, perksTitle } = strings.onboardingModal;

    const dispatch = useDispatch();
    const location = useLocation();
    const { saveSubflow } = useSubflow();

    const { isEligibleForTrial } = useSelector(globalState);
    const content = useSelector(contentState);

    const { isAuthenticated, authChecked, isUserLoggedInPremium, subscriptionStatusChecked, userId } = useAuth();

    const handleCtaClick = () => {
        saveSubflow();

        dispatch({
            type: TRACKING_EVENT,
            event: "click_start_free_trial_cta",
            user_id: userId,
            logged_in: isAuthenticated,
        });
    };

    useEffect(() => {
        dispatch({
            type: TRACKING_EVENT,
            event: "view_start_free_trial_readerapp",
            user_id: userId,
            logged_in: isAuthenticated,
        });
    }, []);

    if (!authChecked || (authChecked && isAuthenticated && !subscriptionStatusChecked)) {
        return <FullpageSpinner isShown />;
    }

    if (isUserLoggedInPremium) {
        return <Redirect to={`/${locale}/${discover}`} />;
    }

    return (
        <Page
            columnSpan={["1 / -1", null, "2 / -2", "3 / -3", "4 / -4"]}
            height="100%"
            backgroundColor={PodmeColor.BlackPearl}
            collapseTop
            meta={{
                title: getPremium + " | Podme",
                description: meta.description,
            }}
        >
            <Flex
                padding={["3rem 1rem", "5rem 1rem", "5rem 0rem"]}
                width="100%"
                maxWidth={["100%", "34rem", "38.5rem"]}
                margin="0 auto"
                justifyContent="center"
            >
                <Flex gridGap="1rem" direction="column" width="100%">
                    <Flex direction="column" gridGap="2rem" position="relative" zIndex={1}>
                        <Grid
                            backgroundColor={PodmeColor.Cinder}
                            padding={["1rem", "2rem"]}
                            rounded="1.5rem"
                            gridGap={["4rem", "2rem"]}
                        >
                            <GridItem>
                                <Box
                                    as="section"
                                    aria-labelledby="premium-section-heading-id"
                                    display="flex"
                                    flexDirection="column"
                                    gridGap="2rem"
                                >
                                    <Heading id="premium-section-heading-id" as="h2" fontSize="1.25rem" fontWeight="700">
                                        {strings.formatString(perksTitle, "Premium")}
                                    </Heading>
                                    <Box style={{ columnCount: 1, columnGap: "1rem" }} as={List} size="lg" gridGap="1rem" spacing={4}>
                                        {content?.premiumBenefits &&
                                            content?.premiumBenefits.map((perk) => (
                                                <ListItem display="flex" key={perk} color={PodmeColor.Silver}>
                                                    <CheckmarkListIcon />
                                                    {perk}
                                                </ListItem>
                                            ))}
                                        {isEligibleForTrial && (
                                            <ListItem color={PodmeColor.Silver} display="flex">
                                                <CheckmarkListIcon />
                                                <Text as="span">{trialCopyNewUsers}</Text>
                                            </ListItem>
                                        )}
                                    </Box>
                                </Box>
                            </GridItem>
                            <GridItem>
                                <Box width="100%" alignSelf={["unset", "flex-end"]}>
                                    <PrimaryButtonNew
                                        to={{ pathname: `/${locale}/${login}`, state: { goBackUrl: location.pathname } }}
                                        background={PodmeColor.Cyan}
                                        width="100%"
                                        fontSize="1rem"
                                        borderRadius="1rem"
                                        onClick={handleCtaClick}
                                    >
                                        {buttonCta}
                                    </PrimaryButtonNew>
                                </Box>
                            </GridItem>
                        </Grid>
                    </Flex>
                </Flex>
            </Flex>
        </Page>
    );
}

export default PremiumPage;
