import { useCallback, useMemo, useState } from "react";

import useEventListener from "./useEventListener";

const useDeviceOnline = (): boolean => {
    const { safeWindow, safeNavigator } = useMemo<{ safeWindow: Window | undefined; safeNavigator: { onLine: boolean } }>(() => {
        if (typeof window === "undefined") {
            return { safeWindow: undefined, safeNavigator: { onLine: true } };
        }

        return { safeWindow: window, safeNavigator: navigator };
    }, []);

    const [isOnline, setOnline] = useState<boolean>(safeNavigator.onLine);

    useEventListener(
        "online",
        useCallback(() => {
            setOnline(true);
        }, []),
        safeWindow as any
    );

    useEventListener(
        "offline",
        useCallback(() => {
            setOnline(false);
        }, []),
        safeWindow as any
    );

    return isOnline;
};

export default useDeviceOnline;
