import { Region } from "@typings/index";
import { RootState } from "app/reducers/rootReducer";
import { call, put, takeLatest, select, SelectEffect, CallEffect, PutEffect } from "redux-saga/effects";
import routes from "app/api";
import {
    GET_STUDENT_LANDING_CONTENT,
    GET_STUDENT_LANDING_CONTENT_FAILED,
    GET_STUDENT_LANDING_CONTENT_SUCCESS,
} from "@containers/StudentLandingPageHitract/redux/types";

const regionSelector = (state: RootState) => state.global.location;

const getRegionEntity = (region: Region) => {
    switch (region) {
        case Region.Finland:
            return "FI_STUDENT_LANDING_PAGE_HITRACT";
        case Region.Norway:
            return "NO_STUDENT_LANDING_PAGE_HITRACT";
        case Region.Sweden:
            return "SE_STUDENT_LANDING_PAGE_HITRACT";
        default:
            return "SE_STUDENT_LANDING_PAGE_HITRACT";
    }
};

const getRegionNumber = (region: Region) => {
    switch (region) {
        case Region.Finland:
            return 3;
        case Region.Norway:
            return 2;
        case Region.Sweden:
            return 1;
        default:
            return 1;
    }
};
export function* getStudentLandingPageContent(): Generator<SelectEffect | CallEffect | PutEffect> {
    try {
        const region = yield select(regionSelector);

        const cmsContent = yield call(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            routes.getStudentLandingContent,
            getRegionEntity(region as Region),
            getRegionNumber(region as Region)
        );

        return yield put({ type: GET_STUDENT_LANDING_CONTENT_SUCCESS, payload: cmsContent });
    } catch (error) {
        return yield put({ type: GET_STUDENT_LANDING_CONTENT_FAILED, error });
    }
}

export default [takeLatest(GET_STUDENT_LANDING_CONTENT, getStudentLandingPageContent)];
