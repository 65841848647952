import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react";
import { Slider, SliderTrack, SliderFilledTrack, SliderThumb, keyframes } from "@chakra-ui/react";

import { PodmeColor } from "../../types/index";
import { useMedia } from "../../../app/hooks";
import { playerTimeFormat, playerTimeToSeconds } from "../../utils/index";
import useDeviceOnline from "../../../app/hooks/useDeviceOnline";

interface Props {
    value: number;
    totalTime?: string;
    audioTrackChanging: boolean;
    handleOnChange: (val: any) => void;
    setCurrentTimeStamp?: Dispatch<SetStateAction<string>>;
    setAudioTrackChanging?: Dispatch<SetStateAction<boolean>>;
    thumbColor?: PodmeColor;
    filledColor?: PodmeColor;
    trackColor?: PodmeColor;
    hideThumb?: boolean;
    [x: string]: any;
}

const fadeIn = keyframes`
    from {
        transform: scale(0.45)
    }
    to {
        transform: scale(1)
    }
`;

const fadeOut = keyframes`
    from {
        transform: scale(1)
    }
    to {
        transform: scale(0.45)
    }
`;

function AudioSlider({
    value,
    totalTime,
    audioTrackChanging,
    setCurrentTimeStamp,
    handleOnChange,
    setAudioTrackChanging,
    thumbColor = PodmeColor.White,
    filledColor = PodmeColor.Cyan,
    trackColor = PodmeColor.LicoriceBlue,
    hideThumb = false,
    ...rest
}: Props): ReactElement | null {
    const [animation, setAnimation] = useState<string>("");
    const [playPosition, updatePlayPosition] = useState<number>(value);
    const [sliderHeight, setSliderHeight] = useState<string>("4px");
    const [sliderHover, setSliderHover] = useState<boolean>(false);
    const { isSmallScreen } = useMedia();
    const isOnline = useDeviceOnline();

    useEffect(() => {
        if (isSmallScreen) {
            setAnimation(() => `${fadeIn} 50ms ease forwards`);
        } else setAnimation(() => `${sliderHover ? fadeIn : fadeOut} 50ms ease forwards`);
    }, [sliderHover]);

    useEffect(() => {
        if (!audioTrackChanging) updatePlayPosition(value);
    }, [value]);

    const handleChange = (e: number) => {
        if (!isOnline) return;

        updatePlayPosition(e);
        setSliderHeight("7px");
        setSliderHover(true);
        setAudioTrackChanging && setAudioTrackChanging(true);

        if (totalTime) {
            const totalTimeInSeconds = playerTimeToSeconds(totalTime);
            const formattedTime = playerTimeFormat(String(totalTimeInSeconds * (e / 100)));

            setCurrentTimeStamp && setCurrentTimeStamp(formattedTime);
        }
    };

    const handleChangeEnd = (e: number) => {
        if (!isOnline) return;

        handleOnChange(e);
        setSliderHeight("4px");
        setAudioTrackChanging && setAudioTrackChanging(false);
    };

    return (
        <Slider
            aria-label="player-track"
            value={playPosition ?? 0}
            onChange={handleChange}
            onChangeEnd={handleChangeEnd}
            onTouchEnd={() => {
                setSliderHeight("4px");
            }}
            onMouseEnter={() => setSliderHover(true)}
            onMouseLeave={() => setSliderHover(false)}
            step={0.01}
            focusThumbOnChange={false}
            padding="7px 0 !important"
            sx={{
                "*": {
                    transition: "minHeight 100ms ease-in",
                    minHeight: sliderHeight,
                },
                "&:hover *": {
                    minHeight: isSmallScreen ? "4px" : "7px",
                },
            }}
        >
            <SliderTrack
                _before={{
                    content: "''",
                    display: "block",
                    position: "absolute",
                    bg: trackColor,
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "100%",
                    height: "inherit",
                    rounded: "5px",
                }}
                bg="transparent"
                padding="1rem 0"
                {...rest}
            >
                <SliderFilledTrack rounded="5px" bg={filledColor} />
            </SliderTrack>
            {!hideThumb && (
                <SliderThumb
                    top="0"
                    left={`calc(${playPosition > 100 ? 100 : playPosition}% - 7px) !important`}
                    animation={animation}
                    bg={thumbColor}
                    _focus={{ boxShadow: "none" }}
                />
            )}
        </Slider>
    );
}

export default AudioSlider;
