import { Popover, Button, PopoverContent, PopoverArrow, PopoverBody, Flex, Text, Input, FormControl, Box } from "@chakra-ui/react";
import { PopoverTrigger as OrigPopoverTrigger } from "@chakra-ui/react";
import { PrimaryButtonNew } from "@components/buttons";
import { PodmeColor } from "@typings/index";
import strings from "app/locale/localization";
import { useRef, useState } from "react";
import { useHistory } from "react-router";

const PopoverTrigger: React.FC<{ children: React.ReactNode }> = OrigPopoverTrigger;

interface IThreeDotsProps {
    totalPages: number;
    baseUrl: string;
    onPageChange: (page: number) => void;
}

function ThreeDotsButton({ totalPages, baseUrl = "", onPageChange }: IThreeDotsProps) {
    const history = useHistory();
    const inputRef = useRef<HTMLInputElement>(null);

    const { go, goToPage } = strings.pagination;

    const [inputValue, setInputValue] = useState<string>("");

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (inputValue === "" && Number(e.target.value) === 0) return;
        if (!isNaN(Number(e.target.value)) && Number(e.target.value) <= totalPages) setInputValue(e.target.value);
    };

    const handleRedirect = () => {
        if (inputValue !== "") {
            setInputValue("");

            history.push({
                pathname: baseUrl,
                search: Number(inputValue) === 1 ? undefined : `?page=${inputValue}`,
                state: {
                    scrollToTop: false,
                },
            });
        }
    };

    return (
        <Popover placement="bottom-start" initialFocusRef={inputRef}>
            {({ onClose }) => (
                <Box
                    // we need to ovveride styles for the arrow class
                    sx={{
                        ".chakra-popover__arrow": {
                            boxShadow: "none !important",
                            bgColor: PodmeColor.SoftWhite + " !important",
                        },
                    }}
                >
                    <PopoverTrigger>
                        <Button
                            minWidth="2rem"
                            width="2rem"
                            height="2rem"
                            display="flex"
                            justifyContent="center"
                            backgroundColor={PodmeColor.Cinder}
                            border={`1px solid ${PodmeColor.Silver}20`}
                            rounded="0.5rem"
                            padding="0"
                            _hover={{ filter: "brightness(1.5)" }}
                        >
                            <Text as="span">. . .</Text>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent bgColor={PodmeColor.SoftWhite} maxWidth="12rem" width="100%">
                        <PopoverArrow />
                        <PopoverBody padding="0" maxWidth="min-content">
                            <Flex direction="column" gridGap="0.5rem" bgColor={PodmeColor.SoftWhite} padding="0.75rem" rounded="1rem">
                                <Flex>
                                    <Text as="span" color={PodmeColor.BlackPearl}>
                                        {goToPage}
                                    </Text>
                                </Flex>
                                <FormControl
                                    as="form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleRedirect();
                                        onClose();

                                        setTimeout(() => {
                                            onPageChange(Number(inputValue) - 1);
                                        }, 500);
                                    }}
                                >
                                    <Flex gridGap="0.5rem">
                                        <Input
                                            type="text"
                                            inputMode="numeric"
                                            autoFocus
                                            ref={inputRef}
                                            value={inputValue}
                                            onChange={handleInputChange}
                                            minWidth="5.5rem"
                                            color={PodmeColor.BlackPearl}
                                            textAlign="right"
                                            border={`2px solid ${PodmeColor.Silver}60 !important`}
                                            _focus={{ border: `2px solid ${PodmeColor.Silver} !important` }}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter" && inputValue !== "") {
                                                    event.preventDefault();
                                                    handleRedirect();
                                                    onClose();

                                                    setTimeout(() => {
                                                        onPageChange(Number(inputValue) - 1);
                                                    }, 500);
                                                }
                                            }}
                                        />
                                        <PrimaryButtonNew
                                            type="submit"
                                            minHeight="100%"
                                            fontWeight="500"
                                            padding="0.5rem 1rem"
                                            borderRadius="0.5rem"
                                        >
                                            {go}
                                        </PrimaryButtonNew>
                                    </Flex>
                                </FormControl>
                            </Flex>
                        </PopoverBody>
                    </PopoverContent>
                </Box>
            )}
        </Popover>
    );
}

export default ThreeDotsButton;
