import { ComponentType, ReactElement, useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router";
import useAuth from "../auth/useAuth";
import strings from "@locale/localization";
import FullpageSpinner from "./FullpageSpinner/FullpageSpinner";
import usePrevPath from "app/hooks/usePrevPath";

interface Props {
    isAuthenticated: boolean;
    authChecked: boolean;
    component: ComponentType<any>;
    isCheckout?: boolean;
}

export const PrivateRoute = ({ component: Component, isCheckout, ...rest }: Props & any): ReactElement => {
    const { locale, login, signup } = strings.routes;
    const { isAuthenticated, authChecked } = useAuth();
    const location = useLocation<{ prevPath: string }>();
    const { prevPath, setPrevPath, removePrevPath } = usePrevPath();

    useEffect(() => {
        if (isAuthenticated && `${location.pathname}${location.search}` === prevPath) {
            removePrevPath();
        }
    }, [isAuthenticated, location, prevPath]);

    if (authChecked && !isAuthenticated) {
        setPrevPath(`${location.pathname}${location.search}`);
    } else if (authChecked && isAuthenticated && prevPath) {
        const redirectPath = prevPath;
        removePrevPath();
        return <Redirect to={redirectPath} />;
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                authChecked ? (
                    isAuthenticated ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: isCheckout ? `/${locale}/${signup}` : `/${locale}/${login}`,
                            }}
                        />
                    )
                ) : (
                    <FullpageSpinner isShown={true} />
                )
            }
        />
    );
};

export default PrivateRoute;
