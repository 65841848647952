import { selectItemEventAction } from "app/tracking/trackingActions";
import { RootState } from "app/reducers/rootReducer";
import { Flex, Box, Image, Text, Heading } from "@chakra-ui/react";
import { TertiaryButton } from "@components/buttons";
import { PodmeColor } from "@typings/index";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { IPremiumPromo } from "../redux/types";
import { Pages } from "@typings/index";
import { useMedia } from "app/hooks";
import CTACheckoutButton from "@components/CTACheckoutButton";

interface Props {
    content: IPremiumPromo;
}

const globalState = (state: RootState) => state.global;

export default function PremiumPromo({ content }: Props): ReactElement {
    const dispatch = useDispatch();
    const { currency, packageId, price } = useSelector(globalState);
    const { isSmallScreen, isMediumScreen } = useMedia();

    return (
        <>
            <Flex
                backgroundColor={PodmeColor.White}
                padding={["1.5rem 1rem", "1.5rem 2rem"]}
                position="relative"
                justifyContent={["flex-start", "center"]}
                margin={["0 1rem 2rem", "0"]}
                rounded={["1.5rem", "0"]}
            >
                <Flex direction={["column", "row"]} justifyContent="center" width="100%" maxWidth="45rem" gridGap="1.5rem">
                    <Box maxWidth={["7rem", "30%"]} flex="6">
                        <Image height={["auto", "100%"]} src={"/assets/images/brand/diamond_logo.svg"} alt="Podme Logo" />
                    </Box>
                    <Flex flexDirection="column" gridGap={4} justifyContent="center" maxWidth={["100%", "70%"]} flex="6">
                        <Heading fontSize="1.875em" p="0 !important" color={PodmeColor.BlackPearl}>
                            Podme{" "}
                            <Text as="span" color={PodmeColor.Cyan} fontWeight="700">
                                Premium
                            </Text>
                        </Heading>
                        <Flex flexDirection={["column"]} alignItems="flex-start" gridGap={[4, 6]}>
                            <Text color={PodmeColor.BlackPearl}>
                                {content.title}{" "}
                                <Text as="span" fontWeight="600">
                                    {content.titleBoldSection}
                                </Text>
                            </Text>
                        </Flex>
                        <Box width={["100%", "fit-content"]}>
                            <CTACheckoutButton
                                renderAs={TertiaryButton}
                                onClickCallback={() =>
                                    dispatch(
                                        selectItemEventAction(
                                            `${isSmallScreen ? "Mobile" : isMediumScreen ? "Tablet" : "Desktop"} / ${
                                                Pages.HomeScreen
                                            } / Premium Promo Banner / Primary CTA button`,
                                            String(packageId),
                                            currency,
                                            price
                                        )
                                    )
                                }
                                fontSize="min(4.5vw, 1rem)"
                            >
                                {content.buttonText}
                            </CTACheckoutButton>
                        </Box>
                        <Box fontSize={["0.75rem"]} color={PodmeColor.BlackPearl + "75"}>
                            <Text>{content.disclaimer}</Text>
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
}
