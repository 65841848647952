import { FC, memo, useEffect } from "react";
import {
    ModalHeader,
    ModalBody,
    Text,
    Modal,
    ModalContent,
    ModalOverlay,
    useDisclosure,
    Link,
    ModalCloseButton,
    Flex,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { ACCEPT_MIGRATION_MODAL_PROMPT, DISMISS_MIGRATION_ERROR_BOX } from "@containers/Migration/redux/types";
import { IErrorContent } from "../MigrationModal";
import useAuth from "@auth/useAuth";
import { RootState } from "app/reducers/rootReducer";
import { PodmeColor } from "@typings/index";
import { PrimaryButtonNew, SecondaryButton } from "@components/buttons";

interface IProps {
    errorContent: IErrorContent;
}

const migrationSelector = (state: RootState) => state.migration;

const ErrorBox: FC<IProps> = ({ errorContent }) => {
    const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
    const dispatch = useDispatch();
    const { logoutUser } = useAuth();
    const { acceptSuccess, isLoading, isVisible, userAcceptedMigration } = useSelector(migrationSelector);

    const handleErrorDismiss = () => {
        onClose();
        dispatch({ type: DISMISS_MIGRATION_ERROR_BOX });
    };

    const retryMigration = () => {
        dispatch({ type: ACCEPT_MIGRATION_MODAL_PROMPT });
    };

    useEffect(() => {
        if (acceptSuccess) onClose();
    }, [acceptSuccess]);

    const handleLogout = () => {
        localStorage.removeItem("SCHIBSTED_SESSION");
        logoutUser();
    };

    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} motionPreset={"slideInBottom"} isCentered>
            <ModalOverlay />
            <ModalContent
                color={PodmeColor.SoftWhite}
                backgroundColor={PodmeColor.Cinder}
                display={"flex"}
                gridTemplateColumns={["1fr", "1fr", "6fr 4fr"]}
                maxW={"30rem"}
                borderRadius={"1.5rem"}
                mx={["0", "0.5rem"]}
            >
                <Flex direction="column" gridGap="1.5rem" padding="1.5rem">
                    <ModalHeader
                        color={PodmeColor.SoftWhite}
                        display={"flex"}
                        alignItems="center"
                        gridGap={"1rem"}
                        padding="0"
                        lineHeight={"calc(1rem + 0.75em)"}
                        maxWidth="90%"
                    >
                        <Text fontSize={"clamp(16px, calc(2vw + 0.8rem), 20px)"} fontWeight={"bold"}>
                            {errorContent.title}
                        </Text>
                    </ModalHeader>
                    <ModalBody padding="0">
                        <Text color={PodmeColor.Silver} fontSize={"clamp(14px, calc(2vw + 0.5rem), 16px)"}>
                            {errorContent.message}
                        </Text>
                        {errorContent.email && (
                            <Text mt={"0.5rem"} fontSize={"clamp(14px, calc(2vw + 0.5rem), 16px)"}>
                                <Link
                                    color={PodmeColor.SoftWhite}
                                    fontWeight={"500"}
                                    borderBottom={`1px solid ${PodmeColor.SoftWhite}`}
                                    transition={"all 70ms ease-in"}
                                    _hover={{
                                        color: PodmeColor.Silver,
                                        borderBottom: `1px solid ${PodmeColor.Silver}`,
                                    }}
                                    href={`mailto:${errorContent.email}`}
                                >
                                    {errorContent.email}
                                </Link>
                            </Text>
                        )}
                    </ModalBody>

                    <Flex
                        gridGap={3}
                        justifyContent="flex-end"
                        sx={{
                            "> button": {
                                height: "auto",
                                borderRadius: "1rem",
                                padding: "1rem 2rem",
                                fontWeight: "400",
                            },
                            "> button:hover": {
                                opacity: "70%",
                            },
                        }}
                    >
                        {errorContent.isErrorGeneric ? (
                            <>
                                <SecondaryButton onClick={handleLogout}>{errorContent.primaryBtn}</SecondaryButton>
                                <PrimaryButtonNew isLoading={isLoading && isVisible && userAcceptedMigration} onClick={retryMigration}>
                                    {errorContent.secondaryBtn}
                                </PrimaryButtonNew>
                            </>
                        ) : errorContent.alreadyMigrated ? (
                            <>
                                <PrimaryButtonNew onClick={logoutUser}>{errorContent.primaryBtn}</PrimaryButtonNew>
                            </>
                        ) : (
                            <>
                                <ModalCloseButton
                                    _focus={{
                                        boxShadow: "none",
                                        outline: "none",
                                    }}
                                    onClick={handleErrorDismiss}
                                />
                                <SecondaryButton width={["100%", "50%"]} onClick={handleErrorDismiss}>
                                    {errorContent.primaryBtn}
                                </SecondaryButton>
                            </>
                        )}
                    </Flex>
                </Flex>
            </ModalContent>
        </Modal>
    );
};

export default memo(ErrorBox);
