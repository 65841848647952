import { Box, Text } from "@chakra-ui/react";
import { PodmeColor } from "@typings/index";
import { Link } from "react-router-dom";

interface IProps {
    page: number;
    currentPage: number;
    urlPath: string;
    onPageChange: (page: number) => void;
}

function PageNumberButton({ page, currentPage, urlPath, onPageChange }: IProps) {
    return (
        <Link
            to={{
                pathname: urlPath,
                search: page === 0 ? undefined : `?page=${page + 1}`,
                state: {
                    scrollToTop: false,
                },
            }}
            style={{
                pointerEvents: page === currentPage ? "none" : "auto",
            }}
        >
            <Box
                as="button"
                role="button"
                display="flex"
                alignItems="center"
                justifyContent="center"
                key={page}
                height="2rem"
                width="2rem"
                minWidth="unset"
                backgroundColor={page === currentPage ? PodmeColor.SoftWhite : PodmeColor.Cinder}
                color={page === currentPage ? PodmeColor.BlackPearl : PodmeColor.SoftWhite}
                border={`1px solid ${PodmeColor.Silver}20`}
                rounded="0.5rem"
                lineHeight="1.5"
                _hover={{
                    filter: "brightness(1.2)",
                }}
                onClick={() => onPageChange(page)}
                cursor={page === currentPage ? "default" : "pointer"}
            >
                <Text as="span">{page + 1}</Text>
            </Box>
        </Link>
    );
}

export default PageNumberButton;
