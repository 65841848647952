import { Flex, Image } from "@chakra-ui/react";
import { PodmeColor } from "@typings/index";
import React, { FC } from "react";

interface IProps {
    visible: boolean;
    direction: "left" | "right";
    carouselElWidth: number;
    handleCarouselMove: (direction: "left" | "right") => void;
}

const CarouselButton: FC<IProps> = ({ visible, direction, carouselElWidth, handleCarouselMove }) => {
    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            position="absolute"
            left={direction === "left" ? "0.5rem" : "unset"}
            right={direction === "right" ? "0.5rem" : "unset"}
            height="3rem"
            width="3rem"
            rounded="50%"
            top={`calc(16px + ${carouselElWidth}px / 2)`}
            transform="translateY(-50%)"
            bgColor={PodmeColor.LicoriceBlue}
            border={`1px solid ${PodmeColor.Silver}20`}
            zIndex="999"
            cursor="pointer"
            opacity={visible ? "1" : "0"}
            pointerEvents={visible ? "auto" : "none"}
            transition="opacity 100ms ease-in"
            _hover={{
                filter: "brightness(1.25)",
            }}
            onClick={() => handleCarouselMove(direction)}
        >
            <Image
                src={`/assets/images/icons/${direction === "left" ? "chevron-left-16x16.svg" : "chevron-right.svg"}`}
                width="1.5rem"
                alt={`${direction === "left" ? "move left" : "move right"} arrow button`}
            />
        </Flex>
    );
};

export default CarouselButton;
