import { Box, Accordion, AccordionItem, AccordionButton, Heading, AccordionPanel, Image } from "@chakra-ui/react";
import { DeviceBreakpoints, PodmeColor, CSSstylesObject } from "../../types/index";
import { ReactElement } from "react";
import { useMedia } from "../../../app/hooks";
import { Document as ContentfulDocument } from "@contentful/rich-text-types";
import CmsDocument from "@components/Cms/CmsDocument";
import { FaqEntity } from "app/api/types";

interface Props {
    faqs: {
        question: string;
        answer: ContentfulDocument | string;
    }[];
    asDocumentNode?: boolean;
    accordionItemClickHandler?: (faq: FaqEntity, index: number, wasFaqExpanded: boolean) => void;
}

const styles: CSSstylesObject = {
    accordionButton: {
        padding: "2rem",
        sx: {
            [`@media ${DeviceBreakpoints.MediumDown}`]: {
                padding: "1rem",
            },
        },
    },
};

function FaqQuestions({ faqs, asDocumentNode, accordionItemClickHandler = () => {} }: Props): ReactElement | null {
    const { isLargeScreen } = useMedia();

    if (!faqs) return null;
    return (
        <Box>
            <Accordion color={PodmeColor.White} display={"flex"} flexDirection={"column"} gridGap={"1rem"} allowToggle>
                {faqs.map((faq, index: number) => {
                    return (
                        <AccordionItem
                            key={index}
                            backgroundColor={PodmeColor.Cinder}
                            borderRadius={"1rem"}
                            border={`1px solid hsl(0, 0%, 75%, 0.1)`}
                            data-testid="faq-question-item"
                        >
                            {({ isExpanded }) => (
                                <>
                                    <Box>
                                        <AccordionButton
                                            onClick={() => accordionItemClickHandler(faq as FaqEntity, index, isExpanded)}
                                            {...styles.accordionButton}
                                            _focus={{
                                                boxShadow: "none !important",
                                            }}
                                            _hover={{
                                                backgroundColor: "unset",
                                            }}
                                        >
                                            <Heading
                                                color={PodmeColor.White}
                                                flex="1"
                                                fontSize={isLargeScreen ? "20px" : "16px"}
                                                fontWeight={"700"}
                                                textAlign={"left"}
                                                marginRight={"1.5rem"}
                                            >
                                                {faq.question}
                                            </Heading>
                                            {!isExpanded && (
                                                <Image
                                                    src="/assets/images/icons/plus.png"
                                                    alt="plus"
                                                    width={isLargeScreen ? "1.5rem" : "1rem"}
                                                />
                                            )}
                                            {isExpanded && (
                                                <Image
                                                    src="/assets/images/icons/minus.png"
                                                    alt="minus"
                                                    width={isLargeScreen ? "1.5rem" : "1rem"}
                                                />
                                            )}
                                        </AccordionButton>
                                    </Box>
                                    <AccordionPanel
                                        fontSize={"1rem"}
                                        fontWeight={"400"}
                                        color={PodmeColor.Silver}
                                        dangerouslySetInnerHTML={asDocumentNode ? undefined : { __html: faq.answer as string }}
                                        paddingLeft={isLargeScreen ? "2rem" : "1rem"}
                                        paddingRight={isLargeScreen ? "5rem" : "3rem"}
                                        sx={{
                                            a: {
                                                color: PodmeColor.White,
                                                fontWeight: "600",
                                                borderBottom: `1px solid ${PodmeColor.White}`,
                                                transition: "all 100ms ease-in",
                                                wordBreak: "break-word",
                                            },
                                            "a:hover": {
                                                color: PodmeColor.Silver,
                                                borderBottom: `2px solid ${PodmeColor.Silver}`,
                                            },
                                            "ul, ol": {
                                                paddingLeft: "2rem",
                                            },
                                        }}
                                    >
                                        {asDocumentNode && (
                                            <CmsDocument
                                                document={faq.answer as ContentfulDocument}
                                                linkProps={{
                                                    _hover: {
                                                        textDecoration: "none",
                                                    },
                                                }}
                                            />
                                        )}
                                    </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>
                    );
                })}
            </Accordion>
        </Box>
    );
}

export default FaqQuestions;
