import { Flex, Box, Text } from "@chakra-ui/react";
import { PodmeColor } from "@typings/index";
import strings from "app/locale/localization";
import { SecondaryButton } from "./buttons";

export interface OfferCardProps {
    title: string;
    price: string;
    priceUnitWithMonth: string;
    ctaButtonText: string;
    description: string;
    disclaimer?: string;
    index: number;
    isActivePlan?: boolean;
    isDarkMode?: boolean;
    isDisabled?: boolean;
    isStandardPlan?: boolean;
    discountLabel?: string;
    handleClick: () => void;
}

function OfferCard({
    title,
    price,
    priceUnitWithMonth,
    ctaButtonText,
    description,
    disclaimer,
    index,
    isActivePlan = false,
    isDarkMode = false,
    isDisabled = false,
    isStandardPlan = true,
    discountLabel,
    handleClick,
}: OfferCardProps) {
    return (
        <Flex
            flexDirection="column"
            justifyContent="space-between"
            backgroundColor={isDarkMode ? PodmeColor.BlackPearl : PodmeColor.SoftWhite}
            color={isDarkMode ? PodmeColor.SoftWhite : "#000"}
            padding="2rem"
            borderRadius="24px"
            gap="2rem"
            height="100%"
            maxWidth="440px"
            minWidth={[null, "400px"]}
            border={isActivePlan ? `1px solid ${PodmeColor.Cyan}` : "unset"}
            position="relative"
            boxShadow={
                isDarkMode
                    ? `-1px -1px 2px -1px #00000020 inset, 2px 2px 2px -2px rgba(255, 255, 255, 0.10) inset, 3px 3px 20px 0px rgba(0, 0, 0, 0.70), -2px -2px 15px 4px #161B21`
                    : `-1px -1px 2px -1px #00000020 inset, 2px 2px 2px -2px #FFF inset, 3px 3px 15px 0px rgba(191, 191, 191, 0.50), -2px -2px 15px 4px #FFF`
            }
        >
            {isActivePlan && <ActivePlanBadge positionIndex={index} />}
            <Flex as="h2" fontSize="1.25rem" fontWeight={400} alignItems="center" gap="0.75rem">
                <img src="/assets/images/brand/diamond_logo.svg" alt="diamond logo" width={24} height={24} />
                {title}
            </Flex>
            <Flex flexDirection="column">
                <Flex alignItems="center" gap="0.75rem">
                    <Box
                        data-testid="offer-price"
                        as="data"
                        fontWeight={500}
                        fontSize="2.5rem"
                        fontStyle="normal"
                        lineHeight="140%"
                        letterSpacing="0.4px"
                    >
                        {price}
                    </Box>
                    <Box color={isDarkMode ? `#FFFFFF60` : `#00000060`}>{priceUnitWithMonth}</Box>
                </Flex>
                {discountLabel && (
                    <Box marginTop="4px" marginBottom="1rem">
                        <Text padding="4px 12px" backgroundColor={PodmeColor.Cyan} fontSize="0.75em" display="inline-block">
                            {discountLabel}
                        </Text>
                    </Box>
                )}
                <Box as="p" fontSize="14px" fontWeight={400} lineHeight={"22px"}>
                    {description}
                </Box>
            </Flex>
            <Box>
                {isStandardPlan ? (
                    <SecondaryButton
                        onClick={handleClick}
                        color={isDarkMode ? ("#000" as PodmeColor) : PodmeColor.SoftWhite}
                        backgroundColor={isDarkMode ? PodmeColor.SoftWhite : PodmeColor.BlackPearl}
                        isDisabled={isDisabled}
                        data-testid="offer-cta-button"
                    >
                        {ctaButtonText}
                    </SecondaryButton>
                ) : (
                    <SecondaryButton
                        onClick={handleClick}
                        color={isDarkMode ? PodmeColor.SoftWhite : ("#000" as PodmeColor)}
                        backgroundColor="transparent"
                        border={`1px solid ${isDarkMode ? PodmeColor.SoftWhite : ("#000" as PodmeColor)}`}
                        isDisabled={isDisabled}
                        data-testid="offer-cta-button"
                    >
                        {ctaButtonText}
                    </SecondaryButton>
                )}
                {disclaimer && (
                    <Text fontSize="0.75em" color="rgba(0, 0, 0, 0.70)" marginTop="1rem">
                        {disclaimer}
                    </Text>
                )}
            </Box>
        </Flex>
    );
}

function ActivePlanBadge({ positionIndex }: { positionIndex: number }) {
    const string = strings.subscriptionsPage;

    return (
        <Box
            position="absolute"
            left={["unset", null, positionIndex === 0 ? "-12px" : "unset"]}
            right={["unset", null, positionIndex === 0 ? "unset" : "-12px"]}
            top={["-12px", null, "50%"]}
            transform={["translate(50%, -50%)", null, `translate(${positionIndex === 0 ? "-50%" : "50%"}, -50%) rotate(-90deg)`]}
            transformOrigin={["top", null, "initial"]}
            padding={["0.5rem 2rem"]}
            backgroundColor={PodmeColor.Cyan}
            color="#000"
            borderRadius={8}
            fontSize="12px"
            fontWeight={500}
            lineHeight="22px"
        >
            {string.yourPlan}
        </Box>
    );
}

export default OfferCard;
