import { List, ListItem } from "@chakra-ui/react";
import { STUDENT_BASE_PATH } from "@containers/Student/constants";
import { PodmeColor } from "@typings/index";
import strings from "app/locale/localization";
import { NavLink } from "react-router-dom";

interface Props {
    linkList?: { title: string; slug: string }[];
    isSheerIdEnabled?: boolean;
}

function PodmePagesLinkList({ linkList, isSheerIdEnabled }: Props) {
    const { locale } = strings.routes;

    if (!linkList) return null;

    return (
        <List spacing={3} color={PodmeColor.Silver}>
            {linkList.map((link) => {
                const isStudentLink = STUDENT_BASE_PATH(locale).includes(link.slug);

                if (isStudentLink && !isSheerIdEnabled) return null;

                return (
                    <ListItem key={link.slug} padding={"0.2rem 0"}>
                        <NavLink to={`/${locale}/${link.slug}`}>{link.title}</NavLink>
                    </ListItem>
                );
            })}
        </List>
    );
}

export default PodmePagesLinkList;
