import { extendTheme } from "@chakra-ui/react";
import { withProse } from "@nikolovlazar/chakra-ui-prose";

import { PodmeColor } from "@typings/index";

// export const XL_SCREEN = "only screen and (min-width: 2000px)";
// export const LARGE_SCREEN = "only screen and (min-width: 1025px)";
// export const SMALL_SCREEN = "only screen and (max-width: 479px)";
// export const MEDIUM_SCREEN = "only screen and (min-width: 480px) and (max-width: 1024px)";
// export const MEDIUM_DOWN = "only screen and (max-width: 1024px)";

const breakpoints = {
    sm: "480px",
    md: "1024px",
    lg: "1280px",
    xl: "1921px",
    "2xl": "3000px",
};

const baseHeadingStyles = {
    fontWeight: "700",
    letterSpacing: "0.03em",
    lineHeight: 1.5,
};

export default extendTheme(
    {
        breakpoints,
        fonts: {
            body: "Garnett, Arial, Helvetica, sans-serif",
            heading: "Garnett, Arial, Helvetica, sans-serif",
            mono: "Menlo, monospace",
        },
        colors: {
            red: {
                500: PodmeColor.Red,
            },
            green: {
                500: PodmeColor.Cyan,
            },
        },
        components: {
            Heading: {
                baseStyle: {
                    fontWeight: "700",
                    letterSpacing: "0.02em",
                    lineHeight: 1.3,
                },
            },
            Text: {
                baseStyle: {
                    fontWeight: "400",
                },
            },
            Button: {
                baseStyle: {
                    _focus: {
                        boxShadow: "none",
                        outline: "none",
                    },
                },
            },
            ModalCloseButton: {
                baseStyle: {
                    _focus: {
                        boxShadow: "none",
                        outline: "none",
                    },
                },
            },
            Link: {
                baseStyle: {
                    _focus: {
                        boxShadow: "none",
                        outline: "none",
                    },
                },
            },
            Radio: {
                baseStyle: {
                    control: {
                        width: "16px",
                        height: "16px",
                        _checked: {
                            backgroundColor: PodmeColor.Cinder,
                            color: PodmeColor.Cinder,
                            borderColor: PodmeColor.Cyan,
                            border: `5px solid ${PodmeColor.Cyan}`,
                        },
                        _active: {
                            backgroundColor: PodmeColor.Cinder,
                            color: PodmeColor.Cinder,
                            border: `5px solid ${PodmeColor.Cyan}`,
                        },
                        _hover: {
                            backgroundColor: "auto",
                            borderColor: "auto",
                            boxShadow: "0 0 0 4px #202529",
                        },
                        _focus: {
                            boxShadow: "0 0 0 4px #29303D",
                        },
                        _disabled: {
                            color: "#707173",
                        },
                    },
                },
            },
        },
    },
    withProse({
        baseStyle: {
            h1: {
                ...baseHeadingStyles,
                fontSize: "2.25rem",
                marginBottom: { base: "1rem", md: "1.5rem" },
            },
            h2: {
                ...baseHeadingStyles,
                fontSize: "2.25rem",
            },
            h3: {
                ...baseHeadingStyles,
                fontSize: "2rem",
            },
            h4: {
                ...baseHeadingStyles,
                fontSize: "1.75rem",
            },
            h5: {
                ...baseHeadingStyles,
                fontSize: "1.5rem",
                fontWeight: 500,
            },
            h6: {
                ...baseHeadingStyles,
                fontFamily: "Inter, sans-serif",
                fontSize: "1.125rem",
                fontWeight: 500,
            },
            p: {
                fontFamily: "Inter, sans-serif",
            },
        },
    })
);
