import { ChangeEvent, ReactElement, useState } from "react";
import { CSSstylesObject, PodmeColor } from "../../types/index";
import { FormControl, Image, Input } from "@chakra-ui/react";

interface Props {
    search: (evt: ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
    value?: string;
}
const styles: CSSstylesObject = {
    searchFieldWrapper: {
        width: "100%",
        height: "3.75rem",
        borderRadius: "1rem",
        border: "2px solid " + PodmeColor.Silver,
        background: PodmeColor.White,
        paddingLeft: ["3.25rem", "3rem"],
        paddingRight: "1rem",
        display: "flex",
        alignItems: "center",
        position: "relative",
        marginBottom: ["2rem", "3rem"],
        color: PodmeColor.BlackPearl,
    },
    searchField: {
        width: "100%",
        background: PodmeColor.White,
        borderRadius: "0",
        height: "3.75rem",
        fontSize: "1rem",
        border: "none",
        borderTop: "2px solid " + PodmeColor.Silver,
        borderBottom: "2px solid " + PodmeColor.Silver,
        paddingInlineStart: "0",
        sx: {
            "::placeholder": {
                color: PodmeColor.Silver,
                fontWeight: 400,
                fontSize: "1rem",
            },
        },
    },
};

function SearchField({ search, placeholder, value = "" }: Props): ReactElement {
    const [searchedString, setSearchedString] = useState<string>(value);

    return (
        <FormControl {...styles.searchFieldWrapper} tabIndex={0} onSubmit={(evt) => evt.preventDefault()} autoComplete="off">
            <Image
                position="absolute"
                top="50%"
                transform="translateY(-50%)"
                left="1rem"
                fontSize="18px"
                src="/assets/images/icons/search-default.svg"
                alt="search icon"
            />
            <Input
                {...styles.searchField}
                _focus={{ outline: "none", fontSize: "1rem" }}
                type="search"
                id="search-field"
                name="search"
                placeholder={placeholder}
                value={searchedString}
                onChange={(evt) => {
                    search(evt);
                    setSearchedString(evt.target.value);
                }}
                onSubmit={(evt: ChangeEvent<HTMLInputElement>) => {
                    evt.preventDefault();
                    search(evt);
                }}
                autoFocus
            />
        </FormControl>
    );
}

export default SearchField;
