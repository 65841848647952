import { GET_LOGIN_WIDGET } from "@auth/authTypes";
import useAuth from "@auth/useAuth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";

const DAY_IN_MS = 86400000;
export const LAST_SEEN_LOGIN_WIDGET_COOKIE = "lastSeenLoginWidget" as const;

function useLoginWidget() {
    const { isAuthenticated, authChecked } = useAuth();
    const dispatch = useDispatch();
    const cookie = new Cookies();

    useEffect(() => {
        const timeNow = Date.now();
        const lastSeenLoginWidgetCookie = cookie.get("lastSeenLoginWidget") || 0;

        if (authChecked && !isAuthenticated && timeNow - Number(lastSeenLoginWidgetCookie) >= DAY_IN_MS) {
            dispatch({ type: GET_LOGIN_WIDGET });
        }
    }, [authChecked, isAuthenticated]);
    return;
}

export default useLoginWidget;
