import { ErrorResponse, ObjectValues, ShowErrorToastAction } from "../types";

export const LOGIN_USER = "auth/LOGIN_USER";
export const LOGIN_USER_SUCCESS = "auth/LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "auth/LOGIN_USER_FAILED";

export const LOGOUT_USER = "auth/LOGOUT_USER";

export const LOGOUT_USER_SUCCESS = "auth/LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILED = "auth/LOGOUT_USER_FAILED";

export const LOGOUT_USER_WITH_ERROR = "auth/LOGOUT_USER_WITH_ERROR";
export const LOGOUT_USER_WITH_ERROR_SUCCESS = "auth/LOGOUT_USER_WITH_ERROR_SUCCESS";
export const LOGOUT_USER_WITH_ERROR_FAILED = "auth/LOGOUT_USER_WITH_ERROR_FAILED";

export const LOGOUT_USER_WITH_CUSTOM_REDIRECT = "auth/LOGOUT_USER_WITH_CUSTOM_REDIRECT";
export const LOGOUT_USER_WITH_CUSTOM_REDIRECT_SUCCESS = "auth/LOGOUT_USER_WITH_CUSTOM_REDIRECT_SUCCESS";

export const GET_USER_DATA = "auth/GET_USER_DATA";
export const GET_USER_DATA_SUCCESS = "auth/GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILED = "auth/GET_USER_DATA_FAILED";

export const GET_LOGIN_WIDGET = "auth/GET_LOGIN_WIDGET";
export const GET_LOGIN_WIDGET_SUCCESS = "auth/GET_LOGIN_WIDGET_SUCCESS";
export const GET_LOGIN_WIDGET_FAILED = "auth/GET_LOGIN_WIDGET_FAILED";

export const CHECK_AUTH_STATUS = "auth/CHECK_AUTH_STATUS";
export const CHECK_AUTH_STATUS_SUCCESS = "auth/CHECK_AUTH_STATUS_SUCCESS";
export const CHECK_AUTH_STATUS_FAILED = "auth/CHECK_AUTH_STATUS_FAILED";

export const LOGIN_WITH_IDP = "auth/LOGIN_WITH_IDP";
export const LOGIN_WITH_IDP_SUCCESS = "auth/LOGIN_WITH_IDP_SUCCESS";
export const LOGIN_WITH_IDP_FAILED = "auth/LOGIN_WITH_IDP_FAILED";

export const REFRESH_TOKEN = "auth/REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "auth/REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILED = "auth/REFRESH_TOKEN_FAILED";

export const RESET_PASSWORD = "auth/RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "auth/RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "auth/RESET_PASSWORD_FAILED";

export const SHOW_PREMIUM_MODAL = "auth/SHOW_PREMIUM_MODAL";
export const DISMISS_PREMIUM_MODAL = "auth/DISMISS_PREMIUM_MODAL";

export const UNAUTHORIZED_REQUEST = "auth/UNAUTHORIZED_REQUEST";
export const FORBIDDEN_REQUEST = "auth/FORBIDDEN_REQUEST";

export const LOGIN_REDIRECT = "auth/LOGIN_REDIRECT";

export const UPDATE_USER_CONSENT = "auth/UPDATE_USER_CONSENT";

export const GET_USER_PRIVACY_SETTINGS = "auth/GET_USER_PRIVACY_SETTINGS";
export const GET_USER_PRIVACY_SETTINGS_SUCCESS = "auth/GET_USER_PRIVACY_SETTINGS_SUCCESS";
export const GET_USER_PRIVACY_SETTINGS_FAILED = "auth/GET_USER_PRIVACY_SETTINGS_FAILED";

export enum IdentityProvider {
    Facebook = "Facebook",
    Google = "Google",
    Apple = "Apple",
    Schibsted = "Schibsted",
    Email = "Email",
}

export const USER_SEGMENT_TYPE = {
    Student: 0,
} as const;

export type UserSegmentType = ObjectValues<typeof USER_SEGMENT_TYPE>;

export enum LoginSource {
    LoginPage,
    LoginModal,
}

export enum IdpRedirectOption {
    SignupPage,
    DiscoverPage,
    BundlerSignup,
    BundlerLogin,
}

export interface LoginUser {
    type: typeof LOGIN_USER;
    email: string;
    password: string;
    loginSource?: LoginSource;
}

export interface LoginUserSuccess {
    type: typeof LOGIN_USER_SUCCESS;
    access_token: string;
    refresh_token: string;
    session_duration: number;
    username: string;
    loginSource?: LoginSource;
    accountCreated?: boolean;
}

export interface LoginUserFailed extends ShowErrorToastAction {
    type: typeof LOGIN_USER_FAILED;
}

export interface LogoutUser {
    type: typeof LOGOUT_USER;
}

export interface LogoutUserWithCustomRedirect {
    type: typeof LOGOUT_USER_WITH_CUSTOM_REDIRECT;
    redirectUri?: string;
}

export interface LogoutUserWithCustomRedirectSuccess {
    type: typeof LOGOUT_USER_WITH_CUSTOM_REDIRECT_SUCCESS;
}

export interface LogoutUserSuccess {
    type: typeof LOGOUT_USER_SUCCESS;
}

export interface LogoutUserFailed {
    type: typeof LOGOUT_USER_FAILED;
    errorResponse: ErrorResponse | null;
}
export interface LogoutUserWithError {
    type: typeof LOGOUT_USER_WITH_ERROR;
    history: any;
}

export interface LogoutUserWithErrorSuccess {
    type: typeof LOGOUT_USER_WITH_ERROR_SUCCESS;
    username: string;
    provider: string;
}

export interface LogoutUserWithErrorFailed {
    type: typeof LOGOUT_USER_WITH_ERROR_FAILED;
    errorResponse: ErrorResponse | null;
}

export interface CheckAuthStatus {
    type: typeof CHECK_AUTH_STATUS;
}

export interface CheckAuthStatusSuccess {
    type: typeof CHECK_AUTH_STATUS_SUCCESS;
    isAuthenticated: boolean;
    provider: IdentityProvider;
    userId: string;
}

export interface CheckAuthStatusFailed {
    type: typeof CHECK_AUTH_STATUS_FAILED;
    error?: any;
}

export interface RefreshToken {
    type: typeof REFRESH_TOKEN;
    token?: string;
    context?: { url: string; headers: { [x: string]: any }; data: any };
}

export interface LoginWithIdp {
    type: typeof LOGIN_WITH_IDP;
    provider: IdentityProvider;
    redirectDestination: IdpRedirectOption;
    redirectUrl?: string;
}

export interface LoginWithIdpSuccess {
    type: typeof LOGIN_WITH_IDP_SUCCESS;
    provider: IdentityProvider;
    accountCreated?: boolean;
    username?: string;
}

export interface LoginWithIdpFailed extends ShowErrorToastAction {
    type: typeof LOGIN_WITH_IDP_FAILED;
    errorResponse?: ErrorResponse;
}

export interface GetLoginWidgetSuccess {
    type: typeof GET_LOGIN_WIDGET_SUCCESS;
}
export interface GetLoginWidgetFailed {
    type: typeof GET_LOGIN_WIDGET_FAILED;
    error?: any;
}

export interface ResetPassword {
    type: typeof RESET_PASSWORD;
}

export interface ResetPasswordSuccess {
    type: typeof RESET_PASSWORD_SUCCESS;
}

export interface ResetPasswordFailed {
    type: typeof RESET_PASSWORD_FAILED;
    error: any;
}

export interface ShowPremiumModal {
    type: typeof SHOW_PREMIUM_MODAL;
}

export interface LoginRedirect {
    type: typeof LOGIN_REDIRECT;
    returnUrl: string;
}

export interface LoginUserRequest {
    username: string;
    password: string;
}

export interface LoginUserResponse {
    access_token: string;
    refresh_token: string;
    id_token: string;
    expires_in: number;
    token_type: string;
}

export interface AuthState {
    isAuthenticated: boolean;
    authChecked: boolean;
    username: string;
    session_duration: number;
    access_token: string;
    refresh_token: string;
    isLoading: boolean;
    isFetchingUserData: boolean;
    isSubmitting: boolean;
    isPremiumModalShown: boolean;
    hasActiveSubscription: boolean | null;
    subscriptionStatusChecked: boolean;
    episodeClicked?: boolean;
    hasCancelledSubscription?: boolean;
    isNewUser: boolean;
    provider: IdentityProvider | undefined;
    userId: string;
    accountIss?: string;
    redirectUri?: string;
    showRedirectOverlay: boolean;
}

export interface SessionStorage {
    access_token: string;
    refresh_token: string;
    id_token: string;
    expires_on: number;
    token_type: string;
    client_id: string;
    tenant_name: string;
    policy_name: string;
    username: string;
}

export interface SchibstedSession {
    access_token: string;
    email: string;
    expires_in: number;
    expiration_time: number;
    id_token: string;
    is_admin: boolean;
    refresh_token: string;
    scope: string;
    server_time: number;
    token_type: string;
    user_id: string;
}

export interface SignUpRedirectConfig {
    redirectDestination: IdpRedirectOption;
    authRedirectUrl?: string;
    redirectUrl?: string;
    callbackFunc?: () => void;
}

export interface UserPrivacySettingsResponse {
    hasOptedOutFromTargetedAdvertising: boolean;
    hasOptedOutFromPersonalizedNews: boolean;
    hasOptedOutFromAnalyticsAndProductDevelopment: boolean;
    hasOptedOutFromMarketing3RdPartyPlatforms: boolean;
}

export enum IdentityAuthority {
    ROPC = "ROPC",
    MSAL = "MSAL",
    SCHIBSTED = "SCHIBSTED",
    NONE = "NONE",
}

export type AuthActions =
    | LoginUser
    | LoginUserSuccess
    | LoginUserFailed
    | LoginWithIdp
    | LoginWithIdpSuccess
    | LoginWithIdpFailed
    | LogoutUser
    | LogoutUserSuccess
    | LogoutUserFailed
    | CheckAuthStatus
    | CheckAuthStatusSuccess
    | CheckAuthStatusFailed
    | LogoutUserWithError
    | LogoutUserWithErrorSuccess
    | LogoutUserWithErrorFailed
    | RefreshToken
    | ShowPremiumModal
    | ResetPassword
    | ResetPasswordSuccess
    | ResetPasswordFailed;

export const BANNED_ACCOUNT_MODAL_STORAGE_KEY = "showBannedAccountModal";
