import { useWheel } from "@use-gesture/react";
import { ReactDOMAttributes } from "@use-gesture/react/dist/declarations/src/types";
import { RefObject, useCallback, useEffect, useState } from "react";

interface ICarousel {
    handleCarouselMove: (direction: string) => void;
    bind: () => ReactDOMAttributes;
    isFirstElementVisible: boolean;
    isLastElementVisible: boolean;
}

function useCarousel(
    carouselRef: RefObject<HTMLDivElement>,
    elementWidth: number,
    elementsGap: number,
    carouselStep: number
): ICarousel {
    const [scrollValue, setScrollValue] = useState<number>(0);
    const [manuallyTriggered, setManuallyTriggered] = useState<boolean>(false);
    const [isFirstElementVisible, setIsFirstElementVisible] = useState<boolean>(true);
    const [isLastElementVisible, setIsLastElementVisible] = useState<boolean>(false);

    const CAROUSEL_STEP = carouselStep;
    const CAROUSEL_ELEMENTS_GAP = elementsGap;
    const CAROUSEL_ELEMENT_WIDTH = elementWidth;

    const stepLength = CAROUSEL_STEP * CAROUSEL_ELEMENT_WIDTH + CAROUSEL_STEP * CAROUSEL_ELEMENTS_GAP;

    const handleCarouselMove = useCallback((direction: string) => {
        setManuallyTriggered(() => true);

        switch (direction) {
            case "right":
                setScrollValue((prev) => {
                    if (
                        carouselRef.current &&
                        prev + stepLength >= carouselRef.current?.scrollWidth - carouselRef.current?.clientWidth
                    )
                        return carouselRef.current?.scrollWidth - carouselRef.current?.clientWidth;
                    return prev + stepLength;
                });
                break;
            case "left":
                setScrollValue((prev) => {
                    if (prev - stepLength <= 0) return 0;
                    if (prev % stepLength !== 0) return stepLength * Math.floor(prev / stepLength);
                    return prev - stepLength;
                });
                break;
            default:
                break;
        }
    }, []);

    const bind = useWheel(({ direction, scrolling, wheeling }) => {
        const currentScrolledLeft = carouselRef.current?.scrollLeft;
        if (!currentScrolledLeft) return;
        if (wheeling || (scrolling && manuallyTriggered)) setManuallyTriggered(() => false);

        const [xDirection] = direction;

        if (!currentScrolledLeft) return;

        if (currentScrolledLeft >= elementWidth * 0.1) {
            setIsFirstElementVisible(() => false);
        } else setIsFirstElementVisible(() => true);

        if (carouselRef.current.scrollWidth - carouselRef.current.clientWidth - currentScrolledLeft <= 32) {
            setIsLastElementVisible(() => true);
        } else setIsLastElementVisible(() => false);

        setScrollValue((prev) => {
            if (prev !== currentScrolledLeft && !manuallyTriggered) {
                if (currentScrolledLeft % stepLength === 0) {
                    return currentScrolledLeft;
                } else if (
                    carouselRef.current &&
                    prev + stepLength >= carouselRef.current?.scrollWidth - carouselRef.current?.clientWidth
                ) {
                    return carouselRef.current?.scrollWidth - carouselRef.current?.clientWidth;
                } else if (
                    xDirection === 1 &&
                    currentScrolledLeft > prev &&
                    currentScrolledLeft > stepLength &&
                    currentScrolledLeft <= Math.ceil(currentScrolledLeft / stepLength)
                ) {
                    return stepLength * Math.ceil(currentScrolledLeft / stepLength);
                } else if (xDirection === 1 && currentScrolledLeft > prev + stepLength) {
                    return prev + Math.floor(currentScrolledLeft / stepLength) * stepLength;
                } else if (xDirection === -1 && currentScrolledLeft < prev - stepLength) {
                    return prev - stepLength;
                } else if (xDirection === -1 && currentScrolledLeft < stepLength) {
                    return 0;
                }
            }
            return prev;
        });
    });

    useEffect(() => {
        if (manuallyTriggered && carouselRef.current) {
            if (scrollValue >= elementWidth * 0.1) {
                setIsFirstElementVisible(() => false);
            } else setIsFirstElementVisible(() => true);

            if (carouselRef.current.scrollWidth - carouselRef.current.clientWidth - scrollValue <= 32) {
                setIsLastElementVisible(() => true);
            } else setIsLastElementVisible(() => false);

            carouselRef.current?.scrollTo({ left: scrollValue, behavior: "smooth" });
        }
    }, [scrollValue, manuallyTriggered, carouselRef.current, carouselRef.current?.scrollLeft]);

    useEffect(() => {
        setIsLastElementVisible(() => {
            if (carouselRef.current?.scrollWidth) {
                return carouselRef?.current?.scrollWidth > window.innerWidth ? false : true;
            }
            return false;
        });
    }, [carouselRef.current?.scrollWidth]);

    return {
        handleCarouselMove,
        bind,
        isFirstElementVisible,
        isLastElementVisible,
    };
}

export default useCarousel;
