import { Location } from "history";
import Cookies from "universal-cookie";

import {
    Category,
    Currency,
    EnvoySession,
    ENVOY_SESSION_KEY,
    MediaQuerySizes,
    SUBSCRIPTION_PLAN_PRODUCT_ID,
    Region,
    STUDENTFLOW_SESSION_KEY,
    SUBFLOW_SESSION_KEY,
    SubscriptionPlan,
    RegionalLocale,
} from "../types";
import config from "../config";
import { MediaMatchList } from "../hooks/useMedia";
import strings from "@locale/localization";
import { ExplorerPodcastCategory } from "app/content/contentTypes";
import xss, { IFilterXSSOptions } from "xss";
import { UserSegment } from "@containers/Student/types";

const cookies = new Cookies();

export const timeToMinutes = (time: string, showSeconds?: boolean): string => {
    const tempArray = time.split(":");
    const hours = Number(tempArray[0]);
    const minutes = Number(tempArray[1]);
    const seconds = Number(tempArray[2]);

    return `${hours > 0 ? `${hours}h ` : ""}${minutes > 0 ? `${minutes}min` : ""}${
        minutes === 0 || showSeconds ? ` ${seconds}s` : ""
    }`;
};

export const limitCopyLength = (copy: string, maxLength: number): string => {
    return copy.length > maxLength ? copy.substring(0, maxLength - 4).trim() + "..." : copy;
};

export const dateFormatter = (date: string): string => {
    const tempDate = new Date(date);
    const year = tempDate.getFullYear();
    let month = "" + (tempDate.getMonth() + 1);
    let day = "" + tempDate.getDate();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
};

export const playerTimeToSeconds = (currentSpot: string | undefined): number => {
    if (!currentSpot || typeof currentSpot !== "string") return 0;

    const timeArr = currentSpot?.split(":");
    let currentSpotInSeconds = 0;

    if (timeArr.length < 2) {
        currentSpotInSeconds = Number(timeArr[0]);
    } else if (timeArr.length < 3) {
        currentSpotInSeconds = Number(timeArr[0]) * 60 + Number(timeArr[1]);
    } else currentSpotInSeconds = Number(timeArr[0]) * 60 * 60 + Number(timeArr[1]) * 60 + Number(timeArr[2]);

    return currentSpotInSeconds;
};

export const playerTimeFormat = (sec: string): string => {
    const currentSpot = Number(sec);
    const playedMinsInt = Math.floor(currentSpot / 60);
    const playedSecs = Math.floor(currentSpot - playedMinsInt * 60);
    const playedHours = Math.floor(playedMinsInt / 60);
    const minutesAfterHours = playedMinsInt % 60;

    const playedMins =
        playedMinsInt >= 60
            ? `${playedHours < 10 ? `0${playedHours}` : playedHours}:${
                  minutesAfterHours < 10 ? `0${minutesAfterHours}` : minutesAfterHours
              }`
            : playedMinsInt;

    return `${playedMins ? (playedMins < 10 ? "0" + playedMins : playedMins) : "00"}:${
        playedSecs ? (playedSecs < 10 ? "0" + playedSecs : playedSecs) : "00"
    }`;
};

export const timespanEightChars = (sec: string): string => {
    const currentSpot = Number(sec);
    const playedMinsInt = Math.floor(currentSpot / 60);
    const playedSecs = Math.floor(currentSpot - playedMinsInt * 60);
    const playedHours = Math.floor(playedMinsInt / 60);
    const minutesAfterHours = playedMinsInt % 60;
    let result: string;

    const playedMins =
        playedMinsInt >= 60
            ? `${playedHours < 10 ? `0${playedHours}` : playedHours}:${
                  minutesAfterHours < 10 ? `0${minutesAfterHours}` : minutesAfterHours
              }`
            : playedMinsInt;

    result = `${playedMins < 10 ? "0" + playedMins : playedMins}:${playedSecs < 10 ? "0" + playedSecs : playedSecs}`;

    if (result.length < 8) result = `00:${result}`;
    return result;
};

export const formatEpisodeDuration = (currentSpot: number, duration: string): { currentTime: string; totalTime: string } => {
    if (!duration) return { currentTime: "0:00", totalTime: "00:00:00" };

    const playedMinsInt = Math.floor(currentSpot / 60);
    const playedSecs = Math.floor(currentSpot - playedMinsInt * 60);
    const playedHours = Math.floor(playedMinsInt / 60);
    const minutesAfterHours = playedMinsInt % 60;

    const playedMins =
        playedMinsInt >= 60
            ? `${playedHours < 10 ? `0${playedHours}` : playedHours}:${
                  minutesAfterHours < 10 ? `0${minutesAfterHours}` : minutesAfterHours
              }`
            : playedMinsInt;

    const totalTime = duration.length > 5 && duration[1] === "0" ? duration.slice(3, duration.length) : duration;

    return {
        currentTime: `${playedMins ? (playedMins < 10 ? "0" + playedMins : playedMins) : "00"}:${
            playedSecs ? (playedSecs < 10 ? "0" + playedSecs : playedSecs) : "00"
        }`,
        totalTime: totalTime,
    };
};

export function getQueryParams(location: Location): { [key: string]: string } {
    let queryString: string = location.search;
    queryString = queryString.replace("?", "");
    const arr = queryString.split("&");

    const queryStringObj: any = {};

    arr.forEach((query: string) => {
        const keyValue = query.split("=");
        const queryItem = { name: keyValue[0], value: keyValue[1] };
        queryStringObj[queryItem.name] = queryItem.value;
    });

    return queryStringObj;
}

export function getPackageId(location: string): number {
    switch (location) {
        case "se":
            return 1;
        case "no":
            return 2;
        case "fi":
            return 3;
        default:
            return 1;
    }
}

export function getLocale(locale: Region): RegionalLocale {
    switch (locale) {
        case "no":
            return "nb-NO";
        case "fi":
            return "fi-FI";
        default:
            return "sv-SE";
    }
}

export function getCurrencyUnit(currency?: Currency): string {
    switch (currency) {
        case Currency.Sweden:
            return "kr";
        case Currency.Norway:
            return "kr";
        case Currency.Finland:
            return "€";
        default:
            return "kr";
    }
}

export function getLanguage(locale: string): string {
    switch (locale) {
        case "no":
            return "nb";
        case "fi":
            return "fi";
        default:
            return "sv";
    }
}

export function encryptPassword(password: string): string {
    const pemPublicKey = config.PUBLIC_KEY;
    const key = forge.pki.publicKeyFromPem(pemPublicKey ?? "");
    const buffer = forge.util.createBuffer(password);
    const bytes = buffer.getBytes();

    const encrypted = key.encrypt(bytes, "RSAES-PKCS1-V1_5");
    const b64Encoded = forge.util.encode64(encrypted);

    return b64Encoded;
}

export function getLocalizedErrorMessage(errorCode?: string): string {
    const string = strings.errors;

    switch (errorCode) {
        case "AADB2C90225":
            return string.invalidDetailsSent;

        case "AADB2C90114":
            return string.accountTempLocked;

        case "AADB2C90111":
            return string.accountLocked;

        case "AADB2C90054":
            return string.invalidDetails;

        case "AADB2C90035":
            return string.serviceDown;

        default:
            return string.general;
    }
}

export function getMediaQueryImageUrl(matchedMedia: MediaMatchList, assets: (string | undefined)[]): string | undefined {
    const mediaQuery = Object.entries(matchedMedia).filter(([_key, value]) => value === true)[0][0];

    switch (mediaQuery) {
        case MediaQuerySizes.Small:
            return assets[0];
        case MediaQuerySizes.Medium:
            return assets[1] ?? assets[0];

        case MediaQuerySizes.Large:
            return assets[2] ?? assets[1] ?? assets[0];

        case MediaQuerySizes.XL:
            return assets[3] ?? assets[2] ?? assets[1] ?? assets[0];
    }
}

export function getTimezone(region: Region): string {
    switch (region) {
        case Region.Sweden:
            return "Europe/Stockholm";
        case Region.Norway:
            return "Europe/Oslo";
        case Region.Finland:
            return "Europe/Helsinki";
    }
}

export function isAudioUrlMp3(audioUrl: string): boolean {
    return /(\S+\.(mp3|mp4|m4a))(\?\S+)?/.test(audioUrl);
}

export function uid(): string {
    return String(Date.now().toString(32) + Math.random().toString(16)).replace(/\./g, "");
}

export function getMimeTypeFromUrl(url: string): string {
    const indexOfLastDot = url.lastIndexOf(".");
    const imageFormat = url.slice(indexOfLastDot + 1);

    switch (imageFormat) {
        case "png":
            return "image/png";
        case "avif":
            return "image/avif";
        case "webp":
            return "image/webp";
        case "jpg":
        case "jpeg":
        default:
            return "image/jpeg";
    }
}

export function formatPrice(amount: number, currency?: Currency, skipCurrencyUnit?: boolean) {
    const { locale } = strings.routes;
    const fullLocale = getLocale(locale as Region);
    const amountString = amount.toLocaleString(fullLocale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const [integerPart, decimalPart] = amountString.split(",");
    const displayDecimalPart = decimalPart === "00" ? "" : `,${decimalPart}`;

    const currencyString = currency !== undefined ? getCurrencyUnit(currency) : strings.settingsPage.transactions.currency;
    const formattedAmount = skipCurrencyUnit
        ? `${integerPart}${displayDecimalPart}`
        : `${integerPart}${displayDecimalPart} ${currencyString}`;
    return formattedAmount;
}

export const camelCaseKey = (key: string) => {
    return key
        .toLowerCase()
        .split("-")
        .map((word, index) => {
            if (index === 0) {
                return word;
            }
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join("");
};

export const getProperCategoryKey = (key: string) => (key.includes("-") ? camelCaseKey(key) : key.toLowerCase());

export const getCategoryPageUrl = (category: Category | ExplorerPodcastCategory) => {
    const formattedCategoryId = getProperCategoryKey(category.key);
    const routeStrings = strings.routes;
    const categoryUrl = routeStrings[formattedCategoryId as keyof typeof routeStrings];

    if ((category as Category).id) return `${routeStrings.categories}/${(category as Category).id}/${categoryUrl}`;
    return `${routeStrings.categories}/${(category as ExplorerPodcastCategory).destination}/${categoryUrl}`;
};

export function getEnvoySession(): EnvoySession {
    const envoySession: EnvoySession = cookies.get(ENVOY_SESSION_KEY);

    if (!envoySession)
        return {
            is_on_envoy_flow: false,
            envoy_share_link_hash: "",
        };

    return envoySession satisfies EnvoySession;
}

export function isOnSubflow(): boolean {
    const subflowSession = cookies.get(SUBFLOW_SESSION_KEY);
    if (!subflowSession) return false;

    return !!subflowSession;
}

export function isOnStudentFlow(): boolean {
    const studentflowSession = cookies.get(STUDENTFLOW_SESSION_KEY);
    if (!studentflowSession) return false;

    return !!studentflowSession;
}

export const sanitizeText = (string: string) => {
    string = string.replace(/<3/g, "&lt;3");

    const options = {
        stripIgnoreTag: true,
    } satisfies IFilterXSSOptions;

    return xss(string, options);
};

export const hasTextContent = (html: string) => {
    const text = html.replace(/<[^>]*>/g, "");

    return text.trim().length > 0;
};

export const getIsStudentPlan = (subscriptionPlan: SubscriptionPlan): boolean => {
    return subscriptionPlan?.productId === SUBSCRIPTION_PLAN_PRODUCT_ID.STUDENT;
};

export const getIsScheduledStudentPlan = (subscriptionPlan: SubscriptionPlan): boolean => {
    return subscriptionPlan?.nextPlanProductId === SUBSCRIPTION_PLAN_PRODUCT_ID.STUDENT;
};

export const getIsOnStudentPlanWithoutVerifiedStudentSegment = (
    subscriptionPlan: SubscriptionPlan,
    studentSegment: UserSegment | undefined
): boolean => {
    return (
        subscriptionPlan.productId === SUBSCRIPTION_PLAN_PRODUCT_ID.STUDENT &&
        (studentSegment === null || studentSegment === undefined || studentSegment.isExpired)
    );
};
