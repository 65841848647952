import { UseQueryResult, useQuery, useQueryClient } from "@tanstack/react-query";
import useAuth from "@auth/useAuth";
import routes from "..";
import Cookies from "universal-cookie";
import { SHEER_ID_COOKIE } from "@containers/Student/constants";
import { useEffect } from "react";
import { VIPPSMOBILEPAY_COOKIE } from "@containers/VippsMobilePay/constants";

export function useGetTrialStatus(): UseQueryResult<boolean> {
    const { hasActiveSubscription, isAuthenticated } = useAuth();

    return useQuery(["trial-status"], () => routes.getTrialStatus(), {
        staleTime: Infinity,
        enabled: Boolean(!hasActiveSubscription && isAuthenticated),
    });
}

export function useGetFeatureAllowed(): UseQueryResult<boolean> {
    const { isAuthenticated, username } = useAuth();

    return useQuery({
        queryKey: ["is-feature-allowed"],
        queryFn: async () => {
            try {
                const response = await routes.getIsFeatureAllowed(username);
                return response;
            } catch (error) {
                return false;
            }
        },
        staleTime: Infinity,
        enabled: isAuthenticated,
        retry: false,
    });
}

export function useGetEnabledFeatures() {
    const queryClient = useQueryClient();
    const cookies = new Cookies();

    useEffect(() => {
        queryClient.invalidateQueries(["enabled-features"]);
    }, []);

    return useQuery<{ isSheerIdEnabled: boolean; isVippsMobilePayEnabled: boolean }>({
        queryKey: ["enabled-features"],
        queryFn: async () => {
            const sheeridCookie = cookies.get(SHEER_ID_COOKIE);
            const vippsMobilePayCookie = cookies.get(VIPPSMOBILEPAY_COOKIE);

            try {
                const response = await routes.getEnabledFeatures();
                return {
                    isSheerIdEnabled: response.enableSheerId ? response.enableSheerId : sheeridCookie ?? false,
                    isVippsMobilePayEnabled: response.enableVippsMobilePay
                        ? response.enableVippsMobilePay
                        : vippsMobilePayCookie ?? false,
                };
            } catch (error: unknown) {
                return {
                    isSheerIdEnabled: sheeridCookie ?? false,
                    isVippsMobilePayEnabled: vippsMobilePayCookie ?? false,
                };
            }
        },
        staleTime: Infinity,
        retry: false,
    });
}
