import { StudentLandingEntity } from "@api/types";

export const GET_STUDENT_LANDING_CONTENT = "studentlanding/GET_STUDENT_LANDING_CONTENT";
export const GET_STUDENT_LANDING_CONTENT_SUCCESS = "studentlanding/GET_STUDENT_LANDING_CONTENT_SUCCESS";
export const GET_STUDENT_LANDING_CONTENT_FAILED = "studentlanding/GET_STUDENT_LANDING_CONTENT_FAILED";

export type StudentLandingPageState = {
    pageContent: StudentLandingEntity | null;
    isFetchingPageContent: boolean;
    error: any;
};

export interface GetStudentLandingContent {
    type: typeof GET_STUDENT_LANDING_CONTENT;
}

export interface GetStudentLandingSuccess {
    type: typeof GET_STUDENT_LANDING_CONTENT_SUCCESS;
}

export interface GetStudentLandingFailed {
    type: typeof GET_STUDENT_LANDING_CONTENT_FAILED;
}

export default {
    GET_STUDENT_LANDING_CONTENT,
    GET_STUDENT_LANDING_CONTENT_SUCCESS,
    GET_STUDENT_LANDING_CONTENT_FAILED,
};
