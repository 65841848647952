import { ReactElement, useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { PodmeColor } from "@typings/index";
import Skeleton from "@components/Skeleton";
import { Category } from "..";

interface TagProps {
    category?: Category;
    isLoading?: boolean;
}

export default function Tag({ category, isLoading = false }: TagProps): ReactElement {
    const [isPremium, setIsPremium] = useState(category && category.key === "premium");

    useEffect(() => {
        if (category) setIsPremium(category.key === "premium");
    }, [category]);

    return (
        <Skeleton borderRadius="1rem" isLoaded={!isLoading}>
            {category && (
                <Box
                    borderRadius="20px"
                    padding="0.25rem 0.5rem"
                    backgroundColor={isPremium ? PodmeColor.Cyan : PodmeColor.LicoriceBlue}
                    transition="all 100ms ease-in-out"
                    _hover={{
                        filter: isPremium ? "brightness(0.9)" : "brightness(1.2)",
                    }}
                >
                    <Text
                        as="span"
                        display="block"
                        fontSize="0.75rem"
                        color={isPremium ? PodmeColor.BlackPearl : PodmeColor.SoftWhite}
                        fontWeight={500}
                        letterSpacing={"-0.25px"}
                    >
                        {category.name}
                    </Text>
                </Box>
            )}
        </Skeleton>
    );
}
