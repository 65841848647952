import { legacy_createStore as createStore, applyMiddleware, Store, Action } from "redux";
import thunkMiddleware from "redux-thunk";
import createRootReducer, { RootState } from "../reducers/rootReducer";
import "regenerator-runtime/runtime";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "@redux-devtools/extension";
import { History } from "history";

import rootSaga from "../sagas/rootSaga";
import { serverDataMiddleware } from "./serverDataMiddleware";

function configureStoreDev(
    history: History,
    initialState?: RootState
): { store: Store<RootState, any>; resolvePromises: () => Promise<unknown> } {
    const sagaMiddleware = createSagaMiddleware();

    const promisesList: Promise<unknown>[] = [];

    const store: Store<RootState, Action> = createStore<RootState, any, any, any>(
        createRootReducer(history),
        initialState,
        composeWithDevTools(applyMiddleware(thunkMiddleware, sagaMiddleware, serverDataMiddleware(promisesList)))
    );

    sagaMiddleware.run(rootSaga);

    return { store, resolvePromises: async () => Promise.all(promisesList) };
}

export default configureStoreDev;
