import { Box, Image } from "@chakra-ui/react";
import { CSSstylesObject, PodmeColor } from "../../types/index";
import { ReactElement } from "react";
import strings from "@locale/localization";

const styles: CSSstylesObject = {
    simpleNavWrapper: {
        width: "100%",
        borderBottom: `1px solid ${PodmeColor.Silver}20`,
    },
    simpleNav: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: PodmeColor.White,
        padding: "1.125rem 2rem",
        position: "relative",
        background: "transparent",
    },
    appLogoImg: {
        width: "auto",
        height: "28.34px",
    },
};

interface IProps {
    [x: string]: unknown;
}

function SimpleNavigation({ navbarRef, ...rest }: IProps): ReactElement {
    const { locale } = strings.routes;

    return (
        <Box ref={navbarRef} {...styles.simpleNavWrapper} {...rest}>
            <Box as={"nav"} {...styles.simpleNav}>
                <a href={`/${locale}/`}>
                    <Image {...styles.appLogoImg} src="/assets/images/brand/podme-logo-white.svg" alt="Podme logo" />
                </a>
            </Box>
        </Box>
    );
}

export default SimpleNavigation;
