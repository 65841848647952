import { motion } from "framer-motion";
import { memo, ReactElement, useMemo, useRef, useState } from "react";
import { Box, Flex, Button, Image, Text, Grid, keyframes } from "@chakra-ui/react";

import BigPlayer from "@components/BigPlayer/BigPlayer";
import LoadingIcon from "@components/LoadingIcon";
import TextScroller from "@components/TextScroller";
import { PlayObject } from "@containers/GlobalPlayer/redux/GlobalPlayerTypes";
import { SoundState } from "@containers/SoundProvider/types";
import { PodmeColor } from "@typings/index";

import { formatEpisodeDuration, playerTimeFormat, playerTimeToSeconds } from "@utils/index";
import { useDeviceOnline } from "../../../../../app/hooks";

interface IProps {
    playObject: PlayObject;
    sound: SoundState;
    skipBackwards: () => void;
    skipForwards: () => void;
    togglePlay: (evt: any) => void;
    setPlayPosition: (evt: any) => void;
    [x: string]: any;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(5rem)
  }
  to {
    opacity: 1;
    transform: translateY(0)
  }
`;

function MobilePlayer({ playObject, sound, setPlayPosition, skipBackwards, skipForwards, togglePlay, ...rest }: IProps): ReactElement {
    const [showBigPlayer, setShowBigPlayer] = useState(false);
    const titleTextWrapper = useRef<HTMLParagraphElement>(null);
    const isOnline = useDeviceOnline();

    const { currentTime, totalTime } = useMemo(() => {
        if (sound.currentAudio === undefined && playObject.currentSpot) {
            return formatEpisodeDuration(playerTimeToSeconds(playObject.currentSpot), playObject.length);
        }
        if (sound.currentAudio?.duration) {
            return formatEpisodeDuration(sound.currentTime, playerTimeFormat(String(sound.currentAudio.duration)));
        }
        return formatEpisodeDuration(sound.currentTime, playObject.length);
    }, [sound, playObject.length]);

    const playPosition = useMemo(() => {
        if (sound.currentAudio === undefined) {
            const durationInSeconds = playerTimeToSeconds(playObject.length);
            const timeInSeconds = playerTimeToSeconds(playObject.currentSpot);
            return (timeInSeconds / durationInSeconds) * 100;
        }

        return (sound.currentTime / sound.currentAudio.duration) * 100;
    }, [sound, playObject]);

    return !showBigPlayer ? (
        <Box animation={`${fadeIn} 300ms ease forwards`} {...rest} width="100%">
            <Box position={"absolute"} top="-2px" width="100%" height="50%" zIndex="-100" transform="scale(1.01)">
                <Box
                    display="block"
                    width="100%"
                    height="100%"
                    position="relative"
                    borderRadius="1.5rem 1.5rem 0 0"
                    overflow="hidden"
                    backgroundColor={PodmeColor.LicoriceBlue}
                >
                    <Box
                        position="absolute"
                        top={playPosition < 5 ? "3px" : "0px"}
                        left="-5px"
                        height="100%"
                        minWidth="4%"
                        backgroundColor={PodmeColor.Cyan}
                        width={playPosition + "%"}
                        transition="width 150ms ease-in"
                    />
                </Box>
            </Box>
            <Box
                width="100%"
                backgroundColor={PodmeColor.Woodsmoke}
                rounded={"1.5rem 1.5rem 0 0"}
                padding={"0.75rem 1rem 1.2rem 1rem"}
            >
                <Grid
                    justifyContent={["space-between", "center"]}
                    alignItems="center"
                    gridTemplateColumns="7fr 4fr"
                    width="100%"
                    padding={"0.2rem 0"}
                    gridGap="0.1rem"
                >
                    <Flex
                        onClick={() => setShowBigPlayer(true)}
                        gridGap={[2, 4]}
                        alignItems="center"
                        flex="1"
                        zIndex="1"
                        userSelect="none"
                        _hover={{
                            cursor: "pointer",
                        }}
                    >
                        <Box as={motion.div} maxHeight={"2.5rem"} maxWidth={"2.5rem"} flexShrink={0}>
                            <Image
                                width={"auto"}
                                height={"auto"}
                                borderRadius={"4px"}
                                src={playObject?.imageUrl}
                                alt={playObject?.title}
                            />
                        </Box>
                        <Flex flexGrow="1" minWidth={[null, null, "500px"]} maxWidth="100%" userSelect="none">
                            <Flex flexDirection="column" justifyContent="space-between" width="100%" gridGap={"0.3rem"}>
                                <Flex
                                    flexDirection="column"
                                    justifyContent="center"
                                    flexGrow="1"
                                    width={["unset", null, "665px"]}
                                    userSelect="none"
                                    gridGap="3px"
                                >
                                    <Flex justifyContent="space-between" alignItems="flex-end" lineHeight="1em">
                                        <Box>
                                            <Text
                                                as={motion.span}
                                                color={PodmeColor.Silver}
                                                lineHeight={1.25}
                                                fontSize={"clamp(11px, calc(2vw + 0.1rem), 13px)"}
                                                fontStyle="normal"
                                                letterSpacing={"-0.01em"}
                                                noOfLines={1}
                                            >
                                                {playObject?.podcastTitle}
                                            </Text>
                                        </Box>
                                    </Flex>
                                    <Flex ref={titleTextWrapper}>
                                        {playObject && playObject?.id > 1000 && titleTextWrapper.current ? (
                                            <TextScroller
                                                isEpisodePlaying={sound.isPlaying && !sound.isLoading}
                                                textBoxWidth={titleTextWrapper.current.clientWidth}
                                                text={playObject?.title}
                                            />
                                        ) : (
                                            <Text
                                                as="h3"
                                                fontSize={["0.875em", "1em", "1.125em"]}
                                                fontWeight={600}
                                                lineHeight={1.25}
                                                noOfLines={[1]}
                                                wordBreak="break-all"
                                            >
                                                {playObject?.title}
                                            </Text>
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex
                        gridGap={2}
                        sx={{
                            "* button": {
                                outline: "none !important",
                                outlineOffset: "0 !important",
                            },
                            "* button:focus": {
                                filter: "opacity(0.9)",
                                boxShadow: "none",
                                outline: "none",
                            },
                            "* button:active": {
                                filter: "opacity(0.7)",
                                outline: "none",
                            },
                        }}
                        bgColor={PodmeColor.Woodsmoke}
                        zIndex="2"
                    >
                        <Box flexBasis="30%" maxWidth="30%">
                            <Button
                                variant="unstyled"
                                onClick={skipBackwards}
                                background={"none"}
                                padding={"0"}
                                minWidth="100%"
                                disabled={sound.currentAudio === undefined || !isOnline}
                            >
                                <Image src="/assets/images/icons/skip-backwards.svg" alt="skip backwards button" />
                            </Button>
                        </Box>
                        <Box flexBasis="40%" minW="40px" maxW="40px" width="40px" height="40px">
                            {sound.isLoading || !isOnline ? (
                                <Button width={"100%"} background={PodmeColor.SoftWhite} padding={"0"} minWidth="100%" rounded="50%">
                                    <LoadingIcon spinnerHeight="35px" spinnerWidth="35px" variant="white" />
                                </Button>
                            ) : (
                                <Button
                                    onClick={togglePlay}
                                    background={PodmeColor.SoftWhite}
                                    rounded="50%"
                                    padding={"0"}
                                    transition={"all 100ms ease-in"}
                                    minWidth="100%"
                                >
                                    {sound.isPlaying ? (
                                        <Image filter="invert(1)" src="/assets/images/icons/play-pause.svg" alt="pause audio button" />
                                    ) : (
                                        <Image filter="invert(1)" src="/assets/images/icons/play-play.svg" alt="play audio button" />
                                    )}
                                </Button>
                            )}
                        </Box>
                        <Box flexBasis="30%" maxWidth="30%">
                            <Button
                                variant="unstyled"
                                onClick={skipForwards}
                                minWidth="100%"
                                background={"none"}
                                padding={"0"}
                                transition={"all 100ms ease-in"}
                                disabled={sound.currentAudio === undefined || !isOnline}
                            >
                                <Image src="/assets/images/icons/skip-forward.svg" alt="skip forward button" />
                            </Button>
                        </Box>
                    </Flex>
                </Grid>
            </Box>
        </Box>
    ) : (
        <BigPlayer
            isOpen={showBigPlayer}
            isPlaying={sound.isPlaying}
            isLoading={sound.isLoading}
            playObject={playObject}
            playPosition={playPosition}
            currentTime={currentTime}
            totalTime={totalTime}
            isDisabled={sound.currentAudio === undefined || !isOnline}
            play={togglePlay}
            setPlayPosition={setPlayPosition}
            skipBackwards={skipBackwards}
            skipForwards={skipForwards}
            onClose={() => setShowBigPlayer(false)}
        />
    );
}

export default memo(MobilePlayer);
