import { SecondaryButton } from "../buttons";
import { PodmeColor } from "app/types";
import { Box, Image, Heading, ListItem, List, Text, Flex, Grid, GridProps } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import { SectionProps } from "../Cms/CmsSections";
import { ValuePropositionContent } from "app/content/contentTypes";
import CTACheckoutButton from "@components/CTACheckoutButton";
import { getFormattedDescription } from "@containers/CategoryPage/utils";
import strings from "app/locale/localization";

interface Props {
    sectionContent: ValuePropositionContent;
    price?: string;
    withCtaButton?: boolean;
    ctaCallbackFunction?: () => void;
    redirectUrl?: string;
}

export type ValuePropositionProps = Props & GridProps & SectionProps;
export default function ValueProposition({
    withCtaButton = true,
    ctaCallbackFunction = () => {},
    sectionContent,
    testId,
    redirectUrl,
    price = "",
    ...rest
}: ValuePropositionProps): ReactElement | null {
    //safeguard for race condition:
    if (!sectionContent) return null;

    const { headline, paragraph, disclaimer, benefits, cta } = useMemo(() => {
        const documentString = JSON.stringify(sectionContent);
        const formattedString = `${strings.formatString(documentString, price)}`;
        return JSON.parse(formattedString);
    }, [sectionContent, price]);

    const hasBenefits = benefits && benefits.length > 0;

    const formattedParagraph = useMemo(() => {
        if (!paragraph) return null;
        return getFormattedDescription(paragraph);
    }, [paragraph]);

    return (
        <Grid
            as="section"
            aria-labelledby="premium-section-title"
            data-testid={testId}
            gridTemplateAreas={[
                `"img img img img"
                "info info info info"
                "info info info info"
                "buttons buttons buttons buttons"`,
                `"img info info info"
                "img info info info"
                "img info info info"
                "buttons buttons buttons buttons"`,
                `"img info info info info"
                "img info info info info"
                "img info info info info"
                "img buttons buttons buttons buttons"`,
            ]}
            gridTemplateColumns="auto 1fr"
            gridColumnGap={["1.5rem", null, "3.5rem"]}
            gridRowGap="1.5rem"
            bgColor={PodmeColor.SoftWhite}
            rounded="1.5rem"
            padding={["1rem", null, "2rem"]}
            alignItems="center"
            {...rest}
        >
            <Box gridArea="img" maxWidth={["6rem", "11.75rem", "16.25rem"]}>
                <Image alt="podme diamond logo" src={"/assets/images/brand/diamond_logo.svg"} pointerEvents="none" cursor="default" />
            </Box>
            <Flex direction="column" gridGap="1rem" gridArea="info">
                <Flex direction="column" gridGap="0.5rem">
                    <Heading
                        id="premium-section-title"
                        color={rest.color ?? PodmeColor.BlackPearl}
                        fontSize={["1.75rem", null, "2.25rem"]}
                    >
                        {headline}
                    </Heading>
                    {formattedParagraph && formattedParagraph.length > 0 && (
                        <Box
                            sx={{ ">p": { color: rest.color ?? PodmeColor.BlackPearl } }}
                            dangerouslySetInnerHTML={{ __html: formattedParagraph }}
                        />
                    )}
                </Flex>
                {hasBenefits && (
                    <Box color={rest.color ?? PodmeColor.LicoriceBlue}>
                        <List display="flex" flexDirection="column" gridGap="0.75rem">
                            {benefits.map((benefit: string, idx: number) => (
                                <ListItem role="listitem" display="flex" alignItems="center" gridGap="0.75rem" key={idx}>
                                    <Image
                                        alt="checkmark icon"
                                        width={"24px"}
                                        height="24px"
                                        src={
                                            rest.bgColor === PodmeColor.DarkBlue
                                                ? "/assets/images/icons/check-white.svg"
                                                : "/assets/images/icons/list_item_check.svg"
                                        }
                                        pointerEvents="none"
                                        cursor="default"
                                    />
                                    <Text>{benefit}</Text>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )}
            </Flex>
            <Flex gridArea="buttons" flexDirection="column" gridGap={4}>
                <Flex>
                    {withCtaButton && (
                        <CTACheckoutButton
                            renderAs={SecondaryButton}
                            background={PodmeColor.Cyan}
                            color={PodmeColor.BlackPearl}
                            width={["100%", null, "unset"]}
                            maxWidth={["100%", "50%", "unset"]}
                            redirectUrl={redirectUrl}
                            onClickCallback={ctaCallbackFunction}
                            coupon={cta?.coupon}
                        >
                            {cta?.text}
                        </CTACheckoutButton>
                    )}
                </Flex>
                {disclaimer && (
                    <Box fontSize={["0.75rem"]} color={rest.color ?? PodmeColor.BlackPearl + "75"}>
                        <Text>{disclaimer}</Text>
                    </Box>
                )}
            </Flex>
        </Grid>
    );
}
