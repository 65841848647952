import { ReactElement } from "react";
import { Box, Heading } from "@chakra-ui/react";
import EpisodeListItem from "./components/EpisodeListItem";
import { CSSstylesObject, Episode, PodmeColor } from "../../types/index";

const styles = {
    episodeListContainer: {
        display: "grid",
        gridTemplateColumns: ["repeat(1, minmax(100%, 1fr))", "repeat(auto-fill, minmax(420px, 1fr))"],
        gridGap: "0.5rem",
    },
    singleColumnLayoutEpisodeListContainer: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridGap: "0.5rem",
    },
} satisfies CSSstylesObject;

interface EpisodeListProps {
    title: string;
    episodes: Episode[];
    showProgressbar: boolean;
    singleColumnLayout?: boolean;
    removePodcastTitleOnEachEpisode?: boolean;
    numberOfEpisodesToShow?: number;
    fixedItemHeight?: string; //height of EpisodeListItem elements, useful for 2 column layout on My Podcasts page
    scrollToTop?: boolean;
    loadingEpisodes?: boolean;
}

function EpisodeList({
    title,
    episodes,
    showProgressbar,
    singleColumnLayout,
    removePodcastTitleOnEachEpisode,
    numberOfEpisodesToShow,
    fixedItemHeight,
    loadingEpisodes,
}: EpisodeListProps): ReactElement {
    //some components need to limit number of displayed episodes on this list:
    const episodesArray = numberOfEpisodesToShow ? episodes.slice(0, numberOfEpisodesToShow) : episodes;

    return (
        <Box color={PodmeColor.White}>
            {/* The 'title' prop is applied as a title of the entire list! */}
            {title !== "" && title.length > 1 && (
                <Heading as="h1" fontSize={["1.5em", "2em", "2.5em"]} mb={8}>
                    {title}
                </Heading>
            )}
            <Box
                data-testid="episode-list"
                {...(singleColumnLayout ? styles.singleColumnLayoutEpisodeListContainer : styles.episodeListContainer)}
            >
                {episodes && episodes.length > 0
                    ? episodesArray.map((episode, index) => (
                          <EpisodeListItem
                              removePodcastTitleOnEachEpisode={removePodcastTitleOnEachEpisode}
                              episode={episode}
                              showProgressbar={showProgressbar}
                              key={index}
                              fixedItemHeight={fixedItemHeight}
                              loadingEpisodes={loadingEpisodes}
                          />
                      ))
                    : Array.apply(null, { length: numberOfEpisodesToShow } as Episode[]).map((item, index) => (
                          <EpisodeListItem
                              removePodcastTitleOnEachEpisode={removePodcastTitleOnEachEpisode}
                              episode={item as Episode}
                              showProgressbar={showProgressbar}
                              key={index}
                              loadingEpisodes={loadingEpisodes}
                          />
                      ))}
            </Box>
        </Box>
    );
}

export default EpisodeList;
