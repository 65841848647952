import LocalizedStrings from "react-localization";

import swedishLocalization from "./sv.json";
import norwayLocalization from "./nb.json";
import finlandLocalization from "./fi.json";

export const strings = new LocalizedStrings({
    sv: swedishLocalization,
    nb: norwayLocalization,
    fi: finlandLocalization,
});

strings.setLanguage("sv");

export default strings;
