import { useMedia } from "app/hooks";
import { Box, Heading, Image, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import EpisodeList from "@components/EpisodeList/EpisodeList";
import { Episode, PodmeColor } from "@typings/index";
import { Dispatch, memo, SetStateAction, useEffect } from "react";
import { useGetEpisode } from "../queries";
import useAuth from "@auth/useAuth";
import { IEpisode } from "../redux/types";

interface Props {
    title: string;
    episodesList: Episode[] | IEpisode[];
    setIsEpisodeListShown: Dispatch<SetStateAction<boolean>>;
}

function EpisodeSlideShelfModal({ title, episodesList, setIsEpisodeListShown }: Props) {
    const { onClose, onOpen } = useDisclosure();
    const { isSmallScreen } = useMedia();
    const { isAuthenticated } = useAuth();

    const episodeListWithData = episodesList.map(
        (episode: Episode | IEpisode) => useGetEpisode(episode.id, isAuthenticated).data as Episode
    );

    useEffect(() => {
        onOpen();
    }, []);

    return (
        <Modal
            isOpen
            onClose={() => {
                onClose();
                setIsEpisodeListShown(() => false);
            }}
        >
            <ModalOverlay bgColor="rgba(0, 0, 0, 0.5)" backdropFilter="blur(0.1rem)" />
            <Box
                sx={{
                    ".chakra-modal__content-container": {
                        height: isSmallScreen ? "100dvh !important" : undefined,
                        zIndex: "1410",
                    },
                }}
            >
                <ModalContent
                    backgroundColor={PodmeColor.LicoriceBlue}
                    maxWidth="35rem"
                    margin={["0", "auto"]}
                    rounded={["0", "1.5rem"]}
                    gridGap="1rem"
                    overflow="unset"
                    height={["100%", "unset"]}
                >
                    <ModalHeader
                        padding={["1rem 1rem 0", "1.5rem 1.5rem 0"]}
                        display="flex"
                        justifyContent="space-between"
                        gridGap="1rem"
                    >
                        <Heading fontSize={["1.5rem"]}>{title}</Heading>
                        <Box
                            width="1.5rem"
                            as="button"
                            onClick={() => {
                                onClose();
                                setIsEpisodeListShown(() => false);
                            }}
                        >
                            <Image width="100%" src="/assets/images/icons/close-16x16.svg" />
                        </Box>
                    </ModalHeader>
                    <Box overflow="hidden" rounded={["0", "0 0 1.5rem 1.5rem"]}>
                        <ModalBody
                            padding={["0 1rem 1rem", "0 1.5rem 1.5rem"]}
                            overflowY="auto"
                            maxHeight={["100%", "45rem"]}
                            sx={{
                                "::-webkit-scrollbar": {
                                    width: "5px",
                                    zIndex: "-999",
                                },
                                "::-webkit-scrollbar-track": {
                                    bg: "transparent",
                                },
                                "::-webkit-scrollbar-thumb": {
                                    bg: PodmeColor.BlackPearl,
                                    paddingRight: "0.25rem",
                                    rounded: "1rem",
                                    backgroundClip: "content-box",
                                },
                            }}
                        >
                            <EpisodeList
                                removePodcastTitleOnEachEpisode={false}
                                title=""
                                showProgressbar
                                singleColumnLayout
                                episodes={episodeListWithData}
                                scrollToTop
                            />
                        </ModalBody>
                    </Box>
                </ModalContent>
            </Box>
        </Modal>
    );
}

export default memo(EpisodeSlideShelfModal);
