import { ReactElement, useCallback, useEffect, useState } from "react";
import { Box, Flex, keyframes, Text, Link } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import strings from "@locale/localization";
import { CSSstylesObject, PodmeColor } from "@typings/index";
import { SecondaryButton } from "@components/buttons";
import PremiumBadge from "@components/PremiumBadge";
import { selectItemEventAction } from "app/tracking/trackingActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/reducers/rootReducer";
import usePrevPath from "app/hooks/usePrevPath";
import HamburgerButton from "@components/HamburgerButton/HamburgerButton";
import useAuth from "@auth/useAuth";
import { IdpRedirectOption } from "@auth/authTypes";
import CTACheckoutButton from "@components/CTACheckoutButton";
import { STUDENT_BASE_PATH } from "@containers/Student/constants";
import { useGetEnabledFeatures } from "app/api/hooks/user";

const slideIn = keyframes`
    0% {
        left: 100%;
        opacity: 0;
    },
    40% {
        left: 60%;
        opacity: 1;
    },
    80% {
        left: 20%;
        opacity: 1;
    }
    100% {
        left: 0%;
        opacity: 1;
    }
`;

const slideOut = keyframes`
    0% {
        left: 0%;
        opacity: 1;
    },
    40% {
        left: 40%;
        opacity: 1;
    },
    90% {
        left: 90%;
        opacity: 0.9;
    }
    100% {
        left: 100%;
        opacity: 0;
    }
`;

const styles: CSSstylesObject = {
    menuItemLink: {
        color: PodmeColor.Silver,
        fontSize: "min(5vh, 2em)",
        display: "inline-block",
        width: "100%",
        borderBottom: `1px solid ${PodmeColor.Cinder}`,
        padding: "1rem",
        transition: "all 50ms ease-in",
        sx: {
            "> span": {
                fontWeight: "700 !important",
            },

            //styles for active links
            "&:active": {
                color: PodmeColor.SoftWhite,
                backgroundColor: PodmeColor.Cinder,
                filter: "brightness(1.01)",
            },
            "&:hover": {
                color: PodmeColor.SoftWhite,
                backgroundColor: PodmeColor.Cinder,
                filter: "brightness(1.01)",
            },
        },
    },
};
interface SlideMenuProps {
    username: string;
    loginMethod: string;
    hasSubscription: boolean;
    closeMenu: () => void;
    logoutUser: () => any;
}
const globalState = (state: RootState) => state.global;

export default function SlideMenu({ username, loginMethod, hasSubscription, closeMenu, logoutUser }: SlideMenuProps): ReactElement {
    const dispatch = useDispatch();
    const { currency, packageId, price } = useSelector(globalState);
    const location = useLocation();
    const { setPrevPath } = usePrevPath();
    const { isAuthenticated, handleSignUpRedirect } = useAuth();
    const enabledFeatures = useGetEnabledFeatures();

    const { locale, search, discover, login, myPodcasts, dashboard, categories } = strings.routes;
    const string = strings.navigation;

    const currentPath = typeof window !== "undefined" ? location.pathname : "";

    const [menuHeight, setMenuHeight] = useState<number>(() => {
        if (typeof window === "undefined") return 0;
        return window.innerHeight + 1;
    });

    const [runAnimation, setRunAnimation] = useState<boolean>(false);
    const [animation, setAnimation] = useState<string>(`${slideIn} 250ms ease-in forwards`);

    const handleLoginRedirect = useCallback(() => {
        setPrevPath(`${location.pathname}`);
        closeMenu();
    }, [locale, location.pathname]);

    const handleClick = useCallback(() => {
        setAnimation(() => `${slideOut} 250ms ease-in forwards`);
        setTimeout(() => {
            closeMenu();
        }, 250);
    }, []);

    const handleSignUpClick = () =>
        handleSignUpRedirect({
            redirectDestination: IdpRedirectOption.SignupPage,
            callbackFunc: () => {
                dispatch(selectItemEventAction("Mobile / Navbar / Create Account button", String(packageId), currency, price));
            },
        });

    useEffect(() => {
        function windowResize() {
            setMenuHeight(window.innerHeight + 1);
        }

        window.addEventListener("resize", windowResize);

        return () => window.removeEventListener("resize", windowResize);
    }, []);

    useEffect(() => {
        setRunAnimation(true);
    }, []);

    return (
        <Box
            position="fixed"
            top={0}
            width="100%"
            height={menuHeight + "px"}
            animation={runAnimation ? animation : undefined}
            zIndex="1410"
            background={PodmeColor.BlackPearl}
        >
            <Flex
                flexDirection="column"
                justifyContent="space-between"
                maxHeight="100vh"
                height="100%"
                maxWidth="100%"
                position="relative"
            >
                <Box>
                    <Flex border={`1px solid ${PodmeColor.Cinder}`} padding="1rem" justifyContent="space-between" alignItems="center">
                        <Box>
                            <Link href={`/${locale}/`} style={{ display: "inline-block" }}>
                                <img
                                    src="/assets/images/brand/podme-logo-white.svg"
                                    alt="Podme logo"
                                    data-testid="slide-menu-podme-logo"
                                    style={{ height: "25px" }}
                                />
                            </Link>
                        </Box>
                        <HamburgerButton data-testid="navbar-menu" isToggled onClick={handleClick} />
                    </Flex>
                    <Box>
                        <Flex flexDirection="column">
                            {isAuthenticated ? (
                                <>
                                    <NavLink
                                        data-testid="slide-menu-discover-link"
                                        onClick={closeMenu}
                                        to={`/${locale}/auth/${discover}`}
                                        activeClassName="active"
                                    >
                                        <Box {...styles.menuItemLink}>
                                            <Text as="span" display="inline-block">
                                                {string.discover}
                                            </Text>
                                        </Box>
                                    </NavLink>
                                    <NavLink
                                        data-testid="slide-menu-my-podcast-link"
                                        onClick={closeMenu}
                                        to={`/${locale}/${myPodcasts}`}
                                        activeClassName="active"
                                    >
                                        <Box {...styles.menuItemLink}>
                                            <Text as="span" display="inline-block">
                                                {string.myPodcasts}
                                            </Text>
                                        </Box>
                                    </NavLink>
                                    <NavLink
                                        data-testid="slide-menu-my-search-link"
                                        onClick={closeMenu}
                                        to={`/${locale}/${search}`}
                                        activeClassName="active"
                                    >
                                        <Box {...styles.menuItemLink}>
                                            <Text as="span" display="inline-block">
                                                {string.search}
                                            </Text>
                                        </Box>
                                    </NavLink>
                                    <NavLink
                                        data-testid="slide-menu-categories-link"
                                        onClick={closeMenu}
                                        to={`/${locale}/${categories}`}
                                        activeClassName="active"
                                    >
                                        <Box {...styles.menuItemLink}>
                                            <Text as="span" display="inline-block">
                                                {string.categories}
                                            </Text>
                                        </Box>
                                    </NavLink>
                                </>
                            ) : (
                                <>
                                    <NavLink
                                        data-testid="slide-menu-my-search-link"
                                        onClick={closeMenu}
                                        to={`/${locale}/${search}`}
                                        activeClassName="active"
                                    >
                                        <Box {...styles.menuItemLink}>
                                            <Text as="span" display="inline-block">
                                                {string.search}
                                            </Text>
                                        </Box>
                                    </NavLink>
                                    <NavLink
                                        data-testid="slide-menu-categories-link"
                                        onClick={closeMenu}
                                        to={`/${locale}/${categories}`}
                                        activeClassName="active"
                                    >
                                        <Box {...styles.menuItemLink}>
                                            <Text as="span" display="inline-block">
                                                {string.categories}
                                            </Text>
                                        </Box>
                                    </NavLink>
                                    <NavLink
                                        data-testid="slide-menu-discover-link"
                                        onClick={closeMenu}
                                        to={`/${locale}/${discover}`}
                                        activeClassName="active"
                                    >
                                        <Box {...styles.menuItemLink}>
                                            <Text as="span" display="inline-block">
                                                {string.discover}
                                            </Text>
                                        </Box>
                                    </NavLink>
                                    {enabledFeatures.data?.isSheerIdEnabled && (
                                        <NavLink
                                            data-testid="slide-menu-student-link"
                                            onClick={closeMenu}
                                            to={STUDENT_BASE_PATH(locale)}
                                            activeClassName="active"
                                        >
                                            <Box {...styles.menuItemLink}>
                                                <Text as="span" display="inline-block">
                                                    {string.student}
                                                </Text>
                                            </Box>
                                        </NavLink>
                                    )}
                                    <CTACheckoutButton
                                        renderAs={Box}
                                        as="button"
                                        textAlign="left"
                                        onClickCallback={() =>
                                            dispatch(
                                                selectItemEventAction(
                                                    "Mobile / Navbar (Account Area) / Get Premium button",
                                                    String(packageId),
                                                    currency,
                                                    price
                                                )
                                            )
                                        }
                                        color={PodmeColor.Cyan}
                                        data-testid="slide-menu-get-premium-button"
                                    >
                                        <Box {...styles.menuItemLink}>
                                            <Text as="span" display="inline-block">
                                                {string.getPremium}
                                            </Text>
                                        </Box>
                                    </CTACheckoutButton>
                                </>
                            )}
                        </Flex>
                    </Box>
                </Box>
                <Flex
                    gridGap={[4]}
                    sx={{
                        "* p": {
                            fontSize: "min(3vh, 1rem)",
                        },
                        "* button": {
                            padding: "1rem",
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        },
                        "* a": {
                            fontSize: "min(3vh, 1rem)",
                            padding: "1rem",
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        },
                    }}
                >
                    {isAuthenticated ? (
                        <Box width="100%">
                            <Flex flexDirection="column" width="100%">
                                <Flex
                                    borderBottom={`1px solid ${PodmeColor.Cinder}`}
                                    data-testid="slide-menu-logged-in-user"
                                    alignItems="center"
                                    width="100%"
                                    padding={"1rem"}
                                    gridGap={4}
                                >
                                    <Flex direction="column" flex="1 0" isTruncated>
                                        <Flex direction="column">
                                            {hasSubscription && <PremiumBadge />}
                                            <Text
                                                data-testid="slide-menu-username"
                                                fontWeight={600}
                                                color={PodmeColor.SoftWhite}
                                                isTruncated
                                            >
                                                {username}
                                            </Text>
                                        </Flex>
                                        <Text fontSize="min(3vh, 0.75rem) !important" color={PodmeColor.Silver}>
                                            {strings.formatString(
                                                string.loggedInWith,
                                                <Box
                                                    data-testid="slide-menu-login-method"
                                                    as="span"
                                                    color={PodmeColor.SoftWhite}
                                                    fontWeight={600}
                                                >
                                                    {loginMethod}
                                                </Box>
                                            )}
                                        </Text>
                                    </Flex>
                                </Flex>
                                {!hasSubscription && (
                                    <Flex
                                        alignItems="center"
                                        justifyContent="space-between"
                                        borderBottom={`1px solid ${PodmeColor.Cinder}`}
                                    >
                                        <CTACheckoutButton
                                            renderAs={Box}
                                            as="button"
                                            data-testid="get-premium-link"
                                            color={PodmeColor.Cyan}
                                            onClickCallback={() =>
                                                dispatch(
                                                    selectItemEventAction(
                                                        "Mobile / Navbar / Get Premium button",
                                                        String(packageId),
                                                        currency,
                                                        price
                                                    )
                                                )
                                            }
                                        >
                                            {string.getPremium}
                                            <MdOutlineArrowForwardIos style={{ fontSize: "1em", fill: PodmeColor.Silver }} />
                                        </CTACheckoutButton>
                                    </Flex>
                                )}
                                <NavLink
                                    to={`/${locale}/${dashboard}`}
                                    data-testid="slide-menu-my-account-link"
                                    onClick={() => closeMenu()}
                                    style={{
                                        borderBottom: `1px solid ${PodmeColor.Cinder}`,
                                    }}
                                >
                                    <span>{string.myAccount}</span>{" "}
                                    <MdOutlineArrowForwardIos style={{ fontSize: "1em", fill: PodmeColor.Silver }} />
                                </NavLink>
                                <Box
                                    borderBottom={`1px solid ${PodmeColor.Cinder}`}
                                    as="button"
                                    data-testid="slide-menu-logout-button"
                                    onClick={() => {
                                        logoutUser();
                                        closeMenu();
                                    }}
                                >
                                    <Text as="span">{string.logout}</Text>
                                    <img src="/assets/images/icons/logout_icon.svg" alt="logout icon" />
                                </Box>
                            </Flex>
                        </Box>
                    ) : (
                        <Box width={"100%"} padding="1rem">
                            <Box display={"grid"} gridTemplateColumns={"1fr"} gridGap={4}>
                                <SecondaryButton
                                    data-testid="slide-menu-login-button"
                                    onClick={handleLoginRedirect}
                                    to={{ pathname: `/${locale}/${login}`, state: { goBackUrl: currentPath } }}
                                    justifyContent="center !important"
                                >
                                    {string.login}
                                </SecondaryButton>
                                <SecondaryButton
                                    data-testid="slide-menu-create-account-button"
                                    onClick={handleSignUpClick}
                                    background={PodmeColor.LicoriceBlue}
                                    color={PodmeColor.SoftWhite}
                                    justifyContent="center !important"
                                >
                                    {string.createAccount}
                                </SecondaryButton>
                            </Box>
                        </Box>
                    )}
                </Flex>
            </Flex>
        </Box>
    );
}
