import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { loadableReady } from "@loadable/component";
import Cookies from "universal-cookie";
import axios from "axios";
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { SoundProvider } from "./containers/SoundProvider";
import configureStore from "./store/configureStore";
import Root from "./containers/Root";
import { UserAgentContext } from "./contexts";
import { RootState } from "./reducers/rootReducer";
import "./config/appInsights";

import { PrivacyProvider } from "./contexts/PrivacyProvider";
import {
    CHECK_AUTH_STATUS,
    IdentityProvider,
    LoginWithIdpSuccess,
    LOGIN_WITH_IDP_SUCCESS,
    UNAUTHORIZED_REQUEST,
    FORBIDDEN_REQUEST,
} from "@auth/authTypes";
import { SET_LOCALE } from "./actions/actionTypes";
import { Region, SetLocale } from "./types";
import GlobalErrorBoundary from "./components/GlobalErrorBoundary/GlobalErrorBoundary";

const cookie = new Cookies();
const state: RootState = window.initialReduxState;
const history = createBrowserHistory();
const { store } = configureStore(history, state);

if (state?.global?.location) {
    cookie.set("region", state.global.location.toUpperCase(), { path: "/" });
} else {
    const pathname = window.location.pathname;
    const locale = pathname.split("/");

    switch (locale[1]) {
        case "no":
            store.dispatch<SetLocale>({
                type: SET_LOCALE,
                location: Region.Norway,
                lang: "nb",
                price: 99,
                packageId: 2,
            });
            cookie.set("region", "NO", { path: "/" });
            break;
        case "fi":
            store.dispatch<SetLocale>({
                type: SET_LOCALE,
                location: Region.Finland,
                lang: "fi",
                price: 9.99,
                packageId: 3,
            });
            cookie.set("region", "FI", { path: "/" });
            break;
        default:
            store.dispatch<SetLocale>({
                type: SET_LOCALE,
                location: Region.Sweden,
                lang: "sv",
                price: 99,
                packageId: 1,
            });
            cookie.set("region", "SE", { path: "/" });
            break;
    }
}

if (cookie.get("jwt-cred")) {
    const parsedTokens = cookie.get("jwt-cred");
    const { accountCreated, email } = parsedTokens;
    const SCHIBSTED_SESSION = "SCHIBSTED_SESSION";
    localStorage.setItem(SCHIBSTED_SESSION, JSON.stringify(parsedTokens));

    store.dispatch<LoginWithIdpSuccess>({
        type: LOGIN_WITH_IDP_SUCCESS,
        provider: IdentityProvider.Schibsted,
        accountCreated: accountCreated,
        username: email,
    });

    cookie.remove("jwt-cred", { path: "/" });
}

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error?.response?.status === 401) {
            store.dispatch({ type: UNAUTHORIZED_REQUEST, context: error });
        }
        if (error?.response?.status === 403) {
            store.dispatch({ type: FORBIDDEN_REQUEST, context: error });
        }
        return Promise.reject(error);
    }
);

window.addEventListener("pageshow", (e: PageTransitionEvent) => {
    if (e.persisted && cookie.get("userIsMigrating")) {
        window.location.reload();
    }
});

const queryClient = new QueryClient();

const App = () => {
    const safeWindow = typeof window !== "undefined" ? window : {};

    if (!store.getState().auth.authChecked) {
        store.dispatch({ type: CHECK_AUTH_STATUS });
    }

    const dehydratedState = window.__REACT_QUERY_STATE__;

    return (
        <GlobalErrorBoundary>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <QueryClientProvider client={queryClient}>
                        <Hydrate state={dehydratedState}>
                            <UserAgentContext.Provider value={navigator.userAgent}>
                                <PrivacyProvider>
                                    <SoundProvider>
                                        <Root windowObj={safeWindow} />
                                    </SoundProvider>
                                </PrivacyProvider>
                            </UserAgentContext.Provider>
                        </Hydrate>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </ConnectedRouter>
            </Provider>
        </GlobalErrorBoundary>
    );
};

loadableReady(() => {
    const root = document.getElementById("react-app");
    const isMarkupPresent = root?.hasChildNodes();

    if (isMarkupPresent) {
        ReactDOM.hydrate(<App />, root);
    } else {
        ReactDOM.render(<App />, root);
    }
});
