import { Box, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { PodmeColor } from "../../types";
import { ReactElement } from "react";

import strings from "@locale/localization";
import { SecondaryButton, TertiaryButton } from "@components/buttons";

interface Props {
    isShown: boolean;
    removeBookmark: (podId: number) => void;
    cancel: () => void;
    [x: string]: any;
}

function ConfirmRemoveBookmarkModal({ isShown, removeBookmark, cancel }: Props): ReactElement | null {
    const string = strings.removeBookmarkModal;

    return isShown ? (
        <Modal onClose={cancel} isOpen={isShown} onOverlayClick={cancel}>
            <ModalOverlay />
            <ModalContent marginTop={"20rem"} backgroundColor={PodmeColor.Cinder} rounded="1.5rem" padding="1.5rem" maxWidth="35rem">
                <ModalBody p="0" display="flex" flexDirection="column" gridGap="2rem">
                    <ModalHeader fontSize={["1rem", "1.5rem"]} p="0">
                        {string.header}
                    </ModalHeader>
                    <Box display="flex" justifyContent="flex-end" gridGap="1rem">
                        <TertiaryButton onClick={cancel}>{string.cancel}</TertiaryButton>
                        <SecondaryButton onClick={removeBookmark}>{string.stopFollow}</SecondaryButton>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    ) : null;
}

export default ConfirmRemoveBookmarkModal;
