/* eslint-disable @typescript-eslint/no-explicit-any */
import { StyleProps } from "@chakra-ui/react";
import { SectionContent } from "@containers/Homescreen/redux/types";
import { SET_EXPERIMENTS, SET_LOCALE } from "app/actions/actionTypes";
import { CSSProperties } from "react";
import { Action } from "redux";

export type ObjectValues<T> = T[keyof T];

//complex Type for nested styles in the 'sx' prop, should be enough to cover most complex css nested selectors.
type SXProp = {
    [x: string]: {
        sx:
            | { [x: string]: StyleProps }
            | { [x: string]: { [x: string]: StyleProps } }
            | { [x: string]: { [x: string]: { [x: string]: StyleProps } } }
            | { [x: string]: { [x: string]: { [x: string]: { [x: string]: StyleProps } } } }
            | { [x: string]: { [x: string]: { [x: string]: { [x: string]: { [x: string]: StyleProps } } } } };
    };
};
//for Chakra UI styles - normal style props plus SX prop type, fallback to 'any' in case some rare CSS properties are used
//that StyleProps interfece does not cover
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CSSstylesObject = { [x: string]: StyleProps } | SXProp | { [x: string]: any };

export const Pages = {
    StartPage: "StartPage",
    DiscoverPage: "DiscoverPage",
    MyAccountPage: "MyAccountPage",
    PodcastPage: "PodcastPage",
    EpisodePage: "EpisodePage",
    HomeScreen: "HomeScreen",
    StudentLandingPage: "StudentLandingPage",
    StudentLandingPageHitract: "StudentLandingPageHitract",
    CategoryPage: "CategoryPage",
} as const;

export type PageType = ObjectValues<typeof Pages>;

export enum DeviceBreakpoints {
    SmallOnly = `only screen and (max-width: 479px)`,
    SmallUp = `only screen and (min-width: 480px)`,
    MediumOnly = `only screen and (min-width: 480px) and (max-width: 1024px)`,
    MediumDown = `only screen and (max-width: 1024px)`,
    MediumUp = `only screen and (min-width: 568px)`,
    LargeUp = `only screen and (min-width: 1025px)`,
    XLarge = `only screen and (min-width: 1750px)`,
}

export enum Package {
    Sweden = 1,
    Norway = 2,
    Finland = 3,
}

export enum Currency {
    Sweden = "SEK",
    Norway = "NOK",
    Finland = "EUR",
}

export enum Region {
    Sweden = "se",
    Norway = "no",
    Finland = "fi",
}

export const REGION_INT = {
    se: 1,
    no: 2,
    fi: 3,
} as const;

export type RegionInt = ObjectValues<typeof REGION_INT>;

export enum SubscriptionState {
    Error = 0,
    Active = 1,
    InTrial = 2,
    InGrace = 3,
    Cancelled = 4,
    OnHold = 5,
    Inactive = 6,
}

export enum SubscriptionType {
    Podcast = 0,
    Package = 1,
    Single = 2,
}

export enum SubscriptionPlatform {
    Adyen = 0,
    Apple = 1,
    Google = 2,
    SchibstedSE = 3,
    SchibstedFI = 4,
    SchibstedNO = 5,
    Bundler = 6,
    VippsMobilePay = 7,
}

export enum PodmeColor {
    //rebranding names
    Platina = "#DFDFDF",
    LightRed = "#F17575",
    Red = "#E76A42",
    AlertRed = "#8f2b34",
    AlertRedBackground = "#C93400",
    Warning = "#FFC83C",
    WarningBright = "#8b7029",
    WarningBackground = "#F1B315",
    MaintenanceYellow = "#fffc75",
    Transparent = "transparent",
    White = "#fbfbfb",
    LicoriceBlue = "#28303D",
    BrightBlue = "#3F4A5C",
    Silver = "#C0C0C0",
    SoftGray = "#f2f2f2",
    SilverTransparent = "rgba(192, 192, 192, 0.10)",
    SilverBorder = "#83868b",
    SoftWhite = "#F5F5F0",
    BlackPearl = "#15191F",
    //used for header
    BlackPearlTransparent = "rgba(21, 25, 31, 0.90)",
    Cinder = "#21252A",
    Cyan = "#75EAF1",
    Sand = "#EFDEC6",
    LightBlue = "#E3F3F4",
    DarkBlue = "#141920",
    Woodsmoke = "#0E1014",
    CheckmarkGreen = "#009B67",
    DarkCyanBlue = "#20252B",
}

export enum MediaQuerySizes {
    Small = "isSmallScreen",
    Medium = "isMediumScreen",
    Large = "isLargeScreen",
    XL = "isXLScreen",
}

export const buttonTheme: { [x: string]: CSSProperties } = {
    dark: {
        color: PodmeColor.White,
        backgroundColor: PodmeColor.LicoriceBlue,
    },
    green: {
        color: PodmeColor.BlackPearl,
        backgroundColor: PodmeColor.Cyan,
    },
    blue: {
        color: PodmeColor.BlackPearl,
        backgroundColor: PodmeColor.Cyan,
    },
    red: {
        color: PodmeColor.White,
        backgroundColor: PodmeColor.Red,
    },
} as const;

export type ButtonTheme = keyof typeof buttonTheme;

export interface AdyenCardDetails {
    firstPspReference: string;
    creationDate: string;
    aliasType: string;
    variant: string;
    recurringDetailReference: string;
    alias: string;
    contractTypes: string[];
    paymentMethodVariant: string;
    additionalData: { cardBin: string };
    card: {
        number: string;
        holderName: string;
        expiryMonth: string;
        expiryYear: string;
    };
}

export interface AdyenStoredPaymentMethod {
    brand: string;
    expiryMonth: string;
    expiryYear: string;
    holderName: string;
    id: string;
    lastFour: string;
    name: string;
    type: string;
    networkTxReference: string;
}

export interface Subscription {
    podcastId: number | null;
    subscriptionState: number;
    subscriptionType: SubscriptionType;
    subscriptionPlatform: SubscriptionPlatform;
    expirationDate: string;
    startDate: string;
    subscriptionPlan: SubscriptionPlan;
    imageUrl: string;
    podcastTitle: string | null;
    willBeRenewed: boolean;
    bundlerPartnerName?: string;
    rewardMonthCredit?: number;
}

export interface ValidationErrorReponse {
    status: number;
    errors: { [x: string]: string[] | null };
    title: string;
    traceId: string;
    type: string;
}

export interface ErrorResponse {
    status: number;
    message: string;
}

export interface SpinnerState {
    showFullpageSpinner: boolean;
    showSignUpSpinner: boolean;
    showDiscoverpageSpinner: boolean;
}

export interface Podcast {
    id: number;
    podcastId?: number;
    title: string;
    podcastTitle?: string;
    highlights: any;
    description: string;
    isPremium: boolean;
    imageUrl: string;
    authorFullName: string;
    hasBookmark?: boolean;
    isBookmarked?: boolean;
    hasSubscription?: boolean;
    categories: Category[];
    subscriptionType: number;
    smallImageUrl: string;
    mediumImageUrl: string;
    slug: string;
    destinationPath?: string;
}

export interface Episode {
    id: number;
    podcastId: number;
    authorFullName: string;
    title: string;
    podcastTitle: string;
    length: string;
    description: string;
    imageUrl: string;
    smallImageUrl: string;
    mediumImageUrl: string;
    streamUrl?: string;
    slug: string;
    podcastSlug?: string;
    currentSpot?: string;
    dateAdded: string;
    isPremium: boolean;
    episodeCanBePlayed: boolean;
    onlyAsPackageSubscription: boolean;
    hasCompleted: boolean;
    episodeData?: Episode;
    totalNoOfEpisodes: number;
}

export interface Category {
    id: number;
    name: string;
    key: string;
}

export type SagaReturnType<T> = Generator<any, T, any>;

export type ActionWithAsyncResult<T> = T & {
    WAIT_FOR_ACTION: string[];
};

export interface SubscriptionPlan {
    name: string;
    packageId: Package;
    priceDecimal: number;
    currency: Currency;
    planGuid: string;
    productId?: string;
    monthLimit?: number;
    nextPlanPriceDecimal?: number;
    nextPlanProductId?: string;
}

export interface SetLocale {
    type: typeof SET_LOCALE;
    location: Region;
    lang: string;
    price?: number;
    packageId?: Package;
}

export interface SetExperiments {
    type: typeof SET_EXPERIMENTS;
    experiments: { id: number; variant: number }[];
}

export interface SearchedPodcast {
    authorFullName: string;
    dateAdded: string;
    highlights: {
        author: string[];
        description: string[];
    };
    imageUrl: string;
    isPremium: boolean;
    podcastId: number;
    podcastTitle: string;
    slug: string;
    types: any;
    wideImageUrl: string;
}
export const SORT_TYPE = {
    NEW: "new",
    OLD: "old",
} as const;

export type SortType = ObjectValues<typeof SORT_TYPE>;

type CategoryEntryMap = Readonly<Record<string, string>>;

export const categoryEntryMap: CategoryEntryMap = {
    "6": "ECONOMY",
    "8": "COMEDY",
    "9": "POLITICS",
    "10": "EDUCATION",
    "11": "MUSIC",
    "12": "RELATIONS",
    "13": "SCIENCE",
    "14": "SOCIETY",
    "16": "CONVERSATION",
    "17": "KIDS",
    "18": "INTERVIEWS",
    "19": "FICTION",
    "20": "DOCUMENTARY",
    "22": "RELIGION",
    "23": "LEISURE",
    "24": "HEALTH",
    "25": "SPORTCATEGORY",
    "26": "HISTORY",
    "27": "TRUECRIME",
    "28": "TECH",
    "222": "PREMIUMCATEGORY",
};
export interface ShowSuccessToastAction extends Action {
    showToastSuccessMessage: boolean;
    message: string;
}

export interface ShowErrorToastAction extends Action {
    showToastErrorMessage: boolean;
    message: string;
}

export const StructuredDataContent = {
    Organization: "Organization",
    BreadcrumbsList: "BreadcrumbList",
    EpisodeList: "EpisodeList",
    PodcastList: "PodcastList",
} as const;

export type StructuredDataContentType = ObjectValues<typeof StructuredDataContent>;

export type EnvoySession = {
    is_on_envoy_flow: boolean;
    envoy_share_link_hash: string;
};

export const ENVOY_SESSION_KEY = "envoySession";
export const SUBFLOW_SESSION_KEY = "subflow";
export const STUDENTFLOW_SESSION_KEY = "studentflow";

export const OFFER_TYPE = {
    STANDARD: 0,
    STUDENT: 1,
} as const;

export type OfferType = ObjectValues<typeof OFFER_TYPE>;

// used for tracking events
export type OfferTypeKey = keyof typeof OFFER_TYPE;

export const SUBSCRIPTION_PLAN_PRODUCT_ID = {
    STUDENT: "student-offer-plan",
    VOUCHER: "coupon-plan",
};

export interface CmsWebPage {
    layoutComponents: SectionContent[];
    region: string;
    slug: string;
    textContent: string;
    title: string;
    type: string;
}

export interface WebPageOptions {
    region: RegionalLocale;
    slug?: string;
    type?: WebPageType;
}

export type RegionalLocale = "sv-SE" | "nb-NO" | "fi-FI";

export const WEB_PAGE_TYPE = {
    PODCAST_PAGE: "PODCAST_PAGE",
    CATEGORIES_PAGE: "CATEGORIES_PAGE",
    CATEGORY_PAGE: "CATEGORY_PAGE",
    DISCOVER_PAGE: "DISCOVER_PAGE",
    FAQ_PAGE: "FAQ_PAGE",
    FOOTER_PAGE: "FOOTER_PAGE",
    LANDING_PAGE: "LANDING_PAGE",
    START_PAGE: "START_PAGE",
    STUDENT_PAGE: "STUDENT_PAGE",
} as const;

export type WebPageType = (typeof WEB_PAGE_TYPE)[keyof typeof WEB_PAGE_TYPE];
