import { memo, ReactElement } from "react";
import { Heading, Flex, Text, AspectRatio, Image, Box } from "@chakra-ui/react";
import { PodmeColor } from "@typings/index";
import { IBannerWithPodcastLink } from "../redux/types";
import { Link } from "react-router-dom";
import strings from "app/locale/localization";

interface Props {
    content: IBannerWithPodcastLink;
}

export function BannerWithPodcastLink({ content }: Props): ReactElement {
    const { hideTitle, title, description, imageUrl, podcastSlug } = content;
    const { locale } = strings.routes;

    const showTitle = !hideTitle && content.title.trim() !== "";
    const showDescription = description.trim() !== "";

    return (
        <Flex
            justifyContent="center"
            position="relative"
            padding={["0 1rem 2rem 1rem", "3rem 2rem", "3rem 0"]}
            maxWidth="70rem"
            margin="0 auto"
            width="100%"
            isolation="isolate"
            _before={{
                content: [undefined, "''"],
                display: "block",
                position: "absolute",
                inset: "-1rem",
                zIndex: "-1",
                backdropFilter: ["none", "blur(0.5rem)"],
                background: [
                    `linear-gradient(90deg, 
                                            rgba(21, 25, 31, 1) 0%, 
                                            rgba(21, 25, 31, 0.95) 15%, 
                                            rgba(21, 25, 31, 0.5) 40%,  
                                            rgba(21, 25, 31, 0.5) 60%, 
                                            rgba(21, 25, 31, 0.95) 85%, 
                                            rgba(21, 25, 31, 1) 100%)`,
                ],
            }}
            _after={{
                content: [undefined, "''"],
                display: "block",
                position: "absolute",
                inset: "-2rem",
                zIndex: "-1",
                backdropFilter: ["none", "blur(0.5rem)"],
                background: [
                    `radial-gradient(circle at 50% -150%, 
                                            rgba(21, 25, 31, 0.5) 0%, 
                                            rgba(21, 25, 31, 0.1) 50%,  
                                            rgba(21, 25, 31, 0.5) 100%)`,
                ],
            }}
        >
            <Box display={["none", "block"]} position="absolute" inset="0" overflow="hidden" backdropBlur="1rem" zIndex="-2">
                <Image
                    src={content.imageUrl}
                    alt={content.title}
                    width="100%"
                    height="100%"
                    objectFit="cover"
                    objectPosition="center"
                />
            </Box>
            <AspectRatio ratio={125 / 48} width="100%" maxWidth="30rem">
                <Link to={`/${locale}/${podcastSlug}`} style={{ display: "block", borderRadius: "1rem" }}>
                    <Flex
                        position="relative"
                        width="100%"
                        height="100%"
                        justifyContent={!showTitle ? "flex-end !important" : "space-between !important"}
                        rounded={["1rem", "1.5rem"]}
                        gridGap="1rem"
                        cursor="pointer"
                        transition="all 100ms ease-in"
                    >
                        <Box position="absolute" zIndex="5" width="100%">
                            <Image src={imageUrl} alt={title} height="100%" width="100%" objectFit="cover" objectPosition="center" />
                        </Box>
                        <Flex
                            zIndex="10"
                            width="100%"
                            height="100%"
                            _hover={{
                                boxShadow: `inset 0 0 10rem 10rem ${PodmeColor.BlackPearl}20`,
                            }}
                        >
                            <Flex
                                padding="1rem"
                                width="100%"
                                alignItems="center"
                                justifyContent={showTitle ? "space-between" : "flex-end"}
                            >
                                {showTitle && (
                                    <Flex direction="column" gridGap="0.5rem" flex="10">
                                        <Heading as="h4" fontSize={["1.125rem", "1.5rem"]} noOfLines={2}>
                                            {content.title}
                                        </Heading>
                                        {showDescription && (
                                            <Text fontSize={["0.875rem", "1rem"]} color={PodmeColor.SoftWhite} noOfLines={2}>
                                                {content.description}
                                            </Text>
                                        )}
                                    </Flex>
                                )}
                                <Box flex="1" maxWidth="fit-content">
                                    <Image src="/assets/images/icons/chevron-right.svg" alt="mover right button" />
                                </Box>
                            </Flex>
                        </Flex>
                    </Flex>
                </Link>
            </AspectRatio>
        </Flex>
    );
}

export default memo(BannerWithPodcastLink);
