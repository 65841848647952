import axios, { Method } from "axios";
import config from "../config";
import cookie from "react-cookies";
import { getLocale } from "@utils/index";
import { Region, RegionInt, WebPageOptions } from "@typings/index";
import { Api } from "./types";

const routes: Api = {
    getPodcast: (slug) => createGetRequest(`podcast/slug-with-autoimport/${slug}`),
    getPodcastById: (id) => createGetRequest(`podcast/${id}`),
    getUserPodcasts: () => createGetRequest("podcast/userpodcasts"),
    getSearchResults: (key) => createGetRequest(`podcast/search?searchText=${key}`),
    getAllCategories: (region: number) => createGetRequest(`cms/categories?region=${region}`),
    getStudentLandingContent: (entity, region) => createGetRequest(`cms/student-page/${entity}?region=${region}`),
    getByPopularityPodcasts: (category = "", podtype = 2, page = 0, pagesize = 12) => {
        if ([1, 2, "1", "2"].includes(category)) {
            if (category === "1" || category === 1) podtype = 1;
            category = "";
        }

        // ugly fix, should be fixed in the api
        if (["intervjuer", "fiktion"].includes(category as string)) {
            category = String(category).charAt(0).toUpperCase() + String(category).slice(1);
        }

        return createGetRequest(`podcast/popular?podcastType=${podtype}&category=${category}&page=${page}&pageSize=${pagesize}`);
    },
    getPodcasts: ({ category = "", podtype, page = 0, pagesize = 12 }: any) => {
        return createGetRequest(`podcast/popular?podcastType=${podtype}&category=${category}&page=${page}&pageSize=${pagesize}`);
    },
    getLatestPodcasts: (podType: number) => createGetRequest(`podcast/latest?podcastType=${podType}`),
    getPopularPremiumSlider: () => createGetRequest(`podcast/popular?podcastType=1&pageSize=12`),
    getPodcastsByCategory: (categoryId: number, pageNumber: number, pageSize: number, region: RegionInt) =>
        createGetRequest(`podcast/category/${categoryId}?page=${pageNumber}&pageSize=${pageSize}&region=${region}`),
    getUserPackagePodcasts: () => createGetRequest(`Podcast/package`),
    getLastPlayedEpisodes: () => createGetRequest(`episode/currentlyplaying`),
    getEpisodes: (slug: string, pageSize: number, page = 0, fetchByOldest = false) => {
        return createGetRequest(`episode/anonymous/slug/${slug}?pageSize=${pageSize}&page=${page}&getbyOldest=${fetchByOldest}`);
    },
    getEpisodesAuth: (slug: string, pageSize: number, page = 0, fetchByOldest = false) => {
        return createGetRequest(`episode/slug/${slug}?pageSize=${pageSize}&page=${page}&getbyOldest=${fetchByOldest}`);
    },
    getEpisode: (id: number) => createGetRequest(`episode/anonymous/${id}`),
    getEpisodeAuth: (id: number) => createGetRequest(`episode/${id}`),
    getLatestEpisodes: () => createGetRequest(`episode/latestpremium`),
    getBookmarkStatus: (id: number) => createGetRequest(`bookmark/${id}`),
    bookmarkPodcast: ({ podcastId }) => createPostRequest(`bookmark/${podcastId}`),
    removeBookmark: ({ podcastId }) => createDeleteRequest(`bookmark/${podcastId}`),
    updateBookmarkPreferences: (data) => createPostRequest("bookmark", data),
    getPremiumSubscriptions: () => createGetRequest(`subscription`),
    renewSubscription: () => {
        return createPutRequest(`subscription/package`);
    },
    cancelSubscription: (id: number) => {
        if (id) return createDeleteRequest(`subscription/podcast/${id}`);

        return createDeleteRequest(`subscription/package`);
    },
    getTrialStatus: () => createGetRequest("subscription/is-trial-eligible"),
    getCardDetails: () => createGetRequest("transaction/paymentmethod"),
    deleteCreditCard: () => createDeleteRequest(`transaction/paymentmethod`),
    getFailedTransactions: (transactionId: number) => createGetRequest(`transaction/failedhistorydetails/${transactionId}`),
    getTransaction: (transactionId: number) => createGetRequest(`transaction/historydetails/${transactionId}`),
    getTransactionCount: () => createGetRequest(`transaction/count`),
    getTransactionHistory: (page = 0, pageSize: number) => createGetRequest(`transaction/history?page=${page}&pageSize=${pageSize}`),

    getDefaultPlan: (region?: Region) => createGetRequest(`subscription/plan`, undefined, undefined, region),

    submitPayment: ({ sessionId, statePaymentMethod, stateBrowserInfo }) => {
        return createPostRequest(
            "adyenpayment/submit-payment",
            JSON.stringify({
                sessionId: sessionId,
                statePaymentMethod: JSON.stringify(statePaymentMethod),
                stateBrowserInfo: JSON.stringify(stateBrowserInfo),
            })
        );
    },
    verifyPaymentResult: ({ sessionId, redirectResult }) => {
        return createPostRequest(
            "adyenpayment/verify-payment-result",
            JSON.stringify({
                sessionId,
                redirectResult,
            })
        );
    },
    submitAdditionalDetails: ({ sessionId, stateData }) => {
        return createPostRequest(
            "adyenpayment/submit-additional-details",
            JSON.stringify({
                sessionId,
                stateData,
            })
        );
    },
    getSession: (sessionId) => createGetRequest(`adyenpayment/get-payment-session?sessionId=${sessionId}`),
    syncDraftAgreementStatus: (encodedAgreementId) =>
        createPostRequest(
            "vippsmobilepay/draft-agreement-status",
            JSON.stringify({
                encodedAgreementId,
            })
        ),
    createSession: (returnUrl: string, intendsToUpdatePaymentMethod?: boolean) => {
        return createPostRequest(
            "adyenpayment/create-payment-session",
            JSON.stringify({
                returnUrl,
                intendsToUpdatePaymentMethod,
            })
        );
    },
    createSessionForStandardOffer(returnUrl) {
        return createPostRequest(
            "adyenpayment/create-payment-session/for-standard-offer",
            JSON.stringify({
                returnUrl,
            })
        );
    },
    createSessionForStudentOffer(returnUrl) {
        return createPostRequest(
            "adyenpayment/create-payment-session/for-student-offer",
            JSON.stringify({
                returnUrl,
            })
        );
    },
    createSessionForPaymentUpdate(returnUrl) {
        return createPostRequest(
            "adyenpayment/create-payment-session/for-payment-method-update",
            JSON.stringify({
                returnUrl,
            })
        );
    },
    createDraftAgreementForStandardOffer(returnUrl, myAccountUrl) {
        return createPostRequest(
            "vippsmobilepay/draft-agreement/for-standard-offer",
            JSON.stringify({
                returnUrl,
                myAccountUrl,
            })
        );
    },
    createDraftAgreementForStudentOffer(returnUrl, myAccountUrl) {
        return createPostRequest(
            "vippsmobilepay/draft-agreement/for-student-offer",
            JSON.stringify({
                returnUrl,
                myAccountUrl,
            })
        );
    },
    applyVoucher: (sessionId, voucherCode) => createPostRequest(`adyenpayment/session/${sessionId}/voucher/${voucherCode}`),
    removeVoucher: (sessionId) => createDeleteRequest(`adyenpayment/session/${sessionId}`),
    applyRewardVoucher: (voucherCode) => createPostRequest(`subscription/voucher/${voucherCode}`),
    startSubscription: (coupon?: string) =>
        createPostRequest(`subscription/start${coupon && coupon !== "undefined" ? `?coupon=${coupon}` : ""}`),

    playerUpdate: (data) => createPostRequest("player/update", data),
    getRegionInfo: () => createGetRequest("region"),
    getStartpageContent: async (locale: Region) => {
        const xVersion = cookie.load("X-Version");
        return axios({
            method: "get",
            url: `${config.BACKEND_API}/cms/startpage`,
            headers: { "X-Region": locale || "SE", "X-Version": xVersion ? xVersion : 1 },
        }).then((response) => response.data);
    },
    getFooterpageContent: async (locale: Region) => {
        const region = cookie.load("region");
        const xVersion = cookie.load("X-Version");
        return axios({
            method: "get",
            url: `${config.CMS_API}/footer-pages?locale=${getLocale(locale)}`,
            headers: { "X-Region": region || "SE", "X-Version": xVersion ? xVersion : 1 },
            withCredentials: true,
        }).then((response) => response.data);
    },
    getWebPage: async (options: WebPageOptions | Record<string, string>) => {
        const region = cookie.load("region");
        const xVersion = cookie.load("X-Version");
        const query = new URLSearchParams(options as Record<string, string>).toString();

        return axios({
            method: "get",
            url: `${config.CMS_API}/web-page?${query}`,
            headers: { "X-Region": region || "SE", "X-Version": xVersion ? xVersion : 1 },
            withCredentials: true,
        }).then((response) => response.data);
    },
    getCmsContent: async (locale: Region, isPreview: boolean) => {
        const region = locale || cookie.load("region");
        const xVersion = cookie.load("X-Version");
        return axios({
            method: "get",
            url: `${config.CMS_API}/content?locale=${getLocale(region)}${isPreview ? "&isPreview=true" : ""}`,
            headers: { "X-Region": region || "SE", "X-Version": xVersion ? xVersion : 1 },
            withCredentials: true,
        }).then((response) => response.data);
    },
    getMigrationModalPrompt: () => createGetRequest("user/api/migration-prompt"),
    getMigrationModalPromptHistoryRecordId: () => createPatchRequest(`user/api/create-migration-prompt-history-record`),
    getMigrationCompletedModalPrompt: (migrationSessionId: string, bearerToken: string) =>
        createGetRequest(`user/api/migrate/${migrationSessionId}`, undefined, bearerToken),
    patchMigrationModalPromptAccept: (migrationSessionId: string) =>
        createPatchRequest(`user/api/user-accepted-migration-prompt/${migrationSessionId}`),
    patchMigrationModalPromptDismiss: (migrationSessionId: string) =>
        createPatchRequest(`user/api/user-cancelled-migration-prompt/${migrationSessionId}`),
    getMigrationDate: async (date: string) => {
        const region = cookie.load("region");
        const xVersion = cookie.load("X-Version");
        return axios({
            method: "post",
            url: `${config.PUBLIC_URL}/migration-date-info`,
            headers: { "X-Region": region || "SE", "X-Version": xVersion ? xVersion : 1 },
            data: { date, region },
            withCredentials: true,
        }).then((response) => response.data);
    },
    getIsFeatureAllowed: async (userEmail: string) => {
        const region = cookie.load("region");
        const xVersion = cookie.load("X-Version");
        return axios({
            method: "get",
            url: `${config.PUBLIC_URL}/util/is-feature-allowed?userEmail=${userEmail}`,
            headers: { "X-Region": region || "SE", "X-Version": xVersion ? xVersion : 1 },
            withCredentials: true,
        }).then((response) => response.data);
    },
    getEnabledFeatures: (region?: Region) => createGetRequest("feature", undefined, undefined, region),
    getUserPrivacySettings: () => createGetRequest(`${process.env.BACKEND_API_WITHOUT_VERSION}/user-account/privacy-settings`),
    postCookieConsent: ({ hasGivenConsentToMarketingCookies, hasGivenConsentToAnalyticsCookies }) => {
        return createPostRequest(
            "user/cookies-consent",
            JSON.stringify({
                hasGivenConsentToMarketingCookies,
                hasGivenConsentToAnalyticsCookies,
            })
        );
    },
    startVerification: () => createPostRequest(`user-segment/start-verification`),
    notifyOfVerificationResult: (data) => createPostRequest("user-segment/notify-of-verification-result", data),
    getUserSegments: () => createGetRequest(`user-segment`),
    postEnvoyEvent: (data) => createPostRequest("gift/tracking-event", data),
    getOffers: (region?: Region) => createGetRequest("offer", undefined, undefined, region),
    getOffersContent: async (region?: Region) => {
        const regionValue = region || cookie.load("region")?.toLowerCase();
        const xVersion = cookie.load("X-Version");
        return axios({
            method: "get",
            url: `${config.CMS_API}/pricing?locale=${getLocale(regionValue)}`,
            headers: { "X-Region": regionValue || "SE", "X-Version": xVersion ? xVersion : 1 },
            withCredentials: true,
        }).then((response) => response.data);
    },
};

export function createRequest<T extends Method>(method: T) {
    return async (
        url: string,
        data?: unknown,
        bearerToken?: string,
        region?: Region,
        headers?: Record<string, string>
    ): Promise<any> => {
        const token = cookie.load("auth");
        const xVersion = cookie.load("X-Version");
        const migrationSessionId = cookie.load("migrationSessionId");
        const userIsMigrating = cookie.load("userIsMigrating");
        region = region ? region : cookie.load("region");

        headers = {
            ...headers,
            "X-Region": region || "SE",
            "X-Version": xVersion ? xVersion : 1,
            "content-type": "application/json",
        };

        if (migrationSessionId && userIsMigrating) {
            headers = { ...headers, "X-InMigrationProcess": migrationSessionId };
        }

        if (token && !bearerToken) {
            headers = { ...headers, Authorization: `Bearer ${token}` };
        } else {
            headers = { ...headers, Authorization: `Bearer ${bearerToken}` };
        }

        return axios({
            method,
            url: /^http/.test(url) ? url : `${config.BACKEND_API}/${url}`,
            headers,
            data,
        }).then((response) => (response ? response.data : null));
    };
}

export const createGetRequest = createRequest("get");
export const createPostRequest = createRequest("post");
export const createDeleteRequest = createRequest("delete");
export const createPutRequest = createRequest("put");
export const createPatchRequest = createRequest("patch");

export default routes;
