import { useState, useEffect, ChangeEvent, ReactElement, MutableRefObject } from "react";
import { Box, Flex, Input } from "@chakra-ui/react";
import { PodmeColor } from "../../types";
import { styles } from "./inputField.styles";

interface Props {
    name: string;
    value: string | undefined;
    error?: string;
    type: string;
    label: string;
    handleChange: (evt: ChangeEvent<HTMLInputElement>) => void;
    handleBlur: (evt: ChangeEvent<HTMLInputElement>) => void;
    minLength?: number;
    maxLength?: number;
    inputRef?: MutableRefObject<HTMLInputElement | null>;
    style?: any;
    isSuccessful?: boolean;
    showErrorText?: boolean;
    autoComplete?: string;
    [x: string]: any;
}

function InputField({
    name,
    value,
    error,
    type,
    label,
    handleChange,
    handleBlur,
    minLength,
    maxLength,
    inputRef,
    isSuccessful,
    autoComplete,
    showErrorText = true,
    ...rest
}: Props): ReactElement {
    const [showSuccessState, setSuccessState] = useState<boolean>(false);

    useEffect(() => {
        const hasValue = value && value.length > 0;
        setSuccessState(Boolean(isSuccessful && hasValue));
    }, [value, isSuccessful, showSuccessState]);

    function sanitizeInput(val: string) {
        let modVal = val && type !== "text" ? val.trim() : val;

        if (type === "number" || type === "tel") {
            modVal = modVal.replace(/\D/, "");
        }

        if (maxLength) {
            modVal = modVal.slice(0, maxLength);
        }

        return modVal || "";
    }

    return (
        <Flex {...styles.inputFieldWrapper} data-testid="input-field-wrapper" flexDir="column" width="100%">
            <Box
                {...styles.inputField}
                backgroundColor={PodmeColor.SoftWhite}
                color={PodmeColor.BlackPearl}
                border={showSuccessState ? `3px solid${PodmeColor.SoftWhite}` : "none"}
                {...rest}
            >
                <Input
                    {...styles.inputFieldInput}
                    {...(error && styles.error)}
                    autoCorrect="on"
                    autoComplete={autoComplete}
                    dir="auto"
                    className="fs-block"
                    type={type}
                    name={name}
                    value={value}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                        evt.currentTarget.setAttribute("value", sanitizeInput(evt.currentTarget.value));

                        handleChange(evt);
                    }}
                    _focus={{
                        boxShadow: "none",
                        border: "none !important",
                    }}
                    onBlur={handleBlur}
                    minLength={minLength}
                    maxLength={maxLength}
                    ref={inputRef}
                    disabled={isSuccessful}
                />
                <Box {...styles.inputFieldLabel} as={"label"} htmlFor={name} color={PodmeColor.Silver}>
                    {label}
                </Box>
            </Box>
            {error && showErrorText && (
                <Box {...styles.errorText} data-testid="error-text" as={"span"}>
                    {error}
                </Box>
            )}
        </Flex>
    );
}

export default InputField;
