import { Region } from "@typings/index";
import { GetContent, GetPreviewContent, GET_CONTENT, GET_PREVIEW_CONTENT } from "./types";

export const getContent = (): GetContent => ({
    type: GET_CONTENT,
});

export const getPreviewContent = (region: Region, hasSubscription: boolean): GetPreviewContent => ({
    type: GET_PREVIEW_CONTENT,
    region,
    hasSubscription,
});
