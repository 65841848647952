import { ReactElement } from "react";
import { Text, Button, Flex, Image, Box } from "@chakra-ui/react";
import DeleteDialog from "../DeleteDialog/DeleteDialog";

import strings from "@locale/localization";
import { PodmeColor } from "../../types";

interface Props {
    name: string;
    brand: string;
    lastFour: string;
    editCardDetails: () => void;
    removeCardDetails?: () => void;
    isLoading: boolean;
    [x: string]: any;
}

export default function CardSummary({ name, brand, lastFour, editCardDetails, removeCardDetails, isLoading }: Props): ReactElement {
    const string = strings.settingsPage;
    const showChangeToCard = name.includes("PlaceHolder") && removeCardDetails;
    const editButtonText = removeCardDetails
        ? showChangeToCard
            ? string.paymentMethod.changeToCard
            : string.paymentMethod.changeCard
        : string.paymentMethod.edit;

    return (
        <Flex direction="column" gridGap="1rem">
            <Flex
                flexDirection="column"
                gridGap={4}
                alignItems="flex-start"
                backgroundColor={PodmeColor.White}
                borderRadius="1.5rem"
                padding={"unset"}
            >
                <Flex
                    flexDirection={["column", "row"]}
                    padding="1rem 1.5rem"
                    justifyContent="space-between"
                    alignItems={["flex-start", "center"]}
                    backgroundColor={PodmeColor.DarkCyanBlue}
                    borderRadius="1rem"
                    boxShadow="-2px -2px 15px 4px #161B2133, 3px 3px 20px 0px #0000001A, 2px 2px 2px -2px #FFFFFF1A inset, -1px -1px 2px -1px #0000001A inset"
                    width="100%"
                    gridGap={[4]}
                >
                    <Flex direction="row" gridGap={4}>
                        <Flex direction="row">
                            <Box alignContent="center" background={PodmeColor.White} borderRadius={3} padding={1} margin="10px 0">
                                <Image maxWidth={8} src={`/assets/images/brand/${brand.toLowerCase()}.svg`} alt={`${brand}`} />
                            </Box>
                        </Flex>
                        <Flex direction="column">
                            <Text data-testid="stored-card-brand-name" fontWeight="600" color={PodmeColor.SoftWhite}>
                                {brand}
                            </Text>
                            <Text data-testid="stored-card-number" color={PodmeColor.Silver}>
                                {`${string.paymentMethod.endsWith} **** ${lastFour}`}
                            </Text>
                        </Flex>
                    </Flex>
                    <Button
                        disabled={isLoading}
                        variant="link"
                        color={PodmeColor.SoftWhite}
                        fontWeight="400"
                        onClick={editCardDetails}
                        textDecoration="underline"
                        data-testid="edit-card-button"
                    >
                        {editButtonText}?
                    </Button>
                </Flex>
            </Flex>
            {removeCardDetails && (
                <Flex>
                    <DeleteDialog
                        buttonText={`${string.paymentMethod.removePaymentMethod}?`}
                        buttonType="link"
                        deleteCta={string.paymentMethod.confirm}
                        cancel={string.paymentMethod.cancel}
                        title={string.paymentMethod.removePaymentMethod}
                        message={string.paymentMethod.removePaymentMethodConfirmationMessage}
                        deleteAction={removeCardDetails}
                    />
                </Flex>
            )}
        </Flex>
    );
}
