export const GET_EPISODE = "globalPlayer/GET_EPISODE";
export const GET_EPISODE_SUCCESS = "globalPlayer/GET_EPISODE_SUCCESS";
export const GET_EPISODE_FAILED = "globalPlayer/GET_EPISODE_FAILED";
export const SHOW_EPISODE_MODAL = "globalPlayer/SHOW_EPISODE_MODAL";
export const TRACK_PLAYBACK = "globalPlayer/TRACK_PLAYBACK";
export const TRACK_PLAYBACK_SUCCESS = "globalPlayer/TRACK_PLAYBACK_SUCCESS";
export const TRACK_PLAYBACK_FAILED = "globalPlayer/TRACK_PLAYBACK_FAILED";
export const LOAD_PLAYER_MEDIA = "globalPlayer/LOAD_PLAYER_MEDIA";
export const PLAY_INTERRUPTED_OFFLINE = "globalPlayer/PLAY_INTERRUPTED_OFFLINE";

export interface PlayObject {
    id: number;
    title: string;
    slug: string;
    podcastTitle: string;
    isPremium: boolean;
    imageUrl: string;
    streamUrl: string | undefined;
    length: string;
    currentSpot?: string;
}

export interface GetEpisode {
    type: typeof GET_EPISODE;
    episodeId: number;
}

export interface GetEpisodeSuccess {
    type: typeof GET_EPISODE_SUCCESS;
    playObject: PlayObject;
}

export interface GetEpisodeFailed {
    type: typeof GET_EPISODE_FAILED;
    error: any;
}

export interface TrackPlayback {
    type: typeof TRACK_PLAYBACK;
    episodeId: number;
    currentSpot: string;
    hasCompleted: boolean;
}

export interface TrackPlaybackSuccess {
    type: typeof TRACK_PLAYBACK_SUCCESS;
}

export interface TrackPlaybackFailed {
    type: typeof TRACK_PLAYBACK_FAILED;
    error: any;
}

export interface LoadPlayerMedia {
    type: typeof LOAD_PLAYER_MEDIA;
    playObject: PlayObject;
}

export interface GlobalPlayerState {
    playObject: PlayObject | null;
    playbackUpdated: boolean;
}

export type GlobalPlayerAction =
    | GetEpisode
    | GetEpisodeSuccess
    | GetEpisodeFailed
    | TrackPlayback
    | TrackPlaybackSuccess
    | TrackPlaybackFailed
    | LoadPlayerMedia;
