import { ReactElement } from "react";

import { Flex, Heading, Image, Modal, ModalBody, ModalContent, ModalOverlay, Text } from "@chakra-ui/react";
import { PodmeColor } from "@typings/index";
import strings from "app/locale/localization";

interface Props {
    isOpen: boolean;
    closeWindow: () => void;
}

function VoucherInfoModal({ isOpen, closeWindow }: Props): ReactElement {
    const string = strings.settingsPage.userSubscriptions;

    return (
        <Modal isOpen={isOpen} onClose={closeWindow}>
            <ModalOverlay background={PodmeColor.Woodsmoke} opacity="0.65 !important" />
            <ModalContent maxWidth="38.5rem" bgColor={PodmeColor.BlackPearl} rounded="1.5rem" position="relative">
                <ModalBody p={0}>
                    <Flex direction="column" gridGap="2rem">
                        <Image
                            src="/assets/images/icons/close-16x16.svg"
                            onClick={closeWindow}
                            width="24px"
                            position="absolute"
                            top={["1rem", "1.5rem"]}
                            right={["1rem", "1.5rem"]}
                            opacity="50%"
                            cursor="pointer"
                            transition="all 100ms ease-in"
                            _hover={{
                                opacity: "100%",
                            }}
                        />

                        <Flex direction="column" gridGap="2rem">
                            <Flex direction="column" gridGap="1.5rem" padding="3rem 2rem">
                                <Heading
                                    as="h1"
                                    lineHeight="2em"
                                    fontWeight="500"
                                    fontSize={["1rem", "1.5rem"]}
                                    color={PodmeColor.SoftWhite}
                                >
                                    {string.vouchers}
                                </Heading>
                                <Flex direction="column">
                                    <Heading as="h2" fontWeight="500" fontSize={["0.75rem", "1rem"]}>
                                        {string.applyingAVoucher}
                                    </Heading>
                                    <Text>{string.applyingAVoucherLegal}</Text>
                                </Flex>
                                <Flex direction="column">
                                    <Heading as="h2" fontWeight="500" fontSize={["0.75rem", "1rem"]}>
                                        {string.activationTiming}
                                    </Heading>
                                    <Text>{string.activationTimingLegal}</Text>
                                </Flex>
                                <Flex direction="column">
                                    <Heading as="h2" fontWeight="500" fontSize={["0.75rem", "1rem"]}>
                                        {string.combiningVouchers}
                                    </Heading>
                                    <Text>{string.combiningVouchersLegal}</Text>
                                </Flex>
                            </Flex>
                            <Flex
                                minHeight={"4rem"}
                                gap="0.5rem"
                                alignContent={"center"}
                                justifyContent={"center"}
                                flexWrap={"wrap"}
                                background={PodmeColor.Cinder}
                                borderBottomLeftRadius="1.5rem"
                                borderBottomRightRadius="1.5rem"
                            >
                                <Text color={PodmeColor.Silver}>{string.needHelp}</Text>
                                <Text textDecoration={"underline"}>{string.contactUs}</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default VoucherInfoModal;
