import { useState, useEffect, useCallback, useMemo, ChangeEvent, ReactElement } from "react";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { Box, Flex } from "@chakra-ui/react";

import useAuth from "../../auth/useAuth";

import { useSound } from "../../containers/SoundProvider";
import { GET_EPISODE, GetEpisode } from "./redux/GlobalPlayerTypes";

import useTrackPlayback from "./hooks/useTrackPlayBack";
import useSetMediaData from "./hooks/useSetMediaData";
import MobilePlayer from "./components/MobilePlayer/MobilePlayer";
import DesktopPlayer from "./components/DesktopPlayer/DesktopPlayer";
import { useDeviceOnline, useMedia } from "../../hooks";
import { timespanEightChars, playerTimeToSeconds } from "@utils/index";
import { PodmeColor } from "@typings/index";
import { playInterruptedOffline } from "./redux/GlobalPlayerActions";
import { MarkEpisodeCompletion, MARK_EPISODE_COMPLETION } from "@containers/PodcastPage/redux/types";
import { addToCartEventAction } from "app/tracking/trackingActions";
import usePlayerState from "app/hooks/usePlayerState";

const STORED_LAST_PLAYED_EPISODE_KEY = "lastPlayedEpisode";

function GlobalPlayer(): ReactElement | null {
    const dispatch = useDispatch();
    const { isSmallScreen } = useMedia();
    const isOnline = useDeviceOnline();
    const { isAuthenticated, hasActiveSubscription, showPremiumModal } = useAuth();
    const { state: sound, load, play, pause, setPlayPosition, skipBackwards, skipForwards, setVolume, toggleMuted } = useSound();
    const { playObject } = usePlayerState();
    const [userHasInteracted, setUserHasInteracted] = useState<boolean>(false);
    const hasAccess = useMemo(
        () => (hasActiveSubscription || (playObject && !playObject.isPremium) ? true : false),
        [hasActiveSubscription, playObject, isAuthenticated]
    );

    useSetMediaData(playObject);
    useTrackPlayback(
        playObject?.id,
        sound.currentTime,
        sound.isPlaying,
        sound.isPlayed,
        sound.currentAudio?.duration,
        isAuthenticated
    );

    // gets episode data if missing
    useEffect(() => {
        if (!isOnline) return;

        const currentAudioId = sound.currentAudioId && sound.currentAudioId.length > 4 ? Number(sound.currentAudioId) : undefined;
        const playObjectId = playObject?.id;

        if (currentAudioId && (!playObjectId || playObjectId !== currentAudioId)) {
            dispatch<GetEpisode>({ type: GET_EPISODE, episodeId: currentAudioId });
        }
    }, [sound.currentAudioId, playObject, isOnline]);

    // load the audio automatically - requires user interaction
    useEffect(() => {
        if (!isOnline || !userHasInteracted) return;

        if (playObject && playObject.streamUrl && Number(sound.currentAudioId) !== playObject.id) {
            load(String(playObject.id), playObject.streamUrl, playerTimeToSeconds(playObject.currentSpot));
        }
    }, [isOnline, playObject, userHasInteracted]);

    // storing current episode in localstorage
    useEffect(() => {
        if (!playObject || !sound.isPlaying) return;

        localStorage.setItem(
            STORED_LAST_PLAYED_EPISODE_KEY,
            JSON.stringify({ ...playObject, currentSpot: timespanEightChars(String(sound.currentTime)) })
        );
    }, [playObject, sound.isPlaying, sound.currentTime]);

    useEffect(() => {
        if (!isOnline) {
            if (sound.isPlaying) dispatch(playInterruptedOffline());

            pause();
        }
    }, [isOnline]);

    useEffect(() => {
        if (sound.currentAudioId) {
            if (sound.isPlaying) {
                dispatch<MarkEpisodeCompletion>({ type: MARK_EPISODE_COMPLETION, episodeId: sound.currentAudioId, completed: false });
            } else if (!sound.isPlaying && sound.currentAudio?.ended) {
                dispatch<MarkEpisodeCompletion>({ type: MARK_EPISODE_COMPLETION, episodeId: sound.currentAudioId, completed: true });
            }
        }
    }, [sound.isPlaying]);

    const togglePlay = useCallback(
        (evt: ChangeEvent<HTMLButtonElement>) => {
            setUserHasInteracted(true);

            if (!isOnline) return;

            if (hasAccess && playObject && playObject.streamUrl) {
                evt.preventDefault();
                load(String(playObject.id), playObject.streamUrl, playerTimeToSeconds(playObject.currentSpot));

                if (sound.isPlaying) {
                    pause();
                } else {
                    play(String(playObject.id));

                    dispatch(
                        addToCartEventAction(
                            String(playObject.id),
                            playObject.title,
                            playObject.podcastTitle,
                            hasActiveSubscription ?? false,
                            isAuthenticated
                        )
                    );
                }
            } else if (!hasAccess) {
                evt.preventDefault();

                showPremiumModal(true);
            }
        },
        [sound.isPlaying, hasAccess, playObject, isOnline]
    );

    const seek = useCallback(
        (newPosition: number) => {
            setUserHasInteracted(true);

            if (!isOnline) return;

            if (hasAccess && playObject) {
                setPlayPosition(newPosition);
            } else if (!hasAccess) {
                showPremiumModal(true);
            }
        },
        [hasAccess, playObject, isOnline]
    );

    if (!playObject) return null;

    return (
        <Box position="relative">
            <Flex
                as={motion.div}
                justifyContent="center"
                alignItems="center"
                direction="column"
                zIndex={isSmallScreen ? "998" : "1000"}
                backgroundColor={isSmallScreen ? "unset" : PodmeColor.Woodsmoke}
                color={PodmeColor.White}
                width="100%"
                borderTop={isSmallScreen ? "unset" : `1px solid ${PodmeColor.Cinder}`}
            >
                {isSmallScreen ? (
                    <MobilePlayer
                        playObject={playObject}
                        sound={sound}
                        skipBackwards={skipBackwards}
                        skipForwards={skipForwards}
                        togglePlay={togglePlay}
                        setPlayPosition={seek}
                        data-testid="global-player"
                    />
                ) : (
                    <DesktopPlayer
                        playObject={playObject}
                        sound={sound}
                        skipBackwards={skipBackwards}
                        skipForwards={skipForwards}
                        setVolume={setVolume}
                        setPlayPosition={seek}
                        togglePlay={togglePlay}
                        toggleMuted={toggleMuted}
                        data-testid="global-player"
                    />
                )}
            </Flex>
        </Box>
    );
}

export default GlobalPlayer;
