import { ReactElement } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { PodmeColor } from "../types/index";

interface Props {
    message: string;
    isSuccess: boolean;
    onClose: () => void;
    bg?: string;
    color?: string;
}

export default function ToastMessage({ message, isSuccess, onClose, bg, color }: Props): ReactElement {
    return (
        <Flex
            minWidth="345px"
            maxWidth="90%"
            alignItems="center"
            justifyContent="space-between"
            color={color || PodmeColor.BlackPearl}
            padding={3}
            bg={bg || PodmeColor.White}
            borderRadius="8px"
        >
            <img
                width="20px"
                height="20px"
                src={`/assets/images/icons/checkmark_${isSuccess ? "success" : "failure"}.svg`}
                alt="checkmark"
            />
            <Text fontSize="1em" paddingLeft={3} paddingRight={3} width="100%">
                {message}
            </Text>
            <button onClick={onClose}>
                <img width="20px" height="20px" src="/assets/images/icons/close_icon.svg" alt="checkmark" />
            </button>
        </Flex>
    );
}
