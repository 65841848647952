import { ReactElement } from "react";
import { AspectRatio, Box, Grid, GridItem } from "@chakra-ui/react";
import Category from "./components/Category";
import { ExplorerPodcastCategory } from "app/content/contentTypes";
import { useMedia } from "../../../app/hooks";
import Skeleton from "@components/Skeleton";

interface Props {
    categories: ExplorerPodcastCategory[];
    showLimitedAmount?: boolean; //for startpage we show limited amount of categories
    onLoad: () => void;
    [x: string]: any;
}

function CategoryGrid({ categories, onLoad, showLimitedAmount }: Props): ReactElement {
    const { isLargeScreen } = useMedia();
    const MOBILE_LIMIT_OF_CATEGORIES = 8;
    const DESKTOP_LIMIT_OF_CATEGORIES = 12;
    const limitOfCategories = isLargeScreen ? DESKTOP_LIMIT_OF_CATEGORIES : MOBILE_LIMIT_OF_CATEGORIES;
    const categoriesToDisplay = !showLimitedAmount ? categories : categories.slice(0, limitOfCategories);

    return (
        <Grid
            templateColumns={[
                "repeat(auto-fill, minmax(calc(50% - 2rem), 1fr))",
                "repeat(auto-fill, minmax(195px, 1fr))",
                null,
                "repeat(6, minmax(100px, 1fr))",
            ]}
            gap={["0.5rem", "1rem"]}
            maxWidth="100%"
        >
            {categoriesToDisplay && categoriesToDisplay.length > 0
                ? categoriesToDisplay.map((category, index) => (
                      <AspectRatio ratio={16 / 10} key={index}>
                          <GridItem width="100%" textDecoration="none" display="inline-block" position="relative">
                              <Category category={category} onLoad={onLoad} />
                          </GridItem>
                      </AspectRatio>
                  ))
                : Array.from({ length: showLimitedAmount ? limitOfCategories : 21 }).map((_, index) => (
                      <AspectRatio ratio={16 / 10} key={index}>
                          <GridItem width="100%" textDecoration="none" display="inline-block" position="relative">
                              <Skeleton width="100%" height="100%" borderRadius="0.5rem">
                                  <Box />
                              </Skeleton>
                          </GridItem>
                      </AspectRatio>
                  ))}
        </Grid>
    );
}
export default CategoryGrid;
