import { ReactElement } from "react";
import { useLocation } from "react-router";
import { Box, Flex } from "@chakra-ui/react";

import strings from "@locale/localization";
import Footer, { FooterBottomBanner } from "@components/Footer/Footer";

import SimpleNavigation from "../../components/SimpleNavigation/SimpleNavigation";
import useAuth from "../../auth/useAuth";
import OnboardingModal from "../OnboardingModal/OnboardingModal";

import { useDeviceOnline } from "../../hooks";
import GlobalPlayer from "@containers/GlobalPlayer/GlobalPlayer";
import MainNavigation from "@components/MainNavigation";
import ErrorBanner from "@components/ErrorBanner/ErrorBanner";
import { useToastMessage } from "../../hooks";
import FullpageSpinner from "@components/FullpageSpinner/FullpageSpinner";
import usePlayerState from "app/hooks/usePlayerState";
import { STUDENT_BASE_PATH } from "@containers/Student/constants";
import { isIOS } from "react-device-detect";
import SchibstedDataControllerBanner from "@components/MainNavigation/components/SchibstedDataControllerBanner";

interface Props {
    children: ReactElement;
}

export default function Layout({ children }: Props): ReactElement {
    const location = useLocation();
    const { isPremiumModalShown, dismissPremiumModal, isFetchingUserData } = useAuth();
    const isOnline = useDeviceOnline();
    const { playObject } = usePlayerState();
    useToastMessage();
    const currentPath = location.pathname;
    const { buy, locale, signup, login, iosFaq } = strings.routes;
    const isOnIosFaqPage = currentPath.includes(iosFaq) && isIOS;

    let NavComponent: ReactElement;

    const studentBasePathWithoutRegion = STUDENT_BASE_PATH(locale).replace(`/${locale}/`, "");
    switch (true) {
        case currentPath === `/${locale}/`:
            NavComponent = <MainNavigation data-testid="main-navigation" />;

            break;
        case isMatchingPath([
            login,
            signup,
            `${signup}/checkout`,
            `${studentBasePathWithoutRegion}/checkout`,
            `${studentBasePathWithoutRegion}/verify`,
            "partner",
            "premium",
            "login-app",
            "login-app-flow",
        ]):
            NavComponent = <SimpleNavigation data-testid="simple-navigation" />;
            break;

        default:
            NavComponent = <MainNavigation data-testid="main-navigation" />;
    }

    function isMatchingPath(paths: string[]) {
        const segments = currentPath.replace(`/${locale}/`, "").split("/");

        return paths.some((path) => {
            const pathSegments = path.split("/");
            return JSON.stringify(segments) === JSON.stringify(pathSegments);
        });
    }

    return (
        <>
            <Box data-testid="layout" position="relative">
                {!isOnIosFaqPage && NavComponent}
                <Box>
                    <Box minHeight={"100vh"} gridColumn="1 / -1" position="relative" overflow="hidden" zIndex="1">
                        {children}
                    </Box>
                    <Box gridColumn="1 / -1" paddingBottom={playObject ? "65px" : "0"}>
                        {!isOnIosFaqPage && <Footer />}
                        <FooterBottomBanner />
                        <SchibstedDataControllerBanner variant="bottom" />
                    </Box>
                    <OnboardingModal
                        isOpen={isPremiumModalShown}
                        onClose={dismissPremiumModal}
                        isCloseable={isMatchingPath([buy]) ? false : true}
                    />
                    {isFetchingUserData && <FullpageSpinner isShown />}
                </Box>
            </Box>
            <Flex position="fixed" bottom="0" width="100%" direction="column" zIndex="1400">
                <GlobalPlayer />
                {!isOnline && <ErrorBanner />}
            </Flex>
        </>
    );
}
