import { ReactElement } from "react";
import { Grid } from "@chakra-ui/react";

interface Props {
    rowGap?: number;
    children: ReactElement | ReactElement[];
    [x: string]: any;
}

export default function GridContainer({ children, rowGap, ...rest }: Props): ReactElement {
    return (
        <Grid
            height='100%'
            templateColumns={`repeat(12, 1fr)`}
            templateRows={`repeat(auto-fill, minmax(4.5rem, 1fr))`}
            gridGap='1rem'
            gridRowGap={rowGap ?? 0}
            justifyContent='center'
            {...rest}
        >
            {children}
        </Grid>
    );
}
