import { call, delay, put, PutEffect, select, takeLatest } from "redux-saga/effects";
import Cookies from "universal-cookie";
import axios from "axios";
import appInsights from "../config/appInsights";
import msalInstance, { scopes } from "../config/msal-init";
import { identity as schibstedIdentity, init as initSchibsted } from "../config/schibsted-init";
import strings from "@locale/localization";

import {
    CHECK_AUTH_STATUS,
    CHECK_AUTH_STATUS_FAILED,
    CHECK_AUTH_STATUS_SUCCESS,
    CheckAuthStatusFailed,
    CheckAuthStatusSuccess,
    GET_LOGIN_WIDGET,
    GET_LOGIN_WIDGET_FAILED,
    GET_LOGIN_WIDGET_SUCCESS,
    GET_USER_DATA,
    GET_USER_DATA_FAILED,
    GET_USER_DATA_SUCCESS,
    GetLoginWidgetFailed,
    GetLoginWidgetSuccess,
    IdentityAuthority,
    IdentityProvider,
    IdpRedirectOption,
    LOGIN_USER,
    LOGIN_USER_FAILED,
    LOGIN_USER_SUCCESS,
    LOGIN_WITH_IDP,
    LOGIN_WITH_IDP_FAILED,
    LOGIN_WITH_IDP_SUCCESS,
    LoginUser,
    LoginUserFailed,
    LoginUserResponse as AuthResponse,
    LoginUserResponse,
    LoginUserSuccess,
    LoginWithIdp,
    LoginWithIdpFailed,
    LoginWithIdpSuccess,
    LOGOUT_USER,
    LOGOUT_USER_FAILED,
    LOGOUT_USER_WITH_CUSTOM_REDIRECT,
    LOGOUT_USER_WITH_CUSTOM_REDIRECT_SUCCESS,
    LOGOUT_USER_WITH_ERROR,
    LOGOUT_USER_WITH_ERROR_FAILED,
    LOGOUT_USER_WITH_ERROR_SUCCESS,
    LogoutUserFailed,
    LogoutUserSuccess,
    LogoutUserWithCustomRedirect,
    LogoutUserWithCustomRedirectSuccess,
    LogoutUserWithErrorFailed,
    LogoutUserWithErrorSuccess,
    REFRESH_TOKEN,
    REFRESH_TOKEN_FAILED,
    REFRESH_TOKEN_SUCCESS,
    RefreshToken,
    RESET_PASSWORD,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_SUCCESS,
    ResetPasswordFailed,
    ResetPasswordSuccess,
    SchibstedSession,
    SessionStorage,
    UPDATE_USER_CONSENT,
    GET_USER_PRIVACY_SETTINGS,
    GET_USER_PRIVACY_SETTINGS_SUCCESS,
    GET_USER_PRIVACY_SETTINGS_FAILED,
    UserPrivacySettingsResponse,
} from "./authTypes";
import routes, { createPostRequest } from "../api";
import { encryptPassword, getLocalizedErrorMessage, isOnSubflow, uid } from "../utils";
import config from "../config";
import { RootState } from "../reducers/rootReducer";
import { SagaReturnType } from "../types";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import {
    ACCEPT_MIGRATION_MODAL_PROMPT_SUCCESS,
    TRIGGER_MIGRATION_FROM_MOBILE,
    TriggerMigrationFromMobile,
} from "@containers/Migration/redux/types";
import jwtDecode from "jwt-decode";
import { AzureErrorCode, getErrorMessageByAzureCode } from "@api/utils";
import { ConsentCategory } from "app/contexts/PrivacyProvider";
import { LAST_SEEN_LOGIN_WIDGET_COOKIE } from "app/hooks/useLoginWidget";
import { UPDATE_CONSENT_ANALYTICS, UPDATE_CONSENT_MARKETING } from "app/tracking/trackingTypes";

const cookie = new Cookies();
const ROPC_SESSION = "ROPC_SESSION";
const B2C_POLICY = config.WEB_SIGN_IN_POLICY_NAME;
const ROPC_POLICY = config.ROPC_POLICY;
const SCHIBSTED_SESSION = "SCHIBSTED_SESSION";
const MSAL_LOGIN_PROCESS = "msalLoginProcess";

const regionSelector = (state: RootState) => state.global.location;
const migrationSessionIdSelector = (state: RootState) => state.migration.migrationSessionId;

function* loginUser({ email, password, loginSource }: LoginUser): any {
    try {
        const isMaintenance = typeof window !== "undefined" && window.experiment ? window.experiment.maintenance_active : false;
        if (isMaintenance) {
            return yield put<LoginUserFailed>({
                type: LOGIN_USER_FAILED,
                showToastErrorMessage: true,
                message: strings.errors.general,
            });
        }

        sessionStorage.clear();
        localStorage.clear();

        const encryptedPassword = encryptPassword(password);
        const loginResponse: LoginUserResponse = yield call(createPostRequest, "user/login", {
            email,
            password: encryptedPassword,
        });

        saveSessionData(loginResponse);
        cookie.set("auth", loginResponse.access_token, { path: "/" });

        yield put({ type: GET_USER_DATA });

        yield put<LoginUserSuccess>({
            type: LOGIN_USER_SUCCESS,
            access_token: loginResponse.access_token,
            refresh_token: loginResponse.refresh_token,
            session_duration: loginResponse.expires_in,
            username: email,
            loginSource,
            accountCreated: false,
        });
        const region = yield select(regionSelector);
        window.location.href = `${document.location.origin}/${region}/`;
    } catch (error: any) {
        if (error?.response && error.response?.data && error.response.data?.errors) {
            const errorCode = error.response.data.errors?.login[0] || "";

            if (errorCode !== AzureErrorCode.wrongCredentials)
                appInsights.trackException({
                    exception: new Error(`${LOGIN_USER_FAILED} - ${error}`),
                });

            return yield put<LoginUserFailed>({
                type: LOGIN_USER_FAILED,
                showToastErrorMessage: true,
                message: getErrorMessageByAzureCode(errorCode),
            });
        }

        appInsights.trackException({
            exception: new Error(`${LOGIN_USER_FAILED} - ${error}`),
        });
        return yield put<LoginUserFailed>({
            type: LOGIN_USER_FAILED,
            showToastErrorMessage: true,
            message: strings.errors.general,
        });
    }
}

function* migrateToSchibsted(): any {
    try {
        const isMaintenance = typeof window !== "undefined" && window.experiment ? window.experiment.maintenance_active : false;
        if (isMaintenance) {
            return yield put<LoginWithIdpFailed>({
                type: LOGIN_WITH_IDP_FAILED,
                showToastErrorMessage: true,
                message: strings.errors.general,
            });
        }

        const region = yield select(regionSelector);
        const migrationSessionId = yield select(migrationSessionIdSelector);
        const { discover } = strings.routes;

        initSchibsted(region);
        return yield schibstedIdentity?.login({
            state: JSON.stringify({
                returnUrl: `${document.location.origin}/${region}/${discover}${document.location.search}`,
                uuid: uid(),
                migrationSessionId: migrationSessionId.toString(),
            }),
            scope: "openid email",
        });
    } catch (error) {
        appInsights.trackException({
            exception: new Error(`${LOGIN_WITH_IDP_FAILED} - ${error}`),
        });

        const errorMessage = getLocalizedErrorMessage();

        return yield put<LoginWithIdpFailed>({
            type: LOGIN_WITH_IDP_FAILED,
            errorResponse: { status: 400, message: errorMessage },
            showToastErrorMessage: true,
            message: errorMessage,
        });
    }
}

function* migrateToSchibstedFromMobile({ migrationSessionId }: TriggerMigrationFromMobile): any {
    try {
        const isMaintenance = typeof window !== "undefined" && window.experiment ? window.experiment.maintenance_active : false;
        if (isMaintenance) {
            return yield put<LoginWithIdpFailed>({
                type: LOGIN_WITH_IDP_FAILED,
                showToastErrorMessage: true,
                message: strings.errors.general,
            });
        }

        const region = yield select(regionSelector);

        const { discover } = strings.routes;

        initSchibsted(region);
        return yield schibstedIdentity?.login({
            state: JSON.stringify({
                returnUrl: `${document.location.origin}/${region}/${discover}`,
                uuid: uid(),
                migrationSessionId: migrationSessionId,
            }),
            scope: "openid email",
        });
    } catch (error) {
        appInsights.trackException({
            exception: new Error(`${LOGIN_WITH_IDP_FAILED} - ${error}`),
        });

        const errorMessage = getLocalizedErrorMessage();

        return yield put<LoginWithIdpFailed>({
            type: LOGIN_WITH_IDP_FAILED,
            errorResponse: { status: 400, message: errorMessage },
            showToastErrorMessage: true,
            message: errorMessage,
        });
    }
}

export function* loginWithIdp({
    provider,
    redirectDestination,
    redirectUrl,
}: LoginWithIdp): SagaReturnType<PutEffect<LoginWithIdpFailed> | Window | undefined | Promise<void>> {
    try {
        const isMaintenance = typeof window !== "undefined" && window.experiment ? window.experiment.maintenance_active : false;
        if (isMaintenance) {
            return yield put<LoginWithIdpFailed>({
                type: LOGIN_WITH_IDP_FAILED,
                showToastErrorMessage: true,
                message: strings.errors.general,
            });
        }

        // solves issue with Safari when interupting login flow
        sessionStorage.clear();
        localStorage.clear();

        const account = msalInstance.getActiveAccount();
        if (!account) {
            const region = yield select(regionSelector);
            const { discover, signup } = strings.routes;
            let domainHint = "";

            const subflow = isOnSubflow();

            let returnUrl = `${document.location.origin}/${region}/`;

            switch (redirectDestination) {
                case IdpRedirectOption.SignupPage:
                    returnUrl += `${signup}/checkout${document.location.search}`;
                    break;
                case IdpRedirectOption.BundlerLogin:
                case IdpRedirectOption.BundlerSignup:
                    returnUrl += `partner/confirmation${document.location.search}`;
                    break;
                default:
                    returnUrl += `${discover}${document.location.search}`;
                    break;
            }

            if (redirectUrl && redirectUrl !== "") returnUrl = redirectUrl;
            if (subflow) returnUrl = `${document.location.origin}/${region}/${signup}/checkout`;

            switch (provider) {
                case IdentityProvider.Facebook:
                    domainHint = "facebook.com";
                    break;
                case IdentityProvider.Google:
                    domainHint = "google.com";
                    break;
                case IdentityProvider.Apple:
                    domainHint = "appleid.apple.com";
                    break;
                case IdentityProvider.Schibsted:
                    yield delay(2000);

                    initSchibsted(region);
                    return yield schibstedIdentity?.login({
                        state: JSON.stringify({
                            returnUrl,
                            uuid: uid(),
                            accountCreated: redirectDestination === IdpRedirectOption.SignupPage ? true : false,
                        }),
                        scope: "openid email",
                    });
                default:
                    break;
            }

            localStorage.setItem(MSAL_LOGIN_PROCESS, "true");

            const saveRedirectUri = returnUrl.split("?")[0];

            return yield msalInstance.loginRedirect({
                domainHint,
                scopes,
                redirectUri: saveRedirectUri,
                prompt: "select_account",
            });
        }
    } catch (error) {
        appInsights.trackException({
            exception: new Error(`${LOGIN_WITH_IDP_FAILED} - ${error}`),
        });

        const errorMessage = getLocalizedErrorMessage();

        return yield put<LoginWithIdpFailed>({
            type: LOGIN_WITH_IDP_FAILED,
            errorResponse: { status: 400, message: errorMessage },
            showToastErrorMessage: true,
            message: strings.errors.general,
        });
    }
}

function* getLoginWidget(): SagaReturnType<PutEffect> {
    try {
        const region = yield select(regionSelector);
        const returnUrl = `${document.location.origin}/${region}/${document.location.pathname.slice(4)}${document.location.search}`;

        initSchibsted(region);
        yield schibstedIdentity?.showSimplifiedLoginWidget({
            state: JSON.stringify({
                returnUrl,
                uuid: uid(),
                accountCreated: false,
            }),
            scope: "openid email",
        });

        cookie.set(LAST_SEEN_LOGIN_WIDGET_COOKIE, JSON.stringify(Date.now()), {
            path: "/",
            maxAge: 60 * 60 * 24,
        });

        return yield put<GetLoginWidgetSuccess>({
            type: GET_LOGIN_WIDGET_SUCCESS,
        });
    } catch (error: any) {
        return yield put<GetLoginWidgetFailed>({
            type: GET_LOGIN_WIDGET_FAILED,
            error,
        });
    }
}

function* getUserData(): any {
    try {
        let accountIss;
        let username;

        const currentSession = yield resolveAuthSession();

        switch (currentSession.ia) {
            case IdentityAuthority.ROPC:
                username = currentSession.sessionData.username;
                break;
            case IdentityAuthority.MSAL:
                const account = yield msalInstance.getAllAccounts()[0];
                if (account) {
                    username = account.username;
                }
                break;
            case IdentityAuthority.SCHIBSTED:
                const schibstedSession: SchibstedSession = currentSession.sessionData;

                if (schibstedSession) {
                    const accessToken = jwtDecode(schibstedSession.access_token) as { iss: string };

                    accountIss = accessToken.iss;
                    username = schibstedSession.email;
                }

                break;
        }

        return yield put({ type: GET_USER_DATA_SUCCESS, username, accountIss });
    } catch (error: any) {
        if (error.response) {
            appInsights.trackException({
                exception: new Error(`${GET_USER_DATA_FAILED} - ${error}`),
            });
            return yield put({ type: GET_USER_DATA_FAILED, error });
        }
    }
}

function* checkAuthStatus(): SagaReturnType<PutEffect<CheckAuthStatusSuccess> | PutEffect<CheckAuthStatusFailed>> {
    try {
        let isAuthenticated = false;
        let loginProvider = IdentityProvider.Email;
        let userToken: { sub: string } = { sub: "" };

        const currentSession: {
            ia: IdentityAuthority;
            sessionData: AccountInfo | AuthenticationResult | SchibstedSession | SessionStorage | null | undefined;
        } = yield resolveAuthSession();

        switch (currentSession.ia) {
            case IdentityAuthority.ROPC: {
                const sessionData = currentSession.sessionData as SessionStorage;
                if (sessionData) {
                    userToken = jwtDecode(sessionData.access_token);

                    const currentTime = Math.round(new Date().getTime() / 1000);
                    const sessionExpiryTime = sessionData.expires_on;

                    if (sessionExpiryTime > currentTime) {
                        isAuthenticated = Boolean(sessionData.access_token && sessionData.username);
                        loginProvider = IdentityProvider.Email;
                        cookie.set("auth", sessionData.access_token, { path: "/" });
                    } else {
                        yield put<RefreshToken>({
                            type: REFRESH_TOKEN,
                            token: sessionData.refresh_token,
                        });
                    }
                }
                break;
            }

            case IdentityAuthority.MSAL: {
                const msalSession = currentSession.sessionData as AuthenticationResult & AccountInfo;
                const currentTime = Math.round(new Date().getTime() / 1000);
                const expiration_time = msalSession?.idTokenClaims?.exp ?? 0;
                const isTokenExpired = currentTime >= expiration_time;
                let tokenResponse;

                if (!isTokenExpired) {
                    if (msalSession && msalSession.accessToken) {
                        tokenResponse = msalSession;
                        const newUser = tokenResponse.idTokenClaims?.newUser;
                        const providerNameLink = tokenResponse.idTokenClaims.idp;
                        switch (providerNameLink) {
                            case "google.com":
                                loginProvider = IdentityProvider.Google;
                                break;
                            case "facebook.com":
                                loginProvider = IdentityProvider.Facebook;
                                break;
                            case "https://appleid.apple.com":
                                loginProvider = IdentityProvider.Apple;
                                break;
                        }

                        yield put<LoginWithIdpSuccess>({
                            type: LOGIN_WITH_IDP_SUCCESS,
                            provider: loginProvider as IdentityProvider,
                            username: msalSession.username,
                            accountCreated: newUser ? true : false,
                        });
                    } else if (msalSession) {
                        const providerNameLink = msalSession.idTokenClaims.idp;
                        switch (providerNameLink) {
                            case "google.com":
                                loginProvider = IdentityProvider.Google;
                                break;
                            case "facebook.com":
                                loginProvider = IdentityProvider.Facebook;
                                break;
                            case "https://appleid.apple.com":
                                loginProvider = IdentityProvider.Apple;
                                break;
                        }

                        tokenResponse = yield msalInstance.acquireTokenSilent({
                            account: msalSession,
                            scopes,
                        });
                    }
                }

                if (tokenResponse) {
                    isAuthenticated = true;
                    userToken = jwtDecode(tokenResponse.accessToken);
                    const newUser = tokenResponse.idTokenClaims?.newUser;
                    const msalLoginProcess = localStorage.getItem(MSAL_LOGIN_PROCESS);

                    if (msalLoginProcess) {
                        localStorage.removeItem(MSAL_LOGIN_PROCESS);

                        yield put<LoginWithIdpSuccess>({
                            type: LOGIN_WITH_IDP_SUCCESS,
                            provider: loginProvider as IdentityProvider,
                            username: msalSession.username,
                            accountCreated: newUser ? true : false,
                        });
                    }

                    cookie.set("auth", tokenResponse.accessToken, {
                        path: "/",
                    });
                }

                break;
            }

            case IdentityAuthority.SCHIBSTED: {
                const region = yield select(regionSelector);
                initSchibsted(region);

                const schibstedSession = currentSession.sessionData as SchibstedSession;

                const currentTime = Math.round(new Date().getTime() / 1000);
                const sessionExpiryTime = schibstedSession.expiration_time;

                if (sessionExpiryTime > currentTime) {
                    isAuthenticated = Boolean(schibstedSession.access_token && schibstedSession.expires_in > 0);
                    loginProvider = IdentityProvider.Schibsted;

                    userToken = jwtDecode(schibstedSession.access_token);

                    cookie.set("auth", schibstedSession.access_token, {
                        path: "/",
                    });
                    yield put<RefreshToken>({
                        type: REFRESH_TOKEN,
                        token: schibstedSession.refresh_token,
                    });
                }

                break;
            }
        }

        if (isAuthenticated) {
            yield put({ type: GET_USER_DATA });
        } else {
            cookie.remove("auth", { path: "/" });
            localStorage.removeItem(ROPC_SESSION);
            localStorage.removeItem(SCHIBSTED_SESSION);
            for (const entry in localStorage) {
                if (entry.includes(B2C_POLICY.toLowerCase())) localStorage.removeItem(entry);
            }
        }

        return yield put<CheckAuthStatusSuccess>({
            type: CHECK_AUTH_STATUS_SUCCESS,
            isAuthenticated,
            provider: loginProvider,
            userId: userToken.sub ?? "",
        });
    } catch (error) {
        appInsights.trackException({
            exception: new Error(`${CHECK_AUTH_STATUS_FAILED} - ${error}`),
        });
        return yield put<CheckAuthStatusFailed>({
            type: CHECK_AUTH_STATUS_FAILED,
            error,
        });
    }
}

function* logoutUserTrigger(redirectUri?: string): any {
    const region = yield select(regionSelector);
    const postLogoutRedirectUri = redirectUri || `${document.location.origin}/${region}/`;

    yield delay(200);

    const currentSession = yield resolveAuthSession();
    cookie.remove("auth", { path: "/" });
    cookie.remove("migrationSessionId", { path: "/" });
    cookie.remove("userIsMigrating", { path: "/" });

    switch (currentSession.ia) {
        case IdentityAuthority.ROPC: {
            localStorage.removeItem(ROPC_SESSION);
            window.location.href = postLogoutRedirectUri;
            break;
        }

        case IdentityAuthority.MSAL: {
            const currentAccount = msalInstance.getAllAccounts()[0];
            if (currentAccount) {
                yield msalInstance.logoutRedirect({
                    account: currentAccount,
                    postLogoutRedirectUri,
                });
            }
            break;
        }

        case IdentityAuthority.SCHIBSTED: {
            localStorage.removeItem(SCHIBSTED_SESSION);
            schibstedIdentity?.logout(postLogoutRedirectUri);
            break;
        }

        case IdentityAuthority.NONE: {
            cookie.remove("auth", { path: "/" });
            localStorage.removeItem(ROPC_SESSION);
            localStorage.removeItem(SCHIBSTED_SESSION);
            for (const entry in localStorage) {
                if (entry.includes(B2C_POLICY.toLowerCase())) localStorage.removeItem(entry);
            }
            window.location.href = postLogoutRedirectUri;
        }
    }
}

function* logoutUser(): SagaReturnType<void | PutEffect<LogoutUserSuccess> | PutEffect<LogoutUserFailed>> {
    try {
        yield logoutUserTrigger();
    } catch (error: any) {
        if (error && error.message) {
            appInsights.trackException({
                exception: new Error(`${LOGOUT_USER_FAILED} - ${error}`),
            });
            return yield put<LogoutUserFailed>({
                type: LOGOUT_USER_FAILED,
                errorResponse: { status: 400, message: error.message },
            });
        }
    }
}

function* logoutUserWithCustomRedirect({
    redirectUri,
}: LogoutUserWithCustomRedirect): SagaReturnType<void | PutEffect<LogoutUserSuccess> | PutEffect<LogoutUserFailed>> {
    try {
        yield logoutUserTrigger(redirectUri);
        return yield put<LogoutUserWithCustomRedirectSuccess>({
            type: LOGOUT_USER_WITH_CUSTOM_REDIRECT_SUCCESS,
        });
    } catch (error: any) {
        if (error && error.message) {
            appInsights.trackException({
                exception: new Error(`${LOGOUT_USER_FAILED} - ${error}`),
            });
            return yield put<LogoutUserFailed>({
                type: LOGOUT_USER_FAILED,
                errorResponse: { status: 400, message: error.message },
            });
        }
    }
}

function* logoutUserWithError(): SagaReturnType<void | PutEffect<LogoutUserWithErrorSuccess> | PutEffect<LogoutUserWithErrorFailed>> {
    try {
        yield delay(200);

        if (cookie.get("userIsMigrating")) cookie.remove("userIsMigrating", { path: "/" });

        localStorage.removeItem(SCHIBSTED_SESSION);

        const session = yield resolveAuthSession();

        let provider = "";
        let tokenResponse = null;

        switch (session.ia) {
            case IdentityAuthority.ROPC:
                provider = IdentityProvider.Email;
                break;

            case IdentityAuthority.MSAL:
                const msalSession = session.sessionData;
                tokenResponse = yield msalInstance.acquireTokenSilent({
                    account: session.sessionData,
                    scopes,
                });

                if (msalSession && msalSession.idTokenClaims) {
                    provider = msalSession.idTokenClaims.idp;
                }
                break;
        }
        if (session.sessionData.access_token) {
            cookie.set("auth", session.sessionData.access_token, { path: "/" });
        } else if (tokenResponse.accessToken) {
            cookie.set("auth", tokenResponse.accessToken, { path: "/" });
        }

        return yield put<LogoutUserWithErrorSuccess>({
            type: LOGOUT_USER_WITH_ERROR_SUCCESS,
            username: session.sessionData.username,
            provider,
        });
    } catch (error: any) {
        if (error && error.message) {
            appInsights.trackException({
                exception: new Error(`${LOGOUT_USER_WITH_ERROR_FAILED} - ${error}`),
            });
            return yield put<LogoutUserWithErrorFailed>({
                type: LOGOUT_USER_WITH_ERROR_FAILED,
                errorResponse: { status: 400, message: error.message },
            });
        }
    }
}

function* refreshToken({ token }: RefreshToken): SagaReturnType<
    | {
          type: typeof REFRESH_TOKEN_SUCCESS;
      }
    | { type: typeof REFRESH_TOKEN_FAILED; error: any }
> {
    try {
        const currentSession = yield resolveAuthSession();

        switch (currentSession.ia) {
            case IdentityAuthority.ROPC: {
                const tenantName = currentSession.sessionData.tenant_name;
                const loginPolicy = currentSession.sessionData.policy_name;
                const clientId = currentSession.sessionData.client_id;
                const refreshToken = currentSession.sessionData.refresh_token;

                const refreshResponse = yield axios
                    .get(
                        `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${loginPolicy}/oauth2/v2.0/token?grant_type=refresh_token&response_type=id_token&client_id=${clientId}&resource=${clientId}&refresh_token=${refreshToken}`
                    )
                    .then((result) => result.data);

                saveSessionData(refreshResponse);
                cookie.set("auth", refreshResponse.access_token, { path: "/" });
                break;
            }

            case IdentityAuthority.SCHIBSTED: {
                const schibstedSession: SchibstedSession = currentSession.sessionData;
                const { email } = schibstedSession;
                const refresh_token = schibstedSession.refresh_token || token;
                if (refresh_token) {
                    const tokenResponse = yield axios
                        .post(`${config.PUBLIC_URL}/auth/refreshSchibstedSession`, { code: refresh_token, state: uid() })
                        .then((result) => result.data);

                    localStorage.setItem(SCHIBSTED_SESSION, JSON.stringify({ ...tokenResponse, email }));
                    cookie.set("auth", tokenResponse.access_token, {
                        path: "/",
                    });
                }

                break;
            }
        }

        return yield put({
            type: REFRESH_TOKEN_SUCCESS,
        });
    } catch (error) {
        appInsights.trackException({
            exception: new Error(`${REFRESH_TOKEN_FAILED} - ${error}`),
        });
        return yield put({ type: REFRESH_TOKEN_FAILED, error });
    }
}

function* resetPassword(): SagaReturnType<PutEffect<ResetPasswordSuccess> | PutEffect<ResetPasswordFailed>> {
    try {
        const { login } = strings.routes;
        const region = yield select(regionSelector);

        // solves issue with Safari when interupting login flow
        sessionStorage.clear();

        yield msalInstance.handleRedirectPromise();

        yield msalInstance.loginRedirect({
            authority: `https://${config.TENANT_NAME}.b2clogin.com/${config.TENANT_NAME}.onmicrosoft.com/${config.PASSWORD_RESET_POLICY}`,
            redirectUri: `/${region}/${login}`,
            scopes,
        });

        return yield put<ResetPasswordSuccess>({
            type: RESET_PASSWORD_SUCCESS,
        });
    } catch (error) {
        appInsights.trackException({
            exception: new Error(`${RESET_PASSWORD_FAILED} - ${error}`),
        });
        return yield put<ResetPasswordFailed>({
            type: RESET_PASSWORD_FAILED,
            error,
        });
    }
}

function saveSessionData(response: AuthResponse) {
    const cachedAt = Math.round(new Date().getTime() / 1000);
    const expiresOn = cachedAt + response.expires_in;

    const id_token = JSON.parse(decodeURIComponent(escape(window.atob(response.id_token.split(".")[1]))));
    const clientId = config.APPLICATION_ID;
    const username = id_token.emails ? id_token.emails[0] : id_token.name;

    const policyName = B2C_POLICY;
    for (const entry in localStorage) {
        if (entry.includes(policyName)) localStorage.removeItem(entry);
    }

    const sessionData: SessionStorage = {
        client_id: clientId,
        tenant_name: "reacthello",
        username: username,
        access_token: response.access_token,
        refresh_token: response.refresh_token,
        id_token: id_token,
        expires_on: expiresOn,
        policy_name: ROPC_POLICY,
        token_type: response.token_type,
    };
    localStorage.setItem(ROPC_SESSION, JSON.stringify(sessionData));
}

export async function resolveAuthSession(): Promise<{
    ia: IdentityAuthority;
    sessionData: AuthenticationResult | AccountInfo | SessionStorage | SchibstedSession | undefined | null;
}> {
    return new Promise(async (resolve, reject) => {
        const schibstedSession = localStorage.getItem(SCHIBSTED_SESSION);
        if (schibstedSession && schibstedSession !== "undefined") {
            const parsedSession: SchibstedSession = JSON.parse(schibstedSession);
            resolve({
                ia: IdentityAuthority.SCHIBSTED,
                sessionData: parsedSession,
            });
        }

        const ropcSessionStorage = localStorage.getItem(ROPC_SESSION);
        if (ropcSessionStorage && ropcSessionStorage !== "undefined") {
            const ropcSession: SessionStorage = JSON.parse(ropcSessionStorage);
            resolve({ ia: IdentityAuthority.ROPC, sessionData: ropcSession });
        }

        await msalInstance.handleRedirectPromise().catch((error) => {
            reject(error);
        });
        const msalSession = msalInstance.getAllAccounts()[0];
        if (msalSession) {
            resolve({
                ia: IdentityAuthority.MSAL,
                sessionData: msalSession,
            });
        }

        resolve({ ia: IdentityAuthority.NONE, sessionData: null });
    });
}

function* updateUserConsent(): SagaReturnType<void> {
    try {
        yield delay(1000);
        //wait for all consent notifications

        const cmpMarketing = localStorage.getItem(ConsentCategory.Marketing);
        const cmpAnalytics = localStorage.getItem(ConsentCategory.Analytics);

        const consentData = {
            hasGivenConsentToMarketingCookies: cmpMarketing === "1",
            hasGivenConsentToAnalyticsCookies: cmpAnalytics === "1",
        };

        yield call(routes.postCookieConsent, consentData);
    } catch (error) {
        appInsights.trackException({
            exception: new Error(`${UPDATE_USER_CONSENT} - ${error}`),
        });
    }
}

function* getUserPrivacySettings(): SagaReturnType<void> {
    try {
        const {
            hasOptedOutFromAnalyticsAndProductDevelopment,
            hasOptedOutFromMarketing3RdPartyPlatforms,
        }: UserPrivacySettingsResponse = yield call(routes.getUserPrivacySettings);

        yield put({ type: UPDATE_CONSENT_ANALYTICS, value: hasOptedOutFromAnalyticsAndProductDevelopment ? "0" : "1" });
        yield put({ type: UPDATE_CONSENT_MARKETING, value: hasOptedOutFromMarketing3RdPartyPlatforms ? "0" : "1" });

        yield put({
            type: GET_USER_PRIVACY_SETTINGS_SUCCESS,
        });
    } catch (error) {
        appInsights.trackException({
            exception: new Error(`${GET_USER_PRIVACY_SETTINGS} - ${error}`),
        });

        yield put({ type: UPDATE_CONSENT_ANALYTICS, value: "0" });
        yield put({ type: UPDATE_CONSENT_MARKETING, value: "0" });
        yield put({
            type: GET_USER_PRIVACY_SETTINGS_FAILED,
        });
    }
}

export default [
    takeLatest(LOGIN_USER, loginUser),
    takeLatest(LOGIN_WITH_IDP, loginWithIdp),
    takeLatest(ACCEPT_MIGRATION_MODAL_PROMPT_SUCCESS, migrateToSchibsted),
    takeLatest(TRIGGER_MIGRATION_FROM_MOBILE, migrateToSchibstedFromMobile),
    takeLatest(CHECK_AUTH_STATUS, checkAuthStatus),
    takeLatest(LOGIN_USER_SUCCESS, checkAuthStatus),
    takeLatest(GET_USER_DATA, getUserData),
    takeLatest(GET_LOGIN_WIDGET, getLoginWidget),
    takeLatest(LOGOUT_USER, logoutUser),
    takeLatest(LOGOUT_USER_WITH_CUSTOM_REDIRECT, logoutUserWithCustomRedirect),
    takeLatest(LOGOUT_USER_WITH_ERROR, logoutUserWithError),
    takeLatest(LOGOUT_USER_WITH_ERROR_SUCCESS, refreshToken),
    takeLatest(REFRESH_TOKEN, refreshToken),
    takeLatest(RESET_PASSWORD, resetPassword),
    takeLatest(UPDATE_USER_CONSENT, updateUserConsent),
    takeLatest(GET_USER_PRIVACY_SETTINGS, getUserPrivacySettings),
];
