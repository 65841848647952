import { ReactElement } from "react";
import { Box, Image, StyleProps } from "@chakra-ui/react";

export default function PremiumBadge({ ...rest }: StyleProps): ReactElement {
    return (
        <Box display="inline-block" maxWidth="4rem" {...rest}>
            <Image width="100%" src="/assets/images/icons/premium-57px.svg" />
        </Box>
    );
}
