import appInsights from "app/config/appInsights";
import { RootState } from "app/reducers/rootReducer";
import { call, CallEffect, PutEffect, select, SelectEffect, TakeEffect, takeLatest } from "redux-saga/effects";

import { UNKNOWN_ADYEN_PRICE } from "../types/loggingTypes";

const authSelector = (state: RootState) => state.auth;
type AuthState = ReturnType<typeof authSelector>;

type SagaEffect = PutEffect | TakeEffect | SelectEffect | CallEffect;

export function* logUnexpectedErrorEvent({ type, event }: { type: string; event: unknown }): Generator<SagaEffect> {
    const { userId } = (yield select(authSelector)) as AuthState;
    yield call(() => {
        appInsights.trackException({
            exception: new Error(`[web-app ERROR]: ${type}`),
            properties: {
                event,
                userId,
            },
        });
    });
}

export default [takeLatest(UNKNOWN_ADYEN_PRICE, logUnexpectedErrorEvent)];
