import { Link } from "react-router-dom";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import strings from "@locale/localization";
import { ReactElement } from "react";
import { CSSstylesObject, DeviceBreakpoints, PodmeColor } from "../../types/index";

const styles: CSSstylesObject = {
    gridHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        paddingBottom: "2.5rem",
        fontFamily: "Inter",
        color: PodmeColor.White,
        sx: {
            a: {
                textDecoration: "none",
                color: PodmeColor.White,
                fontSize: "1.2em",

                span: {
                    marginRight: "10px",
                },
            },
            [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                "&": {
                    paddingBottom: "1.5rem",
                },
                a: {
                    fontSize: "1em",
                },
            },
        },
    },
    gridTitle: {
        color: PodmeColor.White,
        margin: 0,
        letterSpacing: "1px",
        fontWeight: 600,
    },
    gridContainer: {
        maxWidth: "100%",
        display: "grid",
        gridGap: "1rem",
        sx: {
            [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                gridTemplateColumns: "repeat(auto-fill, minmax(calc(50% - 1rem), 1fr)) !important",
                gridGap: "1rem",
            },
        },
    },
    gridItem: {
        height: "auto",
    },
    angleRightIcon: {
        backgroundImage: 'url("/assets/images/icons/chevron-right.svg")',
        width: "28px",
        height: "26px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        marginLeft: "auto",
        display: "block",
    },
};

interface Props {
    title?: string;
    linkUrl?: string;
    linkText?: string;
    columns: number;
    children: any;
    gridGap?: number;
}

function PodGrid({ title, linkUrl, linkText, columns, children, gridGap }: Props): ReactElement {
    const { locale } = strings.routes;

    return (
        <Box>
            {title || linkUrl ? (
                <Flex {...styles.gridHeader} gridGap={2}>
                    {title ? (
                        <Heading as="h2" {...styles.gridTitle} fontSize={["1.5em", "2em", "2.5em"]}>
                            {title}
                        </Heading>
                    ) : null}
                    {linkUrl ? (
                        <Link to={`/${locale}/${linkUrl}`}>
                            <Flex alignItems="center">
                                <Text as="span" display="inline-block" whiteSpace="nowrap">
                                    {linkText}
                                </Text>
                                <Box as={"span"} {...styles.angleRightIcon} />
                            </Flex>
                        </Link>
                    ) : null}
                </Flex>
            ) : null}
            <Box
                {...styles.gridContainer}
                //if number of columns was not passed in prop, default to 5
                gridTemplateColumns={`repeat(${columns ? columns : "5"}, 1fr)`}
                style={{ gridGap: gridGap ? `${gridGap}rem` : "" }}
            >
                {children.map((child: any, index: number) => (
                    <Box {...styles.gridItem} key={index}>
                        {child}
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export default PodGrid;
