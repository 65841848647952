import React, { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useRef } from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import { NavLink as Link, useLocation } from "react-router-dom";
import strings from "@locale/localization";
import { PodmeColor } from "@typings/index";
import { SecondaryButton } from "@components/buttons";
import AccountArea from "@components/AccountArea/AccountArea";
import { MigrationBanner } from "@containers/Migration";
import SchibstedDataControllerBanner from "./SchibstedDataControllerBanner";
import { selectItemEventAction } from "app/tracking/trackingActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/reducers/rootReducer";
import usePrevPath from "app/hooks/usePrevPath";
import useRefDimensions from "app/hooks/useRefDimensions";
import { IdpRedirectOption } from "@auth/authTypes";
import useAuth from "@auth/useAuth";
import Skeleton from "@components/Skeleton";
import CTACheckoutButton from "@components/CTACheckoutButton";
import { STUDENT_BASE_PATH } from "@containers/Student/constants";
import { useGetEnabledFeatures } from "app/api/hooks/user";

interface DesktopHeaderProps {
    isAuthenticated: boolean;
    isAuthLoading: boolean;
    username: string | undefined;
    hasSubscription: boolean;
    logoutUser: () => void;
    showSchibstedBanner: boolean;
    scrollingUp: boolean;
    setNavBarHeight: Dispatch<SetStateAction<number>>;
}

const globalState = (state: RootState) => state.global;

export default function DesktopHeader({
    isAuthenticated,
    isAuthLoading,
    username,
    hasSubscription,
    logoutUser,
    showSchibstedBanner,
    scrollingUp,
    setNavBarHeight,
    ...rest
}: DesktopHeaderProps): ReactElement {
    const dispatch = useDispatch();
    const { currency, packageId, price } = useSelector(globalState);
    const location = useLocation();
    const { setPrevPath } = usePrevPath();
    const { handleSignUpRedirect } = useAuth();
    const enabledFeatures = useGetEnabledFeatures();

    const { locale, discover, myPodcasts, search, login, categories, dashboard } = strings.routes;
    const navString = strings.navigation;

    const bannersRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);

    const bannersHeight = useRefDimensions(bannersRef).height;
    const headerHeight = useRefDimensions(headerRef).height;

    const isMyAccountPageOpen = location.pathname.split("/").findIndex((p) => p === dashboard) === -1 ? false : true;

    const handleCookieSet = useCallback(() => {
        setPrevPath(location.pathname);
    }, [locale, location.pathname]);

    const handleSignUpButton = () =>
        handleSignUpRedirect({
            redirectDestination: IdpRedirectOption.SignupPage,
            callbackFunc: () => {
                dispatch(selectItemEventAction("Desktop / Navbar / Create Account button", String(packageId), currency, price));
            },
        });

    useEffect(() => {
        setNavBarHeight(bannersHeight + headerHeight);
    }, [bannersHeight, headerHeight, bannersRef.current, headerRef.current]);

    const renderAccountArea = () => {
        if (isAuthLoading) return <Skeleton height="2rem" width="12rem" borderRadius="0.5rem" />;
        if (isAuthenticated)
            return (
                <AccountArea username={username ?? ""} locale={locale} hasPackageSubscription={hasSubscription} logout={logoutUser} />
            );

        return (
            <Flex gridGap={6}>
                <SecondaryButton
                    data-testid="navbar-create-account-button"
                    color={PodmeColor.SoftWhite}
                    background={PodmeColor.LicoriceBlue}
                    padding="0.5rem 1rem"
                    borderRadius="0.75rem"
                    onClick={handleSignUpButton}
                >
                    {navString.createAccount}
                </SecondaryButton>
                <SecondaryButton
                    data-testid="navbar-login-button"
                    to={{ pathname: `/${locale}/${login}`, state: { goBackUrl: location.pathname } }}
                    onClick={handleCookieSet}
                    background={PodmeColor.SoftWhite}
                    padding="0.5rem 1rem"
                    borderRadius="0.75rem"
                >
                    {navString.login}
                </SecondaryButton>
            </Flex>
        );
    };

    return (
        <>
            <Flex direction="column" position="relative" zIndex="1410" ref={bannersRef}>
                {showSchibstedBanner && <SchibstedDataControllerBanner variant="top" />}
            </Flex>
            <Box
                as="header"
                transition={scrollingUp ? "150ms ease-in" : "250ms ease-in"}
                position={"sticky"}
                top={0}
                transform={scrollingUp ? "translateY(0px)" : "translateY(-189px)"}
                width="100%"
                zIndex={10}
                background={`${PodmeColor.Woodsmoke}`}
                borderBottom={`1px solid ${PodmeColor.Cinder}`}
                ref={headerRef}
                {...rest}
            >
                <Box padding="1rem 2rem">
                    <Flex justifyContent="space-between" alignItems="center" position="relative">
                        <Flex position="relative" alignItems="center" gridGap="4.5rem" zIndex={1}>
                            <Link to={isAuthenticated ? `/${locale}/auth/${discover}` : `/${locale}/`}>
                                <Image
                                    data-testid="navbar-podme-logo"
                                    src="/assets/images/brand/podme-logo-white.svg"
                                    alt="Podme logo"
                                    height="30px"
                                />
                            </Link>
                            {renderMenuItems()}
                        </Flex>
                        <Box>{renderAccountArea()}</Box>
                    </Flex>
                </Box>
                {isAuthenticated && <MigrationBanner />}
            </Box>
        </>
    );

    function renderMenuItems() {
        if (isMyAccountPageOpen) return null;

        return (
            <Flex alignItems="center" gridGap={[6, null, 12]}>
                {isAuthenticated ? (
                    <>
                        <Link
                            to={`/${locale}/auth/${discover}`}
                            activeStyle={{
                                borderBottom: `2px solid ${PodmeColor.SoftWhite}`,
                            }}
                            data-testid="navbar-discover-link"
                        >
                            {navString.discover}
                        </Link>
                        <Link
                            to={`/${locale}/${myPodcasts}`}
                            activeStyle={{
                                borderBottom: `2px solid ${PodmeColor.SoftWhite}`,
                            }}
                            data-testid="navbar-my-podcasts-link"
                        >
                            {navString.myPodcasts}
                        </Link>
                        <Link
                            to={`/${locale}/${categories}`}
                            activeStyle={{
                                borderBottom: `2px solid ${PodmeColor.SoftWhite}`,
                            }}
                            data-testid="navbar-categories-link"
                        >
                            {navString.categories}
                        </Link>
                        <Link
                            to={`/${locale}/${search}`}
                            activeStyle={{
                                borderBottom: `2px solid ${PodmeColor.SoftWhite}`,
                            }}
                            data-testid="navbar-search-link"
                        >
                            {navString.search}
                        </Link>
                    </>
                ) : (
                    <>
                        <Link
                            to={`/${locale}/`}
                            exact={true}
                            activeStyle={{
                                borderBottom: `2px solid ${PodmeColor.SoftWhite}`,
                            }}
                            data-testid="navbar-home-link"
                        >
                            {navString.home}
                        </Link>
                        <Link
                            to={`/${locale}/${discover}`}
                            activeStyle={{
                                borderBottom: `2px solid ${PodmeColor.SoftWhite}`,
                            }}
                            data-testid="navbar-discover-link"
                        >
                            {navString.discover}
                        </Link>
                        <Link
                            to={`/${locale}/${categories}`}
                            activeStyle={{
                                borderBottom: `2px solid ${PodmeColor.SoftWhite}`,
                            }}
                            data-testid="navbar-categories-link"
                        >
                            {navString.categories}
                        </Link>
                        <Link
                            to={`/${locale}/${search}`}
                            activeStyle={{
                                borderBottom: `2px solid ${PodmeColor.SoftWhite}`,
                            }}
                            data-testid="navbar-search-link"
                        >
                            {navString.search}
                        </Link>
                        {enabledFeatures.data?.isSheerIdEnabled && (
                            <Link
                                to={STUDENT_BASE_PATH(locale)}
                                activeStyle={{
                                    borderBottom: `2px solid ${PodmeColor.SoftWhite}`,
                                }}
                                data-testid="navbar-search-link"
                            >
                                {navString.student}
                            </Link>
                        )}
                        {isAuthLoading ? (
                            <Skeleton height="2rem" width="8rem" borderRadius="0.5rem" />
                        ) : (
                            <CTACheckoutButton
                                renderAs={SecondaryButton}
                                background={PodmeColor.Cyan}
                                color={PodmeColor.BlackPearl}
                                padding="0.5rem 1rem"
                                borderRadius="0.75rem"
                                onClickCallback={() =>
                                    dispatch(
                                        selectItemEventAction(
                                            "Desktop / Navbar / Get Premium button",
                                            String(packageId),
                                            currency,
                                            price
                                        )
                                    )
                                }
                                data-testid="navbar-get-premium-button"
                            >
                                {navString.getPremium}
                            </CTACheckoutButton>
                        )}
                    </>
                )}
            </Flex>
        );
    }
}
