import { Subscription } from "@typings/index";
import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSubscriptionQuery } from "@api/hooks/subscription";
import { RootState } from "../reducers/rootReducer";
import {
    LOGIN_USER,
    CHECK_AUTH_STATUS,
    LOGOUT_USER,
    IdentityProvider,
    LOGIN_WITH_IDP,
    RESET_PASSWORD,
    SHOW_PREMIUM_MODAL,
    DISMISS_PREMIUM_MODAL,
    LoginSource,
    IdpRedirectOption,
    LOGOUT_USER_WITH_ERROR,
    REFRESH_TOKEN,
    SignUpRedirectConfig,
    LOGOUT_USER_WITH_CUSTOM_REDIRECT,
} from "./authTypes";
import { useHistory } from "react-router";

const authState = (state: RootState) => state.auth;

export default function useAuth(): {
    isAuthenticated: boolean;
    username: string;
    authChecked: boolean;
    isAuthLoading: boolean;
    isAuthSubmitting: boolean;
    isFetchingUserData: boolean;
    isPremiumModalShown: boolean;
    hasActiveSubscription: boolean;
    hasActiveStudentSubscription: boolean;
    hasCancelledSubscription?: boolean;
    subscriptionStatusChecked: boolean;
    subscriptionStatusFetching: boolean;
    subscriptions: Subscription[] | undefined;
    activeSubscription: Subscription | undefined;
    episodeClicked?: boolean;
    isNewUser: boolean;
    redirectUri?: string;
    loginProvider?: IdentityProvider;
    userId: string;
    isUserLoggedInPremium: boolean;
    isUserLoggedInFreemium: boolean;
    isUserLoggedOut: boolean;
    showRedirectOverlay: boolean;
    loginUser: (email: string, password: string, loginSource?: LoginSource) => void;
    loginWithIdp: (provider: IdentityProvider, redirectDestination: IdpRedirectOption, redirectUrl?: string) => void;
    logoutUser: () => void;
    logoutUserWithCustomRedirect: (redirectUri?: string) => void;
    logoutUserWithError: () => void;
    checkAuthStatus: () => void;
    resetPassword: () => void;
    showPremiumModal: (episodeClicked?: boolean) => void;
    dismissPremiumModal: () => void;
    refreshToken: () => void;
    handleSignUpRedirect: (config: SignUpRedirectConfig) => void;
} {
    const dispatch = useDispatch();
    const {
        isAuthenticated,
        username,
        authChecked,
        isLoading,
        isPremiumModalShown,
        isSubmitting,
        isFetchingUserData,
        episodeClicked,
        isNewUser,
        provider,
        redirectUri,
        userId,
        showRedirectOverlay,
    } = useSelector(authState);

    const subscriptionResult = useSubscriptionQuery(isAuthenticated);
    const history = useHistory();

    const hasActiveSubscription = subscriptionResult.data?.hasActiveSubscription ?? false;
    const hasActiveStudentSubscription = subscriptionResult.data?.hasActiveStudentSubscription ?? false;
    const hasCancelledSubscription = subscriptionResult.data?.hasCancelledSubscription ?? false;
    const subscriptions = subscriptionResult.data?.subscriptions;
    const activeSubscription = subscriptionResult.data?.activeSubscription;
    const subscriptionStatusChecked = subscriptionResult.isFetched;
    const subscriptionStatusFetching = subscriptionResult.isFetching;

    useEffect(() => {
        sessionStorage.setItem("isAuthenticated", isAuthenticated.toString());
        sessionStorage.setItem("hasActiveSubscription", hasActiveSubscription.toString());
        sessionStorage.setItem("hasActiveStudentSubscription", hasActiveStudentSubscription.toString());
        sessionStorage.setItem("hasCancelledSubscription", hasCancelledSubscription.toString());
    }, [hasActiveSubscription, hasCancelledSubscription, isAuthenticated]);

    const isUserLoggedInPremium = !subscriptionStatusFetching && subscriptionStatusChecked && hasActiveSubscription;
    const isUserLoggedInFreemium = !subscriptionStatusFetching && subscriptionStatusChecked && !hasActiveSubscription;
    const isUserLoggedOut = !subscriptionStatusFetching && authChecked && !isAuthenticated;

    const loginUser = useCallback((email: string, password: string, loginSource?: LoginSource) => {
        dispatch({ type: LOGIN_USER, email, password, loginSource });
    }, []);

    const loginWithIdp = useCallback((provider: IdentityProvider, redirectDestination: IdpRedirectOption, redirectUrl?: string) => {
        dispatch({ type: LOGIN_WITH_IDP, provider, redirectDestination, redirectUrl });
    }, []);

    const logoutUser = useCallback(() => {
        dispatch({ type: LOGOUT_USER });
    }, []);

    const logoutUserWithCustomRedirect = useCallback((redirectUri?: string) => {
        dispatch({ type: LOGOUT_USER_WITH_CUSTOM_REDIRECT, redirectUri });
    }, []);

    const logoutUserWithError = useCallback(() => {
        dispatch({ type: LOGOUT_USER_WITH_ERROR });
    }, []);

    const checkAuthStatus = useCallback(() => {
        dispatch({ type: CHECK_AUTH_STATUS });
    }, []);

    const refreshToken = useCallback(() => {
        dispatch({ type: REFRESH_TOKEN });
    }, []);
    const resetPassword = useCallback(() => {
        dispatch({ type: RESET_PASSWORD });
    }, []);

    const showPremiumModal = useCallback((episodeClicked?: boolean) => {
        dispatch({ type: SHOW_PREMIUM_MODAL, episodeClicked });
    }, []);

    const dismissPremiumModal = useCallback(() => {
        dispatch({ type: DISMISS_PREMIUM_MODAL });
    }, []);

    const handleSignUpRedirect = ({ redirectDestination, authRedirectUrl, redirectUrl, callbackFunc }: SignUpRedirectConfig) => {
        if (callbackFunc) callbackFunc();
        if (!isAuthenticated) loginWithIdp(IdentityProvider.Schibsted, redirectDestination, redirectUrl);
        else if (authRedirectUrl) history.push(authRedirectUrl);
    };

    return {
        isAuthenticated,
        username,
        authChecked,
        isAuthLoading: isLoading,
        isFetchingUserData,
        isPremiumModalShown,
        hasActiveSubscription,
        hasActiveStudentSubscription,
        hasCancelledSubscription,
        isAuthSubmitting: isSubmitting,
        subscriptionStatusChecked,
        subscriptionStatusFetching,
        subscriptions,
        activeSubscription,
        episodeClicked,
        isNewUser,
        redirectUri,
        loginProvider: provider,
        userId,
        isUserLoggedInPremium,
        isUserLoggedInFreemium,
        isUserLoggedOut,
        showRedirectOverlay,
        loginUser,
        loginWithIdp,
        logoutUser,
        logoutUserWithError,
        logoutUserWithCustomRedirect,
        checkAuthStatus,
        resetPassword,
        showPremiumModal,
        dismissPremiumModal,
        refreshToken,
        handleSignUpRedirect,
    };
}
