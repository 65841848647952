import { ReactElement, useEffect, useRef, useState } from "react";

import { useMedia } from "../../hooks";
import useAuth from "../../auth/useAuth";
import DesktopHeader from "./components/DesktopHeader";
import MobileHeader from "./components/MobileHeader";
import { useSchibstedDataBanner } from "app/hooks/useSchibstedDataBanner";

export default function MainNavigation({ ...rest }): ReactElement {
    const { isLargeScreen } = useMedia();
    const { isAuthenticated, isAuthLoading, authChecked, username, loginProvider, hasActiveSubscription, logoutUser } = useAuth();
    const { showSchibstedBanner } = useSchibstedDataBanner();

    //passed down as prop to MobileHeader and DesktopHeader to control if navbar should be hidden or not
    const [scrollingUp, setScrollingUp] = useState<boolean>(true);
    const [navBarHeight, setNavBarHeight] = useState<number>(0);

    const previousScrollPosition = useRef(0);

    useEffect(() => {
        function showHideNavbar(): void {
            //always show navbar on top until scrolled down more than 500px
            if (isLargeScreen ? window.scrollY < navBarHeight + 100 : window.scrollY < navBarHeight) {
                if (scrollingUp) return;

                setScrollingUp(true);
            }

            if (window.scrollY > previousScrollPosition.current) {
                //dont 'update' the state by setting it to the same value as previous one
                if (scrollingUp) {
                    setScrollingUp(false);
                }
                previousScrollPosition.current = window.scrollY;
            } else {
                if (!scrollingUp) {
                    setScrollingUp(true);
                }
                previousScrollPosition.current = window.scrollY;
            }
        }

        function watchScroll() {
            window.addEventListener("scroll", showHideNavbar);
        }
        watchScroll();
        function watchNavigation() {
            window.addEventListener("popstate", showHideNavbar);
        }
        watchNavigation();

        return () => {
            window.removeEventListener("scroll", showHideNavbar);
            window.removeEventListener("popstate", showHideNavbar);
        };
    }, [scrollingUp]);

    return (
        <>
            {isLargeScreen ? (
                <DesktopHeader
                    isAuthenticated={isAuthenticated}
                    isAuthLoading={isAuthLoading && !authChecked}
                    username={username}
                    hasSubscription={hasActiveSubscription ?? false}
                    logoutUser={logoutUser}
                    showSchibstedBanner={showSchibstedBanner}
                    scrollingUp={scrollingUp}
                    setNavBarHeight={setNavBarHeight}
                    {...rest}
                />
            ) : (
                <MobileHeader
                    isAuthenticated={isAuthenticated}
                    username={username}
                    loginMethod={String(loginProvider)}
                    hasSubscription={hasActiveSubscription ?? false}
                    logoutUser={logoutUser}
                    showSchibstedBanner={showSchibstedBanner}
                    scrollingUp={scrollingUp}
                    setNavBarHeight={setNavBarHeight}
                    {...rest}
                />
            )}
        </>
    );
}
