import { useCallback, useMemo } from "react";
import { useLocation } from "react-router";
import Cookies from "universal-cookie";

export default function usePrevPath(): {
    prevPath: string | undefined;
    setPrevPath: (pathname: string) => void;
    removePrevPath: () => void;
} {
    const cookie = new Cookies();
    const location = useLocation<{ from?: { pathname: string } } | undefined>();

    const prevPath = useMemo<string | undefined>(() => {
        const prev = cookie.get("prevPath", { path: "/" }) ?? location?.state?.from?.pathname;
        return prev;
    }, [location]);

    const setPrevPath = useCallback((pathname: string) => {
        cookie.remove("prevPath", { path: "/" });
        cookie.set("prevPath", pathname, { path: "/" });
    }, []);

    const removePrevPath = useCallback(() => {
        cookie.remove("prevPath", { path: "/" });
    }, []);

    return {
        prevPath,
        setPrevPath,
        removePrevPath,
    };
}
