import useAuth from "@auth/useAuth";
import { RefObject, useState, useEffect } from "react";
import { useLocation } from "react-router";

type RefDimensions = {
    width: number;
    height: number;
};

const useRefDimensions = (ref: RefObject<HTMLDivElement>): RefDimensions => {
    const [dimensions, setDimensions] = useState<RefDimensions>({ width: 0, height: 0 });
    const location = useLocation();
    const { authChecked } = useAuth();

    useEffect(() => {
        if (ref.current) {
            const element = ref.current;
            const { width, height } = element.getBoundingClientRect();

            setDimensions((prev) => ({ ...prev, width, height }));
        }
    }, [ref.current?.clientHeight, ref.current?.clientWidth, location.pathname, authChecked]);

    return dimensions;
};

export default useRefDimensions;
