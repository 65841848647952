import { ReactElement } from "react";
import PodItem from "../../components/PodItem/PodItem";

interface Props {
    index: number;
    podcast: any;
    isBookmarked: boolean;
    addBookmark: (podId: any) => any;
    removeBookmark: (podId: any) => any;
}

export default function PodItemWrapper({ index, podcast, isBookmarked, addBookmark, removeBookmark }: Props): ReactElement {
    if (!podcast || (podcast && !podcast.smallImageUrl)) {
        return <PodItem key={index} />;
    }

    return (
        <PodItem
            key={podcast.id || podcast.podcastId}
            smallImgUrl={podcast.smallImageUrl}
            mediumImgUrl={podcast.mediumImageUrl}
            title={podcast.title}
            slug={podcast.slug}
            podId={podcast.id || podcast.podcastId}
            isPremium={podcast.isPremium}
            addBookmarkAction={addBookmark}
            removeBookmarkAction={removeBookmark}
            isBookmarked={isBookmarked}
            price={podcast.price}
            shadow
        />
    );
}
