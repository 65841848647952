import React, { FC, ReactElement, useEffect } from "react";
import { ModalHeader, ModalBody, Text, ModalCloseButton, Flex } from "@chakra-ui/react";
import strings from "@locale/localization";
import { PodmeColor } from "app/types";
import { SecondaryButton } from "@components/buttons";
import { isAndroid, isIOS } from "react-device-detect";
import config from "app/config";
import Cookies from "universal-cookie";

interface IProps {
    onClose: () => void;
}

const SuccessBox: FC<IProps> = ({ onClose }): ReactElement => {
    const { title, body, dismissBtn, openInAppBtn } = strings.migration.successBox;

    const isMobileDevice = isIOS || isAndroid;
    const handleOpenAppClick = () => {
        const cookies = new Cookies();
        const mobileMigrationSessionId = cookies.get("mobileMigrationSessionId") || "";
        const DEEP_LINK_URL = `${config.PUBLIC_URL}/migration-success?migrationSessionId=${mobileMigrationSessionId}`;
        // deeplink to the app //
        //
        // For some reason, the deeplink won't open the iOS app if I use the replace method on the test environment,
        // opening it on local environment works fine though so...
        // apparently opening the deep link in a new window seems to work on the test environment
        if (typeof window !== "undefined") {
            isIOS ? window.open(DEEP_LINK_URL, "_blank") : window.location.replace(DEEP_LINK_URL);
        }
    };

    useEffect(() => {
        const cookies = new Cookies();
        return () => {
            cookies.remove("mobileMigrationSessionId", { path: "/" });
        };
    }, []);

    return (
        <Flex direction="column" gridGap="1.5rem" padding="1.5rem" color={PodmeColor.SoftWhite}>
            <ModalCloseButton
                _focus={{ boxShadow: "none", outline: "none" }}
                onClick={() => {
                    onClose();
                }}
            />
            <ModalHeader p="0" maxWidth="90%">
                <Text fontSize={"clamp(18px, calc(2vw + 0.8rem), 24px)"} fontWeight={"bold"} lineHeight={"calc(1rem + 0.75em)"}>
                    {`${title} 🎉`}
                </Text>
            </ModalHeader>
            <ModalBody p="0" color={PodmeColor.Silver}>
                <Text fontSize={"clamp(14px, calc(2vw + 0.5rem), 16px)"}>{body}</Text>
            </ModalBody>
            <Flex justifyContent="flex-end" flexDirection={["column", "row"]} gridGap="1rem">
                {isMobileDevice && (
                    <SecondaryButton width={["100%", "50%"]} onClick={handleOpenAppClick}>
                        {openInAppBtn}
                    </SecondaryButton>
                )}
                <SecondaryButton
                    color={isMobileDevice ? PodmeColor.SoftWhite : PodmeColor.BlackPearl}
                    background={isMobileDevice ? PodmeColor.Transparent : PodmeColor.SoftWhite}
                    border={isMobileDevice ? `0.5px solid ${PodmeColor.Silver}` : undefined}
                    width={["100%", "50%"]}
                    onClick={onClose}
                >
                    {dismissBtn}
                </SecondaryButton>
            </Flex>
        </Flex>
    );
};

export default SuccessBox;
