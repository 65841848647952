import "./GlobalErrorBoundaryFallbackPage.scss";
import strings from "app/locale/localization";

function GlobalErrorBoundaryFallbackPage() {
    const { headingMessage, subHeadingMessage, explanation, suggestion, refresh } = strings.errorBoundary;
    const handleClick = () => window.location.reload();
    return (
        <div className="error-boundary-container">
            <div className="error-boundary-top-bar">
                <img src="/assets/images/brand/podme-logo-white.svg" alt="Podme" width="auto" height="30" />
            </div>
            <div className="error-boundary-content">
                <h1 className="error-boundary-header">{headingMessage}</h1>
                <h2 className="error-boundary-subheader" id="headline_msg">
                    {subHeadingMessage}
                </h2>
                <div className="error-boundary-brake-line"></div>
                <p className="error-boundary-paragraph" id="p_msg_1">
                    {explanation}
                </p>
                <p className="error-boundary-paragraph" id="p_msg_2">
                    {suggestion}
                </p>
                <button className="error-boundary-refresh-btn" id="refresh_button" onClick={handleClick}>
                    {refresh}
                </button>
            </div>
        </div>
    );
}

export default GlobalErrorBoundaryFallbackPage;
