import { Episode } from "@typings/index";
import { RootState } from "app/reducers/rootReducer";
import { call, put, select, takeLatest } from "redux-saga/effects";

import routes from "../../../api";
import {
    GetEpisode,
    GetEpisodeFailed,
    GetEpisodeSuccess,
    GET_EPISODE,
    GET_EPISODE_FAILED,
    GET_EPISODE_SUCCESS,
    TrackPlayback,
    TRACK_PLAYBACK,
    TRACK_PLAYBACK_FAILED,
    TRACK_PLAYBACK_SUCCESS,
} from "./GlobalPlayerTypes";

const authSelector = (state: RootState) => state.auth.isAuthenticated;
const playerSelector = (state: RootState) => state.globalPlayer;

export function* getEpisodeById({ episodeId }: GetEpisode): any {
    try {
        const isAuthenticated = yield select(authSelector);
        let episode: Episode;

        if (isAuthenticated) {
            episode = yield call(routes.getEpisodeAuth, episodeId);
        } else {
            episode = yield call(routes.getEpisode, episodeId);
        }

        yield put<GetEpisodeSuccess>({
            type: GET_EPISODE_SUCCESS,
            playObject: {
                id: episode.id,
                title: episode.title,
                slug: episode.slug,
                podcastTitle: episode.podcastTitle,
                isPremium: episode.isPremium,
                imageUrl: episode.smallImageUrl,
                streamUrl: episode.streamUrl,
                length: episode.length,
                currentSpot: episode.currentSpot,
            },
        });
    } catch (error) {
        return yield put<GetEpisodeFailed>({ type: GET_EPISODE_FAILED, error });
    }
}

export function* trackPlayback({ episodeId, currentSpot, hasCompleted }: TrackPlayback): any {
    try {
        const { playObject } = yield select(playerSelector);

        const data = {
            episodeId,
            currentSpot,
            hasCompleted,
        };

        data.currentSpot = data.currentSpot.length < 8 ? `00:${data.currentSpot}` : data.currentSpot;
        playObject.currentSpot = data.currentSpot;

        yield call(routes.playerUpdate, data);

        return yield put({ type: TRACK_PLAYBACK_SUCCESS, playObject, episodeId, hasCompleted });
    } catch (error) {
        return yield put({ type: TRACK_PLAYBACK_FAILED, error });
    }
}

export default [takeLatest(GET_EPISODE, getEpisodeById), takeLatest(TRACK_PLAYBACK, trackPlayback)];
