import { useMemo, useCallback, ReactElement } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import AudioSlider from "../AudioSlider/AudioSlider";
import { limitCopyLength } from "../../utils";
import { useMedia } from "../../hooks";
import { useSound } from "../../containers/SoundProvider";
import { CSSstylesObject, DeviceBreakpoints, PodmeColor } from "../../types/index";
interface PodcastAudioPlayerProps {
    trackUrl: string;
    trackName: string;
    isDarkBg: boolean;
    [x: string]: any;
}
const PLAY_ICON_WIDTH_WIDESCREEN = "20px";
const PLAY_ICON_WIDTH_SMALLSCREEN = "15px";
const styles: CSSstylesObject = {
    audioPlayerContainer: {
        position: "relative",
        padding: "1rem 0 2rem",
        overflow: "hidden",
    },
    trackName: {
        margin: 0,
        fontSize: "0.9em",
        fontWeight: 400,
        position: "absolute",
        left: "3.5rem",
        top: "1rem",
        color: PodmeColor.BlackPearl,
        width: "100%",
        sx: {
            [`@media ${DeviceBreakpoints.MediumDown}`]: {
                left: " 22%",
                top: "0.6rem",
            },

            [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                left: "25%",
                fontSize: "0.6em",
            },
        },
    },
    audioPlayerPlay: {
        position: "relative",
        backgroundColor: PodmeColor.BlackPearl,
        borderRadius: "50%",
        cursor: "pointer",
        transition: "transform 0.2s",
        zIndex: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // -webkit-tap-highlight-color: rgba(0, 0, 0, 0),
        // -webkit-tap-highlight-color: transparent,

        sx: {
            "&:hover": {
                transform: "scale(0.9)",
            },
            [`@media ${DeviceBreakpoints.SmallOnly}`]: {
                " &:hover": {
                    transform: "none",
                },
            },
        },
    },
    playIcon: {
        width: PLAY_ICON_WIDTH_WIDESCREEN,
        maxWidth: "100%",
        height: PLAY_ICON_WIDTH_WIDESCREEN,
        maxHeight: "100%",
        transition: "border 200ms",
        sx: {
            [`@media ${DeviceBreakpoints.MediumDown}`]: {
                width: PLAY_ICON_WIDTH_SMALLSCREEN,
                height: PLAY_ICON_WIDTH_SMALLSCREEN,
            },
        },
    },
    playIconPlay: {
        borderRight: "0 solid transparent",
        borderTop: `10px solid transparent`,
        borderBottom: `10px solid transparent`,
        borderLeft: `20px solid ${PodmeColor.White}`,
        marginLeft: "5px",
        sx: {
            [`@media ${DeviceBreakpoints.MediumDown}`]: {
                height: "15px",
                marginLeft: "10px",
                borderTop: `8px solid transparent`,
                borderBottom: `8px solid transparent`,
                borderLeft: `15px solid ${PodmeColor.White}`,
            },
        },
    },
    playIconPause: {
        borderTop: "0 solid transparent",
        width: `calc(${PLAY_ICON_WIDTH_WIDESCREEN} - 2px)`,
        borderRight: `5px solid ${PodmeColor.White}`,
        borderLeft: `5px solid ${PodmeColor.White}`,
        borderBottom: "0 solid transparent",
        sx: {
            [`@media ${DeviceBreakpoints.MediumDown}`]: {
                width: `13px`,
                height: "15px",
                borderRight: `4px solid ${PodmeColor.White}`,
                borderLeft: `4px solid ${PodmeColor.White}`,
            },
        },
    },
};

export default function PodcastAudioPlayer({ trackUrl, trackName, isDarkBg, podId }: PodcastAudioPlayerProps): ReactElement {
    const { isSmallScreen } = useMedia();
    const { state: sound, load, play, pause, setPlayPosition } = useSound();

    const isPlaying = useMemo(
        () => sound.isPlaying && sound.currentAudioId === String(podId),
        [sound.isPlaying, sound.currentAudioId, podId]
    );

    const playMedia = useCallback(() => {
        if (trackUrl) {
            load(podId, trackUrl);

            if (isPlaying) {
                pause();
            } else {
                play(podId);
            }
        }
    }, [isPlaying, podId, trackUrl]);

    const setPosition = useCallback(
        (val: number) => {
            setPlayPosition(val);
        },
        [isPlaying]
    );

    return (
        <>
            <Box {...styles.audioPlayerContainer} color={isDarkBg ? PodmeColor.White : null}>
                <Text {...styles.trackName} noOfLines={1}>
                    {isSmallScreen ? limitCopyLength(trackName, 18) : limitCopyLength(trackName, 25)}
                </Text>
                <Flex display={"flex"}>
                    <Box
                        as="button"
                        {...styles.audioPlayerPlay}
                        data-testid="play-button"
                        flex={["0 0 30px", null, "0 0 50px"]}
                        boxSize={["30px", null, "50px"]}
                        onClick={playMedia}
                    >
                        <Box
                            {...styles.playIcon}
                            data-testid={isPlaying ? "pause" : "play"}
                            {...(isPlaying && styles.playIconPause)}
                            {...(!isPlaying && styles.playIconPlay)}
                        />
                    </Box>
                    <AudioSlider
                        value={isPlaying ? sound.currentPosition : 0}
                        audioTrackChanging={false}
                        filledColor={PodmeColor.BlackPearl}
                        thumbColor={PodmeColor.White}
                        trackColor={PodmeColor.Silver}
                        hideThumb={true}
                        height="5px"
                        marginLeft="-1px"
                        handleOnChange={setPosition}
                    />
                </Flex>
            </Box>
        </>
    );
}
