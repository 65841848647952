import {
    GET_CONTENT,
    GET_CONTENT_FAILED,
    GET_CONTENT_SUCCESS,
    GET_PREVIEW_CONTENT,
    GET_PREVIEW_CONTENT_FAILED,
    GET_PREVIEW_CONTENT_SUCCESS,
    HomescreenState,
} from "./types";

const initialState: HomescreenState = {
    sections: [],
    isFetching: false,
};

function homescreenReducer(state = initialState, action: any): any {
    switch (action.type) {
        case GET_CONTENT:
        case GET_PREVIEW_CONTENT:
            return {
                ...state,
                isFetching: true,
            };

        case GET_CONTENT_SUCCESS:
        case GET_PREVIEW_CONTENT_SUCCESS:
            return {
                ...state,
                sections: action.content.sections,
                isFetching: false,
            };

        case GET_CONTENT_FAILED:
        case GET_PREVIEW_CONTENT_FAILED:
            return {
                ...state,
                isFetching: false,
            };

        default:
            return state;
    }
}

export default homescreenReducer;
