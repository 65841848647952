import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import strings from "@locale/localization";
import { SecondaryButton } from "@components/buttons";
import { useMedia } from "../../../hooks";
import { PodmeColor } from "app/types";
import { ReactElement } from "react";
import { selectItemEventAction } from "app/tracking/trackingActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/reducers/rootReducer";
import CTACheckoutButton from "@components/CTACheckoutButton";
import { LandingHeroContent } from "@containers/Homescreen/redux/types";
import { Prose } from "@nikolovlazar/chakra-ui-prose";

interface Props {
    cmsContent: LandingHeroContent;
    isFirstSection: boolean;
}

const globalState = (state: RootState) => state.global;

export default function HeroSection({ cmsContent, isFirstSection }: Props): ReactElement {
    const dispatch = useDispatch();
    const { headline, lead, cta } = cmsContent;
    const { currency, packageId, price } = useSelector(globalState);
    const { isSmallScreen, isLargeScreen } = useMedia();

    const { locale, search } = strings.routes;
    const { heroSection } = strings.startPage;
    const { secondaryCTA } = heroSection;

    return (
        <Flex
            as={"section"}
            minHeight={["calc(100vh - 72px)", "50vh", "calc(100vh - 72px)", "50rem", "50vh"]}
            direction={["column", "row"]}
            justifyContent={["flex-start", "flex-end", null, null, "center"]}
            alignItems={["flex-start", "center"]}
            position="relative"
            padding={["0 1rem", "4rem 2rem", "0"]}
            marginBottom={["0", "2.5rem", "0"]}
            gridGap={["4rem", "unset", "4.5rem"]}
        >
            <Box
                position={["relative", "absolute", null, null, "static"]}
                zIndex="1"
                left="0"
                top="50%"
                transform={["unset", "translate(-50%, -50%)", "translate(calc(-50% - 8.5vw), -50%)", null, "unset"]}
                minWidth={["unset", "200px", "350px"]}
                maxWidth={["760px", null, "760px", null, "350px"]}
                width={["100%", "clamp(200px, 40vw, 360px)", "clamp(280px, 60vw, 760px)", null, "100%"]}
                marginTop={["-50%", "unset"]}
                clipPath={isFirstSection ? undefined : ["polygon(0 50%, 100% 50%, 100% 100%, 0% 100%)", "unset"]}
                sx={{
                    "@media screen and (min-width: 1921px)": {
                        transform: "unset !important",
                        position: "static",
                        maxWidth: "350px !important",
                    },
                    "@media screen and (min-width: 1730px)": {
                        transform: "translate(calc(-50% - (100vw - 1440px) / 2), -50%)",
                    },
                }}
            >
                <Image alt="podme diamond logo" src="/assets/images/brand/diamond_logo.svg" width="100%" />
            </Box>
            <Box as="section" aria-labelledby="top-section-header" maxWidth={["100%", "70vw", "55vw", null, "50rem"]} width="100%">
                <Flex direction="column" gridGap="2rem">
                    <Flex direction="column" gridGap="1rem">
                        <Heading id="top-section-header" as="h1" fontSize={["2.25rem", null, "5rem"]} color={PodmeColor.Sand}>
                            {headline}
                        </Heading>
                        <Prose
                            sx={{
                                p: {
                                    fontWeight: "500",
                                    fontSize: "1.25rem",
                                },
                            }}
                            maxWidth={["100%", null, "75%"]}
                            dangerouslySetInnerHTML={{ __html: lead }}
                        />
                    </Flex>
                    <Flex gridGap={["0.75rem", null, "1.5rem"]} direction={["column", "row"]}>
                        <CTACheckoutButton
                            renderAs={SecondaryButton}
                            data-testid="hero-section-get-premium-cta-button"
                            background={isSmallScreen ? PodmeColor.White : PodmeColor.SoftWhite}
                            color={PodmeColor.BlackPearl}
                            onClickCallback={() =>
                                dispatch(
                                    selectItemEventAction(
                                        `${isLargeScreen ? "Desktop" : "Mobile"} / Start Page / Conversion Banner / Primary CTA Button`,
                                        String(packageId),
                                        currency,
                                        price
                                    )
                                )
                            }
                        >
                            <Text noOfLines={1} as="span" display="block">
                                {cta.text}
                            </Text>
                        </CTACheckoutButton>
                        <SecondaryButton
                            data-testid="hero-section-search-cta-button"
                            to={`/${locale}/${search}`}
                            background={PodmeColor.LicoriceBlue}
                            color={PodmeColor.SoftWhite}
                            onClick={() => {
                                dispatch(
                                    selectItemEventAction(
                                        `${isLargeScreen ? "Desktop" : "Mobile"}  / Start Page / Hero Section / Searchpage CTA button`,
                                        String(packageId),
                                        currency,
                                        price
                                    )
                                );
                            }}
                        >
                            <Text noOfLines={1} as="span" display="block">
                                {secondaryCTA}
                            </Text>
                        </SecondaryButton>
                    </Flex>
                </Flex>
            </Box>
        </Flex>
    );
}
