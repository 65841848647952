import { Portal, Flex, Box, keyframes } from "@chakra-ui/react";
import { PodmeColor } from "@typings/index";
import strings from "app/locale/localization";
import { useEffect } from "react";

export function SchibstedLoginOverlay() {
    const { redirectingToSchibsted } = strings;
    const fadeIn = keyframes`
        to { opacity: 1; }
    `;

    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    return (
        <Portal>
            <Flex
                opacity="0"
                position="fixed"
                height="100vh"
                width="100%"
                overflowY="auto"
                direction="column"
                alignItems="center"
                justifyContent="center"
                gridGap="1.5rem"
                zIndex="1500"
                inset="0"
                backgroundColor="#fff"
                color={PodmeColor.BlackPearl}
                paddingTop="10rem"
                animation={`${fadeIn} 0.15s ease-in forwards`}
            >
                <DotsLoader />
                <Box as="span" fontSize="0.75rem" fontFamily="Inter" fontWeight="400">
                    {redirectingToSchibsted}
                </Box>
            </Flex>
        </Portal>
    );
}
function DotsLoader() {
    const animation = keyframes`
        from {
            transform: translateY(-0.05rem);
            opacity: 1;
            transform: scale(1);
            color: ${PodmeColor.BlackPearl};
        }
        to {
            transform: translateY(0.05rem);
            opacity: 0.05;
            transform: scale(0.75);
            color: ${PodmeColor.Platina};
        }
    `;

    const dotStyles = {
        width: "0.5rem",
        height: "0.5rem",
        borderRadius: "50%",
        backgroundColor: PodmeColor.BlackPearl,
        animation: `${animation} 0.75s infinite alternate`,
    };

    return (
        <Flex gridGap="0.375rem" alignItems="center">
            <Box {...dotStyles} />
            <Box {...dotStyles} sx={{ animationDelay: "0.2s" }} />
            <Box {...dotStyles} sx={{ animationDelay: "0.4s" }} />
        </Flex>
    );
}
