import { call, CallEffect, delay, PutEffect, select, SelectEffect, take, TakeEffect, takeLatest } from "redux-saga/effects";

import { types as settingsTypes } from "../containers/SettingsPage/redux";
import { SEARCH_PODCAST_SUCCESS } from "../containers/SearchPage/redux/types";

import {
    VIEW_ITEM,
    SELECT_ITEM,
    BEGIN_CHECKOUT,
    ADD_PAYMENT_INFO,
    PURCHASE,
    EcommerceEvent,
    COUPON_INTERACTION,
    TRACKING_EVENT,
    ADD_TO_CART,
    UPDATE_CONSENT_MARKETING,
    UPDATE_CONSENT_ANALYTICS,
    UpdateConsentAnalytics,
    UpdateConsentMarketing,
    UPDATE_CONSENT_BRAZE,
    UpdateConsentBraze,
    REWARD_VOUCHER_INTERACTION,
} from "./trackingTypes";
import {
    CHECK_AUTH_STATUS_SUCCESS,
    IdentityProvider,
    LoginUserSuccess,
    LoginWithIdpSuccess,
    LOGIN_USER_SUCCESS,
    LOGIN_WITH_IDP_SUCCESS,
    GET_USER_DATA_SUCCESS,
} from "../auth/authTypes";

import { PLAY_INTERRUPTED_OFFLINE } from "@containers/GlobalPlayer/redux/GlobalPlayerTypes";
import { RootState } from "app/reducers/rootReducer";
import {
    ApplyRewardVoucherSuccess,
    ApplyVoucherSuccess,
    EnvoyEventName,
    RemoveVoucherSuccess,
    RenewSubscriptionSuccess,
} from "app/api/types";
import strings from "app/locale/localization";
import { getEnvoySession, isOnStudentFlow, isOnSubflow } from "@utils/index";
import { isIOS } from "react-device-detect";
import routes from "app/api";
import { SagaReturnType } from "app/types";
import appInsights from "app/config/appInsights";
import { BrazeService } from "app/services/brazeService";
import { ConsentCategory } from "app/contexts/PrivacyProvider";

const authSelector = (state: RootState) => state.auth;

type AuthState = ReturnType<typeof authSelector>;
type SagaEffect = PutEffect | TakeEffect | SelectEffect | CallEffect;

function pushToDataLayer(event: { event?: string; [x: string]: unknown } | EcommerceEvent) {
    const dataLayer = window.dataLayer || [];
    dataLayer.push(event);
}

function pushGtagToDataLayer(..._args: unknown[]) {
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
}

function pushToBraze(userSdrn: string, consentPayload: { key: string; value: boolean }[]) {
    const brazeService = BrazeService.getInstance();
    brazeService.changeUser(userSdrn);
    brazeService.setUserAttributes(consentPayload);
}

function sendEnvoyEvent(eventName: EnvoyEventName, envoy_share_link_hash: string, userId: string) {
    return routes.postEnvoyEvent({
        eventName,
        hashedShareLink: envoy_share_link_hash,
        sharerId: userId,
        extraData: {
            campaign: "(referral)",
            medium: "referral",
            source: "envoy.com",
            envoy_sharing_flow: true,
        },
    });
}

export function* cancelRenewal(): Generator<CallEffect<void>> {
    const event = {
        event: "cancel_subscription",
    };

    yield call(pushToDataLayer, event);
}

export function* reactivateRenewal({ userId }: { type: string; userId: string }): Generator<SagaEffect> {
    const event = {
        event: "reactivate_subscription",
        user_id: userId,
    };

    yield call(pushToDataLayer, event);
}

export function* fireEvent({ event, ...rest }: { type: string; event: string }): Generator<SagaEffect> {
    const eventBody = {
        event,
        ...rest,
    };

    yield call(pushToDataLayer, eventBody);
}

export function* playMedia({ podId, podTitle }: { type: string; podId: number; podTitle: string }): Generator<SagaEffect> {
    const event = {
        event: "play_media",
        podcastId: podId,
        podcastTitle: podTitle,
    };

    yield call(pushToDataLayer, event);
}

export function* login({ provider, username, accountCreated }: LoginWithIdpSuccess & LoginUserSuccess): Generator<SagaEffect> {
    const subflow = isOnSubflow();

    yield take(CHECK_AUTH_STATUS_SUCCESS);
    yield take(GET_USER_DATA_SUCCESS);

    const { userId } = (yield select(authSelector)) as AuthState;
    const { is_on_envoy_flow, envoy_share_link_hash } = getEnvoySession();
    const studentflow = isOnStudentFlow();

    const event = {
        event: accountCreated ? "sign_up" : "login",
        method: provider ?? IdentityProvider.Email,
        user_email: username,
        user_id: userId,
        ios_subscription_flow: isIOS && subflow,
        envoy_sharing_flow: is_on_envoy_flow,
        student_flow: studentflow,
    };

    if (accountCreated && is_on_envoy_flow) {
        yield call(sendEnvoyEvent, "account_created", envoy_share_link_hash, userId);
    }

    yield delay(1000);

    return yield call(pushToDataLayer, event);
}

export function* search({ searchValue }: { type: string; searchValue: string }): Generator<SagaEffect> {
    const event = {
        event: "search",
        search_term: searchValue,
    };

    yield call(pushToDataLayer, event);
}

export function* couponInteraction({ type, couponName }: { type: string; couponName: string }): Generator<SagaEffect> {
    let event = {};
    switch (type) {
        case COUPON_INTERACTION:
            event = {
                event: "coupon_interaction",
                coupon_value: couponName,
            };
            break;
        case REWARD_VOUCHER_INTERACTION:
            event = {
                event: "reward_voucher_interaction",
                coupon_value: couponName,
            };
            break;
    }

    yield call(pushToDataLayer, event);
}

export function* voucherApplied({
    eventName,
    couponName,
}: {
    type: string;
    eventName: string;
    couponName: string;
}): Generator<SagaEffect> {
    const event = {
        event: eventName,
        coupon_value: couponName,
    };

    yield call(pushToDataLayer, event);
}

export function* voucherRemoved({}: { type: string }): Generator<SagaEffect> {
    const event = {
        event: "coupon_removed",
    };

    yield call(pushToDataLayer, event);
}

export function* sendEcommerceEvent({ event }: { type: string; event: EcommerceEvent }): Generator<SagaEffect> {
    if (event.event === "purchase") {
        const subflow = isOnSubflow();
        const { is_on_envoy_flow, envoy_share_link_hash } = getEnvoySession();
        const { userId } = (yield select(authSelector)) as AuthState;

        event.envoy_sharing_flow = is_on_envoy_flow;

        if (is_on_envoy_flow) {
            yield call(sendEnvoyEvent, "trial_activated", envoy_share_link_hash, userId);
        }

        event.ios_subscription_flow = isIOS && subflow;
    }

    yield call(pushToDataLayer, { ecommerce: null });
    yield call(pushToDataLayer, event);
}

export function* sendSpaPageView({
    payload,
}: {
    type: string;
    payload: { isFirstRendering: boolean };
}): Generator<undefined | CallEffect<void> | SagaEffect> {
    const { isFirstRendering } = payload;

    if (isFirstRendering) return yield;

    yield take(CHECK_AUTH_STATUS_SUCCESS);
    yield take(GET_USER_DATA_SUCCESS);

    // we need to wait for the subscription status to be fetched and updated in the session storage
    yield delay(1000);

    const { isAuthenticated, hasActiveSubscription, subflow } = sessionStorage;
    const { is_on_envoy_flow } = getEnvoySession();
    const { userId } = (yield select(authSelector)) as AuthState;

    const checkBoolean = (value: string) => value === "true";

    const pagePath = document.location.pathname + document.location.search;
    const { congrats, signup, login } = strings.routes;
    const isOnSubflow =
        !!subflow &&
        (pagePath.includes("premium") ||
            pagePath.includes(congrats) ||
            pagePath.includes(signup + "/checkout") ||
            pagePath.includes(signup) ||
            pagePath.includes(login));

    const event = {
        event: "virtualPageview",
        type: "spa-page-load",
        originalLocation: originalLocation,
        pagePath,
        ios_subscription_flow: isOnSubflow,
        logged_in: checkBoolean(isAuthenticated),
        userId,
        user: {
            is_premium: checkBoolean(hasActiveSubscription),
        },
        envoy_sharing_flow: is_on_envoy_flow,
    };

    yield call(pushToDataLayer, event);
}

export function* playInterruptedOffline(): Generator<CallEffect<void>> {
    const event = {
        event: "play_interrupted_offline",
    };

    yield call(pushToDataLayer, event);
}

function* updateConsentMarketing({ value }: UpdateConsentMarketing): SagaReturnType<void> {
    try {
        yield delay(1000);
        if (value === "1") {
            localStorage.setItem(ConsentCategory.Marketing, "1");
            pushGtagToDataLayer("consent", "update", {
                ad_storage: "granted",
                ad_user_data: "granted",
                ad_personalization: "granted",
            });
        } else {
            localStorage.setItem(ConsentCategory.Marketing, "0");
            pushGtagToDataLayer("consent", "update", { ad_storage: "denied", ad_user_data: "denied", ad_personalization: "denied" });
        }

        pushToDataLayer({ event: "CMP_Marketing_Consent_Updated" });
    } catch (error) {
        appInsights.trackException({
            exception: new Error(`${UPDATE_CONSENT_MARKETING} - ${error}`),
        });
    }
}

function* updateConsentBraze({ value, userSdrn }: UpdateConsentBraze): SagaReturnType<void> {
    try {
        if (!userSdrn) return;
        if (value === "1")
            pushToBraze(userSdrn, [
                { key: "$google_ad_user_data", value: true },
                { key: "$google_ad_personalization", value: true },
            ]);
        else
            pushToBraze(userSdrn, [
                { key: "$google_ad_user_data", value: false },
                { key: "$google_ad_personalization", value: false },
            ]);
    } catch (error) {
        appInsights.trackException({
            exception: new Error(`${UPDATE_CONSENT_BRAZE} - ${error}`),
        });
    }
}

function* updateConsentAnalytics({ value }: UpdateConsentAnalytics): SagaReturnType<void> {
    try {
        yield delay(1000);
        if (value === "1") {
            localStorage.setItem(ConsentCategory.Analytics, "1");
            pushGtagToDataLayer("consent", "update", { analytics_storage: "granted" });
        } else {
            localStorage.setItem(ConsentCategory.Analytics, "0");
            pushGtagToDataLayer("consent", "update", { analytics_storage: "denied" });
        }
        pushToDataLayer({ event: "CMP_Analytics_Consent_Updated" });
    } catch (error) {
        appInsights.trackException({
            exception: new Error(`${UPDATE_CONSENT_ANALYTICS} - ${error}`),
        });
    }
}

export default [
    takeLatest(VIEW_ITEM, sendEcommerceEvent),
    takeLatest(SELECT_ITEM, sendEcommerceEvent),
    takeLatest(BEGIN_CHECKOUT, sendEcommerceEvent),
    takeLatest(ADD_PAYMENT_INFO, sendEcommerceEvent),
    takeLatest(ADD_TO_CART, sendEcommerceEvent),
    takeLatest(PURCHASE, sendEcommerceEvent),
    takeLatest(settingsTypes.CANCEL_RENEWAL, cancelRenewal),
    takeLatest(settingsTypes.REACTIVATE_RENEWAL, reactivateRenewal),
    takeLatest<RenewSubscriptionSuccess>("api/RENEW_SUBSCRIPTION_SUCCESS", reactivateRenewal),
    takeLatest(LOGIN_USER_SUCCESS, login),
    takeLatest(LOGIN_WITH_IDP_SUCCESS, login),
    takeLatest(SEARCH_PODCAST_SUCCESS, search),
    takeLatest<ApplyVoucherSuccess>("api/APPLY_VOUCHER_SUCCESS", voucherApplied),
    takeLatest<ApplyRewardVoucherSuccess>("api/APPLY_REWARD_VOUCHER_SUCCESS", voucherApplied),
    takeLatest<RemoveVoucherSuccess>("api/REMOVE_VOUCHER_SUCCESS", voucherRemoved),
    takeLatest(COUPON_INTERACTION, couponInteraction),
    takeLatest(REWARD_VOUCHER_INTERACTION, couponInteraction),
    takeLatest(TRACKING_EVENT, fireEvent),
    takeLatest("@@router/LOCATION_CHANGE", sendSpaPageView),
    takeLatest(PLAY_INTERRUPTED_OFFLINE, playInterruptedOffline),
    takeLatest(UPDATE_CONSENT_MARKETING, updateConsentMarketing),
    takeLatest(UPDATE_CONSENT_BRAZE, updateConsentBraze),
    takeLatest(UPDATE_CONSENT_ANALYTICS, updateConsentAnalytics),
];
