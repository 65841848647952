import { Category, Podcast } from "@typings/index";
import { useEffect, useState } from "react";
import strings from "app/locale/localization";
import { getProperCategoryKey } from "@utils/index";

export default function useSetCorrectLanguageCategories(podcast: Podcast | undefined): Category[] {
    const { categories } = strings;
    const [categoriesToDisplayToUser, setCategoriesToDisplayToUser] = useState<Category[]>(podcast?.categories ?? []);
    const [langMismatch, setLangMismatch] = useState<boolean>(false);

    useEffect(() => {
        if (podcast && podcast.categories) {
            setCategoriesToDisplayToUser(() => {
                podcast.categories.forEach((cat) => {
                    if (!cat.name || cat.name === "") cat.name = categories[getProperCategoryKey(cat.key) as keyof typeof categories];
                    return cat;
                });
                return podcast.categories;
            });
        }
    }, [podcast]);

    useEffect(() => {
        if (categoriesToDisplayToUser && categoriesToDisplayToUser.length > 0) {
            const mismatchInLanguage = !!categoriesToDisplayToUser?.find((cat) => {
                return !Object.values(categories).includes(cat.name);
            });
            setLangMismatch(mismatchInLanguage);
        }
    }, [categoriesToDisplayToUser, podcast?.categories]);

    useEffect(() => {
        if (langMismatch && podcast?.categories) {
            setCategoriesToDisplayToUser(
                podcast.categories.map((cat: Category) => ({
                    id: cat.id,
                    key: cat.key,
                    name: categories[getProperCategoryKey(cat.key) as keyof typeof categories],
                }))
            );
        }
    }, [categories, podcast, langMismatch]);

    return categoriesToDisplayToUser;
}
