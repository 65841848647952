import routes from "@api/index";
import { Episode } from "@typings/index";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

export function useGetEpisode(episodeId: number | string, isAuthenticated: boolean): UseQueryResult<Episode> {
    return useQuery(
        ["episode-data", episodeId],
        () => (isAuthenticated ? routes.getEpisodeAuth(Number(episodeId)) : routes.getEpisode(Number(episodeId))),
        {
            staleTime: Infinity,
        }
    );
}
