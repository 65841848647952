import { ReactElement, ReactNode } from "react";
import { Box, Flex, BoxProps } from "@chakra-ui/react";
import PulseLoader from "react-spinners/PulseLoader";
import { PodmeColor } from "../../types";

//extends from BoxProps to make Typescript happy with Chakra types
interface Props extends BoxProps {
    isLoading: boolean;
    children?: ReactNode;
    spinnerColor?: string;
}

function Spinner({ isLoading, children, spinnerColor, ...props }: Props): ReactElement {
    return (
        <Box
            background={"transparent"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            position="relative"
            {...props}
        >
            {isLoading && (
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    width="100%"
                    position="absolute"
                    top="0"
                    bottom="0"
                    left="0"
                    right="0"
                    backgroundColor="inherit"
                    zIndex={5}
                >
                    <PulseLoader size={15} color={spinnerColor ? spinnerColor : PodmeColor.Cyan} loading />
                </Flex>
            )}
            {children}
        </Box>
    );
}

export default Spinner;
