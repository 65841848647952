import { Box } from "@chakra-ui/react";
import { CSSstylesObject, PodmeColor } from "@typings/index";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";

interface Props {
    isToggled: boolean;
    onClick: (evt: ChangeEvent<EventTarget>) => void;
    [x: string]: any;
}
const styles: CSSstylesObject = {
    hamburgerButton: {
        height: "100%",
        zIndex: 99,
        // -webkit-tap-highlight-color: rgba(0, 0, 0, 0),
        // -webkit-tap-highlight-color: transparent,
        display: "flex",
        alignItems: "center",
    },
    hamburgerButtonContainer: {
        position: "relative",
        display: "inline-block",
        width: "1.5rem",
        height: "10px",
    },

    hamburgerButtonInner: {
        position: "absolute",
        top: 0,
        display: "block",
        marginTop: "-3px",
        width: "1.5rem",
        height: "2px",
        backgroundColor: PodmeColor.SoftWhite,
        transition: "transform 75ms, background-color 200ms ease-out 300ms",
        rounded: "3px",
    },
    hamburgerButtonInnerIsActive: {
        top: "5px",
        backgroundColor: PodmeColor.White,
        transitionDelay: "0.12s",
        transitionTimingFunction: "cubic-bezier(0.215, 0.61, 0.355, 1)",
        transform: "rotate(45deg)",
    },
    hamburgerButtonInnerAfter: {
        content: '""',
        display: "block",
        position: "absolute",
        width: "1.5rem",
        height: "2px",
        backgroundColor: PodmeColor.White,
        transition: "transform 75ms, background-color 200ms ease-out 300ms",
        top: "9px",
        rounded: "3px",
    },
    hamburgerButtonInnerAfterIsActive: {
        backgroundColor: PodmeColor.White,
        top: 0,
        transition: "transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s",
        transform: "rotate(-90deg)",
    },
};

export default function HamburgerButton({ isToggled, onClick, ...rest }: Props): ReactElement {
    const [isActive, setIsActive] = useState<boolean>(isToggled);

    useEffect(() => {
        setIsActive(isToggled);
    }, [isToggled]);

    return (
        <Box as={"button"} aria-label="hamburger menu button" onClick={onClick} {...styles.hamburgerButton} {...rest}>
            <Box {...styles.hamburgerButtonContainer}>
                <Box
                    {...styles.hamburgerButtonInner}
                    {...(isActive && styles.hamburgerButtonInnerIsActive)}
                    //after pseudoelement styles:
                    _after={{
                        ...styles.hamburgerButtonInnerAfter,
                        ...(isActive && styles.hamburgerButtonInnerAfterIsActive),
                    }}
                    as="span"
                />
            </Box>
        </Box>
    );
}
