import { Box } from "@chakra-ui/react";
import { ReactElement } from "react";
import { useMedia } from "../../hooks";

interface Props {
    padTop?: number;
    padBot?: number;
    fixed?: boolean;
    children: any;
    [x: string]: any;
}

function Spacer({ padTop = 0, padBot = 1, children, fixed, ...rest }: Props): ReactElement {
    const { isSmallScreen } = useMedia();

    const paddingTop = isSmallScreen && !fixed ? padTop / 2 : padTop;
    const paddingBot = isSmallScreen && !fixed ? padBot / 2 : padBot;

    return (
        <Box
            style={{
                paddingTop: padTop >= 0 ? `${paddingTop}rem` : 0,
                paddingBottom: padBot >= 0 ? `${paddingBot}rem` : "1rem",
            }}
            {...rest}
        >
            {children}
        </Box>
    );
}

export default Spacer;
