import { ChangeEvent, ReactElement, useContext, useEffect, useState } from "react";
import { Box, Flex, FormControl, FormLabel, Select, Switch } from "@chakra-ui/react";
import { useDispatch } from "react-redux";

import Homescreen from "@containers/Homescreen";
import { PodmeColor, Region } from "@typings/index";
import { getPreviewContent } from "@containers/Homescreen/redux/actions";
import strings from "@locale/localization";
import { getLocale } from "@utils/index";
import { ThemeContext } from "@emotion/react";

enum ViewportDevice {
    Mobile = "mobile",
    Tablet = "tablet",
    Desktop = "desktop",
}

interface Settings {
    hasSubscription: boolean;
    region: Region;
    viewport: ViewportDevice;
}

export default function CmsPreviewPage(): ReactElement {
    const dispatch = useDispatch();
    const [viewportSize, setViewportSize] = useState<string>("100vw");

    const [settings, updateSettings] = useState<Settings>({
        hasSubscription: false,
        region: Region.Sweden,
        viewport: ViewportDevice.Desktop,
    });

    const themeContext = useContext<any>(ThemeContext);

    console.log(themeContext.breakpoints);

    useEffect(() => {
        if (settings) {
            dispatch(getPreviewContent(settings.region, settings.hasSubscription));
        }
    }, [settings]);

    useEffect(() => {
        if (settings.viewport === ViewportDevice.Mobile) {
            setViewportSize("320px");
        }
    }, [settings.viewport]);

    return (
        <Box background={PodmeColor.Silver}>
            <PreviewToolbar settings={settings} updateSettings={updateSettings} />
            <Box position="relative" overflow="hidden" margin="0 auto" backgroundColor={PodmeColor.LightBlue} width={viewportSize}>
                <Homescreen />;
            </Box>
        </Box>
    );
}

interface PreviewToolbarProps {
    settings: Settings;
    updateSettings: (newSettings: Settings) => void;
}

function PreviewToolbar({ settings, updateSettings }: PreviewToolbarProps): ReactElement {
    return (
        <Flex backgroundColor={PodmeColor.White} padding={4}>
            <FormControl display="flex" gridGap={8} justifyContent="center" alignItems="center" color={PodmeColor.BlackPearl}>
                <Flex alignItems="center">
                    <Select
                        defaultValue="desktop"
                        onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                            updateSettings({ ...settings, region: evt.target.value as Region });
                            strings.setLanguage(getLocale(evt.target.value as Region).split("-")[0]);
                        }}
                    >
                        <option value="se">Sweden</option>
                        <option value="no">Norway</option>
                        <option value="fi">Finland</option>
                    </Select>
                </Flex>

                <Flex alignItems="center">
                    <Select
                        defaultValue="desktop"
                        onChange={(evt: ChangeEvent<HTMLSelectElement>) =>
                            updateSettings({ ...settings, viewport: evt.target.value as ViewportDevice })
                        }
                    >
                        <option value="desktop">Desktop</option>
                        <option value="tablet">Tablet</option>
                        <option value="mobile">Mobile</option>
                    </Select>
                </Flex>
                <Flex alignItems="center" gridGap={2}>
                    <FormLabel htmlFor="hasSubscription" margin={0}>
                        Is Premium user?
                    </FormLabel>
                    <Switch
                        id="hasSubscription"
                        defaultChecked
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                            console.log(evt.target.checked);
                            updateSettings({ ...settings, hasSubscription: Boolean(evt.target.checked) });
                        }}
                    />
                </Flex>
            </FormControl>
        </Flex>
    );
}
