import { Text } from "@chakra-ui/react";
import { ReactElement } from "react";
import { PodmeColor } from "../types";

interface Props {
    children: string;
    color?: PodmeColor;
    isUnderlined?: boolean;
    [x: string]: any;
}

export default function Span({ children, color = PodmeColor.Cyan, isUnderlined = false, ...rest }: Props): ReactElement {
    return (
        <Text as="span" color={color} textDecoration={isUnderlined ? "underline" : "unset"} {...rest}>
            {children}
        </Text>
    );
}
