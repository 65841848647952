import { PodmeColor } from "@typings/index";
import { hasTextContent, sanitizeText } from "@utils/index";

export const findEmailsAndLinks = (text: string, addSpace = false) => {
    const emailAndLinkRegex = /([\w.-]+@[\w.-]+\.\w+\.?(?=\s|$)|https?:\/\/[^\s]+\.?(?=\s|$)|www\.[^\s]+\.?(?=\s|$))/g;
    let match: RegExpExecArray | null;
    const result = [];
    let lastIndex = 0;
    while ((match = emailAndLinkRegex.exec(text))) {
        if (match.index !== lastIndex) {
            result.push(text.slice(lastIndex, match.index));
        }
        let dot = "";
        if (match[0].endsWith(".")) {
            dot = ".";
        }
        if (match[0].includes("@") && !match[0].includes("www.")) {
            // This is for email addresses
            const mailLink = match[0].replace(/\.$/, ""); // remove trailing dot
            result.push(
                `${addSpace ? "<br />" : ""}<a href="mailto:${mailLink}" style="color:${PodmeColor.SoftWhite}; text-decoration:underline; font-weight:bold">${mailLink}</a>${dot}${addSpace ? "<br/><br/>" : ""}`
            );
        } else {
            // This is for URLs
            const url = match[0].replace(/\.$/, ""); // remove trailing dot
            result.push(
                `${addSpace ? "<br/>" : ""}<a href="${
                    !url.startsWith("http") ? "https://" + url : url
                }" style="color:white; text-decoration:underline; font-weight:bold" target="_blank">${url}</a>${dot}${addSpace ? "<br/><br/>" : ""}`
            );
        }
        lastIndex = emailAndLinkRegex.lastIndex;
    }
    if (lastIndex !== text.length) {
        result.push(text.slice(lastIndex));
    }
    return result.join("");
};

export const getFormattedPodcastDescription = (cmsDescription: string | undefined, adminPanelDescription: string | undefined) => {
    let richTextDescription = findEmailsAndLinks(
        cmsDescription && hasTextContent(cmsDescription)
            ? sanitizeText(cmsDescription)
            : adminPanelDescription
              ? sanitizeText(adminPanelDescription)
              : "",
        true
    );
    if (!richTextDescription.startsWith("<p>") && !richTextDescription.endsWith("</p>")) {
        richTextDescription = "<p>" + richTextDescription + "</p>";
    }

    return richTextDescription;
};
