import { ReactElement, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Text, Flex, Box } from "@chakra-ui/react";
import Skeleton, { SkeletonCircle } from "@components/Skeleton";
import strings from "@locale/localization";
import { dateFormatter, timeToMinutes, playerTimeToSeconds, playerTimeFormat } from "@utils/index";
import PlayButton from "@components/PlayButton";
import { CSSstylesObject, Episode, PodmeColor } from "@typings/index";
import { useSound } from "@containers/SoundProvider";
import { useMedia } from "../../../../app/hooks";
import EpisodeImage from "./EpisodeImage";
import EpisodeProgressBar from "./EpisodeProgressBar";

const styles: CSSstylesObject = {
    episodeContainer: {
        display: "flex",
        flexDirection: "row",
        gridGap: "1rem",
        position: "relative",
        height: "100%",
        padding: ["0.75rem 0", "1rem 0 1rem 1rem"],
        background: PodmeColor.Cinder,
        border: ["none", `1px solid ${PodmeColor.LicoriceBlue}`],
        rounded: ["0", "1.5rem"],
        cursor: "pointer",
        textDecoration: "none",
        transition: ["unset", "all 100ms ease-in"],
        alignItems: "center",
        sx: {
            "&:hover": {
                background: [PodmeColor.Cinder + "00", PodmeColor.Cinder + "90"],
                boxShadow: ["none", `inset 0 0 10rem 10rem ${PodmeColor.Cinder + "90"}`],
            },
            "&:hover::before": {
                boxShadow: ["none", `inset 0 0 10rem 10rem ${PodmeColor.Cinder + "90"}`],
            },
            "&:active::before": {
                boxShadow: `inset 0 0 10rem 10rem rgba(255,255,255, 0.04)`,
            },
        },
    },
    episodeContent: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    episodeCopy: {
        height: "100%",
        color: PodmeColor.SoftWhite,
        display: "flex",
        flexDirection: "column",
    },
    title: {
        fontSize: ["1rem", "1.125rem"],
        fontWeight: "700",
    },
    episodeLength: {
        fontSize: ["0.75rem", "0.875em"],
        color: PodmeColor.Silver,
    },
    authorWrapper: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        lineHeight: "1",
        gridGap: "0.4rem",
    },
    author: {
        fontSize: "0.8em",
        color: PodmeColor.Silver,
        display: "flex",
        justifyContent: "space-between",
        wordBreak: "break-all",
    },
    premiumLabel: {
        fontWeight: "600",
        color: PodmeColor.Cyan,
        fontSize: "0.75rem",
        lineHeight: "1",
    },
};
interface Props {
    episode: Episode;
    showProgressbar: boolean;
    removePodcastTitleOnEachEpisode?: boolean;
    fixedItemHeight?: string;
    loadingEpisodes?: boolean;
}

export default function EpisodeListItem({
    episode,
    showProgressbar,
    removePodcastTitleOnEachEpisode,
    loadingEpisodes = false,
}: Props): ReactElement {
    const { episodeHome } = strings;
    const { isSmallScreen } = useMedia();
    const {
        state: { currentAudioId, currentTime },
    } = useSound();

    const [isLoading, setIsLoading] = useState(
        loadingEpisodes || !episode || (episode && (!episode.smallImageUrl || !episode.imageUrl))
    );
    const [currentTimeInPercentage, setCurrentTimeInPercentage] = useState<number | undefined>(0);

    useMemo(() => {
        if (!loadingEpisodes && episode && episode.id) {
            setIsLoading(false);
        }
        if (episode && episode.currentSpot) {
            const currentSpot = playerTimeToSeconds(episode.currentSpot ?? "");
            const episodeDuration = playerTimeToSeconds(episode.length);
            setCurrentTimeInPercentage(Math.floor((currentSpot / episodeDuration) * 100));
        }
    }, [episode, loadingEpisodes]);

    useMemo(() => {
        if (loadingEpisodes) {
            setIsLoading(true);
        }
    }, [loadingEpisodes]);

    useEffect(() => {
        if (episode && episode.id === Number(currentAudioId)) {
            const episodeDuration = playerTimeToSeconds(episode.length);
            setCurrentTimeInPercentage(Math.floor((currentTime / episodeDuration) * 100));
            episode.currentSpot = playerTimeFormat(String(currentTime));
        }
    }, [currentTime, episode]);

    return (
        <Link
            to={{
                pathname: `/${strings.routes.locale}/${episode?.slug ?? episode?.podcastSlug}/${episode?.id}`,
            }}
            style={{
                pointerEvents: isLoading ? "none" : "auto",
            }}
        >
            <Box
                {...styles.episodeContainer}
                textAlign="left"
                _before={{
                    display: "block",
                    position: "absolute",
                    content: isSmallScreen ? "''" : "unset",
                    left: "-1rem",
                    right: "-1rem",
                    top: "0",
                    height: "100%",
                    bgColor: PodmeColor.Cinder,
                    transition: "all 100ms ease-in",
                }}
            >
                <EpisodeImage
                    imageUrl={episode?.imageUrl}
                    smallImageUrl={episode?.smallImageUrl}
                    podcastTitle={episode?.podcastTitle}
                    marginTop={!episode?.isPremium && removePodcastTitleOnEachEpisode ? "0.25rem" : undefined}
                    loadingEpisodes={loadingEpisodes}
                />
                <Flex alignItems="center" height="100%" width="100%" gridGap={["0.5rem", 0]}>
                    <Box {...styles.episodeContent} gridGap={1} minWidth={0} zIndex="2">
                        <Box {...styles.episodeCopy} minWidth={0} width="100%" gridGap={1} justifyContent="center">
                            <Flex direction="column" gridGap={episode?.isPremium && removePodcastTitleOnEachEpisode ? "0.25rem" : "0"}>
                                <Skeleton
                                    isLoaded={!isLoading}
                                    width={isLoading ? "50%" : "unset"}
                                    height={isLoading ? "1rem" : "unset"}
                                    marginBottom={isLoading ? "0.25rem" : "unset"}
                                    rounded="0.25rem"
                                >
                                    <Box {...styles.authorWrapper}>
                                        {episode?.isPremium && (
                                            <>
                                                <Box as={"span"} {...styles.premiumLabel}>
                                                    Premium
                                                </Box>
                                            </>
                                        )}
                                        {!removePodcastTitleOnEachEpisode && (
                                            <>
                                                {episode?.isPremium && (
                                                    <Box as="span" height="0.75rem" width="2px" backgroundColor={PodmeColor.Silver} />
                                                )}
                                                <Box as="span" {...styles.author} fontStyle="normal" noOfLines={1}>
                                                    {episode?.podcastTitle}
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                </Skeleton>
                                <Skeleton
                                    isLoaded={!isLoading}
                                    width={isLoading ? "90%" : "unset"}
                                    height={isLoading ? "2em" : "unset"}
                                    rounded="0.25rem"
                                >
                                    <Text {...styles.title} as="h3" whiteSpace="unset" noOfLines={2}>
                                        {episode?.title}
                                    </Text>
                                </Skeleton>
                            </Flex>
                            <Flex direction="column" gridGap="0.5rem">
                                <Skeleton
                                    isLoaded={!isLoading}
                                    width={isLoading ? "30%" : "unset"}
                                    height={isLoading ? "1rem" : "unset"}
                                    rounded="0.25em"
                                >
                                    <Flex {...styles.episodeLength} gridGap="0.15rem">
                                        {episode?.dateAdded && <Text as="span">{dateFormatter(episode.dateAdded)}</Text>}
                                        {episode?.length && <Text as="span"> • {timeToMinutes(episode.length)}</Text>}
                                        {currentTimeInPercentage && currentTimeInPercentage >= 99 ? (
                                            <Flex
                                                alignItems="center"
                                                gridGap="0.15rem"
                                                sx={{
                                                    "> svg > path": {
                                                        stroke: PodmeColor.Sand,
                                                    },
                                                }}
                                            >
                                                <Text as="span">•</Text>
                                                <Text as="span" color={PodmeColor.Sand}>
                                                    {" " + episodeHome.markedAsPlayed}
                                                </Text>
                                            </Flex>
                                        ) : null}
                                    </Flex>
                                </Skeleton>
                                {showProgressbar ? (
                                    <EpisodeProgressBar isLoaded={!isLoading} currentTimeInPercentage={currentTimeInPercentage} />
                                ) : null}
                            </Flex>
                        </Box>
                    </Box>
                    <Flex
                        flex={["0 0 70px", "0 0 96px"]}
                        height="100%"
                        alignItems="center"
                        justifyContent={"center"}
                        transition="transform 100ms ease-in"
                        zIndex="2"
                        rounded="50%"
                        width="100%"
                        marginRight={["-0.5rem", "0"]}
                    >
                        <SkeletonCircle boxSize={isLoading ? "3rem" : "100%"} style={{ alignSelf: "center" }} isLoaded={!isLoading}>
                            <PlayButton
                                playObject={{
                                    id: episode?.id,
                                    title: episode?.title,
                                    podcastTitle: episode?.podcastTitle,
                                    slug: episode?.slug,
                                    imageUrl: episode?.smallImageUrl ?? episode?.imageUrl,
                                    length: episode?.length,
                                    currentSpot: episode?.currentSpot,
                                    streamUrl: episode?.streamUrl,
                                    isPremium: episode?.isPremium,
                                }}
                                maxWidth={["70px", "96px"]}
                            />
                        </SkeletonCircle>
                    </Flex>
                </Flex>
            </Box>
        </Link>
    );
}
