import React, { ReactElement, useMemo } from "react";
import { Flex, Spinner } from "@chakra-ui/react";
import Page from "@components/Page/Page";
import { useSelector } from "react-redux";
import { GET_CONTENT } from "./redux/types";
import { useDispatch } from "react-redux";
import { useLocation, Redirect } from "react-router";
import { RootState } from "app/reducers/rootReducer";
import useAuth from "@auth/useAuth";
import strings from "app/locale/localization";
import usePrevPath from "app/hooks/usePrevPath";
import CmsSections from "@components/Cms/CmsSections";
import { PodmeColor } from "@typings/index";

const homescreenSelector = (state: RootState) => state.homescreen;

export default function Homescreen(): ReactElement {
    const { sections, isFetching } = useSelector(homescreenSelector);
    const { isAuthenticated, authChecked } = useAuth();
    const dispatch = useDispatch();
    const location = useLocation<{ from: { pathname: string } }>();
    const { meta } = strings.discoverPage;

    const { prevPath, removePrevPath } = usePrevPath();

    const { locale, discover } = strings.routes;

    useMemo(() => {
        if (!sections || (sections && sections.length === 0)) dispatch({ type: GET_CONTENT });
    }, [sections]);

    if (authChecked && isAuthenticated && prevPath) {
        removePrevPath();
        return <Redirect to={prevPath} />;
    }

    if (authChecked && isAuthenticated) {
        return <Redirect to={`/${locale}/auth/${discover}`} />;
    }

    if (isAuthenticated && location?.state?.from?.pathname) {
        return <Redirect to={location.state.from.pathname} />;
    }

    if (typeof window !== "undefined" && ((sections && sections.length === 0) || isFetching)) {
        return (
            <Flex
                height="100%"
                justifyContent="center"
                position="absolute"
                backgroundColor={PodmeColor.BlackPearl}
                zIndex="10"
                marginTop="-1px"
                paddingTop={["3rem", "7rem"]}
                left="0"
                right="0"
                top={["9.5rem", "11rem"]}
            >
                <Spinner size="xl" color={PodmeColor.Cyan} speed="650ms" />
            </Flex>
        );
    }

    return (
        <Page
            meta={{
                title: meta.title,
                description: meta.description,
            }}
            columnSpan="1 / -1"
            paddingInline="0"
            paddingBottom="4rem"
        >
            <Flex
                flexDirection="column"
                gridGap={["1rem", "3rem"]}
                sx={{
                    "* h2": {
                        padding: ["0 1rem", "0 2rem"],
                    },
                    "* .hide-scrollbar > span": {
                        margin: ["0.5rem 0", null, "1rem 2rem"],
                    },
                }}
            >
                <CmsSections sections={sections} />
            </Flex>
        </Page>
    );
}
