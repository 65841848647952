import { Region, REGION_INT } from "@typings/index";
import { RootState } from "app/reducers/rootReducer";
import { call, put, takeEvery, all, takeLatest, select } from "redux-saga/effects";
import routes, { createGetRequest } from "../../../api";

import types, { PodcastByCategory } from "./types";

const regionSelector = (state: RootState) => state.global;

export function* getAllCategories(): any {
    try {
        const { location } = yield select(regionSelector);
        let regionNumber = 1;

        regionNumber = location === Region.Norway ? 2 : location === Region.Finland ? 3 : 1;

        yield put({ type: types.GET_CATEGORIES });
        const result = yield call(routes.getAllCategories, regionNumber);
        const categories = result;

        return yield put({ type: types.GET_CATEGORIES_SUCCESS, categories });
    } catch (error) {
        return yield put({ type: types.GET_CATEGORIES_FAILED, error });
    }
}

export function* getCategoryPageContent({ entryTitle }: { type: typeof types.GET_CATEGORY_PAGE_CONTENT; entryTitle: string }): any {
    try {
        const result = yield call(createGetRequest, `cms/categories-page/${entryTitle}`);
        const categoryPageContent = result;

        return yield put({ type: types.GET_CATEGORY_PAGE_CONTENT_SUCCESS, categoryPageContent });
    } catch (error) {
        return yield put({ type: types.GET_CATEGORY_PAGE_CONTENT_FAILED, error });
    }
}

export function* getPodcastsByCategory({
    categoryId,
    page,
    pageSize,
    region,
}: {
    type: typeof types.GET_PODCASTS_BY_CATEGORY;
    categoryId: number;
    page: number;
    pageSize: number;
    region: Region;
}): any {
    try {
        const podcastsByCategory: { total: number; podcasts: PodcastByCategory[] } = yield call(
            routes.getPodcastsByCategory,
            categoryId,
            page,
            pageSize,
            REGION_INT[region as keyof typeof REGION_INT] || 1
        );

        return yield put({
            type: types.GET_PODCASTS_BY_CATEGORY_SUCCESS,
            podcastsByCategory: podcastsByCategory.podcasts,
            numberOfPodcasts: podcastsByCategory.total,
        });
    } catch (error) {
        return yield put({ type: types.GET_PODCASTS_BY_CATEGORY_FAILED, error });
    }
}

export function* getPopularPodcastsByCategory({
    categoryId,
    podType,
    page,
    pageSize,
}: {
    type: typeof types.GET_POPULAR_PODCASTS_BY_CATEGORY;
    categoryId: string;
    podType: number;
    page: number;
    pageSize: number;
}): any {
    try {
        const popularPodcastsByCategory = yield call(routes.getByPopularityPodcasts, categoryId, podType, page, pageSize);

        return yield put({ type: types.GET_POPULAR_PODCASTS_BY_CATEGORY_SUCCESS, popularPodcastsByCategory });
    } catch (error) {
        return yield put({ type: types.GET_POPULAR_PODCASTS_BY_CATEGORY_FAILED, error });
    }
}

export function* getInitialData(): any {
    yield all([call(getAllCategories)]);
}

export default [
    takeEvery(types.GET_INITIAL_CATEGORIES_DATA, getInitialData),
    takeLatest(types.GET_PODCASTS_BY_CATEGORY, getPodcastsByCategory),
    takeLatest(types.GET_POPULAR_PODCASTS_BY_CATEGORY, getPopularPodcastsByCategory),
    takeLatest(types.GET_CATEGORY_PAGE_CONTENT, getCategoryPageContent),
];
