import useAuth from "@auth/useAuth";
import { RootState } from "app/reducers/rootReducer";
import { useSelector } from "react-redux";

const STORED_LAST_PLAYED_EPISODE_KEY = "lastPlayedEpisode";

function usePlayerState() {
    const { isAuthenticated } = useAuth();
    const { playObject } = useSelector((state: RootState) => globalPlayerState(state, isAuthenticated));

    function globalPlayerState(state: RootState, isAuthenticated: boolean) {
        if (typeof window === "undefined" || !isAuthenticated) return state.globalPlayer;

        if (!state.globalPlayer.playObject) {
            const lastPlayedEpisode = localStorage.getItem(STORED_LAST_PLAYED_EPISODE_KEY);

            if (lastPlayedEpisode) {
                const parsedItem = JSON.parse(lastPlayedEpisode);

                state.globalPlayer.playObject = parsedItem;
            }
        }
        return state.globalPlayer;
    }

    return {
        playObject,
    };
}

export default usePlayerState;
