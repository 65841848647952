import { ReactElement, useState } from "react";
import FollowButton from "./components/FollowButton/FollowButton";
import strings from "@locale/localization";
import useAuth from "../../auth/useAuth";
import { CSSstylesObject, DeviceBreakpoints, PodmeColor } from "@typings/index";
import { Image, Box, keyframes, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { fadeIn } from "@containers/SearchPage/animation";
import Skeleton from "@components/Skeleton";

export interface PodItemProps {
    smallImgUrl: string;
    mediumImgUrl: string;
    title: string;
    slug: string;
    podId: string | number;
    isPremium: boolean;
    addBookmarkAction?: (id: string | number) => void;
    removeBookmarkAction?: (id: string | number) => void;
    isBookmarked: boolean;
    openDialog?: () => void;
    shadow: boolean;
    animation?: boolean;
    onLoad?: () => void;
}

interface PodItemLoadingProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any;
}

const placeholderShimmerAnimationKeyframes = keyframes`
0% {
    background-position: -468px 0;
}

100% {
    background-position: 468px 0;
}
`;
const placeholderShimmerAnimation = `${placeholderShimmerAnimationKeyframes} infinite 1s linear forwards`;

const styles: CSSstylesObject = {
    podItem: {
        marginBottom: "-5px",
        width: "100%",
        textDecoration: "none",
        display: "inline-block",
        paddingTop: "100%",
        position: "relative",
        height: 0,
        // -webkit-tap-highlight-color: 'transparent',
        sx: {
            //all nested span elements get full opacity when podItem element is hovered or below Medium screen breakpoint
            "&:hover": {
                span: {
                    opacity: 1,
                },
            },
            [`@media ${DeviceBreakpoints.MediumDown}`]: {
                "&": {
                    span: {
                        opacity: 1,
                    },
                },
            },
        },
    },
    imageWrapper: {
        maxWidth: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflow: "hidden",
        borderRadius: "3px",
    },
    podImage: {
        display: "block",
        width: "100%",
        height: "auto",
        minWidth: "100%",
        minHeight: "100%",
        borderRadius: "1rem",
        // border: "1px solid " + PodmeColor.Silver + "20",
    },
    podTitle: {
        color: PodmeColor.White,
        paddingTop: "0.5rem",
        textAlign: "center",
        display: "block",
    },
    premiumBadge: {
        backgroundImage: 'url("/assets/images/icons/premium-badge.png")',
        width: "15%",
        height: "40px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        position: "absolute",
        top: "-3px",
        right: "5%",
    },
    podOverlay: {
        opacity: 0,
        position: "absolute",
        inset: 0,
        transition: "all 0.2s",
        borderRadius: "1rem",
        // -webkit-box-shadow: inset 0px -115px 61px -61px rgba(0, 0, 0, 0.75),
        // -moz-box-shadow: inset 0px -115px 61px -61px rgba(0, 0, 0, 0.75),
        boxShadow: `inset 0px -115px 61px -61px rgba(0, 0, 0, 0.75)`,
    },
    followButtonWrapper: {
        opacity: 0,
        color: PodmeColor.SoftWhite,
        position: "absolute",
        right: "0.75rem",
        bottom: "1rem",
    },
    shimmer: {
        background: `${PodmeColor.Cinder}`,
        backgroundImage: `linear-gradient(to right, ${PodmeColor.Cinder} 0%, ${PodmeColor.LicoriceBlue} 20%, ${PodmeColor.Cinder} 40%, ${PodmeColor.LicoriceBlue} 100%)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "800px 300px",
        display: "inline-block",
        position: "relative",
        animation: placeholderShimmerAnimation,
    },
    premiumLabel: {
        position: "absolute",
        bottom: "-1.25rem",
        paddingLeft: "8px",
        fontWeight: "600",
        color: PodmeColor.Cyan,
        fontSize: "0.75rem",
        lineHeight: "1",
    },
};

function PodItem({
    smallImgUrl,
    mediumImgUrl,
    title,
    slug,
    podId,
    addBookmarkAction,
    removeBookmarkAction,
    isBookmarked,
    openDialog,
    isPremium,
    animation,
    onLoad = () => null,
}: PodItemProps | PodItemLoadingProps): ReactElement {
    const { isAuthenticated } = useAuth();
    const { locale } = strings.routes;

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    return (
        <Link
            to={`/${locale}/${slug}`}
            data-testid="pod-item"
            style={{ position: "relative", display: "inline-block", width: "100%", marginBottom: isPremium ? "1rem" : "0" }}
        >
            <Skeleton isLoaded={isLoaded} width="100%" height="100%" borderRadius="1rem" style={{ aspectRatio: "1 / 1" }}>
                <Box {...styles.podItem} animation={animation ? `${fadeIn} 200ms ease-in` : "none"}>
                    <Box {...styles.imageWrapper}>
                        {smallImgUrl ? (
                            <>
                                <Image
                                    loading={onLoad ? undefined : "lazy"}
                                    {...styles.podImage}
                                    src={mediumImgUrl}
                                    alt={title}
                                    srcSet={`${smallImgUrl} 320w,
                                ${mediumImgUrl} 640w,`}
                                    sizes="(max-width: 500px) 200px,
                                640px"
                                    onLoad={() => {
                                        setIsLoaded(true);
                                        onLoad();
                                    }}
                                    onError={() => {
                                        setIsLoaded(true);
                                        onLoad();
                                    }}
                                />

                                {isAuthenticated && addBookmarkAction && (
                                    <>
                                        <Box as={"span"} {...styles.podOverlay} />
                                        <Box as={"span"} {...styles.followButtonWrapper}>
                                            <FollowButton
                                                isFollowing={isBookmarked}
                                                followAction={() => addBookmarkAction(podId)}
                                                unfollowAction={removeBookmarkAction}
                                                podcastId={podId}
                                                openDialog={openDialog}
                                            />
                                        </Box>
                                    </>
                                )}
                            </>
                        ) : (
                            <Box {...styles.podImage} {...styles.shimmer} />
                        )}
                    </Box>
                    {isPremium && isLoaded && (
                        <Text as={"span"} {...styles.premiumLabel}>
                            Premium
                        </Text>
                    )}
                </Box>
            </Skeleton>
        </Link>
    );
}

export default PodItem;
