import routes from "@api/index";
import types, { CancelRenewal, CANCEL_RENEWAL, ReactivateRenewal, REACTIVATE_RENEWAL } from "./SettingsPageTypes";

export function cancelRenewal(productId: number | null): CancelRenewal {
    return {
        type: CANCEL_RENEWAL,
        productId,
    };
}

export function reactivateRenewal(userId: string): ReactivateRenewal {
    return {
        type: REACTIVATE_RENEWAL,
        userId,
    };
}

export function getMyTransactions(page: any, pageSize: any): any {
    return (dispatch: any) => {
        return routes
            .getTransactionHistory(page, pageSize)
            .then((response) => dispatch({ type: types.MY_TRANSACTIONS_LOADED, response: response }))
            .catch((error) => dispatch({ type: types.MY_TRANSACTIONS_LOADED_FAILED, error }));
    };
}

export function getTransactionCount(): any {
    return (dispatch: any) => {
        return routes
            .getTransactionCount()
            .then((response) => dispatch({ type: types.TRANSACTION_COUNT_LODAED, response: response }))
            .catch((error) => dispatch({ type: types.TRANSACTION_COUNT_LODAED_FAILED, error }));
    };
}

export function getMyTransaction(transactionId: any) {
    return (dispatch: any) => {
        return routes
            .getTransaction(transactionId)
            .then((response) => dispatch({ type: types.MY_TRANSACTION_LOADED, response: response }))
            .catch((error) => dispatch({ type: types.MY_TRANSACTION_LOADED_FAILED, error }));
    };
}

export function getMyFailedTransaction(transactionId: any) {
    return (dispatch: any) => {
        return routes
            .getFailedTransactions(transactionId)
            .then((response: any) => dispatch({ type: types.MY_FAILED_TRANSACTION_LOADED, response: response.data }))
            .catch((error) => dispatch({ type: types.MY_FAILED_TRANSACTION_LOADED_FAILED, error }));
    };
}

export default {
    cancelRenewal,
    reactivateRenewal,
    getMyTransactions,
    getTransactionCount,
    getMyTransaction,
    getMyFailedTransaction,
};
