/* eslint-disable @typescript-eslint/no-empty-function */
import strings from "@locale/localization";
import { CSSstylesObject, PodmeColor } from "../../../types";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { ReactElement } from "react";
import { SecondaryButton } from "@components/buttons";
import CategoryGrid from "@components/CategoryGrid/CategoryGrid";
import { ExploreCategoriesSectionContent } from "@containers/Homescreen/redux/types";

const styles: CSSstylesObject = {
    mainContainer: {
        background: PodmeColor.BlackPearl,
        color: PodmeColor.SoftWhite,
        padding: ["2rem 1rem", "2rem", "2rem 0"],
        marginBottom: ["3rem", null, "5rem"],
        marginTop: ["3rem", null, 0],
    },
    mainTitleContainer: {
        gridGap: "1rem",
        paddingBottom: "3rem",
    },
    title: { color: PodmeColor.SoftWhite },
    subtitle: { color: PodmeColor.Silver, fontWeight: 400 },
    exploreButtonWrapper: {
        display: "flex",
        justifyContent: "right",
        marginTop: "2rem",
    },
};
interface Props {
    cmsContent: ExploreCategoriesSectionContent;
}
export default function ExploreCategoriesSection({ cmsContent }: Props): ReactElement {
    const { categories, headline, subheadline, cta } = cmsContent;
    const { slug, text } = cta;
    const { locale } = strings.routes;

    const categoriesToDisplay = categories.map((category) => {
        if (category.image) {
            category.imageUrl = category.image.url || "";
        }
        return category;
    });

    return (
        <Box as="section" {...styles.mainContainer}>
            <Flex direction="column" {...styles.mainTitleContainer}>
                <Heading fontSize={["1.75rem", "2.25rem"]} {...styles.title}>
                    {headline}
                </Heading>
                <Heading as="h3" fontSize={"1.25rem"} {...styles.subtitle}>
                    {subheadline}
                </Heading>
            </Flex>
            {/* for startpage we show limited amount of categories */}
            <CategoryGrid showLimitedAmount categories={categoriesToDisplay} onLoad={() => {}} />
            <Box {...styles.exploreButtonWrapper}>
                <SecondaryButton
                    to={`/${locale}/${slug}`}
                    background={PodmeColor.SoftWhite}
                    color={PodmeColor.BlackPearl}
                    width={["100%", null, "auto"]}
                >
                    {text}
                </SecondaryButton>
            </Box>
        </Box>
    );
}
