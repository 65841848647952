import { Episode, Podcast } from "@typings/index";
import { IEpisode, IPodcast } from "../redux/types";

export const carouselElementsGroup = (carouselElArr: Podcast[] | IPodcast[] | Episode[] | IEpisode[], carouselStep: number) => {
    const carouselElements = [...carouselElArr];
    const carouselGroup = [];

    while (carouselElements.length > 0) {
        carouselGroup.push(carouselElements.slice(0, carouselStep));

        for (let i = 0; i < carouselStep; i++) {
            carouselElements.shift();
        }
    }
    return carouselGroup;
};
