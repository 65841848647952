import { AnyAction } from "redux";

import { ShowErrorToastAction, ShowSuccessToastAction } from "@typings/index";

interface messageState {
    showErrorToast: boolean;
    showSuccessToast: boolean;
    message: string;
    createAccountFailed: boolean;
}

const initialState: messageState = {
    showErrorToast: false,
    showSuccessToast: false,
    message: "",
    createAccountFailed: false,
};

function messageReducer(state = initialState, action: ShowSuccessToastAction | ShowErrorToastAction | AnyAction): messageState {
    if ("showToastSuccessMessage" in action) {
        const successAction = action as ShowSuccessToastAction;
        return {
            ...state,
            showSuccessToast: successAction.showToastSuccessMessage,
            showErrorToast: false,
            message: successAction.message,
        };
    }

    if ("showToastErrorMessage" in action) {
        const errorAction = action as ShowErrorToastAction;
        return {
            ...state,
            showErrorToast: errorAction.showToastErrorMessage,
            showSuccessToast: false,
            message: errorAction.message,
        };
    }

    return initialState;
}

export default messageReducer;
