import { Flex, Image } from "@chakra-ui/react";
import { PodmeColor } from "@typings/index";
import { ReactElement } from "react";

interface Props {
    alt?: string;
}

export default function CheckmarkListIcon({ alt }: Props): ReactElement {
    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            padding="3px"
            rounded="50%"
            bgColor={PodmeColor.Sand}
            minWidth="1.5rem"
            height="1.5rem"
            marginRight="1rem"
            marginTop="1px"
        >
            <Image src="/assets/images/icons/check-black.svg" alt={alt} height="100%" />
        </Flex>
    );
}
