import { ReactElement } from "react";

import { PrimaryButton } from "../buttons";
import { Flex, Heading, Image, Modal, ModalBody, ModalContent, ModalOverlay, Text } from "@chakra-ui/react";
import { PodmeColor } from "@typings/index";
import strings from "app/locale/localization";

interface Props {
    isOpen: boolean;
    confirmCTA: () => void;
    abortCTA: () => void;
}

function CancelSubscriptionModal({ isOpen, confirmCTA, abortCTA }: Props): ReactElement {
    const isDarkMode = false;
    const string = strings.cancelSubscriptionModal;

    return (
        <Modal isOpen={isOpen} onClose={abortCTA}>
            <ModalOverlay background={PodmeColor.Woodsmoke} opacity="0.65 !important" />
            <ModalContent
                maxWidth="38.5rem"
                bgColor={PodmeColor.Cinder}
                padding={["1.5rem 1rem", "1.5rem"]}
                rounded="1.5rem"
                position="relative"
            >
                <ModalBody p={0}>
                    <Flex direction="column" gridGap="2rem">
                        <Flex direction="column" gridGap="1rem" padding="2rem 0">
                            <Image
                                src="/assets/images/icons/close-16x16.svg"
                                onClick={abortCTA}
                                width="24px"
                                position="absolute"
                                top={["1rem", "1.5rem"]}
                                right={["1rem", "1.5rem"]}
                                opacity="50%"
                                cursor="pointer"
                                transition="all 100ms ease-in"
                                _hover={{
                                    opacity: "100%",
                                }}
                            />

                            <Heading fontWeight="500" fontSize={["1rem", "1.5rem"]} color={PodmeColor.SoftWhite} maxWidth="95%">
                                {string.title}
                            </Heading>
                            <Text>{string.paragraph}</Text>
                        </Flex>
                        <Flex gridGap="1.5rem" justifyContent="flex-end">
                            <PrimaryButton
                                bgColor={PodmeColor.BlackPearl}
                                color={PodmeColor.SoftWhite}
                                border={`1px solid ${isDarkMode ? PodmeColor.SoftWhite : ("#000" as PodmeColor)}`}
                                padding="0.625rem 1rem"
                                onClick={abortCTA}
                                fontFamily="Inter"
                                fontWeight="700"
                            >
                                {string.abort}
                            </PrimaryButton>
                            <PrimaryButton
                                color={PodmeColor.BlackPearl}
                                bgColor={PodmeColor.SoftWhite}
                                padding="0.625rem 1rem"
                                fontFamily="Inter"
                                fontWeight="700"
                                onClick={() => {
                                    confirmCTA();
                                    abortCTA();
                                }}
                            >
                                {string.cancel}
                            </PrimaryButton>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default CancelSubscriptionModal;
