import {
    TRACK_PLAYBACK_SUCCESS,
    GlobalPlayerState,
    GET_EPISODE_SUCCESS,
    LOAD_PLAYER_MEDIA,
    GlobalPlayerAction,
} from "./GlobalPlayerTypes";

const initialState: GlobalPlayerState = {
    playObject: null,
    playbackUpdated: false,
};

function globalPlayerReducer(state = initialState, action: GlobalPlayerAction): GlobalPlayerState {
    switch (action.type) {
        case LOAD_PLAYER_MEDIA:
        case GET_EPISODE_SUCCESS: {
            return {
                ...state,
                playObject: action.playObject,
            };
        }

        case TRACK_PLAYBACK_SUCCESS: {
            return {
                ...state,
                playbackUpdated: true,
            };
        }

        default:
            return state;
    }
}

export default globalPlayerReducer;
