import loadable from "@loadable/component";

export const StartPage = loadable(() => import("./StartPage/StartPage"));

export const InfoPage = loadable(() => import("./InfoPage"));

export const PageNotFound = loadable(() => import("./PageNotFound"));

export const MyPodcastsPage = loadable(() => import("./MyPodcastsPage"));

export const SearchPage = loadable(() => import("./SearchPage/SearchPage"));

export const SettingsPage = loadable(() => import("./SettingsPage"));

export const EpisodePage = loadable(() => import("./EpisodePage"));

export const LandingPage = loadable(() => import("./LandingPages"));

export const LoginPage = loadable(() => import("./LoginPage/LoginPage"));
export const LoginAppFlowPage = loadable(() => import("./LoginPage/LoginAppFlowPage"));
export const LoginOpenAppPage = loadable(() => import("./LoginPage/LoginOpenAppPage"));

export const SignupPage = loadable(() => import("./SignupPage/SignupPage"));

export const PremiumConfirmationPage = loadable(() => import("./ConfirmationPage/PremiumConfirmationPage"));

export const PodcastPage = loadable(() => import("./PodcastPage"));

export const Checkout = loadable(() => import("./Checkout"));

export const CategoriesPage = loadable(() => import("./AllCategoriesPage"));

export const CategoryPage = loadable(() => import("./CategoryPage"));

export const Homescreen = loadable(() => import("./Homescreen"));

export const HomescreenAuth = loadable(() => import("./HomescreenAuth"));

export const CmsPreviewPage = loadable(() => import("./CmsPreviewPage"));

export const BundlerRoutes = loadable(() => import("./Bundler"));

export const StudentLandingPageHitract = loadable(() => import("./StudentLandingPageHitract/StudentLandingPageHitract"));

export const StudentRoutes = loadable(() => import("./Student"));

export const ManageSubscriptionsPage = loadable(() => import("./ManageSubscriptionsPage"));

export const MigrationPage = loadable(() => import("./Migration/MigrationPage"));

export const Transaction = loadable(() => import("./SettingsPage/Transaction"));
