import {
    GET_EPISODE,
    GET_EPISODES,
    GET_EPISODE_SUCCESS,
    GET_EPISODES_FAILED,
    GET_EPISODES_SUCCESS,
    GET_EPISODE_FAILED,
    GetEpisode,
    GetEpisodes,
} from "./types";

import { ActionWithAsyncResult } from "@typings/index";

export function getEpisode(id: number, isAuthenticated: boolean): ActionWithAsyncResult<GetEpisode> {
    return {
        type: GET_EPISODE,
        id,
        isAuthenticated,
        WAIT_FOR_ACTION: [GET_EPISODE_SUCCESS, GET_EPISODE_FAILED],
    };
}

export function getEpisodes(
    slug: string,
    page: number,
    fetchByOldest: boolean,
    isAuthenticated: boolean
): ActionWithAsyncResult<GetEpisodes> {
    return {
        type: GET_EPISODES,
        slug,
        page,
        fetchByOldest,
        isAuthenticated,
        WAIT_FOR_ACTION: [GET_EPISODES_SUCCESS, GET_EPISODES_FAILED],
    };
}
