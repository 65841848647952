import { AnyAction, combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";

import * as types from "../actions/actionTypes";

import contentReducer from "../content/contentReducer";
import messageReducer from "./messageReducer";
import migrationReducer from "@containers/Migration/redux";
import authReducer from "../auth/authReducer";
import podcastPageReducer from "../containers/PodcastPage/redux";
import settingsPageReducer from "../containers/SettingsPage/redux";
import globalPlayerReducer from "../containers/GlobalPlayer/redux";
import spinnerReducer from "./spinnerReducer";
import homescreenReducer from "@containers/Homescreen/redux";
import homescreenAuthReducer from "@containers/HomescreenAuth/redux";
import categoriesReducer from "@containers/AllCategoriesPage/redux";
import studentLandingPageReducer from "@containers/StudentLandingPageHitract/redux";

import { SettingsPageState } from "../containers/SettingsPage/redux/SettingsPageTypes";
import { AuthState, LOGOUT_USER_FAILED, LOGOUT_USER_SUCCESS } from "../auth/authTypes";
import { ContentState } from "../content/contentTypes";
import { Currency, Region, SpinnerState } from "@typings/index";
import { PodcastPageState } from "@containers/PodcastPage/redux/types";
import { GlobalPlayerState } from "@containers/GlobalPlayer/redux/GlobalPlayerTypes";
import { MigrationState } from "@containers/Migration/redux/types";
import { HomescreenState } from "@containers/Homescreen/redux/types";
import { HomescreenAuthState } from "@containers/HomescreenAuth/redux/types";
import { CategoriesPageState } from "@containers/AllCategoriesPage/redux/types";
import { StudentLandingPageState } from "@containers/StudentLandingPageHitract/redux/types";
import episodeHomeReducer from "@containers/EpisodePage/redux/reducer";
import { EpisodeHomeState } from "@containers/EpisodePage/redux/types";

export interface GlobalState {
    modalIsClosed: boolean;
    location: string;
    lang: string;
    isEligibleForTrial: boolean;
    variables: { maintenance_active: boolean; premium_banner_cta: boolean };
    experiments: { id: string; variant: number }[];
    packageId: number;
    currency: Currency;
    price: number;
    region: Region;
}

const intialState: GlobalState = {
    modalIsClosed: false,
    location: "se",
    lang: "sv",
    isEligibleForTrial: true,
    variables: { maintenance_active: false, premium_banner_cta: false },
    experiments: [],
    packageId: 0,
    currency: Currency.Sweden,
    price: 0,
    region: Region.Sweden,
};

function globalReducer(state = intialState, action: AnyAction): GlobalState {
    switch (action.type) {
        case "CLOSE_MODAL":
            return {
                ...state,
                modalIsClosed: true,
            };

        case types.SET_LOCALE: {
            let currency;
            let region;

            switch (action.location) {
                case "se":
                    currency = Currency.Sweden;
                    region = Region.Sweden;
                    break;
                case "no":
                    currency = Currency.Norway;
                    region = Region.Norway;
                    break;
                case "fi":
                    currency = Currency.Finland;
                    region = Region.Finland;
                    break;
                default:
                    currency = Currency.Sweden;
                    region = Region.Sweden;
                    break;
            }

            return {
                ...state,
                location: action.location,
                lang: action.lang,
                currency,
                packageId: action.packageId,
                price: action.price,
                region,
            };
        }

        case types.GET_REGION_INFO_SUCCESS: {
            return {
                ...state,
                region: action.region,
            };
        }

        case types.SET_EXPERIMENT_VARIABLES: {
            return {
                ...state,
                variables: action.value,
            };
        }

        case types.SET_EXPERIMENTS: {
            return {
                ...state,
                experiments: action.experiments,
            };
        }

        default:
            return { ...state, modalIsClosed: false };
    }
}

export interface RootState {
    router: RouterState<unknown>;
    auth: AuthState;
    settingsPage: SettingsPageState;
    podcastPage: PodcastPageState;
    globalPlayer: GlobalPlayerState;
    episodeHome: EpisodeHomeState;
    global: GlobalState;
    content: ContentState;
    message: any;
    spinner: SpinnerState;
    migration: MigrationState;
    homescreen: HomescreenState;
    homescreenAuth: HomescreenAuthState;
    categories: CategoriesPageState;
    studentLandingPage: StudentLandingPageState;
}

const createCombined = (history: History) => {
    return combineReducers<RootState>({
        router: connectRouter(history),

        auth: authReducer,
        settingsPage: settingsPageReducer,
        podcastPage: podcastPageReducer,
        globalPlayer: globalPlayerReducer,
        episodeHome: episodeHomeReducer,

        global: globalReducer,
        content: contentReducer,
        message: messageReducer,
        spinner: spinnerReducer,

        migration: migrationReducer,
        homescreen: homescreenReducer,
        homescreenAuth: homescreenAuthReducer,
        categories: categoriesReducer,
        studentLandingPage: studentLandingPageReducer,
    });
};

export default (history: History): any => {
    const combinedReducer: any = createCombined(history);
    return (state: any, action: any) => {
        if (action.type === LOGOUT_USER_SUCCESS || action.type === LOGOUT_USER_FAILED) {
            const { router, global, auth } = state;
            state = { router, global, auth };
            delete global.subscriptions;
        }

        return combinedReducer(state, action);
    };
};
