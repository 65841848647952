import { GET_SUBSCRIPTIONS_FAILED, GET_SUBSCRIPTIONS_SUCCESS } from "../actions/actionTypes";
import { Subscription, SubscriptionState } from "../types";
import {
    AuthState,
    CHECK_AUTH_STATUS_FAILED,
    CHECK_AUTH_STATUS_SUCCESS,
    GET_USER_DATA_SUCCESS,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER,
    SHOW_PREMIUM_MODAL,
    DISMISS_PREMIUM_MODAL,
    LOGIN_USER_FAILED,
    LOGIN_WITH_IDP_FAILED,
    LOGIN_USER,
    LOGIN_WITH_IDP,
    LOGIN_WITH_IDP_SUCCESS,
    LOGOUT_USER_WITH_ERROR,
    LOGOUT_USER_WITH_ERROR_SUCCESS,
    GET_USER_DATA,
    CHECK_AUTH_STATUS,
    GET_USER_DATA_FAILED,
    IdentityProvider,
    LOGOUT_USER_WITH_CUSTOM_REDIRECT,
    LOGOUT_USER_WITH_CUSTOM_REDIRECT_SUCCESS,
} from "./authTypes";
import { AnyAction } from "redux";

const initialState: AuthState = {
    isAuthenticated: false,
    authChecked: false,
    username: "",
    session_duration: 0,
    access_token: "",
    refresh_token: "",
    isLoading: true,
    isFetchingUserData: false,
    isSubmitting: false,
    isPremiumModalShown: false,
    hasActiveSubscription: null,
    subscriptionStatusChecked: false,
    episodeClicked: undefined,
    hasCancelledSubscription: undefined,
    isNewUser: false,
    provider: undefined,
    userId: "",
    accountIss: undefined,
    redirectUri: undefined,
    showRedirectOverlay: false,
};

function authReducer(state = initialState, action: AnyAction): AuthState {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                isSubmitting: true,
                isNewUser: false,
                isLoading: true,
            };

        case LOGIN_WITH_IDP:
            const showRedirectOverlay = action.provider === IdentityProvider.Schibsted;

            return {
                ...state,
                showRedirectOverlay,
                isLoading: true,
                isNewUser: false,
            };

        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isSubmitting: false,
                access_token: action.access_token,
                refresh_token: action.refresh_token,
                session_duration: action.session_duration,
                isLoading: false,
                isNewUser: action.accountCreated ? true : false,
            };

        case LOGIN_USER_FAILED:
        case LOGIN_WITH_IDP_FAILED:
            return {
                ...state,
                isLoading: false,
                isSubmitting: false,
                showRedirectOverlay: false,
            };

        case LOGIN_WITH_IDP_SUCCESS:
            return {
                ...state,
                isNewUser: false,
                isLoading: false,
                provider: action.provider,
                showRedirectOverlay: false,
            };

        case GET_USER_DATA:
            return {
                ...state,
                isFetchingUserData: false,
            };

        case GET_USER_DATA_SUCCESS:
            return {
                ...state,
                username: action.username,
                accountIss: action.accountIss,
                isFetchingUserData: false,
            };

        case GET_USER_DATA_FAILED:
            return {
                ...state,
                isFetchingUserData: false,
            };

        case CHECK_AUTH_STATUS:
            return {
                ...state,
                isFetchingUserData: true,
            };

        case CHECK_AUTH_STATUS_SUCCESS:
            return {
                ...state,
                authChecked: true,
                isAuthenticated: action.isAuthenticated,
                isLoading: false,
                provider: action.provider,
                userId: action.userId,
                isFetchingUserData: false,
            };

        case CHECK_AUTH_STATUS_FAILED:
            return {
                ...state,
                authChecked: true,
                isAuthenticated: false,
                isFetchingUserData: false,
            };

        case LOGOUT_USER:
            return {
                ...state,
                isLoading: true,
            };
        case LOGOUT_USER_WITH_CUSTOM_REDIRECT:
            return {
                ...state,
                isLoading: true,
            };
        case LOGOUT_USER_WITH_CUSTOM_REDIRECT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                username: "",
                session_duration: 0,
                access_token: "",
                refresh_token: "",
                isLoading: true,
            };

        case LOGOUT_USER_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                username: "",
                session_duration: 0,
                access_token: "",
                refresh_token: "",
                isLoading: true,
            };

        case LOGOUT_USER_WITH_ERROR:
            return {
                ...state,
                isLoading: true,
            };

        case LOGOUT_USER_WITH_ERROR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                username: action.username,
                provider: action.provider,
            };

        case SHOW_PREMIUM_MODAL:
            return {
                ...state,
                isPremiumModalShown: true,
                episodeClicked: action.episodeClicked,
            };

        case DISMISS_PREMIUM_MODAL:
        case "@@router/LOCATION_CHANGE":
            return {
                ...state,
                isPremiumModalShown: false,
                episodeClicked: false,
            };

        case GET_SUBSCRIPTIONS_SUCCESS: {
            const hasSubscription =
                action.subscriptions && action.subscriptions.length > 0
                    ? action.subscriptions.some(
                          (sub: Subscription) =>
                              (sub.subscriptionType === 1 && sub.subscriptionPlan) ||
                              ((sub.subscriptionType === 0 || sub.subscriptionType === 2) && sub.subscriptionPlan === null)
                      )
                    : false;

            const hasCancelledSubscription =
                action.subscriptions && action.subscriptions.length > 0
                    ? action.subscriptions.some(
                          (sub: Subscription) =>
                              sub.subscriptionType === 1 &&
                              sub.subscriptionPlan &&
                              sub.subscriptionState === SubscriptionState.Cancelled
                      )
                    : false;

            return {
                ...state,
                hasActiveSubscription: hasSubscription,
                hasCancelledSubscription: hasCancelledSubscription,
                subscriptionStatusChecked: true,
            };
        }

        case GET_SUBSCRIPTIONS_FAILED: {
            return {
                ...state,
                subscriptionStatusChecked: true,
            };
        }

        default:
            return state;
    }
}

export default authReducer;
