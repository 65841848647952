import types, { CategoriesPageState, PodcastByCategory } from "./types";
import strings from "@locale/localization";
import { AnyAction } from "redux";
import { getFormattedDescription } from "@containers/CategoryPage/utils";

const initialState: CategoriesPageState = {
    categories: null,
    pageContent: null,
    bookmarkToggled: false,
    bookmarks: [],
    podcastsByCategory: null,
    popularPodcastsByCategory: null,
    isFetchingPodcasts: false,
    isFetchingPageContent: false,
    numberOfPodcasts: 0,
};

function categoriesPageReducer(state = initialState, action: AnyAction): any {
    switch (action.type) {
        case types.GET_INITIAL_CATEGORIES_DATA:
            return {
                ...state,
                podcastsByCategory: null,
            };

        case types.GET_CATEGORIES_SUCCESS: {
            const categoriesString = strings.categories;

            let modCategories = [{ name: categoriesString.popular, key: "popular" }];

            modCategories = modCategories.concat(action.categories.categories).sort((a) => {
                if (a.key === "premium") {
                    return -1;
                }
                return 1;
            });

            return {
                ...state,
                categories: {
                    categoriesList: modCategories,
                },
            };
        }

        case types.GET_POPULAR_PODCASTS_BY_CATEGORY:
            return {
                ...state,
                popularPodcastsByCategory: null,
            };

        case types.GET_PODCASTS_BY_CATEGORY:
            return {
                ...state,
                podcastsByCategory: null,
                isFetchingPodcasts: true,
            };

        case types.GET_PODCASTS_BY_CATEGORY_SUCCESS: {
            const bookmarks = state.bookmarks;

            const podcasts = action.podcastsByCategory
                .filter((p: PodcastByCategory) =>
                    state.podcastsByCategory && state.podcastsByCategory.some((x) => x.id === p.id) ? false : true
                )
                .map((pod: PodcastByCategory) => ({
                    ...pod,
                    isBookmarked: bookmarks.some((bookmark) => bookmark.id === Number(pod.id)),
                }));

            return {
                ...state,
                podcastsByCategory: state.podcastsByCategory ? state.podcastsByCategory.concat(podcasts) : podcasts,
                canLoadMorePodcasts: podcasts && podcasts.length >= 15 ? true : false,
                isFetchingPodcasts: false,
                numberOfPodcasts: action.numberOfPodcasts,
            };
        }

        case types.GET_POPULAR_PODCASTS_BY_CATEGORY_SUCCESS: {
            return {
                ...state,
                popularPodcastsByCategory: action.popularPodcastsByCategory,
            };
        }

        case types.GET_CATEGORY_PAGE_CONTENT: {
            return {
                ...state,
                pageContent: null,
                isFetchingPageContent: true,
            };
        }

        case types.GET_CATEGORY_PAGE_CONTENT_SUCCESS: {
            const formattedDescription = getFormattedDescription(action.categoryPageContent.description);
            const updatedPageContent = { ...action.categoryPageContent, description: formattedDescription };

            return {
                ...state,
                pageContent: updatedPageContent,
                isFetchingPageContent: false,
            };
        }

        case "CLOSE_MODAL":
        case "@@router/LOCATION_CHANGE":
            return {
                ...state,
                podcastsByCategory: null,
            };

        default:
            return state;
    }
}

export default categoriesPageReducer;
