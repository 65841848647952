import { createContext, ReactElement, useCallback, useContext, useMemo, useReducer } from "react";

import { useEventListener } from "../../hooks";
import { UseSound, Dispatch, SoundState } from "./types";
import { SoundReducer, initalState } from "./reducer";

const SoundContext = createContext<UseSound | undefined>(undefined);

interface SoundProviderProps {
    children: ReactElement;
}

function SoundProvider({ children }: SoundProviderProps): ReactElement {
    const [state, dispatch] = useReducer(SoundReducer, initalState);
    useAttachEventListeners(state, dispatch);

    const load = useCallback(
        (id: string | number, url: string | undefined, startTime?: number) => {
            if (url === undefined) {
                throw new Error("Cannot load audio without stream url");
            }

            const audioAlreadyLoaded = state.loadedAudioList.some((x) => x.id === id);
            if (!audioAlreadyLoaded) {
                dispatch({ type: "LOAD_AUDIO", id: String(id), url, startTime });
            }
        },
        [state.currentAudio]
    );

    const play = useCallback(
        (id: string | number) => {
            const isPlaying = state.currentAudioId === id && !state.currentAudio?.paused;
            if (!isPlaying) {
                dispatch({ type: "PLAY_AUDIO", id: String(id) });
            }
        },
        [state.isPlaying]
    );

    const pause = useCallback(() => {
        if (state.isPlaying) {
            dispatch({ type: "PAUSE_AUDIO" });
        }
    }, [state.isPlaying]);

    const setPlayPosition = useCallback((timeInPercentage: number) => {
        dispatch({ type: "SEEK", timeInPercentage });
    }, []);

    const skipBackwards = useCallback(() => {
        dispatch({ type: "SKIP_BACKWARDS" });
    }, []);

    const skipForwards = useCallback(() => {
        dispatch({ type: "SKIP_FORWARDS" });
    }, []);

    const setVolume = useCallback((volume: number) => {
        dispatch({ type: "SET_VOLUME", volume });
    }, []);

    const toggleMuted = useCallback((prevVolume: number) => {
        dispatch({ type: "TOGGLE_MUTED", prevVolume });
    }, []);

    const value = useMemo<UseSound>(
        () => ({ state, load, play, pause, setPlayPosition, skipBackwards, skipForwards, setVolume, toggleMuted }),
        [state]
    );

    return <SoundContext.Provider value={value}>{children}</SoundContext.Provider>;
}

function useSound(): UseSound {
    const context = useContext(SoundContext);
    if (context === undefined) {
        throw new Error("useSound must be used within a SoundProvider");
    }
    return context;
}

function useAttachEventListeners(state: SoundState, dispatch: Dispatch) {
    const sound = state.currentAudio;

    if (typeof window !== "undefined" && "mediaSession" in window.navigator) {
        window.navigator.mediaSession.setActionHandler(
            "play",
            useCallback(() => {
                if (!state.isPlaying && state.currentAudioId) {
                    dispatch({ type: "PLAY_AUDIO", id: state.currentAudioId });
                }
            }, [state.isPlaying])
        );
        window.navigator.mediaSession.setActionHandler(
            "pause",
            useCallback(() => {
                if (state.isPlaying) {
                    dispatch({ type: "PAUSE_AUDIO" });
                }
            }, [state.isPlaying])
        );
        window.navigator.mediaSession.setActionHandler(
            "seekbackward",
            useCallback(() => dispatch({ type: "SKIP_BACKWARDS" }), [])
        );
        window.navigator.mediaSession.setActionHandler(
            "seekforward",
            useCallback(() => dispatch({ type: "SKIP_FORWARDS" }), [])
        );
    }

    useEventListener(
        "timeupdate",
        useCallback(() => {
            if (sound) {
                dispatch({ type: "UPDATE_CURRENT_POSITION", currentTime: sound.currentTime });
            }
        }, [sound]),
        sound
    );

    useEventListener(
        "pause",
        useCallback(() => {
            if (state.isPlaying) {
                dispatch({ type: "PAUSE_AUDIO" });
            }
        }, [state.isPlaying]),
        sound
    );

    useEventListener(
        "play",
        useCallback(() => {
            if (!state.isPlaying && state.currentAudioId) {
                dispatch({ type: "PLAY_AUDIO", id: state.currentAudioId });
            }
        }, [state.isPlaying]),
        sound
    );

    useEventListener(
        "seeked",
        useCallback(() => {
            if (sound) {
                setTimeout(() => {
                    dispatch({ type: "SEEKED", id: sound.id });
                }, 300);
            }
        }, [sound]),
        sound
    );

    useEventListener(
        "ended",
        useCallback(() => {
            dispatch({ type: "AUDIO_ENDED" });
        }, []),
        sound
    );

    useEventListener(
        "loadeddata",
        useCallback(() => {
            if (sound) {
                sound.currentTime = state.currentTime;

                setTimeout(() => {
                    dispatch({ type: "AUDIO_LOADED" });
                }, 300);
            }
        }, [sound]),
        sound
    );

    // useEventListener(
    //     "canplay",
    //     useCallback(() => {
    //         dispatch({ type: "CAN_PLAY" });
    //     }, []),
    //     sound
    // );
}

export { SoundProvider, useSound };
