import { SubscriptionState } from "@typings/index";
import { useQuery } from "@tanstack/react-query";
import routes from "..";
import { getIsStudentPlan } from "@utils/index";

export function useSubscriptionQuery(isAuthenticated: boolean) {
    return useQuery({
        queryKey: ["subscription"],
        queryFn: async () => {
            const subscription = await routes.getPremiumSubscriptions();

            if (!subscription || subscription.length === 0) {
                return { hasActiveSubscription: false, hasCancelledSubscription: false };
            }

            const activeSubscription = subscription?.find(
                (sub) =>
                    (sub.subscriptionType === 1 && sub.subscriptionPlan) ||
                    ((sub.subscriptionType === 0 || sub.subscriptionType === 2) && sub.subscriptionPlan === null)
            );

            const activeStudentSubscription = subscription?.find(
                (sub) => sub.subscriptionState !== SubscriptionState.Inactive && getIsStudentPlan(sub.subscriptionPlan)
            );

            const hasCancelledSubscription = subscription?.some(
                (sub) => sub.subscriptionType === 1 && sub.subscriptionPlan && sub.subscriptionState === SubscriptionState.Cancelled
            );

            return {
                hasActiveSubscription: Boolean(activeSubscription),
                hasActiveStudentSubscription: Boolean(activeStudentSubscription),
                hasCancelledSubscription,
                activeSubscription: activeSubscription,
                subscriptions: subscription,
            };
        },
        retry: false,
        staleTime: Infinity,
        enabled: isAuthenticated,
    });
}
