import { ReactElement, useMemo, useRef, useState } from "react";
import { Box, Flex, Image, Link } from "@chakra-ui/react";
import { IEpisode, IPodcastPromoCarousel } from "../redux/types";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import strings from "../../../locale/localization";
import { useCarousel, useMedia, useStructuredData } from "app/hooks";
import CarouselButton from "./CarouselButton";
import { carouselElementsGroup } from "../utils";
import { Helmet } from "react-helmet";
import { PodmeColor, StructuredDataContent } from "@typings/index";
import config from "app/config";
import { isEmpty } from "lodash";
import { uid } from "@utils/index";

interface Props {
    content: IPodcastPromoCarousel;
    width: number[];
    height: number[];
}

export default function PodcastPromoCarousel({ content, width, height }: Props): ReactElement {
    const { locale } = strings.routes;
    const { isSmallScreen } = useMedia();
    const { pathname } = useLocation();

    const carouselRef = useRef<HTMLDivElement>(null);
    const [carouselHover, setCarouselHover] = useState<boolean>(false);

    const CAROUSEL_STEP = 1;
    const CAROUSEL_ELEMENTS_GAP = 16;
    const CAROUSEL_ELEMENT_WIDTH = isSmallScreen ? width[0] : width[1];

    const { handleCarouselMove, bind, isFirstElementVisible, isLastElementVisible } = useCarousel(
        carouselRef,
        CAROUSEL_ELEMENT_WIDTH,
        CAROUSEL_ELEMENTS_GAP,
        CAROUSEL_STEP
    );

    const carouselElements = carouselElementsGroup(content.promotedPodcasts.filter(Boolean), CAROUSEL_STEP);

    const structuredData = useStructuredData(StructuredDataContent.PodcastList, {
        url: `${config.PUBLIC_URL}${pathname}`,
        podcastSection: content,
    });

    const structuredDataKey = useMemo(() => uid(), []);

    return (
        <>
            <Helmet>
                {!isEmpty(structuredData) && (
                    <script type="application/ld+json" key={structuredDataKey}>
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </Helmet>
            <Box position="relative" onMouseEnter={() => setCarouselHover(true)} onMouseLeave={() => setCarouselHover(false)}>
                <Flex
                    ref={carouselRef}
                    gridGap={CAROUSEL_ELEMENTS_GAP + "px"}
                    overflowX="scroll"
                    overflowY="hidden"
                    {...bind()}
                    padding={["1rem", "1rem 2rem"]}
                    style={{
                        scrollSnapType: "x mandatory",
                        scrollPaddingInline: isSmallScreen ? "1rem" : "2rem",
                        overscrollBehaviorInline: "contain",
                    }}
                    css={{
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                >
                    <CarouselButton
                        visible={!isFirstElementVisible && carouselHover && !isSmallScreen}
                        direction="left"
                        carouselElWidth={(CAROUSEL_ELEMENT_WIDTH / 3) * 4}
                        handleCarouselMove={() => handleCarouselMove("left")}
                    />
                    {carouselElements.map((group: any[], groupIdx: number) => (
                        <Flex
                            key={groupIdx}
                            gridGap={4}
                            style={{
                                scrollSnapAlign: "start",
                            }}
                        >
                            {group.map((el: IEpisode) => (
                                <Link
                                    as={NavLink}
                                    to={`/${locale}/${el.destinationPath}`}
                                    key={el.id}
                                    position="relative"
                                    _hover={{
                                        "> img": {
                                            transform: "scale(1.05)",
                                        },
                                        "&::after": {
                                            transform: "scale(1.05)",
                                        },
                                    }}
                                    _after={{
                                        content: content.isToplist
                                            ? `"${content.promotedPodcasts.findIndex((podcast) => podcast.id === el.id) + 1}"`
                                            : undefined,
                                        display: "block",
                                        position: "absolute",
                                        bottom: 0,
                                        left: "-0.75rem",
                                        fontSize: "7rem",
                                        fontWeight: 700,
                                        color: PodmeColor.BlackPearl,
                                        WebkitTextStroke: `1px ${PodmeColor.SoftWhite}`,
                                        lineHeight: "6rem",
                                        pointerEvents: "none",
                                        userSelect: "none",
                                        transition: "transform 100ms ease-in",
                                    }}
                                >
                                    <Image
                                        loading="lazy"
                                        key={el.id}
                                        src={el.imageUrl}
                                        alt={el.podcastTitle + " | " + el.title}
                                        height={height}
                                        width={width}
                                        minWidth={width}
                                        borderRadius="1rem"
                                        transition="all 100ms ease-in"
                                        boxShadow={`0 0 0 1px ${PodmeColor.Silver}20`}
                                    />
                                </Link>
                            ))}
                        </Flex>
                    ))}
                    <CarouselButton
                        visible={!isLastElementVisible && carouselHover && !isSmallScreen}
                        direction="right"
                        carouselElWidth={(CAROUSEL_ELEMENT_WIDTH / 3) * 4}
                        handleCarouselMove={() => handleCarouselMove("right")}
                    />
                </Flex>
            </Box>
        </>
    );
}
