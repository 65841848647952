export const SUBMIT_CONTACT_FORM = Symbol("SUBMIT_CONTACT_FORM");
export const SUBMIT_CONTACT_FORM_FAILED = Symbol("SUBMIT_CONTACT_FORM_FAILED");

export const SUBMIT_INTEREST_FORM = Symbol("SUBMIT_INTEREST_FORM");
export const SUBMIT_INTEREST_FORM_FAILED = Symbol("SUBMIT_INTEREST_FORM_FAILED");

export const CANCEL_RENEWAL = Symbol("CANCEL_RENEWAL");
export const CANCEL_RENEWAL_FAILED = Symbol("CANCEL_RENEWAL_FAILED");

export const REACTIVATE_RENEWAL = Symbol("REACTIVATE_RENEWAL");
export const REACTIVATE_RENEWAL_FAILED = Symbol("REACTIVATE_RENEWAL_FAILED");

export const CARD_INFO_LOADED = Symbol("CARD_INFO_LOADED");
export const CARD_INFO_LOADED_FAILED = Symbol("CARD_INFO_LOADED_FAILED");

export const WELCOME_FETCHED = Symbol("WELCOME_FETCHED");
export const WELCOME_FETCHED_ERROR = Symbol("WELCOME_FETCHED_ERROR");

export const CANCEL_CREDIT_CARD = Symbol("CANCEL_CREDIT_CARD");
export const CANCEL_CREDIT_CARD_FAILED = Symbol("CANCEL_CREDIT_CARD_FAILED");

// PODCAST
export const FETCHING_INITIAL_PODCAST_DATA = "FETCHING_INITIAL_PODCAST_DATA";
export const FETCHING_INITIAL_PODCAST_DATA_SUCCESS = "FETCHING_INITIAL_PODCAST_DATA_SUCCESS";
export const FETCHING_INITIAL_PODCAST_DATA_FAILED = "FETCHING_INITIAL_PODCAST_DATA_FAILED";

export const GET_PODCAST = "GET_PODCAST";

export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_FAILED = "GET_ALL_CATEGORIES_FAILED";
export const GET_PODCASTS_BY_CATEGORY = "GET_PODCASTS_BY_CATEGORY";
export const GET_PODCASTS_BY_CATEGORY_FAILED = "GET_PODCASTS_BY_CATEGORY_FAILED";
export const GET_MORE_PODCASTS_IN_CATEGORY = "GET_MORE_PODCASTS_IN_CATEGORY";

export const GET_SUBSCRIPTIONS = "global/GET_SUBSCRIPTIONS";
export const GET_SUBSCRIPTIONS_SUCCESS = "global/GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_FAILED = "global/GET_SUBSCRIPTIONS_FAILED";

export const SET_LOCALE = "global/SET_LOCALE";

export const GET_REGION_INFO = "GET_REGION_INFO";
export const GET_REGION_INFO_SUCCESS = "GET_REGION_INFO_SUCCESS";

export const SET_EXPERIMENT_VARIABLES = "SET_EXPERIMENT_VARIABLES";
export const SET_EXPERIMENTS = "global/SET_EXPERIMENTS";
