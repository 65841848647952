import { ReactElement, useEffect, useState } from "react";
import { Box, Button, Checkbox, Flex } from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";

import strings from "@locale/localization";
import InputField from "../InputField/InputField";
import { PrimaryButtonNew } from "../buttons";
import config from "../../config";
import { PodmeColor } from "../../types/index";

interface FormValues {
    email: string;
    password: string;
}

interface LoginFormProps {
    onSubmit: (values: FormValues) => void;
    resetPassword: () => void;
    isLoading?: boolean;
}

const SAVED_USERNAME = "saved_login_details";

export default function LoginForm({ onSubmit, resetPassword, isLoading }: LoginFormProps): ReactElement {
    const string = strings.loginPage;

    const [storedUsername, setStoredUsername] = useState<string>();
    const [isCheckboxChecked, setCheckboxChecked] = useState<boolean>(false);

    useEffect(() => {
        const username = localStorage.getItem(SAVED_USERNAME);
        if (username) setStoredUsername(username);
    }, []);

    useEffect(() => {
        if (storedUsername) {
            setCheckboxChecked(true);
        } else {
            setCheckboxChecked(false);
        }
    }, [storedUsername]);

    function saveAccountDetailsInStorage(email: string | undefined) {
        if (email) localStorage.setItem(SAVED_USERNAME, email);
    }

    function deleteAccountDetailsInStorage() {
        if (storedUsername) localStorage.removeItem(SAVED_USERNAME);
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{
                email: storedUsername ?? "",
                password: "",
            }}
            validate={(values) => {
                const errors: any = {};
                if (!values.email) {
                    errors.email = string.validation.empty_email;
                }
                if (values.email && !config.EMAIL_REGEX.test(values.email)) {
                    errors.email = string.validation.invalid_email;
                }
                if (!values.password) {
                    errors.password = string.validation.empty_password;
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                setTimeout(() => {
                    setSubmitting(false);
                    if (isCheckboxChecked) {
                        saveAccountDetailsInStorage(values.email);
                    } else {
                        deleteAccountDetailsInStorage();
                    }
                    onSubmit(values);
                }, 800);
            }}
        >
            {({ values, handleChange, handleBlur, isSubmitting, isValid, dirty }) => (
                <Form>
                    <Flex direction="column" gridGap="1rem">
                        <Flex direction="column" gridGap="1.5rem">
                            <Flex direction="column" gridGap="0.5rem">
                                <Field name="email">
                                    {({ meta }: FieldProps) => (
                                        <InputField
                                            name="email"
                                            type="email"
                                            label={string.email}
                                            value={values.email}
                                            error={meta.touched ? meta.error : undefined}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            minHeight="60px"
                                        />
                                    )}
                                </Field>
                                <Field name="password">
                                    {({ meta }: FieldProps) => (
                                        <InputField
                                            name="password"
                                            type="password"
                                            label={string.password}
                                            value={values.password}
                                            error={meta.touched ? meta.error : undefined}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            minHeight="60px"
                                        />
                                    )}
                                </Field>
                            </Flex>

                            <Box>
                                <PrimaryButtonNew
                                    type="submit"
                                    isLoading={isSubmitting || isLoading}
                                    disabled={Boolean(!isValid || !dirty)}
                                    borderRadius="1rem"
                                    fontSize="1rem"
                                    width="100%"
                                >
                                    {isSubmitting || isLoading ? string.loginInProcess : string.title}
                                </PrimaryButtonNew>
                            </Box>
                        </Flex>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            fontFamily="Inter"
                            sx={{
                                ".chakra-checkbox__control[data-checked]": {
                                    backgroundColor: PodmeColor.SoftWhite,
                                    borderColor: PodmeColor.SoftWhite,
                                },
                                ".chakra-checkbox__control[data-focus]": {
                                    boxShadow: `0 0 0 1px ${PodmeColor.Cyan}`,
                                },
                            }}
                        >
                            <Checkbox
                                isChecked={isCheckboxChecked}
                                iconColor={PodmeColor.BlackPearl}
                                onChange={(e) => setCheckboxChecked(e.target.checked)}
                                borderRadius="4px"
                                borderColor={PodmeColor.SoftWhite}
                                color={PodmeColor.SoftWhite}
                                sx={{
                                    span: {
                                        fontSize: "0.875rem",
                                        fontWeight: "500",
                                        color: PodmeColor.SoftWhite,
                                    },
                                }}
                            >
                                {string.rememberMe}
                            </Checkbox>
                            <Button
                                color={PodmeColor.SoftWhite}
                                fontWeight="500"
                                textDecoration="underline"
                                variant="link"
                                onClick={resetPassword}
                                fontSize="0.875rem"
                            >
                                {string.forgotPassword}
                            </Button>
                        </Box>
                    </Flex>
                </Form>
            )}
        </Formik>
    );
}
