import { call, put, takeLatest, select, all } from "redux-saga/effects";
import routes from "../api";
import {
    GetCmsContent,
    GetCmsContentSuccess,
    GET_CMS_CONTENT,
    GET_CMS_CONTENT_FAILED,
    GET_CMS_CONTENT_SUCCESS,
    GET_FOOTER_PAGES_CONTENT,
    GET_FOOTER_PAGES_CONTENT_FAILED,
    GET_FOOTER_PAGES_CONTENT_SUCCESS,
    GET_STARTPAGE_CONTENT_SUCCESS,
    GET_STARTPAGE_CONTENT_FAILED,
    GET_STARTPAGE_CONTENT,
    GetStartpageSuccess,
    GetStartpageContent,
    CmsContent,
    GET_WEB_PAGE_SUCCESS,
    GET_WEB_PAGE_FAILED,
    GET_WEB_PAGE,
    GetWebPage,
} from "./contentTypes";
import { RootState } from "app/reducers/rootReducer";
import { ContentType, IEpisode, IPodcast, SectionContent } from "@containers/Homescreen/redux/types";
import { WEB_PAGE_TYPE } from "@typings/index";

const isPreviewSelector = (state: RootState) => state.content.isPreview;

export function* getFooterPages({ locale }: any): any {
    try {
        const data = yield call(routes.getFooterpageContent, locale);

        yield put({ type: GET_FOOTER_PAGES_CONTENT_SUCCESS, content: data });
    } catch (error) {
        yield put({ type: GET_FOOTER_PAGES_CONTENT_FAILED, error });
    }
}

export function* getWebPage({ options }: GetWebPage): any {
    try {
        const data = yield call(routes.getWebPage, options);

        yield put({ type: GET_WEB_PAGE_SUCCESS, content: data });
    } catch (error) {
        yield put({ type: GET_WEB_PAGE_FAILED, error });
    }
}

export function* getContent({ region }: GetCmsContent): any {
    try {
        const isPreview = yield select(isPreviewSelector);

        const data: CmsContent = yield call(routes.getCmsContent, region, isPreview);

        yield put<GetCmsContentSuccess>({ type: GET_CMS_CONTENT_SUCCESS, content: data });
    } catch (error) {
        yield put({ type: GET_CMS_CONTENT_FAILED });
    }
}

export function* getStartpage({ region }: GetStartpageContent): any {
    try {
        const data = yield call(routes.getWebPage, {
            region,
            type: WEB_PAGE_TYPE.START_PAGE,
        });
        const startPageResponse = data[0];

        // Find the index of the Carousel section
        const mainCarouselSectionIndex = startPageResponse.layoutComponents.findIndex(
            (section: SectionContent) => section.type === ContentType.ListOfHeroCards
        );
        const podcastCarouselSectionIndex = startPageResponse.layoutComponents.findIndex(
            (section: SectionContent) => section.type === ContentType.PodcastCarousel
        );

        // If the Carousel section exists, fetch the episode and podcast data. This is because the Start Page
        // is no longer fetched through the CMS service but through our BFF service. The Contentful Client does not
        // return all the data we need for the Main Carousel section.
        if (mainCarouselSectionIndex !== -1) {
            const mainCarouselSection = startPageResponse.layoutComponents[mainCarouselSectionIndex];
            const updatedHeroCards = yield all(
                mainCarouselSection.heroCards.map(function* (card: { type: string; typeId: number }) {
                    if (card.type === "episode") {
                        const episode: IEpisode = yield call(routes.getEpisode, card.typeId);
                        return {
                            ...card,
                            ...episode,
                        };
                    } else {
                        const podcast: IPodcast = yield call(routes.getPodcastById, card.typeId);
                        return {
                            ...card,
                            ...podcast,
                        };
                    }
                })
            );
            mainCarouselSection.heroCards = updatedHeroCards;
            startPageResponse.layoutComponents[mainCarouselSectionIndex] = mainCarouselSection;
        }

        if (podcastCarouselSectionIndex !== -1) {
            const podcastCarouselSection = startPageResponse.layoutComponents[podcastCarouselSectionIndex];
            const updatedPodcasts = yield all(
                podcastCarouselSection.podcasts.map(function* (carouselElement: { podcastId: number }) {
                    const podcast: IPodcast = yield call(routes.getPodcastById, carouselElement.podcastId);
                    return {
                        ...carouselElement,
                        ...podcast,
                    };
                })
            );
            podcastCarouselSection.podcasts = updatedPodcasts;
            startPageResponse.layoutComponents[podcastCarouselSectionIndex] = podcastCarouselSection;
        }

        yield put<GetStartpageSuccess>({ type: GET_STARTPAGE_CONTENT_SUCCESS, content: data });
    } catch (error) {
        yield put({ type: GET_STARTPAGE_CONTENT_FAILED });
    }
}

export default [
    takeLatest(GET_WEB_PAGE, getWebPage),
    takeLatest(GET_FOOTER_PAGES_CONTENT, getFooterPages),
    takeLatest(GET_CMS_CONTENT, getContent),
    takeLatest(GET_STARTPAGE_CONTENT, getStartpage),
];
