import { Box, Modal, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/reducers/rootReducer";
import MainBox from "./components/MainBox";
import SuccessBox from "./components/SuccessBox";
import {
    DISMISS_MIGRATION_MODAL_PROMPT,
    GET_MIGRATION_MODAL_PROMPT,
    OPEN_MIGRATION_MODAL_PROMPT,
} from "@containers/Migration/redux/types";
import ErrorBox from "./components/ErrorBox";
import { LOGOUT_USER_WITH_ERROR } from "@auth/authTypes";
import strings from "@locale/localization";
import { PodmeColor } from "app/types";
import { useLocation } from "react-router-dom";

export interface IErrorContent {
    title: string;
    message: string;
    primaryBtn: string;
    secondaryBtn?: string;
    email: string;
    isErrorGeneric: boolean;
    alreadyMigrated?: boolean;
}

const migrationModalSelector = (state: RootState) => state.migration;
const userSelector = (state: RootState) => state.auth;

const MigrationModal: FC = (): ReactElement | null => {
    const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: true });
    const [errorContent, setErrorContent] = useState<IErrorContent>({
        title: "",
        message: "",
        email: "",
        primaryBtn: "",
        secondaryBtn: undefined,
        isErrorGeneric: false,
        alreadyMigrated: false,
    });
    const { isVisible, isMigrationCompleted, isDismissed, isError, errorCode, migratedPodmeAccount, isHard, canMigrate } =
        useSelector(migrationModalSelector);
    const { isAuthenticated } = useSelector(userSelector);
    const { me03, alreadyMigrated, generic } = strings.migration.errorBox;

    const dispatch = useDispatch();

    const location = useLocation();

    const handleOpenMigrationModal = () => {
        dispatch({ type: OPEN_MIGRATION_MODAL_PROMPT });
        onOpen();
    };

    useEffect(() => {
        if (isHard && !migratedPodmeAccount && canMigrate && !location.pathname.includes("schibsted-faq")) {
            handleOpenMigrationModal();
        }
    }, [isHard, canMigrate, location.pathname]);

    useEffect(() => {
        if (isAuthenticated) {
            if (isError) {
                dispatch({ type: LOGOUT_USER_WITH_ERROR });
            } else {
                dispatch({ type: GET_MIGRATION_MODAL_PROMPT });
            }
        }
    }, [isAuthenticated, isError]);

    useEffect(() => {
        if (isVisible || isError) onOpen();
    }, [isVisible, isError]);

    useEffect(() => {
        if (migratedPodmeAccount) {
            setErrorContent((state) => ({
                ...state,
                title: alreadyMigrated.title,
                message: alreadyMigrated.body,
                primaryBtn: alreadyMigrated.logoutBtn,
                alreadyMigrated: true,
            }));
        } else if (isError) {
            switch (errorCode) {
                case "ME-03":
                    setErrorContent((state) => ({
                        ...state,
                        title: me03.title,
                        message: me03.body,
                        email: me03.email,
                        primaryBtn: me03.closeBtn,
                    }));
                    break;
                default:
                    setErrorContent((state) => ({
                        ...state,
                        title: generic.title,
                        message: generic.body,
                        email: generic.email,
                        primaryBtn: generic.logoutBtn,
                        secondaryBtn: generic.retryBtn,
                        isErrorGeneric: true,
                    }));
                    break;
            }
        }
    }, [isError, errorCode, migratedPodmeAccount]);

    useEffect(() => {
        if (isDismissed) onClose();
    }, [isDismissed]);

    const dismissModal = () => {
        dispatch({ type: DISMISS_MIGRATION_MODAL_PROMPT });

        if (!isDismissed) return;
        onClose();
    };

    const onSuccessModalClose = () => {
        if (typeof window !== "undefined") window.location.reload();
        onClose();
    };

    return isVisible && isAuthenticated && !isError && !migratedPodmeAccount ? (
        <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={isMigrationCompleted ? onClose : dismissModal}
            motionPreset={"slideInBottom"}
            autoFocus={false}
            isCentered
        >
            <ModalOverlay zIndex="9998" bgColor={PodmeColor.Woodsmoke} opacity="0.75 !important" />
            <Box
                sx={{
                    "> div > .chakra-modal__content-container": {
                        zIndex: "9999",
                    },
                }}
            >
                <ModalContent
                    color={PodmeColor.SoftWhite}
                    display={"flex"}
                    alignContent={"center"}
                    maxW={"44rem"}
                    borderRadius={"1.5rem"}
                    mx={["0", "0.5rem"]}
                    backgroundColor={PodmeColor.Cinder}
                    zIndex="9999 !important"
                >
                    {isMigrationCompleted ? (
                        <SuccessBox onClose={onSuccessModalClose} />
                    ) : (
                        <MainBox isMigrationHard={isHard} onClose={onClose} dismissModal={dismissModal} />
                    )}
                </ModalContent>
            </Box>
        </Modal>
    ) : (isError || migratedPodmeAccount) && isVisible ? (
        <ErrorBox errorContent={errorContent} />
    ) : null;
};

export default MigrationModal;
