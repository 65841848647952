import { Subscription } from ".";

type MaintainerTeam = "convert" | "attract"; // used as key to query in appInsights

export const UNKNOWN_ADYEN_PRICE = "Unknown Adyen Price";

export interface UnknownAdyenPriceEvent {
    maintainer: MaintainerTeam;
    subscription: Subscription;
}

export interface UnknownAdyenPriceEventAction {
    type: typeof UNKNOWN_ADYEN_PRICE;
    event: UnknownAdyenPriceEvent;
}
