import { getMimeTypeFromUrl } from "@utils/index";
import { useEffect, useRef } from "react";
import { PlayObject } from "../redux/GlobalPlayerTypes";

const useSetMediaData = (playObject: PlayObject | null): void => {
    const mediaSessionRef = useRef<MediaSession>();

    useEffect(() => {
        if (playObject) {
            const imageMimeType = getMimeTypeFromUrl(playObject.imageUrl);

            const metadata = new MediaMetadata({
                title: playObject.title,
                artist: playObject.podcastTitle,
                artwork: [
                    { src: playObject.imageUrl, sizes: "96x96", type: imageMimeType },
                    { src: playObject.imageUrl, sizes: "128x128", type: imageMimeType },
                    { src: playObject.imageUrl, sizes: "192x192", type: imageMimeType },
                    { src: playObject.imageUrl, sizes: "256x256", type: imageMimeType },
                    { src: playObject.imageUrl, sizes: "384x384", type: imageMimeType },
                    { src: playObject.imageUrl, sizes: "512x512", type: imageMimeType },
                ],
            });

            if ("mediaSession" in navigator) {
                mediaSessionRef.current = navigator.mediaSession;
                mediaSessionRef.current.metadata = metadata;
            }
        }
    }, [playObject?.id]);
};

export default useSetMediaData;
