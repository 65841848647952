import { OfferTypeKey } from "@typings/index";
import { getEnvoySession, isOnStudentFlow, uid } from "@utils/index";
import {
    VIEW_ITEM,
    ViewItemEvent,
    ViewItemEventAction,
    SelectItemEventAction,
    SELECT_ITEM,
    SelectItemEvent,
    BeginCheckoutEvent,
    BeginCheckoutEventAction,
    BEGIN_CHECKOUT,
    AddPaymentInfoEventAction,
    ADD_PAYMENT_INFO,
    AddPaymentInfoEvent,
    PurchaseEventAction,
    PurchaseEvent,
    PURCHASE,
    COUPON_INTERACTION,
    CouponInteractionEvent,
    AddToCartEvent,
    ADD_TO_CART,
    AddToCartEventAction,
    REWARD_VOUCHER_INTERACTION,
    RewardVoucherInteractionEvent,
} from "./trackingTypes";

export function viewItemEventAction(variant: string, packageId: string, currency: string, price: number): ViewItemEventAction {
    const event: ViewItemEvent = {
        event: "view_item",
        ecommerce: {
            currency: currency,
            value: price,
            items: [
                {
                    item_id: packageId,
                    item_name: "package",
                    item_category: "package",
                    item_variant: variant,
                    price: price,
                    currency: currency,
                    quantity: 1,
                },
            ],
        },
    };

    return { type: VIEW_ITEM, event };
}

export function selectItemEventAction(variant: string, packageId: string, currency: string, price: number): SelectItemEventAction {
    const event: SelectItemEvent = {
        event: "select_item",
        ecommerce: {
            items: [
                {
                    item_id: packageId,
                    item_name: "package",
                    item_category: "package",
                    item_variant: variant,
                    price: price,
                    currency: currency,
                    quantity: 1,
                },
            ],
        },
    };

    return { type: SELECT_ITEM, event };
}

export function addToCartEventAction(
    episodeId: string,
    episodeTitle: string,
    podcastTitle: string,
    is_premium: boolean,
    logged_in: boolean
): AddToCartEventAction {
    const event: AddToCartEvent = {
        event: "add_to_cart",
        ecommerce: {
            items: [
                {
                    item_id: episodeId,
                    item_name: episodeTitle,
                    item_brand: podcastTitle,
                    quantity: 1,
                },
            ],
        },
        user: {
            is_premium,
            logged_in,
        },
    };

    return { type: ADD_TO_CART, event };
}

export function beginCheckoutEventAction(
    packageId: string,
    currency: string,
    price: number,
    userId: string,
    offerType: OfferTypeKey
): BeginCheckoutEventAction {
    const { is_on_envoy_flow: envoy_sharing_flow } = getEnvoySession();
    const student_flow = isOnStudentFlow();

    const event: BeginCheckoutEvent = {
        event: "begin_checkout",
        ecommerce: {
            offer_type: offerType,
            envoy_sharing_flow,
            student_flow,
            currency: currency,
            value: price,
            user_id: userId,
            items: [
                {
                    item_id: packageId,
                    item_name: "package",
                    item_category: "package",
                    item_variant: "",
                    price: price,
                    currency: currency,
                    quantity: 1,
                },
            ],
        },
    };

    return { type: BEGIN_CHECKOUT, event };
}

export function addPaymentInfoEventAction(
    paymentType: string,
    packageId: string,
    currency: string,
    price: number,
    userId: string,
    offerType: OfferTypeKey,
    coupon?: string
): AddPaymentInfoEventAction {
    const { is_on_envoy_flow: envoy_sharing_flow } = getEnvoySession();
    const student_flow = isOnStudentFlow();

    const event: AddPaymentInfoEvent = {
        event: "add_payment_info",
        ecommerce: {
            offer_type: offerType,
            envoy_sharing_flow,
            student_flow,
            currency,
            value: price,
            payment_type: paymentType === "scheme" ? "card" : paymentType,
            user_id: userId,
            coupon,
            items: [
                {
                    item_id: packageId,
                    item_name: "package",
                    item_category: "package",
                    item_variant: "",
                    price: price,
                    currency,
                    quantity: 1,
                    coupon,
                },
            ],
        },
    };

    localStorage.setItem("paymentMethod", event.ecommerce.payment_type);

    return { type: ADD_PAYMENT_INFO, event };
}

export function purchaseEventAction(
    packageId: string,
    currency: string,
    price: number,
    userId: string,
    offerType: OfferTypeKey,
    coupon?: string
): PurchaseEventAction {
    const paymentType = localStorage.getItem("paymentMethod");
    const student_flow = isOnStudentFlow();
    const event: PurchaseEvent = {
        event: "purchase",
        ecommerce: {
            offer_type: offerType,
            student_flow,
            transaction_id: uid(),
            value: price,
            currency: currency,
            payment_type: paymentType,
            coupon: coupon,
            user_id: userId,
            items: [
                {
                    item_id: packageId,
                    item_name: "package",
                    item_category: "package",
                    item_variant: "",
                    price: price,
                    currency: currency,
                    quantity: 1,
                    coupon: coupon,
                },
            ],
        },
    };

    localStorage.removeItem("paymentMethod");

    return { type: PURCHASE, event };
}

export function couponInteractionEvent(
    type: typeof COUPON_INTERACTION | typeof REWARD_VOUCHER_INTERACTION,
    couponName: string
): CouponInteractionEvent | RewardVoucherInteractionEvent {
    return {
        type,
        couponName,
    };
}
