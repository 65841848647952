import config from "app/config";

export class BrazeService {
    private static instance: BrazeService;

    private constructor() {
        const brazeSDK = this.getBrazeSDK();
        brazeSDK?.initialize(config.BRAZE_API_KEY, {
            baseUrl: config.BRAZE_SDK_ENDPOINT,
            enableLogging: false,
        });
    }

    public static getInstance(): BrazeService {
        if (!BrazeService.instance) {
            BrazeService.instance = new BrazeService();
        }

        return BrazeService.instance;
    }

    public changeUser(userSdrn: string) {
        if (!userSdrn) return;
        const brazeSDK = this.getBrazeSDK();
        brazeSDK?.changeUser(userSdrn);
        brazeSDK?.openSession();
    }

    public setUserAttributes(attributes: { key: string; value: number | string | boolean }[]) {
        const brazeSDK = this.getBrazeSDK();
        const user = brazeSDK?.getUser();
        if (user && attributes?.length > 0) {
            attributes.forEach(({ key, value }) => {
                user.setCustomUserAttribute(key, value);
            });
        }
    }

    private getBrazeSDK() {
        if (!typeof window && !typeof window.navigator) return;
        const brazeSDK = require("@braze/web-sdk");
        return brazeSDK;
    }
}
