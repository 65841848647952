import React, { ReactElement, useState } from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import strings from "@locale/localization";
import { CSSstylesObject, DeviceBreakpoints, PodmeColor } from "../../../../types/index";

interface Props {
    isFollowing: boolean;
    followAction: any;
    unfollowAction: any;
    podcastId: any;
    openDialog: any;
}

const styles: CSSstylesObject = {
    bookmarkIcon: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        sx: {
            span: {
                fontSize: "1em",
                marginRight: "5px",
            },

            i: {
                fontSize: "1.2em",
            },

            "&:hover": {
                color: PodmeColor.White,
            },
            [`@media ${DeviceBreakpoints.MediumDown}`]: {
                opacity: 1,
                fontSize: "0.9em",
            },
        },
    },
};

function FollowButton({ isFollowing, followAction, unfollowAction, podcastId, openDialog }: Props): ReactElement {
    const [isActive, setIsActive] = useState<boolean>(false);
    const string = strings.bookmarkButton;

    return (
        <Box
            as="span"
            {...styles.bookmarkIcon}
            data-testid="bookmark-icon"
            color={PodmeColor.SoftWhite}
            onClick={(evt) => {
                evt.preventDefault();

                if (isFollowing || isActive) {
                    setIsActive(false);
                    if (typeof openDialog === "function") {
                        openDialog(podcastId);
                    } else {
                        unfollowAction(podcastId);
                    }
                } else {
                    setIsActive(true);
                    followAction(podcastId);
                }
            }}
        >
            {isFollowing ? (
                <>
                    <Box as="span" data-testid="checked" color={PodmeColor.Silver}>
                        {string.unfollow}
                    </Box>
                    <Flex rounded="50%" bgColor={PodmeColor.Cyan} padding="2px">
                        <Image src="/assets/images/icons/check-black.svg" />
                    </Flex>
                </>
            ) : (
                <>
                    <Box as="span">{string.follow}</Box>
                    <Flex rounded="50%" bgColor={PodmeColor.SoftWhite} padding="2px">
                        <Image src="/assets/images/icons/check-black.svg" />
                    </Flex>
                </>
            )}
        </Box>
    );
}

export default React.memo(FollowButton);
