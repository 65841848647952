import { Box } from "@chakra-ui/react";
import { CSSstylesObject, DeviceBreakpoints, PodmeColor } from "../../types/index";
import { ReactElement, useState } from "react";
import strings from "@locale/localization";

const styles: CSSstylesObject = {
    infoMessage: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        background: PodmeColor.MaintenanceYellow,
        padding: "1rem",
        color: PodmeColor.BlackPearl,
        zIndex: 1001,
        sx: {
            [`@media ${DeviceBreakpoints.SmallOnly}`]: { padding: "2.5rem 1rem 1rem" },
        },
    },
    infoMessageContainer: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "0.9em",
        width: "80%",
        margin: "0 auto",
        lineHeight: "1.5",
    },
    infoMessageCloseButton: {
        position: "absolute",
        right: "1rem",
        top: "1rem",
        width: "22px",
        height: "22px",
        opacity: 0.5,
        display: "inline-block",
        cursor: "pointer",
        zIndex: 10,
    },
    infoMessageCloseButtonAfterAndBefore: {
        position: "absolute",
        right: "calc(50% - 1px)",
        content: '" "',
        height: "22px",
        width: "2px",
        backgroundColor: PodmeColor.BlackPearl,
    },
};

function InfoMessage(): ReactElement | null {
    const [isHidden, setIsHidden] = useState<boolean>(false);

    const text = strings.startPage.infoMessage;

    if (isHidden) return null;

    return (
        <Box {...styles.infoMessage}>
            <Box
                as={"span"}
                {...styles.infoMessageCloseButton}
                _after={{ ...styles.infoMessageCloseButtonAfterAndBefore, transform: "rotate(-45deg)" }}
                _before={{ ...styles.infoMessageCloseButtonAfterAndBefore, transform: "rotate(45deg)" }}
                _hover={{ opacity: 1 }}
                onClick={() => setIsHidden(true)}
            />
            <Box as={"p"} {...styles.infoMessageContainer}>
                {strings.formatString(text, "❤️")}
            </Box>
        </Box>
    );
}

export default InfoMessage;
