import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Image, Text } from "@chakra-ui/react";
import strings from "@locale/localization";
import Skeleton from "@components/Skeleton";
import { ExplorerPodcastCategory } from "app/content/contentTypes";
import { getCategoryPageUrl } from "@utils/index";
import { PodmeColor } from "@typings/index";
import { capitalize } from "lodash";

interface Props {
    category: ExplorerPodcastCategory;
    onLoad: () => void;
}

export default function Category({ category, onLoad }: Props): ReactElement {
    const categoryUrl = getCategoryPageUrl(category as ExplorerPodcastCategory);
    const { imageUrl, name } = category;

    const { locale, categories } = strings.routes;

    const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

    const handleImageLoad = () => {
        setIsImageLoaded(() => true);
        onLoad();
    };

    // onLoad is called when the image is loaded, but if the image is cached, it won't be called
    // so we need to check if the image is cached and if it is, we need to set isImageLoaded to true manually
    useEffect(() => {
        const imagePerformanceEntry = window.performance.getEntriesByName(imageUrl)[0] as PerformanceResourceTiming;

        if (imagePerformanceEntry && imagePerformanceEntry.transferSize === 0) {
            setIsImageLoaded(true);
        }
    }, []);

    return (
        <Link to={`/${locale}/${categoryUrl}`} style={{ display: "block", width: "100%", height: "100%" }}>
            <Skeleton isLoaded={typeof window === "undefined" ? true : isImageLoaded} width="100%" height="100%" borderRadius="0.5rem">
                <Box position="relative" borderRadius="0.5rem" overflow="hidden" bg="gray.700" width="100%" height="100%">
                    <Image
                        src={imageUrl}
                        alt={`${capitalize(categories)} - ${name}`}
                        width="100%"
                        height="100%"
                        objectFit="cover"
                        filter="brightness(0.4)"
                        onLoad={handleImageLoad}
                        onError={handleImageLoad}
                    />
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        bgColor={PodmeColor.Cinder}
                        border={`1px solid ${PodmeColor.Silver}20`}
                        opacity={0.75}
                        rounded="0.5rem"
                        _hover={{
                            bgColor: PodmeColor.Sand,
                            opacity: 0.5,
                        }}
                    />
                    <Text
                        as="span"
                        pointerEvents="none"
                        position="absolute"
                        bottom={0}
                        padding="0.5rem"
                        fontWeight="800"
                        color={PodmeColor.SoftWhite}
                        fontFamily="Garnett, sans-serif"
                    >
                        {name}
                    </Text>
                </Box>
            </Skeleton>
        </Link>
    );
}
