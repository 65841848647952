import { call, put, takeLatest } from "redux-saga/effects";
import { createDeleteRequest, createPutRequest } from "@api/index";
import types, {
    CancelRenewal,
    CancelRenewalFailed,
    CancelRenewalSuccess,
    ReactivateRenewalFailed,
    ReactivateRenewalSuccess,
} from "./SettingsPageTypes";
import strings from "app/locale/localization";

const string = strings.settingsPage.userSubscriptions;
const errorString = strings.errors;

export function* cancelSubscription({ productId }: CancelRenewal): any {
    try {
        if (productId) {
            yield call(createDeleteRequest, `subscription/podcast/${productId}`);
        } else {
            yield call(createDeleteRequest, `subscription/package`);
        }

        return yield put<CancelRenewalSuccess>({
            type: types.CANCEL_RENEWAL_SUCCESS,
            showToastSuccessMessage: true,
            message: string.subscriptionHasBeenCancelled,
        });
    } catch (error) {
        yield put<CancelRenewalFailed>({
            type: types.CANCEL_RENEWAL_FAILED,
            showToastErrorMessage: true,
            message: errorString.general,
        });
    }
}

export function* reactivateSubscription(): any {
    try {
        yield call(createPutRequest, `subscription/package`);

        return yield put<ReactivateRenewalSuccess>({
            type: types.REACTIVATE_RENEWAL_SUCCESS,
            showToastSuccessMessage: true,
            message: string.subcriptionHasBeenRenewed,
        });
    } catch (error) {
        yield put<ReactivateRenewalFailed>({
            type: types.REACTIVATE_RENEWAL_FAILED,
            showToastErrorMessage: true,
            message: errorString.renewalFailed,
        });
    }
}

export default [takeLatest(types.REACTIVATE_RENEWAL, reactivateSubscription), takeLatest(types.CANCEL_RENEWAL, cancelSubscription)];
