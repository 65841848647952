import React, { ReactElement } from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    Text,
} from "@chakra-ui/react";
import { PodmeColor } from "../../types/index";
import { SecondaryButton, TertiaryButton } from "@components/buttons";

interface Props {
    buttonText: string;
    buttonType: string;
    deleteCta: string;
    cancel: string;
    title: string;
    message: string;
    deleteAction: () => void;
}

function DeleteDialog({ buttonText, buttonType, deleteAction, title, message, deleteCta, cancel }: Props): ReactElement {
    const [isOpen, setIsOpen] = React.useState(false);
    const onClose = () => setIsOpen(false);
    const cancelRef = React.useRef(null);

    return (
        <>
            <Button
                variant={buttonType}
                color={PodmeColor.LightRed}
                fontWeight="400"
                onClick={() => setIsOpen(true)}
                size="sm"
                data-testid="remove-payment-method-button"
            >
                {buttonText}
            </Button>

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay background={PodmeColor.Cinder + "95"}>
                    <AlertDialogContent
                        display="flex"
                        flexDirection="column"
                        gridGap="1.5rem"
                        bgColor={PodmeColor.Cinder}
                        rounded="1.5rem"
                        p="1.5rem"
                    >
                        <AlertDialogHeader p="0" fontSize={["1rem", "1.5rem"]} fontWeight="bold" color={PodmeColor.SoftWhite}>
                            {title}
                        </AlertDialogHeader>

                        <AlertDialogBody p="0" color={PodmeColor.Silver}>
                            <Text>{message}</Text>
                        </AlertDialogBody>

                        <AlertDialogFooter display="flex" gridGap="1rem" p="0">
                            <TertiaryButton onClick={onClose}>{cancel}</TertiaryButton>
                            <SecondaryButton
                                onClick={() => {
                                    deleteAction();
                                    onClose();
                                }}
                            >
                                {deleteCta}
                            </SecondaryButton>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}

export default DeleteDialog;
